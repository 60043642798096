import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-tag-input",
  templateUrl: "./tag-input.component.html",
  styleUrls: ["./tag-input.component.scss"],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TagInputComponent },
  ],
})
export class TagInputComponent implements ControlValueAccessor {
  @Input() label;
  @Input() tags: string[];
  @Output() onChange: EventEmitter<string[]> = new EventEmitter();

  ngOnInit(): void {
    this.tags = [];
  }

  add($event) {
    this.markAsTouched();
    if (this.disabled) return;

    const value: string = $event.target.value;

    if (!value || !value.trim()) return;

    this.tags.push($event.target.value);
    $event.target.value = "";

    this.onChange.emit([...this.tags]);
    this.change(this.tags);
  }

  change = (tags) => {
    return;
  };

  onTouched = () => {
    return;
  };
  touched = false;

  disabled = false;

  addByButton($event) {
    this.markAsTouched();
    if (this.disabled) return;

    const value: string = $event.value;

    if (!value || !value.trim()) return;

    this.tags.push($event.value);
    $event.value = "";

    this.onChange.emit([...this.tags]);
    this.change(this.tags);
  }

  remove(index) {
    this.markAsTouched();

    if (this.disabled) return;
    this.tags = this.tags.filter((_tag, i) => i !== index);

    this.onChange.emit(this.tags);
    this.change(this.tags);
  }

  writeValue(tags: string[]): void {
    this.tags = tags || [];
  }
  registerOnChange(fn: any): void {
    this.change = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
