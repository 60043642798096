import { EducationLevelModel } from "src/app/core/models/education-level.model";

export const EDUCATION_LEVEL:EducationLevelModel[] = [
  {
    id:1,
    name:"Fundamental"
  },
{
    id:2,
    name:"Médio"
  },{
    id:3,
    name:"Superior (Graduação)"
  },{
    id:4,
    name:"Pós-graduação"
  },{
    id:5,
    name:"Mestrado"
  },{
    id:6,
    name:"Doutorado"
  },
]
