import { IRadarChart } from "./IRadarChart";

export const radarChartResponse: IRadarChart = {
  competences: [
    "Relacionamento com cliente",
    "Auto Gestão",
    "Vendas",
    "Controle de Risco",
    "Poder de convencimento",
  ],
  datasets: {
    currentCycle: [5, 2, 5, 3, 3],
    previousCycle: [3, 3, 5, 2, 0],
  },
  evolutionPercentage: 15.38,
  currentCycleAverage: 3.75,
  previousCycleAverage: 3.25,
};
