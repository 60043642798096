<section id="drop-down-menu">
  <article class="list-options">
    <div class="row list-options-content m-15">
      <span *ngIf="header" class="info">{{ header }}:</span>
      <div class="form-check" *ngFor="let item of menuItems">
        <div class="cursor-pointer" [ngClass]="{'disabled': item.disabled}" (click)="onSelectItem(item)">
          <ng-container *ngTemplateOutlet="items; context: { $implicit: item }">

          </ng-container>
        </div>
      </div>
    </div>
  </article>
</section>
