import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-remove-button",
  templateUrl: "./remove-button.component.html",
  styleUrls: ["./remove-button.component.scss"],
})
export class RemoveButtonComponent {

  @Output() OnClick = new EventEmitter<any>();

  onClick() {
    this.OnClick.emit("remove clicked");
  }
}
