import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CompanyService } from "src/app/core/services/company.service";

@Component({
  selector: "app-documentation-step",
  templateUrl: "./documentation-step.component.html",
  styleUrls: ["./documentation-step.component.scss"],
})
export class DocumentationStepComponent {
  @Input() documentation;
  @Input() isContributorProfile = false;
  @Input() isExhibition = false;
  @Input() schoolRemovedId;
  @Input() mandatoryDocuments;

  @Output() back = new EventEmitter();
  @Output() next = new EventEmitter<number>();

  @Output() IdCardValue = new EventEmitter();
  @Output() CpfValue = new EventEmitter();
  @Output() WorkCardValue = new EventEmitter();
  @Output() BirthMarriageCertificateDoc = new EventEmitter();
  @Output() VoterRegistrationValue = new EventEmitter();
  @Output() AddressProofDocument = new EventEmitter();
  @Output() AddGenericDocument = new EventEmitter();

  @Output() SaveSchoolValue = new EventEmitter();
  @Output() DeleteSchoolValue = new EventEmitter();
  @Output() logout = new EventEmitter();

  private genericDocumentationExceptions = [
    "CPF",
    "RG",
    "CERTIDAO_CASAMENTO_NASCIMENTO",
    "COMPROVANTE_RESIDENCIA",
    "CTPS",
    "TITULO_ELEITOR",
    "FORMACAO_ACADEMICA",
  ];

  ufs$ = this.companyService?.getAllCountries(" ");

  constructor(private companyService: CompanyService) {}

  getIdCardValue(payload) {
    this.IdCardValue.emit(payload);
  }

  getCpfValue(payload) {
    this.CpfValue.emit(payload);
  }

  getWorkCardfValue(payload) {
    this.WorkCardValue.emit(payload);
  }

  getBirthMarriageCertificateDoc(payload) {
    this.BirthMarriageCertificateDoc.emit(payload);
  }

  getVoterRegistration(payload) {
    this.VoterRegistrationValue.emit(payload);
  }

  getAddressProofDocument(payload) {
    this.AddressProofDocument.emit(payload);
  }

  getSaveSchool(payload) {
    this.SaveSchoolValue.emit(payload);
  }

  deleteSchool(payload) {
    this.DeleteSchoolValue.emit(payload);
  }

  logoutEmit() {
    this.logout.emit();
  }

  canShowGenericDocument(name) {
    return !this.genericDocumentationExceptions.includes(name);
  }

  getMandatoryDocumentValue(mandatoryDocuments: any[],item:any) {
    return mandatoryDocuments?.find(doc => doc.documentId === item.id);
  }

}
