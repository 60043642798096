import { Component, Input, SimpleChanges } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { BenefitsService } from "src/app/core/services/portal-admission/benefits.service";
import { EmployeesHiringService } from "src/app/core/services/portal-admission/employees-hiring.service";
import { Categories } from "../../../core/models/categories.enum";

@Component({
  selector: "app-parking-accordion",
  templateUrl: "./parking-accordion.component.html",
  styleUrls: ["./parking-accordion.component.scss"],
})
export class ParkingAccordionComponent {
  @Input() benefits;
  selectedChoice: string;
  selectedChoiceNine: string;
  selectedChoiceTen: string;
  planControl = new FormControl();
  isDisabled = true;
  form: FormGroup;""
  response: any;

  constructor(
    private benefitsService: BenefitsService,
    private authService: AuthenticationService,
    private employeesHiringService: EmployeesHiringService,
    private fb: FormBuilder
  ) {}
  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  selected: boolean;
  discount: string;
  selectedPlanName: string;
  plans: any[] = [];

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    // this.getEmployeesHiring();
    this.form = this.fb.group({
      vehicleType: [null, Validators.required],
      model: [null, Validators.required],
      color: [null, Validators.required],
      licensePlate: [null, Validators.required],
      badgeNumber: [null],
    });

    this.submitForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  // getEmployeesHiring() {
  //   this.employeesHiringService
  //     .getEmployeesHiring(this.employeeId)
  //     .subscribe((res: any) => {
  //       const id = res.hiringProcessId;
  //       this.hiringProcessId = id;

  //       this.getBenefits(id);
  //     });
  // }


  getBenefits(benefits: any) {
    if (!benefits) return;
    // this.benefitsService.getBenefits(id).subscribe(
    // (response: any) => {
     this.response = benefits;
    if (this.response.benefitTypes && this.response.benefitTypes.length > 0) {
      for (const type of this.response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.Estacionamento
        );

        if (foundBenefit) {
          this.benefit = foundBenefit;
          this.selected = foundBenefit.wantTheBenefit;
          this.discountValue = foundBenefit.existDiscountToHolder;
          this.discount = foundBenefit.existDiscountToHolder;
          this.benefits = foundBenefit.items?.map((item) => ({
            name: item.name,
            index: item.index,
          }));

          if (
            foundBenefit.wantTheBenefit &&
            foundBenefit.item &&
            foundBenefit.item.index
          ) {
            const selectedPlan = this.benefits.find(
              (plan) => plan.index === foundBenefit.item.index
            );
            if (selectedPlan) {
              this.selectedPlanName = selectedPlan.name;
              this.selectedChoice = foundBenefit.wantTheBenefit ? "sim" : "nao";
            }
            this.selectedChoice = foundBenefit.wantTheBenefit ? "sim" : "nao";
          }
        }
      }
    }
    // },
    //     (error) => {
    //       console.error(
    //         "Error fetching benefits:",
    //         error.message || JSON.stringify(error)
    //       );
    //     }
    //   );
  }
  setBenefitChoice(value){
   console.log(this.response)
   console.log(this.benefit)
  }

  submitForm() {
    if (this.form.valid) {
      const id = this.hiringProcessId;
      const body = this.form.value;

      this.benefitsService.PostBenefitsValue(id, body).subscribe(
        (response: any) => {
          console.log("POST successful:", response);

          if (response && response.someField) {
            this.form.get("vehicleType").setValue(response.someField);
            this.form.get("model").setValue(response.someOtherField);
          }
        },
        (error) => {
          console.error("Error while making POST request:", error);
        }
      );
    }
  }
  postDefaultData(data: any) {
    const id = this.hiringProcessId; // Certifique-se de que este ID está definido
    this.benefitsService.PostBenefitsValue(id, data).subscribe(
      (response: any) => {
        console.log("POST successful:", response);
      },
      (error) => {
        console.error("Error while making POST request:", error);
      }
    );
  }
}
