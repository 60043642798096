<section class="card">
  <article class="card-body">
    <div class="row d-flex align-items-center">
      <div class="flex-shrink">
        <span class="text-default-label m-2 d-inline">
          {{ currentValueWith }}
        </span>
      </div>
      <div class="col-11">
        <div class="progress">
          <div
            class="progress-bar animated-progess"
            [ngClass]="progressbarClass"
            role="progressbar"
            aria-valuemin="0"
            [attr.aria-valuenow]="currentValue"
            [attr.aria-valuemax]="maxValue"
            [style.width]="currentValueWith"
          ></div>
        </div>
      </div>
    </div>
  </article>
</section>
