<section class="row justify-content-center">
  <article class="col-sm-12 topbar">
    <img
      src="../../../assets/images/meurh360-white-logo1.png"
      height="52"
      alt="logo-meurh-pequeno"
      class="logo-sm"
    />
  </article>
  <article class="col-sm-12 front-cover">
    <img src="../../../assets/images/logo-politica-xg.png" alt="logo-meurh" />
    <span class="text-uppercase policy">politica de privaciade</span>
  </article>
  <article class="col-sm-10 text-align-start">
    <p>
      <strong>1. Contexto</strong>
      A presente Política de privacidade e uso de cookies tem como objetivo
      fornecer orientações sobre como ocorre o tratamento (coleta,
      armazenamento, uso, etc.) dos cookies relativos aos usuários que utilizam
      as funcionalidades disponíveis portal Meu RH 360. Cookies são arquivos
      auxiliares geridos pelo seu navegador para “memorizar” a visita a páginas
      na internet.
    </p>
    <p>
      <strong> 2. Definição</strong><br />
      ANONIMIZAÇÃO: Utilização de meios técnicos, razoáveis e disponíveis no
      momento do tratamento de dados pessoais, por meio dos quais um dado perde
      a possibilidade de associação, direta ou indireta, a um indivíduo. O dado
      anonimizado não é considerado dado pessoal para os fins da LGPD.
      COOKIES: Cookies são, na terminologia da informática, pequenos arquivos de
      texto depositados por um site servidor no computador do cliente usuário
      para “memorizar” algumas informações relativas àquela navegação. DADO
      PESSOAL: Informação relacionada a pessoa natural identificada ou
      identificável. Também são considerados dados pessoais aqueles utilizados
      para formação do perfil comportamental de determinada pessoa natural. LEI
      GERAL DE PROTEÇÃO DE DADOS PESSOAIS (“LGPD”):  Lei nº 13.709, de 14 de
      agosto de 2018 que dispõe sobre o tratamento de dados pessoais em meios
      digitais ou físicos realizados por pessoa natural ou por pessoa jurídica,
      de direito público ou privado, tendo como objetivo defender os titulares
      de dados pessoais e ao mesmo tempo permitir o uso dos dados para
      finalidades diversas, equilibrando interesses e harmonizando a proteção
      humana com o desenvolvimento tecnológico e econômico. TITULAR DE DADOS
      PESSOAIS (“TITULAR”): Pessoa natural a quem se referem os dados pessoais
      que são objeto de tratamento. TRATAMENTO DE DADOS PESSOAIS
      (“TRATAMENTO”): Toda operação realizada com dados pessoais, como as que se
      referem a coleta, produção, recepção, classificação, utilização, acesso,
      reprodução, transmissão, distribuição, processamento, arquivamento,
      armazenamento, eliminação, avaliação, controle da informação, modificação,
      comunicação, transferência, difusão ou extração.
    </p>
    <p>
      <strong>3. Escopo</strong><br />
      A presente Política estabelece as informações relativas ao uso
      de cookies no portal Meu RH 360, especialmente no que concerne à relação
      com dados pessoais dos Usuários durante a sua navegação. Utiliza-se como
      referência, quando aplicável, a Lei Geral de Proteção de Dados Pessoais,
      entre outras normas nacionais e internacionais relativas à privacidade e
      proteção de dados pessoais.
    </p>
    <p>
      <strong>4. Aplicabilidade</strong><br />
      Esta Política busca conferir a transparência e a compreensão, pelos
      usuários, sobre as possíveis maneiras como os seus dados pessoais podem
      ser tratados, por meio dos cookies, em sua interação com o nosso portal.
    </p>

    <p>
      <strong> 5. Objetivos</strong><br />
      São objetivos da Política de Cookies do Portal Meu RH 360: Estabelecer a
      transparência e as diretrizes que assegurem e reforcem o compromisso da
      Instituição com o cumprimento das legislações de proteção de dados
      pessoais aplicáveis; e Informar aos Usuários sobre como ocorrem os
      tratamentos das suas informações e dados pessoais, por meio de cookies,
      durante o uso do Portal Meu RH 360.
    </p>

    <p>
      <strong>6. Política de Privacidade e uso de Cookies</strong><br />
      Este site é mantido e operado por DCBI Consulting. Nós coletamos e
      utilizamos alguns dados pessoais que pertencem àqueles que utilizam nosso
      site. Ao fazê-lo, agimos na qualidade de controlador desses dados e
      estamos sujeitos às disposições da Lei Federal n. 13.709/2018 (Lei Geral
      de Proteção de Dados Pessoais - LGPD). Nós cuidamos da proteção de seus
      dados pessoais e, por isso, disponibilizamos esta política de privacidade,
      que contém informações importantes sobre: - Quem deve utilizar nosso site
      - Quais dados coletamos e o que fazemos com eles; - Seus direitos em
      relação aos seus dados pessoais; e - Como entrar em contato conosco.
    </p>

    <p>
      <strong>6.1. Dados que coletamos e motivos da coleta</strong><br />
      Nosso site coleta e utiliza alguns dados pessoais de nossos usuários, de
      acordo com o disposto nesta seção.
    </p>
    <p>
      <strong
        >6.1.1. Dados pessoais fornecidos expressamente pelo usuário</strong
      ><br />
      Nós coletamos os seguintes dados pessoais que nossos usuários nos fornecem
      expressamente ao utilizar nosso site: - Nome Completo; - CPF; - Endereço
      de e-mail; - Número de Telefone; - Mensagens de Contato; - Data de
      Nascimento; - RG; - CTPS; - CHN. A coleta destes dados ocorre nos
      seguintes momentos: - Quando o usuário faz seu cadastro no site; - Quando
      o usuário é cadastrado por uma empresa; - Quando o usuário ingressa em um
      processo seletivo e/ou é aprovado. Os dados fornecidos por nossos usuários
      são coletados com as seguintes finalidades: - Participação em processo
      seletivo; - Gestão de colaboradores de uma empresa pelo RH.
    </p>

    <p>
      <strong> 6.1.2. Dados pessoais obtidos de outras formas</strong><br />
      Nós coletamos os seguintes dados pessoais de nossos usuários: - Endereço
      de IP; A coleta destes dados ocorre nos seguintes momentos: - Quando o
      usuário efetua login/logout no site. Estes dados são coletados com as
      seguintes finalidades: - Garantir a segurança, a autenticidade e a
      rastreabilidade da conexão do usuário; - Cumprir a determinação legal de
      armazenamento de registros de acesso a aplicações.
    </p>
    <p>
      <strong>6.1.3. Dados sensíveis</strong><br />
      Não serão coletados dados sensíveis de nossos usuários, assim entendidos
      aqueles definidos nos arts. 11 e seguintes da Lei de Proteção de Dados
      Pessoais. Assim, não haverá coleta de dados sobre origem racial ou étnica,
      convicção religiosa, opinião política, filiação a sindicato ou a
      organização de caráter religioso, filosófico ou político, dado referente à
      saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a
      uma pessoa natural.
    </p>

    <p>
      <strong>6.1.4. Dados de crianças e adolescentes</strong><br />
      Nós coletamos os seguintes dados de crianças e adolescentes: - Nome
      Completo; - CPF; - RG - Data Nascimento; - Filiação. A coleta de dados de
      crianças e adolescentes acontece nos seguintes momentos: - No momento em
      que é realizado o cadastro de dependentes do usuário para fins de gestão
      de benefícios pelo RH da Empresa. Os dados de crianças e de adolescentes
      que coletamos são utilizados exclusivamente com as seguintes finalidades:
      - Gestão de benefícios e trabalhistas pelo RH da empresa contratante. O
      tratamento de dados de crianças e adolescentes é realizado com base no
      melhor interesse da criança ou do adolescente.
    </p>

    <p>
      <strong>6.1.5. Cookies</strong><br />
      Cookies são pequenos arquivos de texto baixados automaticamente em seu
      dispositivo quando você acessa e navega por um site. Eles servem,
      basicamente, para seja possível identificar dispositivos, atividades e
      preferências de usuários. Os cookies não permitem que qualquer arquivo ou
      informação sejam extraídos do disco rígido do usuário, não sendo possível,
      ainda, que, por meio deles, se tenha acesso a informações pessoais que não
      tenham partido do usuário ou da forma como utiliza os recursos do site. a.
      Cookies do site Os cookies do site são aqueles enviados ao computador ou
      dispositivo do usuário e administrador exclusivamente pelo site. As
      informações coletadas por meio destes cookies são utilizadas para melhorar
      e personalizar a experiência do usuário, sendo que alguns cookies podem,
      por exemplo, ser utilizados para lembrar as preferências e escolhas do
      usuário, bem como para o oferecimento de conteúdo personalizado. b.
      Cookies de terceiros Alguns de nossos parceiros podem configurar cookies
      nos dispositivos dos usuários que acessam nosso site. Estes cookies, em
      geral, visam possibilitar que nossos parceiros possam oferecer seu
      conteúdo e seus serviços ao usuário que acessa nosso site de forma
      personalizada, por meio da obtenção de dados de navegação extraídos a
      partir de sua interação com o site. O usuário poderá obter mais
      informações sobre os cookies de terceiro e sobre a forma como os dados
      obtidos a partir dele são tratados, além de ter acesso à descrição dos
      cookies utilizados e de suas características, acessando o seguinte link:
      Google Analytics:
      https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage;
      O Google Analytics é um serviço que permite compreender melhor como os
      Usuários interagem com o Portal Meu RH 360. Para a prestação desse
      serviço, o Google Analytics coleta alguns dados, como aqueles relacionados
      ao dispositivo/navegador, endereço IP, geolocalização, as atividades no
      portal, dentre outras possibilidades. É possível que o Google compartilhe
      tais dados com partes terceiras no caso de haver uma obrigação legal, ou
      no caso em que partes terceiras tratem os dados em nome do Google. c.
      Gestão de cookies O usuário poderá se opor ao registro de cookies pelo
      site, bastando que desative esta opção no seu próprio navegador. Mais
      informações sobre como fazer isso em alguns dos principais navegadores
      utilizados hoje podem ser acessadas a partir dos seguintes links: Internet
      Explorer:
      https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
      Safari: https://support.apple.com/pt-br/guide/safari/sfri11471/mac Google
      Chrome: https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt
      Mozila Firefox:
      https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
      Opera: https://www.opera.com/help/tutorials/security/privacy/ A
      desativação dos cookies, no entanto, pode afetar a disponibilidade de
      algumas ferramentas e funcionalidades do site, comprometendo seu correto e
      esperado funcionamento. Outra consequência possível é remoção das
      preferências do usuário que eventualmente tiverem sido salvas,
      prejudicando sua experiência.
    </p>

    <p>
      <strong>6.1.6. Coleta de dados não previstos expressamente</strong><br />
      Eventualmente, outros tipos de dados não previstos expressamente nesta
      Política de Privacidade poderão ser coletados, desde que sejam fornecidos
      com o consentimento do usuário, ou, ainda, que a coleta seja permitida com
      fundamento em outra base legal prevista em lei. Em qualquer caso, a coleta
      de dados e as atividades de tratamento dela decorrentes serão informadas
      aos usuários do site.
    </p>
    <p>
      <strong>6.2. Compartilhamento de dados pessoais com terceiros</strong
      ><br />
      Nós não compartilhamos seus dados pessoais com terceiros. Apesar disso, é
      possível que o façamos para cumprir alguma determinação legal ou
      regulatória, ou, ainda, para cumprir alguma ordem expedida por autoridade
      pública.
    </p>

    <p>
      <strong
        >6.3. Por quanto tempo seus dados pessoais serão armazenados</strong
      ><br />
      Os dados pessoais coletados pelo site são armazenados e utilizados por
      período de tempo que corresponda ao necessário para atingir as finalidades
      elencadas neste documento e que considere os direitos de seus titulares,
      os direitos do controlador do site e as disposições legais ou regulatórias
      aplicáveis. Uma vez expirados os períodos de armazenamento dos dados
      pessoais, eles são removidos de nossas bases de dados ou anonimizados,
      salvo nos casos em que houver a possibilidade ou a necessidade de
      armazenamento em virtude de disposição legal ou regulatória.
    </p>

    <p>
      <strong>6.4. Bases legais para o tratamento de dados pessoais</strong
      ><br />
      Cada operação de tratamento de dados pessoais precisa ter um fundamento
      jurídico, ou seja, uma base legal, que nada mais é que uma justificativa
      que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais. Todas
      as Nossas atividades de tratamento de dados pessoais possuem uma base
      legal que as fundamenta, dentre as permitidas pela legislação. Mais
      informações sobre as bases legais que utilizamos para operações de
      tratamento de dados pessoais específicas podem ser obtidas a partir de
      nossos canais de contato, informados ao final desta Política. Os titulares
      de dados pessoais tratados por nós poderão exercer seus direitos por meio
      do formulário disponibilizado no seguinte caminho:
      https://meurh360/formulario-lgpd. Alternativamente, se desejar, o titular
      poderá enviar um e-mail ou uma correspondência ao nosso Encarregado de
      Proteção de Dados Pessoais. As informações necessárias para isso estão na
      seção "Como entrar em contato conosco" desta Política de Privacidade. Os
      titulares de dados pessoais tratados por nós poderão exercer seus direitos
      a partir do envio de mensagem ao nosso Encarregado de Proteção de Dados
      Pessoais, seja por e-mail ou por correspondência. As informações
      necessárias para isso estão na seção "Como entrar em contato conosco"
      desta Política de Privacidade. Para garantir que o usuário que pretende
      exercer seus direitos é, de fato, o titular dos dados pessoais objeto da
      requisição, poderemos solicitar documentos ou outras informações que
      possam auxiliar em sua correta identificação, a fim de resguardar nossos
      direitos e os direitos de terceiros. Isto somente será feito, porém, se
      for absolutamente necessário, e o requerente receberá todas as informações
      relacionadas.
    </p>

    <p>
       <strong>6.5. Medidas de segurança no tratamento de dados pessoais</strong
      ><br />
      Empregamos medidas técnicas e organizativas aptas a proteger os dados
      pessoais de acessos não autorizados e de situações de destruição, perda,
      extravio ou alteração desses dados. As medidas que utilizamos levam em
      consideração a natureza dos dados, o contexto e a finalidade do
      tratamento, os riscos que uma eventual violação geraria para os direitos e
      liberdades do usuário, e os padrões atualmente empregados no mercado por
      empresas semelhantes à nossa. Entre as medidas de segurança adotadas por
      nós, destacamos as seguintes: - Os dados pessoais e sensíveis dos
      usuários, além das respectivas senhas de acesso são armazenados utilizando
      hashes criptográficos ou anonimizados; - O acesso ao banco de dados é
      restrito a aplicação e a pessoas habilitadas no estrito cumprimento de
      suas atividades laborais. Ainda que adote tudo o que está ao seu alcance
      para evitar incidentes de segurança, é possível que ocorra algum problema
      motivado exclusivamente por um terceiro - como em caso de ataques de
      hackers ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que
      ocorre, por exemplo, quando ele mesmo transfere seus dados a terceiro.
      Assim, embora sejamos, em geral, responsáveis pelos dados pessoais que
      tratamos, nos eximimos de responsabilidade caso ocorra uma situação
      excepcional como essas, sobre as quais não temos nenhum tipo de controle.
      De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que
      possa gerar risco ou dano relevante para qualquer de nossos usuários,
      comunicaremos os afetados e a Autoridade Nacional de Proteção de Dados
      acerca do ocorrido, em conformidade com o disposto na Lei Geral de
      Proteção de Dados.
    </p>

    <p>
      <strong>6.6. Como entrar em contato conosco</strong><br />
      Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou
      sobre os dados pessoais que tratamos, entre em contato com nosso
      Encarregado de Proteção de Dados Pessoais, por alguns dos canais
      mencionados abaixo: E-mail: contato@meurh360.com.br Endereço postal: Av. 9
      de Julho, 3.229 - Conj. 1102 - Jardins, São Paulo - SP 
    </p>

    <p>
      <strong>6.7. Alterações nesta política</strong><br />
      A presente versão desta Política de Privacidade foi atualizada pela última
      vez em: 06/10/2022. Reservamo-nos o direito de modificar, a qualquer
      momento, as presentes normas, especialmente para adaptá-las às eventuais
      alterações feitas em nosso site, seja pela disponibilização de novas
      funcionalidades, seja pela supressão ou modificação daquelas já
      existentes. Sempre que houver uma modificação, nossos usuários serão
      notificados acerca da mudança.
    </p>
  </article>
</section>
