<button
  type="button"
  class="btn btn-outline-secondary action-button m-1"
  id="dropdownManual"
  [ngClass]="{ 'background-gray': secondBgColor }"
  (click)="myDrop.open()"
  [disabled]="disabled"
>
  <i class="mdi mdi-dots-vertical"></i>
</button>
<div ngbDropdown #myDrop="ngbDropdown">
  <div ngbDropdownMenu aria-labelledby="dropdownManual">
    <ng-container *ngTemplateOutlet="items"> </ng-container>
  </div>
</div>
