import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  HostListener,
} from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnChanges {
  @Input() isExhibition = false;
  @Input() showModal = false;
  @Input() selectedImage: string | undefined;
  @Input() selectedPdf: SafeResourceUrl | undefined;
  @Input() allowedExtensions = ["png", "jpeg", "jpg", "pdf"];
  @Output() selectFile = new EventEmitter<string | null>();
  @Output() selectFileWithExtension = new EventEmitter<any | null>();
  showInvalidFileFormat = false;
  showFileSizeExceededError = false;
  fileSizeLimit = 5 * 1024 * 1024;

  dragged = false;
  fileName = "";
  currentFile: any;
  // aaa = "";

  ngOnChanges() {
    // this.aaa = this.selectedImage;
    if (!this.selectedImage || this.selectedImage?.includes(';base64,null') || this.selectedImage?.includes(';base64,undefined')) {
      this.selectedImage = undefined;
    }
    if (this.selectedImage === "data:image/png;base64,undefined"){
      this.selectedImage = undefined;
    }

    if (this.selectedImage?.includes("application/pdf")) {
      this.selectedPdf = this.selectedImage;
      this.selectedImage = undefined;
    }
  }

  get getAllowedFormats() {
    return this.allowedExtensions?.reduce(
      (prev, curr) => prev + " | " + curr,
      ""
    );
  }

  handleFileSelect(event: any): void {
    this.showInvalidFileFormat = false;
    this.showFileSizeExceededError = false;

    const files = event.target.files;

    if (files && files.length > 0) {
      for (const file of files) {
        this.currentFile = file;

        if (this.isFileExtensionInvalid(file, this.allowedExtensions)) {
          this.showInvalidFileFormat = true;
          break;
        }

        if (this.isFileSizeInvalid(file, this.fileSizeLimit)) {
          this.showFileSizeExceededError = true;
          break;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
          const base64 = (await this.convertToBase64(file)).split(",")[1];
          this.selectFile.emit(base64);
          const fileName = file.name;
          const extension = fileName.lastIndexOf('.') === -1 ? null : fileName.substring(fileName.lastIndexOf('.') + 1);
          this.selectFileWithExtension.emit({
            base64: base64,
            type: file.type,
            fileName: fileName,
            extension: extension,
          });
        };

        reader.readAsDataURL(file);
      }
    }
  }

  handleDropFile(files: any): void {
    this.dragged = true;
    this.fileName = files[0]?.name;

    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64 = (await this.convertToBase64(file)).split(",")[1];
        this.selectFile.emit(base64);
      };
      reader.readAsDataURL(file);
    }
  }

  removeFile(): void {
    this.selectedImage = undefined;
    this.selectedPdf = undefined;
    this.selectFile.emit(undefined);
    this.selectFileWithExtension.emit({});
    this.dragged = false;
  }

  openModal(): void {
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  errorHandler($event) {
    console.log($event);
    console.log(this.selectedImage);
    this.selectedImage = "../../../../assets/images/alert.png";
  }
  @HostListener("document:keydown", ["$event"])
  onKeyDown($event) {
    if ($event.key === "Escape" || $event.key === "Esc") {
      this.showModal = false;
    }
  }

  private isFileExtensionInvalid = (file: File, allowedTypes: string[]) => {
    if (file) {
      const fileType = file.type.split("/")[1];
      return !allowedTypes.includes(fileType);
    }
    return false;
  };

  private isFileSizeInvalid = (file: File, maxSize: number) =>
    file.size > maxSize;
}
