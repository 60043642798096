<header class="navbar fixed-top bg-light p-3 mb-3">
    <div class="container-fuid">
        <div class="d-flex flex-wrap align-items-center justify-content-between">
            <div>
                <a routerLink="/" class="d-block">
                    <img src="assets/images/rh360-white-logo2.png" alt="" height="60">
                </a>
            </div>
        </div>
    </div>
</header>
