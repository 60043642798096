import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment'
@Component({
  selector: 'app-show-list',
  templateUrl: './show-list.component.html',
  styleUrls: ['./show-list.component.css']
})
export class ShowListComponent {
  @Input() itemList: any[]; // Listagem a ser interada.
  @Input() propShow: string = "informe uma prop que deseja apresentar de 1 item da listagem itemList";
  @Input() textInfoPlural: string = "texto de aopio para o usuário clicar e mostrar o restante do conteúdo";
  @Input() textInfoSingular: string = "texto de aopio para o usuário clicar e mostrar o restante do conteúdo";

  public listItens: any[] = [];
  public environment = environment;

  public showThisQuantity(): any[] {
    const items: any[] = this.itemList.length > this.environment.maxShowItemsList ? this.itemList.slice(0, this.environment.maxShowItemsList) : this.itemList;
    return items;
  }

  public showList(item) {
    item.clicked = true;
    this.listItens = [];
    const items: any[] = this.itemList.slice(this.environment.maxShowItemsList, this.itemList.length);
    this.listItens.push(...items);
  }

  public getNestedProperty(obj: any, propertyPath: string) {
    const properties = propertyPath.split('.');
    let currentObject = obj;
    for (let property of properties) {
      if (currentObject.hasOwnProperty(property)) {
        currentObject = currentObject[property];
      } else {
        console.log("Property not find");
        return undefined;
      }
    }
    return currentObject;
  }
}
