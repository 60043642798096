import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
@Component({
  selector: "app-add-new",
  templateUrl: "./add-new.component.html",
  styleUrls: ["./add-new.component.scss"],
})
export class AddNewComponent {
  @Input() buttonLabel: string;
  @Input() title: string;
  @Input() toggleStatus: boolean;
  @Input() showToggle = true;
  @Input() showAddButton = false;
  @Input() showMoreOptionsButton = true;
  @Input() showRemoveAll = true;
  @Input() withParamter = false;
  @Input() showCustomButton = false;
  @Input() customLabel: string;
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() clickAddNew: EventEmitter<void> = new EventEmitter();
  @Output() clickRemoveAll: EventEmitter<void> = new EventEmitter();
  @Output() customButtomAction: EventEmitter<void> = new EventEmitter();
}
