<app-accordion class="my-1" [title]="'Plano Odontológico'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">Titular</label>
      <div class="w-100 row mx-0 align-items-end">
        <label class="select-plantext">Selecione o plano</label>
        <ng-select [(ngModel)]="selectedPlan" (change)="onPlanChange()">
          <ng-option *ngFor="let item of benefit.items" [value]="item">
            {{ item.name }}
          </ng-option>
        </ng-select>

        <div class="col-4">
          <button
            class="btn btn-outline-primary mt-2"
            type="button"
            (click)="openCoverageModal()"
          >
            VER COBERTURA
          </button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-3 col-12 col-sale" *ngIf="selectedPlan">
          <label class="text-valuedesc">Valor </label>
          {{ selectedPlan?.cost | currency : "BRL" : "symbol" }}
        </div>
        <div class="row mt-4"></div>
        <div class="col-md-3 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ selectedPlan?.holderDiscount > 0 ? "Sim" : "Não" }}</span>
        </div>
        <div class="col-md-4 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>

          <ng-container *ngIf="selectedPlan?.holderDiscountType === 2">
            <span>{{ selectedPlan?.holderDiscount }}%</span>
          </ng-container>
          <ng-container *ngIf="selectedPlan?.holderDiscountType === 1">
            <span>{{
              selectedPlan?.holderDiscount | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>

        <div class="col-md-4 col-12 col-benefic">
          <label class="text-benefictransp">Deseja aderir ao beneficio</label>
          <div class="custom-checkbox-container">
            <label class="custom-checkbox">
              <input
                type="radio"
                name="choiceTre"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="false"
                (change)="setWantBenefit()"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Não
            </label>

            <label class="custom-checkbox">
              <input
                type="radio"
                name="choiceTre"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="true"
                [disabled]="!selectedPlan"
                (change)="setWantBenefit()"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Sim
            </label>
          </div>
        </div>
      </div>
      <ng-container *ngIf="benefit?.wantTheBenefit">
        <div class="row position-rowdepen">
          <label class="text-default-label text-dep mt-3 mb-2"
            >Dependentes</label
          >
          <label class="select-plantext"
            >Selecione os dependentes que deseja incluir</label
          >
          <div class="col-md-12 px-0">
            <ng-select
              multiple="true"
              [(ngModel)]="selectedDependents"
              placeholder="Selecione os dependentes"
              [notFoundText]="'Nenhum dependente cadastrado'"
              (change)="setWantBenefitDependent()"
            >
              <ng-option
                *ngFor="let item of selectedPlan?.includedDependents"
                [value]="item"
                >{{ item.name }}</ng-option
              >
            </ng-select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-4 col-12 col-sale">
            <label class="text-sale">Desc. dependente</label>
            <span>{{ selectedPlan?.existDependentDiscount }}</span>
          </div>
          <div class="col-md-4 col-12 col-value">
            <label class="text-valuedesc">Valor ou % do desconto</label>

            <ng-container *ngIf="selectedPlan?.dependentDiscountType === 2">
              <span>{{ selectedPlan?.dependentDiscount }}% por dependente</span>
            </ng-container>
            <ng-container *ngIf="selectedPlan?.dependentDiscountType === 1">
              <span
                >{{
                  selectedPlan?.dependentDiscount | currency : "BRL" : "symbol"
                }}
                por dependente</span
              >
            </ng-container>
          </div>
          <div class="col-md-3 col-12 col-benefic">
            <label class="text-benefictransp"
              >Deseja incluir dependentes</label
            >
            <div class="custom-checkbox-container">
              <label class="custom-checkbox">
                <input
                  type="radio"
                  name="addDependents"
                  [value]="false"
                  [(ngModel)]="benefit.wantTheBenefitForDependents"
                  [disabled]="!selectedDependents?.length"
                  (change)="setWantBenefitDependent()"
                />
                <span class="checkmark">
                  <span class="checkmark-inner"></span>
                </span>
                Não
              </label>

              <label class="custom-checkbox">
                <input
                  type="radio"
                  name="addDependents"
                  [value]="true"
                  [disabled]="!selectedDependents?.length"
                  [(ngModel)]="benefit.wantTheBenefitForDependents"
                  (change)="setWantBenefitDependent()"
                />
                <span class="checkmark">
                  <span class="checkmark-inner"></span>
                </span>
                Sim
              </label>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="col-md-12 col-12 px-0 position-totalbenef">
        <label class="text-valuedesc">Desconto total do benefício</label>
        <p>R$ {{ getTotalDiscount }} / mês</p>
      </div>
    </div>
    <!-- <div class="w-100 d-flex justify-content-end px-2 my-3">
      <button class="btn btn-outline-primary" (click)="openApprovalModal()">
        SOLICITAR APROVAÇÃO DO RH
      </button>
    </div> -->
  </div>

  <app-new-modal
    [title]="'Detalhes das coberturas'"
    [modalOpen]="showModal"
    (closeModalEvent)="showModal = false"
  >
    <div class="modal-content custom-modal">
      <div class="row">
        <div class="col-12 mt-2" *ngIf="selectedPlan">
          <h4 class="title-t">Detalhes da Cobertura</h4>
          <div *ngFor="let coverage of selectedPlan.planCoverage">
            <p>{{ coverage.name }}</p>
            <div *ngFor="let planCoverageItem of coverage.planCoverage">
              <p>- {{ planCoverageItem.name }}</p>
            </div>
          </div>
        </div>
        <div class="custom-checkbox-container">
          <div class="col-md-12 mt-3 text-align-end justify-content-end">
            <button class="btn btn-secondary position-btn" type="button">
              VOLTAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </app-new-modal>
</app-accordion>
