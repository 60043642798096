<section id="tag-input">
  <article class="input-group input-group-lg tag-content">
    <label for="label"
           class="col-md-12 text-default-label">{{label}}</label>
    <div class="tagbox">
      <div class="tag-items">
        <span *ngFor="let tag of tags;index as i"
              class="tag-item">
          <i class="bx bx-x"
             (click)="remove(i)"></i>
          {{tag}}
        </span>
      </div>
      <input type="text"
             name="tag-value"
             id="tag-value"
             (keyup.enter)="add($event)"
             #tag
             placeholder="Digite o nome e pressione enter">
    </div>
  </article>
</section>
