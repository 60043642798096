import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-accordion",
  templateUrl: "./accordion.component.html",
  styleUrls: ["./accordion.component.scss"],
})
export class AccordionComponent {
  iconClass = "mdi-chevron-down";

  @Input() title: string;
  @Input() iconTitle: string;
  @Input() isExpanded: boolean;

  @Output() isExpandedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  toggleAccordion(): void {
    this.isExpanded = !this.isExpanded;
    this.iconClass = this.isExpanded ? "mdi-chevron-up" : "mdi-chevron-down";
    this.isExpandedChange.emit(this.isExpanded);
  }
}
