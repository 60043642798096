<app-accordion [title]="'CPF'">
  <div class="d-flex flex-column">
    <app-form-card>
      <form [formGroup]="formCpf">
        <div class="row my-5">
          <div class="col-md-6">
            <label for="" class="text-default-label">Tipo de documento*</label>
            <ng-select class="" formControlName="documentType">
              <ng-option
                *ngFor="let type of documentType"
                [value]="type.value"
                >{{ type.name }}</ng-option
              >
            </ng-select>
            <small class="text-danger" *ngIf="isInvalidField('documentType')"
              >Por favor, selecione o tipo de documento.</small
            >
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-md-12">
          <label class="text-default-label">Carregar Foto*</label>
          <app-upload-file
            [isExhibition]="isExhibition"
            [selectedImage]="cpfDocument && 'data:application/'+ cpfDocumentExtension +';base64,' + cpfDocument"
            (selectFileWithExtension)="getDoc($event)"
          >
          </app-upload-file>
          <small class="text-danger" *ngIf="!cpfDocument"
            >Por favor, carregue o CPF.</small
          >
        </div>
      </div>

      <div
        class="d-flex w-100 justify-content-end align-itens-center py-4"
        *ngIf="!isExhibition"
      >
        <button
          class="btn btn-outline-primary text-uppercase"
          (click)="emitValue()"
        >
          salvar
        </button>

        <button
          type="button"
          class="btn btn-outline-secondary text-uppercase m-1"
          (click)="downloadFile()"
        >
          baixar documento
        </button>
      </div>
    </app-form-card>
  </div>
</app-accordion>
