import { Component, Input, SimpleChanges } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { ApprovalModalComponent } from "../approval-modal/approval-modal.component";
import { Observable } from "rxjs";
import { Categories } from "../../../core/models/categories.enum";
import { CoverageModalComponent } from "src/app/coverage-modal/coverage-modal.component";

@Component({
  selector: "app-dental-plan-accordion",
  templateUrl: "./dental-plan-accordion.component.html",
  styleUrls: ["./dental-plan-accordion.component.scss"],
})
export class DentalPlanAccordionComponent {
  constructor(
    private modalService: NgbModal,
    private authService: AuthenticationService
  ) {}
  @Input() selectedPlanName: string;
  @Input() coverages: any[];
  @Input() disableInputs = false;

  @Input() benefits;
  depends = [];
  planControl = new FormControl();
  isDisabled = true;
  includedDependents: any[] = [];
  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  discount: string;
  plans: any[] = [];
  selectedChoice: string;
  seuFormulario: FormGroup;
  descCategory = "";
  changedAccordion = "Plano Odontológico";
  selectedPlanCoverages = [];
  currentBenefitId: number | null = null;
  selectedPlan: any;
  selectedDependents: any;
  showModal = false;

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    this.planControl.disable();
    this.seuFormulario = new FormGroup({
      beneficiaries: new FormArray([]),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  get beneficiariesFormArray() {
    return this.seuFormulario.get("beneficiaries") as FormArray;
  }

  get getTotalDiscount() {
    let holderDiscount = 0;
    let dependentDiscount = 0;
    if (!this.selectedPlan) return 0;

    if (this.selectedPlan.holderDiscount > 0)
      holderDiscount =
        this.selectedPlan.holderDiscountType === 2
          ? this.selectedPlan.cost / this.selectedPlan.holderDiscount
          : this.selectedPlan.holderDiscount;

    if (this.selectedPlan.dependentDiscountType)
      dependentDiscount =
        this.selectedPlan.dependentDiscountType === 2
          ? this.selectedPlan?.cost / this.selectedPlan.dependentDiscount
          : this.selectedPlan.dependentDiscount;

    return (
      dependentDiscount * (this.selectedDependents?.length || 0) +
      holderDiscount
    );
  }

  addBeneficiary() {
    const beneficiaryGroup = new FormGroup({
      name: new FormControl(""),
      percentageParticipation: new FormControl(""),
      degreeParentesco: new FormControl(""),
      document: new FormControl(""),
    });

    this.beneficiariesFormArray.push(beneficiaryGroup);
  }

  removeBeneficiary(index: number) {
    this.beneficiariesFormArray.removeAt(index);
  }

  setWantBenefitDependent(): void {
    this.selectedPlan.includedDependents?.forEach(
      (element) => (element.selected = false)
    );

    const want =
      this.benefit.wantTheBenefitForDependents && this.benefit.wantTheBenefit;

    this.benefit.wantTheBenefitForDependents = want;

    if (!want) return;

    this.selectedPlan?.includedDependents.forEach(element =>
    element.selected =  this.selectedDependents.some(selected => selected.id === element.id)
    );
    // for (
    //   let index = 0;
    //   index < this.selectedPlan?.includedDependents?.length;
    //   index++
    // ) {
    //   const element = this.selectedPlan?.includedDependents[index];

    //   element.selected = want;
    // }

    console.log(this.benefit);
  }

  setWantBenefit() {
    this.setWantBenefitDependent();

    this.benefit.items.forEach((element) => (element.selected = false));

    if (!this.selectedPlan) {
      this.benefit.wantTheBenefit = false;
      return;
    }

    const plan = this.benefit.items.find(
      (plan) => plan.index === this.selectedPlan?.index
    );

    if (plan) {
      plan.selected = this.benefit.wantTheBenefit;
    }

    console.log(this.benefit);
  }

  onPlanChange(): void {
    this.selectedPlan.includedDependents?.forEach(
      (element) => (element.selected = false)
    );
    this.benefit.items?.forEach((element) => {
      element.selected = false;
      element.includedDependents.forEach((dep) => (dep.selected = false));
    });

    this.benefit.wantTheBenefit = false;
    this.benefit.wantTheBenefitForDependents= false;
    this.selectedDependents = [];
  }

  onSubmit() {
    if (this.seuFormulario.valid) {
      const formData = this.seuFormulario.value;
      console.log("Dados do Formulário:", formData);
    }
  }
  openCoverageModal() {
    // const modalRef = this.modalService.open(CoverageModalComponent);
    // console.log("Opening modal with benefitId:", this.currentBenefitId);
    // modalRef.componentInstance.benefitId = this.currentBenefitId;
    this.showModal = true;
  }

  getBenefits(benefits: any) {
    if (!benefits) return;
    // this.benefitsService.getBenefits(id).subscribe(
    // (response: any) => {
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.Odonto
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;
          this.discountValue = foundBenefit.holderDiscount;
          this.discount = foundBenefit.existDiscountToHolder;

          this.selectedPlan = this.benefit.items?.find((plan) => plan.selected);

          this.selectedDependents =
            this.selectedPlan?.includedDependents?.filter(
              (dependent) => dependent.selected
            );

          break;
        }
      }
    }
    // },
    // (error) => {
    //   console.error(
    //     "Error fetching benefits:",
    //     error.message || JSON.stringify(error)
    //   );
    // }
    // );
  }

  openApprovalModal() {
    const modalRef = this.modalService.open(ApprovalModalComponent);
    modalRef.componentInstance.changedAccordion = this.changedAccordion;
  }
}
