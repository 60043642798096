import { Component, OnInit } from "@angular/core";
import { TenantStore } from "./core/services/Stores/tenant.store";
import { AuthenticationService } from "./core/services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {}
