import { Component, ViewChild, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from 'src/app/core/models/modal-options.models';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {


  @ViewChild('content') content;
  show: boolean;
  type: string;
  buttonPrimaryLabel?: string;
  buttonSecondaryLabel?: string;

  constructor(private modal: NgbModal, private modalService: ModalService) { }

  title: string;
  description: string;

  ngOnInit() {
    this.subscribeModalStatus();
    this.subscribeModalData();
  }


  public subscribeModalStatus() {
    this.modalService.getShowModalStatus()
      .subscribe({ next: (toggle) => this.modalStatusHandler(toggle) })
  }

  public subscribeModalData() {
    this.modalService.getModalData()
      .subscribe({ next: (data) => this.modalDataHandler(data) })
  }

  private modalDataHandler(data: ModalOptions): void {
    this.title = data.title;
    this.description = data.description;
    this.type = data.type;
    this.buttonPrimaryLabel = data.buttonPrimaryLabel;
    this.buttonSecondaryLabel = data.buttonSecondaryLabel;
  }


  private modalStatusHandler(toggle: boolean): void {
    if (toggle) {
      this.show = toggle;
      this.modal.open(this.content, { centered: true })
        .result.then(value => this.modalService.setsResult(value))
    }
  }
}
