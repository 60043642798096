import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class UtilsService {
  /**
   *
   */

  public breakLineText(arr: any[]): any {
    if (!arr || arr.length == 0) return [];
    return arr
      ?.reduce((acc, current) => (acc = acc + "," + current))
      .replace(new RegExp(",", "g"), "<br />");
  }

  public getUserNickname(name: string) {
    let splitedName = name.split(" ");

    splitedName = splitedName || [name];

    if (splitedName.length > 1) {
      return `${splitedName[0].charAt(0)}${splitedName[1].charAt(0)}`;
    } else if (splitedName.length) {
      return `${splitedName[0].charAt(0)}${splitedName[0].charAt(1)}`;
    }
  }

  downloadFile(base64, documentName) {
    const str2ab = (str: string): ArrayBuffer => {
      const buf = new ArrayBuffer(str.length);
      const bufView = new Uint8Array(buf);
      for (let i = 0; i < str.length; i++) {
        bufView[i] = str.charCodeAt(i);
      }
      return buf;
    };

    const binaryData = atob(base64);

    const blob = new Blob([str2ab(binaryData)], {
      type: "application/octet-stream",
    });

    const url = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;

    anchor.download = documentName;

    anchor.click();

    URL.revokeObjectURL(url);
  }

  downloadImageFrom = (imageUrl: string) => {
    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = "downloaded-file";
    a.click();
    URL.revokeObjectURL(imageUrl);
  };

  containsOnlyNumbers(str: any): boolean {
    return /^\d+$/.test(str);
  }
}
