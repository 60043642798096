import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { BenefitsService } from "src/app/core/services/portal-admission/benefits.service";
import { EmployeesHiringService } from "src/app/core/services/portal-admission/employees-hiring.service";
import { Categories } from "../../../core/models/categories.enum";

@Component({
  selector: "app-pharmacy-accordion",
  templateUrl: "./pharmacy-accordion.component.html",
  styleUrls: ["./pharmacy-accordion.component.scss"],
})
export class PharmacyAccordionComponent implements OnChanges {
  @Input() benefits;
  constructor(
    private benefitsService: BenefitsService,
    private authService: AuthenticationService,
    private employeesHiringService: EmployeesHiringService
  ) {}

  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  selected: boolean;
  discount: string;

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    // this.getEmployeesHiring();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  // getEmployeesHiring() {
  //   this.employeesHiringService
  //     .getEmployeesHiring(this.employeeId)
  //     .subscribe((res: any) => {
  //       const id = res.hiringProcessId;
  //       this.hiringProcessId = id;

  //       this.getBenefits(id);
  //     });
  // }

  getBenefits(benefits: any) {
    // this.benefitsService.getBenefits(id).subscribe(
    // (response: any) => {
    if (!benefits) return;
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.Farmacia //"Farmácia"
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;
          this.selected = foundBenefit.wantTheBenefit;
          this.discountValue = foundBenefit.holderDiscount;
          this.discount = foundBenefit.existDiscountToHolder;
          break;
        }
      }
    }
    //   },
    //   (error) => {
    //     console.error(
    //       "Error fetching benefits:",
    //       error.message || JSON.stringify(error)
    //     );
    //   }
    // );
  }
}
