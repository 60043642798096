import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import {
  NgOptionComponent,
  NgSelectComponent,
  NgSelectModule,
} from "@ng-select/ng-select";

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
  NgbToast,
  NgbDatepickerI18n,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbTooltipModule,
  NgbModalModule,
  NgbNavModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

import { LoaderComponent } from "./loader/loader.component";
import { AppToastsComponent } from "./app-toasts/app-toasts.component";
import { NgxMaskModule } from "ngx-mask";
import { ModalComponent } from "./modal/modal.component";
import { TableComponent } from "./table/table.component";
import { NgxPaginationModule } from "ngx-pagination";
import { AddNewComponent } from "./add-new/add-new.component";
import { WizardStepsComponent } from "./wizard/wizard-steps/wizard-steps.component";
import { WizardStepContentComponent } from "./wizard/wizard-step-content/wizard-step-content.component";
import { WizardComponent } from "./wizard/wizard.component";
import { UploadFileComponent } from "./upload-file/upload-file.component";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CustomDatepickerI18n } from "./wizard/datepicker/CustomDatepickerI18n";
import { CustomAdapter } from "./wizard/datepicker/CustomAdapter";
import { CustomDateParserFormatter } from "./wizard/datepicker/CustomDateParserFormatter";
import { I18n } from "./wizard/datepicker/I18n";
import { AvatarComponent } from "./avatar/avatar.component";
import { AccordionComponent } from "./accordion/accordion.component";
import { DropDownMenuComponent } from "./drop-down-menu/drop-down-menu.component";
import { FormCardComponent } from "./form-card/form-card.component";
import { NewModalComponent } from "./new-modal/modal.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { DragAndDropDirective } from "./upload-file/drag-and-drop.directive";
import { AddGroupMatrixComponent } from "./add-group-matrix/add-group-matrix.component";
import { NgToggleModule } from "ng-toggle-button";
import { OutsideClickDirective } from "../directives/outside-click.directive";
import { DropdownTreeViewComponent } from "./dropdown-tree-view/dropdown-tree-view.component";
import { TableCompetenceComponent } from "./table-competence/table-competence.component";
import { AccordionComponentMetlife } from "./accordion-metlife/accordion-metlife.component";
import { PopoverListComponent } from "./popover-list/popover-list.component";
import { ListPopoverListComponent } from "./list-popover-list/list-popover-list.component";
import { ShowListComponent } from "./show-list/show-list.component";
import { MoreOptionsButtonComponent } from "./more-options-button/more-options-button.component";
import { KbToMbPipe } from "./upload-file/KbToMbPipe";
import { CurrentTimeComponent } from "./current-time/current-time.component";
import { InfoTooltipComponent } from "./info-tooltip/info-tooltip.component";
import { TimerComponent } from "./timer/timer.component";
import { TimerFormatPipe } from "./timer/timer-format.pipe";
import { SelectComponent } from "./select/select.component";
import { AppointmentItemComponent } from "./appointment-item/appointment-item.component";
import { StepProgressbarComponent } from "./step-progressbar/step-progressbar.component";
import { ProgressbarComponent } from "./progressbar/progressbar.component";
import { RadarChartComponent } from "./radar-chart/radar-chart.component";
import { StepperComponent } from "./stepper/stepper.component";
import { NineboxChartComponent } from "./ninebox-chart/ninebox-chart.component";
import { NumbersOnlyDirective } from "../directives/numbers-only.directive";
import { SpiderChartComponent } from "./spider-chart/spider-chart.component";
import { TabComponent } from "./tab/tab.component";
import { WorkedDaysComponent } from "./worked-days/worked-days.component";
import { RemoveButtonComponent } from "./remove-button/remove-button.component";
import { SortComponent } from "./sort/sort.component";

@NgModule({
  declarations: [
    LoaderComponent,
    AppToastsComponent,
    ModalComponent,
    TableComponent,
    TableCompetenceComponent,
    AddNewComponent,
    AddGroupMatrixComponent,
    WizardStepsComponent,
    WizardStepContentComponent,
    WizardComponent,
    UploadFileComponent,
    AvatarComponent,
    AccordionComponent,
    AccordionComponentMetlife,
    DropDownMenuComponent,
    FormCardComponent,
    NewModalComponent,
    BreadcrumbComponent,
    DragAndDropDirective,
    OutsideClickDirective,
    NumbersOnlyDirective,
    DropdownTreeViewComponent,
    PopoverListComponent,
    SelectComponent,
    ListPopoverListComponent,
    ShowListComponent,
    MoreOptionsButtonComponent,
    KbToMbPipe,
    CurrentTimeComponent,
    InfoTooltipComponent,
    TimerComponent,
    TimerFormatPipe,
    AppointmentItemComponent,
    StepProgressbarComponent,
    ProgressbarComponent,
    RadarChartComponent,
    StepperComponent,
    NineboxChartComponent,
    SpiderChartComponent,
    TabComponent,
    WorkedDaysComponent,
    RemoveButtonComponent,
    SortComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgToggleModule,
    NgbToast,
    NgSelectModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    RouterModule,
    CurrencyMaskModule,
    CKEditorModule,
    NgbTooltipModule,
    NgbModalModule,
    NgbNavModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  exports: [
    LoaderComponent,
    AppToastsComponent,
    NgSelectComponent,
    NgOptionComponent,
    ModalComponent,
    TableComponent,
    TableCompetenceComponent,
    AddNewComponent,
    AddGroupMatrixComponent,
    WizardStepsComponent,
    WizardStepContentComponent,
    WizardComponent,
    UploadFileComponent,
    NgbDatepickerModule,
    CurrencyMaskModule,
    CKEditorModule,
    NgbTooltipModule,
    AvatarComponent,
    NgbModalModule,
    NgbNavModule,
    AccordionComponent,
    AccordionComponentMetlife,
    DropDownMenuComponent,
    FormCardComponent,
    NewModalComponent,
    BreadcrumbComponent,
    DragAndDropDirective,
    NgxMaskModule,
    OutsideClickDirective,
    NumbersOnlyDirective,
    DropdownTreeViewComponent,
    PopoverListComponent,
    SelectComponent,
    ListPopoverListComponent,
    ShowListComponent,
    MoreOptionsButtonComponent,
    CurrentTimeComponent,
    InfoTooltipComponent,
    TimerComponent,
    AppointmentItemComponent,
    StepProgressbarComponent,
    ProgressbarComponent,
    RadarChartComponent,
    StepperComponent,
    NineboxChartComponent,
    SpiderChartComponent,
    TabComponent,
    WorkedDaysComponent,
    RemoveButtonComponent,
    SortComponent
  ],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class UIModule { }
