export enum Categories {
  Saude = "d05a6b67-6392-41e5-a892-338c68dc97d6",
  Odonto = "1f83032c-85ac-463e-9685-af1918c4910e",
  Vida = "3a52139a-768a-4140-a0f2-b3ef51d1e955",
  Previdencia = "68283d45-698d-471f-af5b-27e33e5b2ba3",
  Funeral = "ca29c790-0d82-4300-9406-f8cfae7cd729",
  CreditoCosignado = "8bafef88-e4d8-4cf7-825f-ab0a45dba465",
  Carro = "e133833a-25c7-4937-8067-929fdfd72d6b",
  Estacionamento = "342ad180-559e-4d6d-98f8-e90c2d14a8b8",
  VR_VA = "af678bde-043a-470b-bd90-5b0eed612420",
  VR = "a3546ed2-1ad4-4a5d-ae87-c85ef1eaf442",
  VA = "f07e6c17-eaea-4a7a-9f13-f1259a167103",
  VT = "2ad1c091-8395-41d3-9a85-c1d35b7fd246",
  Fretado = "6d14e952-eeba-4e7f-8b9f-ff8071c3ce8f",
  Baba = "4795a907-5b90-4b21-8d44-84e3d6c3554c",
  Creche = "f2eccc14-89f0-4e73-8b04-fa448c4cbfe5",
  Academia = "efccf81a-8dab-44c8-a739-b77934ce7820",
  PCD = "db6ef9ef-14cb-4eee-877c-1773532222fa",
  Farmacia = "54600575-bf64-4886-a969-a5ecb5434936",
  VC = "f516a68a-4884-4293-b4cc-fe0e824fd665",
  Refeitorio = "570f15f2-3148-46fa-9488-b1153ee7ba48",
  PAC = "3a81515b-492b-49b0-ba67-18647921188a",
}
