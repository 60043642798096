<app-accordion class="my-1" [title]="'Farmácia'" *ngIf="benefit">
    <div class="w-100">
      <div class="row mt-3">
        <label class="text-default-label"
          >{{ benefit.descBenefit }}</label
        >
        <div class="row mt-4">
          <div class="col-md-12 col-12 col-sale">
            <label class="text-sale">Valor do benefício</label>
            <span>R$ {{ benefit.items[0].cost }}</span>
          </div>
          <div class="col-md-12 col-12 col-value mt-2">
            <p>{{ benefit.items[0].name }}</p>
          </div>
          <p>
            <small>Esse benefício viabiliza a compra de medicamentos e perfumaria, com desconto direto em folha de pagamento</small>
          </p>
        </div>
      </div>
    </div>
  </app-accordion>
