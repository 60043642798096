<section *ngIf="isAdmin" id="app-group-matrix">
    <article class="col-md-12">
      <div class="col-md-12 p-1">
        <div class="col-md-3 row p-0">
          <label
            for="deadlineDate"
            class="text-default-label">Destinatários</label>
            <div class="form-check col-md-6">
              <input
                class="form-check-input"
                type="radio"
                name="custom"
                checked="true"
                (change)="isCustom=false"
                [value]="false"
              />
              <label class="form-check-label" for="all"> Todos </label>
            </div>
          <div class="form-check col-md-6">
            <input
              class="form-check-input "
              type="radio"
              name="custom"
              (change)="isCustom=true"
              [value]="true"
            />
            <label class="form-check-label" for="custom"> Personalizado </label>
          </div>
        </div>
      </div>
      <div class="col-md-12 row" *ngIf="isCustom" >
          <div *ngFor="let dest of arrDestinations; let i = index" class="col-md-3">
            <div  listcustom>{{ dest.group.name }}
              <i class="bx bx-edit-alt edition" (click)="listCustom()"></i>
              <i class="bx bx-trash-alt edition" (click)="removeCustom(i)"></i>
            </div>
        </div>
        <div class="col-md-3">
            <div addnewcustom (click)="addNew()">+</div>
        </div>
      </div>
  </article>
</section>
<section *ngIf="!isAdmin" id="app-group-matrix">
  <article class="col-md-12">
    <div class="col-md-12">
      <label class="text-default-label">Selecionar filial</label>
      <ng-select notFoundText="Nenhuma filial disponivel"
                 class="custom"
                 placeholder="Digite para pesquisar ou selecione"
                 >
        <ng-option *ngFor="let branch of arrBranches"
                   [value]="branch.id">{{
          branch.name
          }}</ng-option>
      </ng-select>
    </div>
  </article>
</section>

<app-new-modal  [title]="'ADICIONAR DESTINATÁRIO'"
                [modalOpen]="modalOpen"
                (closeModalEvent)="closeModal()">
  <hr/>
  <div style="height: 500px;">
    <div class="w-100 lh-lg">
        <label
          for="deadlineDate"
          class="text-default-label">Selecionar Grupo Econômico*</label>
        <ng-select
            [items]="arrGroup"
            [multiple]="false"
            bindLabel="name"
            (change)="groupSelectedEvent($event)"
            [selectableGroup]="true"
            [selectableGroupAsModel]="false"
            placeholder="Digite para pesquisar ou selecione"
            notFoundText="Nenhum grupo econômico disponivel"
            [closeOnSelect]="true"
            bindValue="id">
            <ng-option *ngFor="let group of arrGroup"
                        [value]="group.id">{{
                group.name
                }}
            </ng-option>
        </ng-select>
      </div>
      <div *ngIf="selectedGroup && comboMatrix.length>0" class="lh-lg py-3 toggles">
        <strong>ESCOLHER MATRIZ </strong>
        <ng-toggle
            [(ngModel)]="toggleMatrix"
        ></ng-toggle>
      </div>
      <div *ngIf="toggleMatrix" class="w-100 lh-lg">
        <label
          for="deadlineDate"
          class="text-default-label">Matriz</label>
        <ng-select
            [items]="comboMatrix"
            [multiple]="false"
            bindLabel="name"
            (change)="matrixSelectedEvent($event)"
            [selectableGroup]="true"
            [selectableGroupAsModel]="false"
            placeholder="Digite para pesquisar ou selecione"
            notFoundText="Nenhuma matriz disponivel"
            [closeOnSelect]="true"
            bindValue="id">
            <ng-option *ngFor="let matrix of comboMatrix"
                        [value]="matrix.id">{{
                matrix.name
                }}
            </ng-option>
        </ng-select>
      </div>
      <div *ngIf="selectedMatrix && comboBranches.length>0" class="lh-lg py-3 toggles">
        <strong>ESCOLHER FILIAIS </strong>
        <ng-toggle
            [(ngModel)]="toggleBranches"
        ></ng-toggle>
      </div>
      <div *ngIf="toggleBranches" class="w-100 lh-lg">
        <label
          for="branches"
          class="text-default-label">Filiais</label>
        <ng-select
            [items]="comboBranches"
            [multiple]="true"
            bindLabel="name"
            (change)="branchSelectedEvent($event)"
            [selectableGroup]="true"
            [selectableGroupAsModel]="false"
            placeholder="Digite para pesquisar ou selecione"
            notFoundText="Nenhuma filial disponivel"
            [closeOnSelect]="true"
            bindValue="id">
            <ng-option *ngFor="let branch of comboBranches"
                        [value]="branch.id">{{
                branch.name
                }}
            </ng-option>
        </ng-select>
      </div>
    </div>
      <hr/>
      <div class="w-100 text-uppercase text-end">
        <button class="btn btn-outline-primary mx-3" (click)="closeModal()">cancelar</button>
        <button class="btn btn-primary" (click)="apply()">aplicar</button>
      </div>

</app-new-modal>
