<ng-template #content let-modal id="modal">
  <div class="modal-header border-bottom-0">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-4">
      <div class="avatar-md mx-auto mb-4">
        <div
          class="avatar-title bg-white rounded-circle h1"
          [ngClass]="{
            'text-danger': type === 'danger',
            'text-primary': type === 'notification',
            'text-warning': type === 'alert'
          }"
        >
          <i class="mdi mdi-alert-circle-outline"></i>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-10">
          <h4 class="text-secondary">{{ title }}</h4>
          <p class="text-muted font-size-14 mb-4" [innerHTML]="description"></p>

          <button
            type="button"
            class="btn btn-outline-danger m1"
            (click)="modal.close(true)"
            [ngClass]="{
              'btn-outline-danger': type === 'danger',
              'btn-outline-primary': type === 'notification'
            }"
          >
            {{
              buttonPrimaryLabel
                ? buttonPrimaryLabel
                : type === "danger"
                ? "DELETAR"
                : "CONFIRMAR"
            }}
          </button>
          <button
            type="button"
            class="btn btn-secondary m-1"
            (click)="modal.close(false)"
          >
            {{ buttonSecondaryLabel ? buttonSecondaryLabel : "CANCELAR" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
