<div class="d-flex align-items-center justify-content-center flex-column">
  <canvas id="ninebox">{{ chart }}</canvas>

  <div class="col-md-12 chart-info">
    <span
      >Resultado anterior:
      {{ chartDataSet?.previousGrade?.scorePercentage }}%</span
    >
    <span
      >Resultado atual: {{ chartDataSet?.currentGrade?.scorePercentage }}%</span
    >
    <span class="d-flex align-items-center"
      >Evolução: {{ chartDataSet?.evolutionPercentage }}%
      <i
        class="mdi"
        [ngClass]="getEvolutionBadgeClass(chartDataSet?.evolutionPercentage)"
      ></i
    ></span>
  </div>
</div>
