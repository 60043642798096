<app-accordion class="my-1" [title]="'Vale Transporte'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">Transporte</label>
      <div class="row ml-3">
        <div class="col-12 col-transp mb-1">
          <img
            src="assets/images/HomeOutlined.svg"
            alt=""
            class="close-img"
            height="24"
            width="24"
          />
          <span class="text-home"> Endereço residencial: </span>
          <span *ngIf="getEmployeeAddress"> {{ getEmployeeAddress }}</span>
          <!-- <input
            [(ngModel)]="employeeAddress"
            class="input-end"
            placeholder="Insira o endereço residencial"
          /> -->
        </div>
        <div class="col-12 col-transp">
          <img
            src="assets/images/HomeWorkOutlined.svg"
            alt=""
            class="close-img"
            height="24"
            width="24"
          />
          <span class="outhome-text"> Endereço da unidade: </span>
          <!-- <input
            [(ngModel)]="jobAddress"
            class="input-end"
            placeholder="Insira o endereço da unidade"
          /> -->
          <span *ngIf="getJobAddress"> {{ getJobAddress }}</span>
        </div>
        <!-- <div class="col-4">
          <button
            class="btn btn-primary m-1 text-uppercase btn-calc"
            (click)="calculateDistance()"
          >
            Calcular Distância
          </button>
        </div> -->

        <div class="col-12 col-md-12 col-google mt-3 ml-2">
          <span *ngIf="calculateDistanceNotFound" class="text-danger"
            >Não foi possivel calcular a distância: Endereço de origem/destino
            não encontrado.</span
          >
          <label class="dist-text" *ngIf="!calculateDistanceNotFound"
            >Distância: <span>{{ calculatedDistance }}</span></label
          >
          <label class="font">Fonte: Google Maps</label>
        </div>
      </div>

      <app-route
        title="Ida"
        selectedChoiceTranspFor="'sim'"
        (clickAddButton)="open(content, 'origin')"
        (clickEdit)="edit($event, content, 'origin')"
        (clickRemove)="remove($event, 'origin')"
        [transport]="transportToJob"
      ></app-route>

      <app-route
        title="Volta"
        (clickAddButton)="open(content, 'destination')"
        (clickEdit)="edit($event, content, 'destination')"
        (clickRemove)="remove($event, 'destination')"
        [transport]="transportFromJob"
      ></app-route>

      <!-- MODAL -->
      <ng-template #content>
        <div class="modal-content custom-modal p-3">
          <div class="row">
            <div class="col-12">
              <h4 class="text-ptransp">ADICIONAR TRANSPORTE</h4>
            </div>
            <div class="col-12 mt-2">
              <h4 class="title-t">Escolha o tipo de transporte</h4>
              <div class="custom-checkbox-container">
                <label class="custom-checkbox">
                  <input
                    type="radio"
                    name="choiceTransTre"
                    [(ngModel)]="selectedChoiceTranspFor"
                    [value]="true"
                    (change)="this.requestedPublic.emit()"
                  />
                  <span class="checkmark">
                    <span class="checkmark-inner"></span>
                  </span>
                  Vale Transporte Público
                </label>

                <label class="custom-checkbox">
                  <input
                    type="radio"
                    name="choiceTransTre"
                    [(ngModel)]="selectedChoiceTranspFor"
                    [disabled]="isWithinDistanceLimit"
                    [value]="false"
                    (change)="requestedChartered.emit()"
                  />
                  <span class="checkmark">
                    <span class="checkmark-inner"></span>
                  </span>
                  Transporte Fretado
                </label>
              </div>
            </div>
            <div class="row row-boxestado" *ngIf="selectedChoiceTranspFor">
              <div class="col-md-12 row mt-3">
                <div class="col-md-3 mt-2 mr-2 col-estado">
                  <label
                    for="stateIdLabel"
                    class="text-default-label estado-text"
                    >Estado*</label
                  >
                  <ng-select
                    [(ngModel)]="selectedEstado"
                    (change)="onEstadoChange($event)"
                    (clear)="clearCountry()"
                  >
                    <ng-option *ngFor="let item of statesAndCities" [value]="item.state">{{
                      item.state
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md-9 mt-2 mr-2 ml-5">
                  <label for="cityLabel" class="text-default-label cidade-text"
                    >Cidade*</label
                  >
                  <ng-select
                    [(ngModel)]="selectedCidade"
                    (change)="onCityChangeEvent($event)"
                  >
                    <ng-option *ngFor="let item of cities" [value]="item">{{
                      item
                    }}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md-12 mt-2 mr-2 ml-5">
                  <label class="text-default-label operadora-text"
                    >Operadora</label
                  >
                  <ng-select (change)="onSelectOperator($event)">
                    <ng-option *ngFor="let item of operators" [value]="item">{{
                      item
                    }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 mt-3">
                <label for="operadora" class="text-default-label line-text"
                  >Linha</label
                >
                <ng-select [(ngModel)]="selectedLine">
                  <ng-option
                    *ngFor="let item of filteredLines"
                    [value]="item"
                    >{{
                      (item.line ? item.line + " | " : "") +
                        item.description +
                        " | " +
                        (item.tariff | currency : "BRL" : "symbol")
                    }}</ng-option
                  >
                </ng-select>

                <div class="col-sm-12 row mt-3" *ngIf="selectedLine">
                  <label class="text-default-label col-sm-6 d-inline"
                    >Linha:</label
                  >
                  <span>{{ selectedLine?.line }}</span>
                  <label class="text-default-label col-sm-6">Descrição:</label>
                  <span>{{ selectedLine?.description }}</span>
                  <label class="text-default-label col-sm-6">Tarifa:</label>
                  <span>{{
                    selectedLine?.tariff | currency : "BRL" : "symbol"
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-3 text-align-end">
              <button class="btn btn-secondary" type="button" (click)="close()">
                VOLTAR
              </button>

              <button
                class="btn btn-primary"
                type="button"
                (click)="addTransport()"
              >
                AVANÇAR
              </button>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="row mt-4">
        <div class="col-lg-4 col-sm-12 col-md-12">
          <label class="text-default-label ">Desconto do titular</label>
          <span class="d-block">{{ benefit.items[0]?.existDiscountToHolder }}</span>
        </div>
        <div class="col-lg-4 col-sm-12 col-value">
          <label class="text-default-label">Valor ou % do desconto</label>

          <ng-container *ngIf="benefit.items[0]?.holderDiscountType === 2">
            <span>{{ benefit.items[0]?.holderDiscount || 0 }}%</span>
          </ng-container>
          <ng-container *ngIf="benefit.items[0]?.holderDiscountType === 1">
            <span>{{
              benefit.items[0]?.holderDiscount || 0
                | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>
        <div class="col-lg-4 col-sm-12 ">
          <label class="text-default-label">Deseja aderir ao beneficio</label>
          <div class="custom-checkbox-container">
            <label class="custom-checkbox">
              <input
                type="radio"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="false"
                (change)="wantBenefitChange()"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Não
            </label>

            <label class="custom-checkbox">
              <input
                type="radio"
                name="choice"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="true"
                (change)="wantBenefitChange()"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Sim
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
