<div class="row mb-5 mt-2 card">
  <label class="next">{{ title }}</label>
  <div
    class="col-md-12 row card-body justify-content-between flex-nowrap overflow-x m-0"
  >
    <div class="col-md-12 justfiy-content-start row flex-shrink-100">
      <button
        class="col-md-2 col-sm-12 btn-addtransp"
        (click)="clickAddButton.emit()"
      >
        ADICIONAR +
      </button>
      <!-- transporte publico -->
      <ng-container *ngIf="selectedChoiceTranspFor !== 'sim'">
        <div
          *ngFor="let item of transport"
          class="col-md-3 col-sm-12 row col-infotransp justify-content-start"
        >
          <div class="col-md-9 col-sm-10">
            <label
              class="text-default-label d-block"
              *ngIf="item.linha; else chartered"
              >Linha <span>{{ item.linha }}</span></label
            >
            <ng-template #chartered>
              <label class="text-default-label d-block"
                >Fretado <span>{{ item.description }}</span></label
              >
            </ng-template>
            <label class="value-txt d-block" *ngIf="item.valor"
              >Valor
              <span>{{ item.valor | currency : "BRL" : "symbol" }}</span></label
            >
          </div>

          <div class="col-md-3 col-sm-2">
            <div class="drop-menu" ngbDropdown container="body">
              <button
                type="button"
                class="btn btn-outline-secondary btn-position-drop action-button m-1"
                ngbDropdownToggle
              >
                <i class="mdi mdi-dots-vertical"></i>
              </button>
              <div
                class="dropdown-menu menu"
                ngbDropdownMenu
                aria-labelledby="dropdownManual"
              >
                <button (click)="clickEdit.emit(item)" ngbDropdownItem>
                  Editar
                </button>
                <button (click)="clickRemove.emit(item)" ngbDropdownItem>
                  Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-2 col-infotransp-total">
      <label class="text-total-all">Total</label>
      <span class="price">{{
        getTotalValor() | currency : "BRL" : "symbol"
      }}</span>
    </div>
  </div>
</div>
