import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AesService } from "../services/aes.service";
import { AuthenticationService } from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private aesService: AesService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.enableEncrypit) request = this.encryptPayload(request);

    if (!request.url.startsWith(environment.url)) return next.handle(request);

    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request);
  }

  private encryptPayload(request: HttpRequest<any>) {
    let encryptedPayload = request.body;
    if (request.body) {
      encryptedPayload = this.aesService.encryptData(
        request.body,
        environment.encryptionIv,
        environment.encryptionKey
      );
    }
    request = request.clone({
      body: {
        data: encryptedPayload,
      },
    });
    return request;
  }
}
