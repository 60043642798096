import { Component, Input, OnInit } from "@angular/core";
import { Chart, Ticks } from "chart.js";
import { radarChartResponse } from "./radar-chart-mock";
import { IRadarChart } from "./IRadarChart";
import { IAssessmentRadarChartDatasets } from "./IAssessmentCharteDatasets";

@Component({
  selector: "app-radar-chart",
  templateUrl: "./radar-chart.component.html",
  styleUrls: ["./radar-chart.component.scss"],
})
export class RadarChartComponent implements OnInit {
  @Input() data: IRadarChart = radarChartResponse;

  chart: any;

  ngOnInit(): void {
    const { competences, datasets } = this.data;
    this.fillChart(competences, datasets);
  }

  private fillChart(
    competences: string[],
    datasets: IAssessmentRadarChartDatasets
  ) {
    this.chart = new Chart("canvas", {
      type: "radar",
      data: {
        labels: competences,
        datasets: [
          {
            data: datasets.currentCycle,
            borderWidth: 1,
            label: "Avaliação atual",
            backgroundColor: "rgba(104,204,70, 0.50)",
          },
          {
            data: datasets.previousCycle,
            borderWidth: 1,
            label: "Avaliação anterior",
            backgroundColor: "rgba(255, 246, 31, 0.50)",
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            ticks: { display: false },
          },
        },
      },
    });
  }
}
