import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  distinctUntilChanged,
} from "rxjs";
import { AutoUnsubscribe } from "src/app/core/helpers/AutoSub.decorator";

@Component({
  selector: "app-dropdown-tree-view",
  templateUrl: "./dropdown-tree-view.component.html",
  styleUrls: ["./dropdown-tree-view.component.scss"],
})
@AutoUnsubscribe()
export class DropdownTreeViewComponent implements OnInit, OnDestroy {
  @Output() changed = new EventEmitter();

  @ViewChild("searchInput") searchInputfield: ElementRef;

  private searchSubject = new Subject<string>();
  private showListSubject = new Subject<boolean>();
  private selectedSubject = new BehaviorSubject<any[]>(null);


  showListItems = false;
  list = [
    {
      id: 1,
      name: "Matriz 1",
      selected: false,
      indeterminate: false,
      childrens: [
        { id: 2, name: "filial 1", selected: false },
        { id: 3, name: "filial 2", selected: false },
        { id: 4, name: "filial 3", selected: false },
        { id: 5, name: "filial 4", selected: false },
        { id: 6, name: "filial 5", selected: false },
      ],
    },

    { id: 7, name: "matriz 7", selected: false },
    { id: 8, name: " matriz 8", selected: true },
    { id: 9, name: "matriz 9", selected: false },
  ];

  selected$ = this.selectedSubject.asObservable();
  filteredList: any[];

  ngOnInit(): void {
    this.registerFilter();
    this.registerUpdateSelectItems();

    this.filteredList = [...this.list];
    this.selectedSubject.subscribe((data) => console.log(data));
  }

  ngOnDestroy(): void {
    return;
  }

  outsideClickEvent($event) {
    if ($event) {
      this.showListSubject.next(false);
      this.showListItems = false;
    }
  }

  selectItemEvent(id: number) {
    const item = this.list.find((item) => item.id === id);

    const selected = !item.selected;

    if (item.childrens?.length)
      item.childrens.forEach((sub) => (sub.selected = selected));

    item.selected = selected;
  }

  selectSubItemEvent(id: number) {
    const childrens = this.list
      .flatMap((item) => item.childrens)
      .find((item) => item.id === id);

    const item = this.list.find((item) =>
      item.childrens?.some((sub) => sub.id === id)
    );

    childrens.selected = !childrens.selected;
    item.indeterminate = !item.childrens?.every((sub) => sub.selected);

    if (item.childrens.every((sub) => !sub.selected)) {
      item.selected = false;
      item.indeterminate = false;
      return;
    }

    item.selected = !item.indeterminate && !item.selected;
  }

  selectAll() {
    const selectAllItems = (arr: any[]) => {
      arr?.forEach((item) => {
        item.selected = !item.selected;
        selectAllItems(item.childrens);
      });
    };

    selectAllItems(this.list);
  }

  filterList($event) {
    this.searchSubject.next($event.target.value);
  }

  openList() {
    this.showListItems = true;
    this.searchInputfield.nativeElement.focus();
  }

  remove(removedItem) {
    const checkAsNotSelected = (arr: any[], item: any) => {
      if (!arr) return;
      arr?.forEach((x) =>
        x.id === item.id
          ? (x.selected = false)
          : checkAsNotSelected(x.childrens, item)
      );
    };

    const selected = this.selectedSubject.getValue();
    const updated = [...selected.filter((item) => item.id !== removedItem.id)];
    checkAsNotSelected(this.list, removedItem);
    this.selectedSubject.next(updated);
  }

  private registerUpdateSelectItems() {
    const getSelected = (arr: any[], ids: number[]) => {
      arr?.forEach((item) => {
        if (item.selected) ids.push(item);
        getSelected(item.childrens, ids);
      });
    };

    this.showListSubject.subscribe(() => {
      const selecteds = [];

      getSelected(this.list, selecteds);
      this.selectedSubject.next(selecteds);
    });
  }

  private registerFilter() {
    const filter = (arr: any[], value: string) =>
      arr.filter(
        (item) =>
          item.name.includes(value) ||
          item.childrens?.some((sub) => sub.name.includes(value))
      );

    this.searchSubject
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((value) =>
        value
          ? (this.filteredList = filter(this.list, value))
          : (this.filteredList = [...this.list])
      );
  }
}
