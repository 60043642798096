import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Transporte } from "src/app/core/models/transporte.models";

@Component({
  selector: "app-route",
  templateUrl: "./route.component.html",
  styleUrls: ["./route.component.scss"],
})
export class RouteComponent {
  @Input() selectedChoiceTranspFor;
  @Input() title: string;
  @Input() transport: Transporte[];
  @Output() clickRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickAddButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickEdit: EventEmitter<any> = new EventEmitter<any>();
  showRemoveAll: boolean;
  showMoreOptionsButton: boolean;

  getTotalValor(): number {
    return this.transport?.length > 0
      ? this.transport
          ?.map((t) => t.valor && Number.parseFloat(t.valor.toString()))
          .reduce((prev, curr) => prev + curr)
      : 0;
  }
}
