<div>
  <div class="row">
    <div class="col-8 col-md-8">
      <h3 class="text-uppercase text-title">ATENDIMENTO</h3>
    </div>
    <div class="col-4 col-md-4">
      <h3 class="text-sub">ATENDIMENTO <span class="text-id"> / ID 68532</span></h3>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body row">
    <div class="row mb-5">
      <div class="col-12 col-md-12">
        <h3 class="text-uppercase text-title">ATENDIMENTO ID 68532 <span class="text-loading">Aguardando</span></h3>
      </div>
      <div class="col-12 col-md-12">
        <h3 class="text-date">26/10/2023</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <label class="text-default-label">Tipo de atendimento</label>
        <ng-select
          class="custom"
          [items]=""
          bindLabel="name"
          bindValue="value"
          placeholder="Selecione "
          notFoundText="Nenhum atendimento encontrado."
        >
        </ng-select>
      </div>
      <div class="col-8">
        <label class="text-default-label">Categoria</label>
        <ng-select
          class="custom"
          [items]=""
          bindLabel="name"
          bindValue="value"
          placeholder="Selecione "
          notFoundText="Nenhum atendimento encontrado."
        >
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <label class="text-default-label">Observações</label>
        <input
          placeholder="Digite aqui o motivo dessa atendimento, detalhando da melhor maneira possível. Lembre-se de incluir tudo que for necessário para avaliação do RH."
          type="text"
          [(ngModel)]="justification"
          class="form-control"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <label class="text-default-label">Anexos:</label>
        <p class="p-style">Inclua em anexo todos os documentos que contribuam para sua solicitação.</p>
        
        <!-- Componente de Upload para múltiplos arquivos -->
        <app-upload-file (selectFileWithExtension)="getDocumentFile($event)" [isExhibition]="isExhibition"></app-upload-file>
        
        <!-- Lista de arquivos carregados -->
        <div class="image-container">
            <div *ngFor="let file of documentFiles; let i = index" class="img-extension">
               <!-- Visualização do arquivo -->
               <img  [src]="'data:' + extensions[i] + ';base64,' + file" alt="Arquivo carregado" width="100" *ngIf="file">
               
               <!-- Botão para excluir -->
               <button class="btn-transparent" (click)="removeFile(i)"> <img
                src="assets/images/DeleteOutlined.svg"
                alt=""
                class="close-img mt-2"
                height="24"
                width="24"
              /></button>
            </div>
         </div>

      <div class="col-md-12 mt-3 text-align-end">
        <button
          class="btn btn-secondary"
          type="button"
          [routerLink]="['/service']"
        >
          VOLTAR
        </button>
      </div>
    </div>
  </div>
</div>
