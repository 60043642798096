<section class="card">
  <article class="card-body">
    <h4 class="card-title">{{ title }}</h4>
    <div class="position-relative mt-5 m-4">
      <div class="progress" style="height: 1px">
        <div
          *ngIf="steps"
          class="progress-bar"
          role="progressbar"
          aria-valuemin="0"
          [attr.aria-valuenow]="currentProgressValue"
          [attr.aria-valuemax]="steps[steps?.length - 1]?.stepValue"
          [style.width]="currentProgressWidth"
        ></div>
      </div>
      <div class="row">
        <span
          *ngFor="let label of stepLabels; let i = index"
          class="col mt-4"
          >{{ label.label }}</span
        >
      </div>

      <ng-container *ngFor="let step of steps; let i = index">
        <button
          type="button"
          class="position-absolute top-0 translate-middle btn btn-sm btn-primary rounded-pill"
          style="width: 2rem; height: 2rem"
          [style.left]="step?.stepPercent"
        >
          {{ i + 1 }}
        </button>
      </ng-container>
    </div>
  </article>
</section>
