import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./core/guards/auth.guard";
import { LayoutComponent } from "./layouts/layout.component";
import { Page404Component } from "./extrapages/page404/page404.component";
import { canDeactivateHiringProcess } from "./core/guards/hiring-process.guard";
import { AccountComponent } from "./account/account.component";

const routes: Routes = [
  {
    path: "account",
    component: AccountComponent,
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },

  {
    path: "portal",
    canDeactivate: [canDeactivateHiringProcess],
    loadChildren: () =>
      import("./portal-admission/portal-admission.module").then(
        (m) => m.PortalAdmissionModule
      ),
  },
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },

  {
    path: "pages",
    loadChildren: () =>
      import("./extrapages/extrapages.module").then((m) => m.ExtrapagesModule),
  },
  { path: "**", component: Page404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
