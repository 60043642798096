import { filter } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AddressService } from "src/app/core/services/addres.service";
import { debounceTime, map } from "rxjs/operators";
import { CompanyService } from "src/app/core/services/company.service";
import { AppToastService } from "src/app/core/services/app-toast.service";
import { EnumsService } from "src/app/core/services/enums.service";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-dependents-metlife",
  templateUrl: "./dependents-metlife.component.html",
  styleUrls: ["./dependents-metlife.component.scss"],
})
export class DependentsComponentMetlife implements OnInit, OnChanges {
  @Input() isContributorProfile = false;
  @Input() isExhibition = false;
  @Input() dependentsValue;
  @Input() dependentsRemovedId;
  @Input() mandatoryDocuments;
  @Input() dependentsBenefits = [];
  @Input() mandatoryDocumentItem;
  @Input() genericDocumentValue;
  @Input() enabledOnChangeEvent = false;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() saveDependentValue = new EventEmitter();
  @Output() deleteDependentsValue = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() AddGenericDocument = new EventEmitter();
  @Output() fileChange :EventEmitter<any> = new EventEmitter();
  benefitTable = [];
  selectedBenefit = [];
  form: FormGroup;
  ufs = [];
  cities: any = [];
  documentFile: any;
  documentFileExtension: any;
  birthCertificateFiles = [];
  frontOfRgFiles = [];
  backOfRgFiles = [];
  addressProofFiles = [];
  mandatoryDocumentsFiles = [];

  dependentType = [];

  documentExceptions = [
    "COMPROVANTE_RESIDENCIA",
    "FRENTE_RG",
    "VERSO_RG",
    "CERTIDAO_CASAMENTO_NASCIMENTO",
  ];

  martialStatusArray = [];

  genderArray = [
    { name: 'Masculino', value: 1 },
    { name: 'Feminino', value: 2 },
    { name: 'Não binário(a)', value: 3 },
    { name: 'Fluido(a) de gênero', value: 4 },
    { name: 'Bigênero', value: 5 }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private addressService: AddressService,
    private toast: AppToastService,
    private enumsService: EnumsService,
    private utilsService:UtilsService
  ) {}

  get dependents() {
    return this.form?.controls["dependents"] as FormArray;
  }

  getDependent(index: number) {
    const formGroup = this.dependents.controls[index] as FormGroup;
    return formGroup.controls;
  }

  ngOnInit(): void {
    this.InitializeForm();
    this.companyService
      .getAllCountries()
      .subscribe((res: any) => (this.ufs = res));

    this.enumsService
      .getKinshipTypes()
      .subscribe((resp) => (this.dependentType = resp));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.dependentsValue?.currentValue;
    if (data) {
      data.forEach((payload, i) => {
        this.add(payload);
        this.getBirthCertificateFile(i, { base64: payload?.birthCertificateFile, extension: payload?.birthCertificateFile });
        this.getFrontOfRgFile(i, { base64: payload?.frontRgFile, extension: payload?.frontRgFileExtension });
        this.getBackOfRgFile(i, { base64: payload?.backRgFile, extension: payload?.backRgFileExtension });
        this.getAddressProofFile(i, { base64: payload?.addressProofFile, extension: payload?.addressProofFileExtension });
        payload?.mandatoryDocuments?.forEach((document) =>
          this.addMandatoryDocumentFile(i, document)
        );
      });
    }
  }

  removeData(changes: SimpleChanges) {
    const id = changes.dependentsRemovedId?.currentValue;
    if (id) {
      const array = this.dependents.controls;
      const i = array.findIndex((el: any) => el.id === id);
      this.dependents?.removeAt(i);
      this.birthCertificateFiles?.splice(i, 1);
      this.frontOfRgFiles?.splice(i, 1);
      this.backOfRgFiles?.splice(i, 1);
      this.addressProofFiles?.splice(i, 1);
    }
  }

  add(value?) {
    const newForm = this.formBuilder.group({
      id: [null],
      type: [{ value: "", disabled: this.isExhibition }, Validators.required],
      name: [{ value: "", disabled: this.isExhibition }, Validators.required],
      rg: [{ value: "", disabled: this.isExhibition }, Validators.required],
      cpf: [{ value: "", disabled: this.isExhibition }, Validators.required],
      motherName: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      birthdate: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      postalCode: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      address: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      number: [{ value: null, disabled: this.isExhibition }],
      neighborhood: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      complement: [{ value: "", disabled: this.isExhibition }],
      countryId: [
        { value: null, disabled: this.isExhibition },
        Validators.required,
      ],
      cityId: [
        { value: null, disabled: this.isExhibition },
        Validators.required,
      ],
    });

    newForm.controls.countryId.valueChanges.subscribe((value) => {
      if (value) this.getCitiesByState(value);
    });

    if (value) {
      newForm.patchValue(value);
      newForm.patchValue({ birthdate: value?.birthDate.split(" ")[0] });
    }

    this.dependents?.push(newForm);

    const index = this.dependents?.controls.length - 1;
    this.getAddresByCep(index);
  }

  remove(i) {
    const id = this.dependents.controls[i].value.id;
    if (id) {
      this.deleteDependentsValue.emit(id);
    }
    this.dependents.removeAt(i);
    this.birthCertificateFiles?.splice(i, 1);
    this.frontOfRgFiles?.splice(i, 1);
    this.backOfRgFiles?.splice(i, 1);
    this.addressProofFiles?.splice(i, 1);
    this.removeMandatoryDocuments(i);
  }

  getDocumentFile({ base64, extension }) {
    this.documentFile = base64;
    this.documentFileExtension = extension;

    this.genericDocumentValue = this.createGenericDocumenyPayload();

    if(this.enabledOnChangeEvent)
     this.fileChange.emit(this.genericDocumentValue);
  }

  // downloadFile() {
  //   const documentName = `${this.mandatoryDocumentItem.name}.${
  //     this.genericDocumentValue.documentExtension.split("/")[1]
  //   }`;

  //   this.utilsService.downloadFile(this.genericDocumentValue.documentFile,documentName);
  // }

  private createGenericDocumenyPayload() {
    return {
      documentFile: this.documentFile,
      documentId: this.mandatoryDocumentItem?.id,
      documentExtension: this.documentFileExtension,
    };
  }
  saveDependent(i) {
    if (
      !this.hasAddedAllRequiredDocuments(i) ||
      this.dependents.controls[i].invalid
    ) {
      this.dependents.controls.forEach((form: FormGroup) =>
        Object.keys(form.controls).forEach((key) => {
          form.controls[key].markAsDirty();
        })
      );

      return;
    }

    const payload = {
      ...this.dependents.value[i],
      birthCertificateFile: this.birthCertificateFiles[i]?.doc,
      birthCertificateFileExtension: this.birthCertificateFiles[i]?.docExtension,
      frontOfRgFile: this.frontOfRgFiles[i]?.doc,
      frontOfRgFileExtension: this.frontOfRgFiles[i]?.docExtension,
      backOfRgFile: this.backOfRgFiles[i]?.doc,
      backOfRgFileExtension: this.backOfRgFiles[i]?.docExtension,
      addressProofFile: this.addressProofFiles[i]?.doc,
      addressProofFileExtension: this.addressProofFiles[i]?.docExtension,
      mandatoryDocuments: this.getDependentDocuments(
        i,
        this.mandatoryDocumentsFiles
      )?.map((data) => data.doc),
    };

    this.saveDependentValue.emit(payload);
  }

  nextPage() {
    this.next.emit();
  }

  private InitializeForm() {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      dependents: this.formBuilder.array([]),
    });
  }

  getAddresByCep(index: number) {
    const formGroup = this.dependents?.controls[index] as FormGroup;

    formGroup
      ?.get("postalCode")
      ?.valueChanges.pipe(
        filter((value) => value?.length === 8),
        debounceTime(500),
        map((cep: string) => {
          this.addressService
            .getAddressByCEP(cep)
            .subscribe(async (res: any) => {
              if (res?.erro) {
                this.toast.show("Busca por cep", "Cep não encontrado", false);
                return;
              }
              if (res) {
                const uf = this.ufs.filter(
                  (data: any) => data?.name === res?.uf
                )[0]?.id;

                formGroup.get("countryId")?.patchValue(uf);
                formGroup.get("address")?.patchValue(res.logradouro);
                formGroup.get("neighborhood")?.patchValue(res.bairro);
                formGroup.get("cityId")?.patchValue(res.localidade);
                formGroup.get("complement")?.patchValue(res.complemento);
                formGroup.get("state")?.patchValue(res.localidade);

                this.getCitiesByState(uf, index, res.localidade);
              }
            });
        })
      )
      .subscribe();
  }

  getCitiesByState(countryId: number, index?: number, term?: string) {
    this.companyService.getAllCities(countryId, term).subscribe((data) => {
      this.cities = data;

      if (this.cities.length === 1 && index !== null) {
        const formGroup = this.dependents.controls[index] as FormGroup;
        formGroup.get("cityId").setValue(data[0].id);
      }
    });
  }

  getBirthCertificateFile(i, payload) {
    if (!payload) {
      this.birthCertificateFiles?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.extension,
    };

    this.birthCertificateFiles.push(document);
  }

  getFrontOfRgFile(i, payload) {
    if (!payload) {
      this.frontOfRgFiles?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.extension,
    };

    this.frontOfRgFiles.push(document);
  }

  getBackOfRgFile(i, payload) {
    if (!payload) {
      this.backOfRgFiles?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.extension,
    };

    this.backOfRgFiles.push(document);
  }

  getAddressProofFile(i, payload) {
    if (!payload) {
      this.addressProofFiles?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.extension,
    };

    this.addressProofFiles.push(document);
  }

  addMandatoryDocumentFile(i, payload) {
    const removeDuplicatedDocument = (documentId: number, arr: any[]): any[] =>
      arr.filter(({ doc }) => doc.documentId !== documentId);

    if (!payload) {
      this.mandatoryDocumentsFiles?.splice(i, 1);
      return;
    }

    let dependentDocuments = this.getDependentDocuments(
      i,
      this.mandatoryDocumentsFiles
    );

    if (dependentDocuments) {
      dependentDocuments = removeDuplicatedDocument(
        payload.documentId,
        dependentDocuments
      );

      this.mandatoryDocumentsFiles = [
        ...this.mandatoryDocumentsFiles.filter(({ id }) => id !== i),
        ...dependentDocuments,
      ];
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.extension,
    };

    this.mandatoryDocumentsFiles.push(document);
  }

  getDependentTitle(i) {
    const id = this.getDependent(i).type?.value;
    const title = this.dependentType.filter(
      (type: any) => type.value === id
    )[0];

    return title?.name;
  }

  logoutEmit() {
    this.logout.emit();
  }

  getMandatoryDocumentValue(item: any, i: number) {
    return this.getDependentDocuments(i, this.mandatoryDocumentsFiles)?.find(
      ({ doc }) => doc.documentId === item.id
    )?.doc;
  }

  downloadFile(i:number,mandatoryDocument:any,{doc, docExtension}) {
    const documentName = `${mandatoryDocument.name}.${docExtension}`;

    this.utilsService.downloadFile(doc,documentName);
  }

  private getDependentDocuments = (dependentId: number, arr: any[]): any[] =>
    arr.filter(({ id }) => id === dependentId);

  private removeMandatoryDocuments = (i: number) =>
    (this.mandatoryDocumentsFiles = [
      ...this.mandatoryDocumentsFiles.filter(({ id }) => id !== i),
    ]);

  private hasAddedAllRequiredDocuments(i: number) {
    let canSave = true;
    this.mandatoryDocuments.dependentDocuments.forEach((document) => {
      if (document.required && !this.getMandatoryDocumentValue(document, i)) {
        this.toast.show(
          "Documentos obrigatórios",
          `O documento "${document.displayName}" é obrigatório.`
        );
        canSave = false;
      }
    });

    return canSave;
  }
}
