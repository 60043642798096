<app-navbar></app-navbar>

<div class="container-portal">
  <section class="card">
    <article class="card-body row flex-shrink">
      <h4 class="col-md-12 mb-3 text-uppercase">Processo de admissão</h4>
      <app-wizard
        [steps]="steps"
        [currentStepIndex]="currentStepIndex"
        (stepChangeIndex)="currentStepIndex = $event"
        [allowNavigation]="true"
      >
        <app-wizard-step-content [step]="steps[0]">
          <app-data-access
            [dataAccess]="getDataAccess$ | async"
            [currentStepIndex]="currentStepIndex"
            (dataAccessValue)="getDataAccessValue($event)"
            (previousStepIndexChange)="onPreviousStepIndexChange()"
          ></app-data-access>
        </app-wizard-step-content>

        <app-wizard-step-content [step]="steps[1]">
          <app-personal-bank-details
            [getPersonalBankDetails]="getPersonalBankDetails$ | async"
            [bankList]="bankList$ | async"
            (personalBankDetailsValue)="getPersonalBankDetailsValue($event)"
            (previousStepIndexChange)="onPreviousStepIndexChange()"
          ></app-personal-bank-details>
        </app-wizard-step-content>

        <app-wizard-step-content [step]="steps[2]">
          <h6>Documentação</h6>
          <app-documentation-step
            [documentation]="documentation$ | async"
            [schoolRemovedId]="schoolRemovedId"
            [mandatoryDocuments]="mandatoryDocumentsList$ | async"
            (IdCardValue)="getIdCardValue($event)"
            (CpfValue)="getCpfValue($event)"
            (WorkCardValue)="WorkCardValue($event)"
            (BirthMarriageCertificateDoc)="BirthMarriageCertificateDoc($event)"
            (VoterRegistrationValue)="VoterRegistrationValue($event)"
            (AddressProofDocument)="AddressProofDocument($event)"
            (SaveSchoolValue)="SaveSchoolValue($event)"
            (DeleteSchoolValue)="DeleteSchoolValue($event)"
            (AddGenericDocument)="addGenericDocument($event)"
            (next)="onStepIndexChanged($event)"
            (back)="onPreviousStepIndexChange()"
            (logout)="logout()"
          >
          </app-documentation-step>
        </app-wizard-step-content>

        <app-wizard-step-content [step]="steps[3]">
          <app-dependents
            [dependentsValue]="dependents$ | async"
            [dependentsRemovedId]="dependentsRemovedId"
            [mandatoryDocuments]="mandatoryDocumentsList$ | async"
            (deleteDependentsValue)="deleteDependentsValue($event)"
            (saveDependentValue)="saveDependentValue($event)"
            (next)="onStepIndexChanged($event)"
            (back)="onPreviousStepIndexChange()"
            (logout)="logout()"
          >
          </app-dependents>
        </app-wizard-step-content>

        <app-wizard-step-content [step]="steps[4]">
          <app-benefits
            [benefits]="benefits$ | async"
            [dependentsBenefits]="benefitsValue$ | async"
            [employeeAddress]="getPersonalBankDetails$ | async"
            [matriz]="matriz"
            (previous)="onPreviousStepIndexChange()"
            (logout)="getBenefitsValue($event, true)"
            (saveAndFinish)="finish($event)"
          >
          </app-benefits>
        </app-wizard-step-content>
      </app-wizard>
    </article>
  </section>
</div>
