<section id="app-add-new">
  <article class="col-md-12 row justify-content-between">
    <div class="col-md-5 m-1">
      <h4 class="align-self-end d-inline-block form-check-label text-uppercase">
        {{ title }}
      </h4>
      <div class="form-check form-switch d-inline-block">
        <input
          class="form-check-input d-inline-block"
          type="checkbox"
          id="flexSwitchCheckDefault"
          *ngIf="showToggle"
          [(checked)]="toggleStatus"
          (click)="toggleChange.emit($event.target.checked)"
        />
      </div>
    </div>
    <div class="col-md-7 row justify-content-end">
      <button
        (click)="clickAddNew.emit()"
        type="button"
        class="btn btn-primary col-md-5 col-sm-10 m-1"
      >
        {{ buttonLabel }}
      </button>
      <app-more-options-button *ngIf="showMoreOptionsButton">
        <ng-template let-items #items>
          <button
            *ngIf="showRemoveAll && !withParamter"
            (click)="clickRemoveAll.emit()"
            ngbDropdownItem
          >
            Excluir Ítens
          </button>
          <button
            *ngIf="showRemoveAll && withParamter"
            (click)="clickRemoveAll.emit($event)"
            ngbDropdownItem
          >
            Opa Excluir Ítens
          </button>
          <button
            *ngIf="showCustomButton"
            (click)="customButtomAction.emit()"
            ngbDropdownItem
          >
            {{ customLabel }}
          </button>
        </ng-template>
      </app-more-options-button>
    </div>
    <hr class="w-100 border-bottom mt-3" />
  </article>
</section>
