import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, shareReplay } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class EnumsService {
  private url = `${environment.url}/v1/enums`;

  constructor(private http: HttpClient) {}

  getKinshipTypes = (): Observable<any> =>
    this.http
      .get(`${this.url}/dependent`)
      .pipe(map((data: any[]) => data.filter((item) => item.value !== 0)));

  getBankAccountType = (): Observable<any> =>
    this.http.get(`${this.url}/bank-account`).pipe(shareReplay());

  getGenderTpes = (): Observable<any> =>
    this.http.get(`${this.url}/gender`).pipe(shareReplay());

  getMaritalType = (): Observable<any> =>
    this.http.get(`${this.url}/marital-status`);

  getCtpsType = (): Observable<any> => this.http.get(`${this.url}/ctps-type`);

  getCpfDocumentType = (): Observable<any> =>
    this.http.get(`${this.url}/cpf-document-type`);

  getEmployeeStatus = (): Observable<any> =>
    this.http.get(`${this.url}/employee-data-status`);

  getUserServiceStatus = (): Observable<any> =>
    this.http
      .get(`${this.url}/employee-service-status`)
      .pipe(map((data: any[]) => data.filter((item) => item.value !== 0)));

  getUserServiceType = (): Observable<any> =>
    this.http.get(`${this.url}/employee-service-type`).pipe(
      map((data: any[]) => data.filter((item) => item.value !== 0)),
      map((data) => data.filter((item) => item.value === 1))
    );

  getUserServiceCategory = () =>
    this.http.get(`${this.url}/employee-service-category`);

  getRaceColor = () =>
    this.http
      .get<any>(`${this.url}/race-colors`)
      .pipe(
        map((data) =>
          data.map((item) => ({ value: item.id, name: item.description }))
        )
      );

  getForeignStatus = () =>
    this.http
      .get<any>(`${this.url}/foreign-status`)
      .pipe(
        map((data) =>
          data.map((item) => ({ value: item.id, name: item.description }))
        )
      );

  getRetiredType = () =>
    this.http
      .get<any>(`${this.url}/retired-types`)
      .pipe(
        map((data) =>
          data.map((item) => ({ value: item.id, name: item.description }))
        )
      );

  getPcdType = () =>
    this.http
      .get<any>(`${this.url}/pcd-types`)
      .pipe(
        map((data) =>
          data.map((item) => ({ value: item.id, name: item.description }))
        )
      );
}
