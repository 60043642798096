import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-academic-education",
  templateUrl: "./academic-education.component.html",
  styleUrls: ["./academic-education.component.scss"],
})
export class AcademicEducationComponent implements OnInit, OnChanges {
  form: FormGroup;

  documentList = [];

  @Input() academicEducation;
  @Input() isExhibition = false;
  @Input() schoolRemovedId;

  @Output() SaveSchool = new EventEmitter();
  @Output() DeleteSchoolValue = new EventEmitter();

  constructor(private fb: FormBuilder, private utilsService: UtilsService) {}

  ngOnInit() {
    this.buidlForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
    this.removeData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.academicEducation?.currentValue;

    if (data) {
      data.forEach((education, i) => {
        const document = `${education?.document}`;
        const documentExtension = `${education?.documentExtension}`;
        this.addEducation(education);
        this.getDocument(i, { base64:document, extension:documentExtension } );
      });
    }
  }

  getEducationForm(index: number) {
    const formGroup = this.educations.controls[index] as FormGroup;
    return formGroup.controls;
  }

  removeData(changes: SimpleChanges) {
    const id = changes.schoolRemovedId?.currentValue;
    if (id) {
      const array = this.educations.controls;
      const i = array.findIndex((el: any) => el.id === id);
      this.educations.removeAt(i);
      this.documentList.splice(i, 1);
    }
  }

  get educations(): FormArray {
    return this.form.get("educations") as FormArray;
  }

  buidlForm() {
    this.form = this.fb.group({
      educations: this.fb.array([]),
    });
  }

  addEducation(value?) {
    const education = this.fb.group({
      id: [null],
      educationInstitutionName: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      courseName: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      conclusionDate: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
    });

    if (value) {
      education.patchValue(value);
      education.patchValue({
        conclusionDate: value.conclusionDate.split(" ")[0],
      });
    }

    this.educations.push(education);
  }

  removeEducation(i: number) {
    const id = this.educations.controls[i].value.id;
    if (id) {
      this.DeleteSchoolValue.emit(id);
    } else {
      this.educations.removeAt(i);
    }
  }

  saveForm(i) {
    const form = this.educations.controls[i];
    const doc = this.documentList[i]?.doc?.replace("", "");
    const docExtension = this.documentList[i]?.docExtension?.replace("", "");
    const payload = {
      ...form.value,
      certificateFile: doc,
      certificateFileExtension: docExtension,
    };

    if (this.educations.controls[i].invalid) {
      this.educations.controls.forEach((form: FormGroup) =>
        Object.keys(form.controls).forEach((key) => {
          form.controls[key].markAsDirty();
        })
      );
      return;
    }

    this.SaveSchool.emit(payload);
  }

  downloadFile(index: number) {
    const doc = this.documentList[index]?.doc?.replace("", "");
    const docExtension = this.documentList[index]?.docExtension?.replace("", "");

    if (doc) this.utilsService.downloadFile(doc, `CERTIFICADO_${index}.${docExtension}`);
  }

  getDocument(i, payload) {
    if (!payload) {
      this.documentList?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.extension
    };

    this.documentList.splice(i, 1);
    this.documentList.splice(i, 0, document);
    // this.documentList.push(document);
  }

  removeSchoolById(array, id) {
    const indice = array.findIndex((elemento) => elemento.id === id);
    if (indice !== -1) {
      array.splice(indice, 1);
    }
  }
}
