
import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-sort",
  templateUrl: "./sort.component.html",
  styleUrls: ["./sort.component.scss"],
})
export class SortComponent {
  selected = "";
  @Output() clicked = new EventEmitter<string>();

  onClick(value: string) {
    this.selected = value;
    this.clicked.emit(this.selected);
  }
}

