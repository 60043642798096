<div class="row">
  <ng-container *ngIf="mandatoryDocuments">
    <ng-container *ngFor="let item of mandatoryDocuments.employeeDocuments">
      <app-id-card
        *ngIf="item?.name === 'RG'"
        [isExhibition]="isExhibition"
        [ufs]="ufs$ | async"
        [idCardValue]="documentation?.rg"
        (formValue)="getIdCardValue($event)"
      >
      </app-id-card>

      <app-cpf
        *ngIf="item?.name === 'CPF'"
        [isExhibition]="isExhibition"
        [cpfValue]="documentation?.cpf"
        (formValue)="getCpfValue($event)"
      >
      </app-cpf>

      <app-work-card
        *ngIf="item?.name === 'CTPS'"
        [isExhibition]="isExhibition"
        [ufs]="ufs$ | async"
        [ctps]="documentation?.ctps"
        (formValue)="getWorkCardfValue($event)"
      >
      </app-work-card>

      <app-birth-marriage-certificate
        *ngIf="item?.name === 'CERTIDAO_CASAMENTO_NASCIMENTO'"
        [isExhibition]="isExhibition"
        [birthCertificate]="documentation?.birthCertificate"
        [birthCertificateExtension]="documentation?.birthCertificateExtension"
        (birthCertificateDocumentValue)="getBirthMarriageCertificateDoc($event)"
      >
      </app-birth-marriage-certificate>

      <app-voter-registration
        *ngIf="item?.name === 'TITULO_ELEITOR'"
        [isExhibition]="isExhibition"
        [ufs]="ufs$ | async"
        [voter]="documentation?.voter"
        (formValue)="getVoterRegistration($event)"
      >
      </app-voter-registration>

      <app-proof-residence
        *ngIf="item?.name === 'COMPROVANTE_RESIDENCIA'"
        [isExhibition]="isExhibition"
        [addressProofDocument]="documentation?.addressProofDocument"
        [addressProofDocumentExtension]="documentation?.addressProofDocumentExtension"
        (formValue)="getAddressProofDocument($event)"
      >
      </app-proof-residence>

      <app-academic-education
        *ngIf="item?.name === 'FORMACAO_ACADEMICA'"
        [isExhibition]="isExhibition"
        [academicEducation]="documentation?.academicEducation"
        [schoolRemovedId]="schoolRemovedId"
        (SaveSchool)="getSaveSchool($event)"
        (DeleteSchoolValue)="deleteSchool($event)"
      >
      </app-academic-education>

      <app-generic-document
        *ngIf="canShowGenericDocument(item?.name)"
        [mandatoryDocumentItem]="item"
        [isExhibition]="isExhibition"
        [genericDocumentValue]="getMandatoryDocumentValue(documentation?.mandatoryDocuments,item)"
        (formValue)="AddGenericDocument.emit($event)"
      >
      </app-generic-document>
    </ng-container>
  </ng-container>

  <hr class="w-100 border-bottom my-5" />

  <div class="col-md-12 row justify-content-end my-">
    <div class="col-md-4 text-align-end" *ngIf="!isContributorProfile">
      <button
        type="submit"
        class="btn btn-outline-secondary m-1 text-uppercase"
        (click)="logoutEmit()"
      >
        salvar e sair
      </button>

      <button
        class="btn btn-secondary text-uppercase"
        type="button"
        (click)="back.emit()"
      >
        Voltar
      </button>

      <button
        type="submit"
        class="btn btn-primary m-1 text-uppercase"
        (click)="next.emit(2)"
      >
        Salvar e avançar
      </button>
    </div>
  </div>
</div>
