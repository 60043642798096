import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DependentsService {

  private baseUrlApi = environment.url;

  constructor(private http: HttpClient) { }

  getDependents(id) {
    const endpoint = `${this.baseUrlApi}/v1/hirings/${id}/steps/4`
    return this.http.get(endpoint)
  }

  postDependents(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/hirings/${id}/steps/4`
    return this.http.post(endpoint, body)
  }

  putDependents(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/hirings/${id}/steps/4`
    return this.http.put(endpoint, body)
  }

  deleteDependent(id, dependentId) {
    const endpoint = `${this.baseUrlApi}/v1/hirings/${id}/steps/4/${dependentId}`
    return this.http.delete(endpoint);
  }
}
