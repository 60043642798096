import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppToastService } from "src/app/core/services/app-toast.service";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-id-card",
  templateUrl: "./id-card.component.html",
  styleUrls: ["./id-card.component.scss"],
})
export class IdCardComponent implements OnInit, OnChanges {
  idCardForm: FormGroup;
  frontDocumentFile: any;
  frontDocumentFileExtension: any;
  backDocumentFile: any;
  backDocumentFileExtension: any;

  loadFrontDocumentFile: any;
  loadBackDocumentFile: any;

  RGValidPattern = /^\d{1,2}\.\d{3}\.\d{3}-\d{1}|^\d{9}$/;

  @Input() idCardValue;
  @Input() ufs;
  @Input() isExhibition = false;

  @Output() formValue: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private toast: AppToastService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.idCardForm) this.buildForm();

    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.idCardValue?.currentValue;

    if (data) {
      this.idCardForm?.patchValue({
        issueDate: data.dateIssue?.split(" ")[0],
        issuingAgency: data.issuingAgency,
        countryId: data.countryId,
      });

      this.frontDocumentFile = `${data.frontOfDocument}`;
      this.frontDocumentFileExtension = `${data.frontOfDocumentExtension}`;
      this.backDocumentFile = `${data.backOfDocument}`;
      this.backDocumentFileExtension = `${data.backOfDocumentExtension}`;
    }
  }

  buildForm() {
    this.idCardForm = this.fb.group({
      issueDate: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      issuingAgency: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      countryId: [
        { value: null, disabled: this.isExhibition },
        Validators.required,
      ],
    });
  }

  isInvalidField(field: string): boolean | undefined {
    const formField = this.idCardForm.get(field);
    return formField?.invalid && formField?.touched;
  }

  getFrontDocumentFile(event) {
    this.frontDocumentFile = event.base64;
    this.frontDocumentFileExtension = event.extension;
  }

  getBackDocumentFile(event) {
    this.backDocumentFile = event.base64;
    this.backDocumentFileExtension = event.extension;
  }

  idFormValueEmit() {
    this.idCardForm.markAllAsTouched();

    if (
      this.idCardForm.invalid ||
      !this.frontDocumentFile ||
      !this.backDocumentFile
    ) {
      this.toast.show(
        "Validação de formulário",
        "Informações obrigatórias não preenchidas. Revise os campos e salve novamente."
      );
      return;
    }

    const idCardFormValue = {
      ...this.idCardForm.getRawValue(),
      frontDocumentFile: this.frontDocumentFile,
      frontDocumentFileExtension: this.frontDocumentFileExtension,
      backDocumentFile: this.backDocumentFile,
      backDocumentFileExtension: this.backDocumentFileExtension,
    };

    this.formValue.emit(idCardFormValue);
  }

  downloadFile() {
    if (this.frontDocumentFile && this.frontDocumentFile !== "null")
      this.utilsService.downloadFile(this.frontDocumentFile, `RG_FRENTE.${this.frontDocumentFileExtension}`);

    if (this.backDocumentFile && this.backDocumentFile !== "null")
      this.utilsService.downloadFile(this.backDocumentFile, `RG_VERSO.${this.backDocumentFileExtension}`);
  }
}
