import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class TraveService {
  constructor(private http: HttpClient) {}

  baseUrl = `${environment.url}/v1/travel-itinerary`;

  public getPublicTravel(params):Observable<any> {
    return this.http.get(`${this.baseUrl}/public`, { params: params || {} });
  }
public getCharteredTravel():Observable<any> {
    return this.http.get(`${this.baseUrl}/chartered`,{params:{description:''}});
  }
}
