<app-accordion [title]="'Certidão de nascimento / casamento'">
  <div class="d-flex flex-column">
    <app-form-card>
      <div class="row">
        <div class="col-md-12">
          <label class="text-default-label">Carregar arquivo*</label>
          <app-upload-file
            (selectFileWithExtension)="getBirthMarriageCertificate($event)"
            [selectedImage]=" birthCertificateDocument && 'data:application/'+ birthCertificateDocumentExtension +';base64,' + birthCertificateDocument"
            [isExhibition]="isExhibition"
          >
          </app-upload-file>
          <small class="text-danger" *ngIf="!birthCertificateDocument">
            Por favor, forneça uma cópia da certidão de nascimento e da certidão
            de casamento.
          </small>
        </div>
      </div>

      <div
        class="d-flex w-100 justify-content-end align-itens-center py-4"
        *ngIf="!isExhibition"
      >
        <button
          class="btn btn-outline-primary text-uppercase m-1"
          (click)="emitBirthMarriageCertificate()"
        >
          salvar
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary text-uppercase m-1"
          (click)="downloadFile()"
        >
          baixar documento
        </button>
      </div>
    </app-form-card>
  </div>
</app-accordion>
