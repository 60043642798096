import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeesHiringService {

  constructor(private http: HttpClient) { }

  getEmployeesHiring(employeeId: string) {
    const endpoint = `${environment.url}/v1/employees/${employeeId}/hiring`;
    return this.http.get(endpoint);
  }

  finish(id:number){
    const endpoint = `${environment.url}/v1/hirings/${id}/finish`
    return this.http.post(endpoint, {});
  }

}
