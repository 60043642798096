import { Component, Input } from "@angular/core";

@Component({
  selector: "app-step-progressbar",
  templateUrl: "./step-progressbar.component.html",
  styleUrls: ["./step-progressbar.component.scss"],
})
export class StepProgressbarComponent {
  @Input() currentProgressValue: number;
  @Input() stepAmount: number;
  @Input() stepLabels: Array<{ label: string }>;
  @Input() title: string;

  constructor() {
    this.title = "Titulo";
    this.currentProgressValue = 33.33;
    this.stepAmount = 3;
  }

  get currentProgressWidth() {
    return this.currentProgressValue + "%";
  }

  get steps() {
    const slice = 100 / this.stepAmount;

    const result = Array.from(
      { length: this.stepAmount },
      (v, index) => ({
        stepPercent: `${index * slice}%`,
        stepValue: index * slice,
      })
      // index + 1 == this.stepAmount ? "100%" : `${index * slice}%`
    );

    return result;
  }
}
