<app-accordion class="my-1" [title]="'Benefício Mobilidade'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">{{ benefit.alias }}</label>

      <div class="row mt-4">
        <div class="col-md-12 col-12 col-sale">
          <label class="text-sale">Escolha a modalidade de benefício</label>
          <ng-select
            placeholder="Selecione os benefício"
            [(ngModel)]="selectedPlan"
            (change)="setBenefitChoice()"
          >
            <ng-option *ngFor="let item of benefit.items" [value]="item">{{
              item.name
            }}</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="row mt-4" *ngIf="selectedPlan">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-valuedesc">Valor </label>
          {{ selectedPlan?.cost | currency : "BRL" : "symbol" }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-2 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ selectedPlan?.existDiscountToHolder }}</span>
        </div>

        <div class="col-md-6 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>

          <ng-container *ngIf="selectedPlan?.holderDiscountType === 2">
            <span>{{ selectedPlan?.holderDiscount }}%</span>
          </ng-container>
          <ng-container *ngIf="selectedPlan?.holderDiscountType === 1">
            <span>{{
              selectedPlan?.holderDiscount | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>

        <div class="col-md-4 col-12 col-benefic">
          <label class="text-benefictransp">Deseja aderir ao benefício</label>
          <div class="custom-checkbox-container">
            <label class="custom-checkbox">
              <input
                type="radio"
                name="choiceNine"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="false"
                (change)="setBenefitChoice()"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Não
            </label>

            <label class="custom-checkbox">
              <input
                type="radio"
                name="choiceNine"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="true"
                (change)="setBenefitChoice()"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Sim
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
