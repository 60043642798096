<section class="card" id="app-create-update-user">
  <div class="card-body row">
    <div class="col-md-12">
      <h6>{{ companyName }}</h6>
    </div>
    <div class="col-md-12 mt-3">
      <form action="" class="row form-group" [formGroup]="form">
        <div class="col-md-12 row align-items-start">
          <div class="col-md-6 my-3">
            <label class="text-default-label">Filiais</label>
            <input type="text" class="form-control" formControlName="branch" />
          </div>

          <div class="col-md-6 my-3">
            <label class="text-default-label">Área</label>
            <input type="text" class="form-control" formControlName="area" />
          </div>
        </div>

        <div class="row my-3 align-items-start">
          <div class="col-md my-3">
            <label class="text-default-label">Superior imediato</label>
            <input
              type="text"
              class="form-control"
              formControlName="directSupervisor"
            />
          </div>

          <div class="col-md my-3">
            <label class="text-default-label">Cargo</label>
            <input type="text" class="form-control" formControlName="role" />
          </div>

          <div class="col-md my-3">
            <label class="text-default-label">Salário</label>
            <input
              type="text"
              class="form-control"
              currencyMask
              formControlName="salary"
              [options]="{ prefix: 'R$', thousands: '.', decimal: ',' }"
              formControlName="salary"
            />
          </div>
        </div>

        <div class="col-md-12">
          <label class="text-default-label">Descrição do cargo</label>
          <div
            class="border pt-3 px-3"
            [innerHTML]="dataAccess?.vacancyDescription"
          ></div>
        </div>

        <div class="col-md-12 row align-items-start">
          <div class="col-md-3 my-3">
            <label class="text-default-label">Nome do centro de custo</label>
            <input
              type="text"
              class="form-control"
              formControlName="centralCostName"
            />
          </div>
          <div class="col-md-3 my-3">
            <label class="text-default-label">Nº do centro de custo</label>
            <input
              type="number"
              class="form-control"
              formControlName="centralCostNumber"
            />
          </div>
          <div class="col-md-2 my-3">
            <label class="text-default-label">Lotação</label>
            <input
              type="text"
              class="form-control"
              formControlName="capacity"
            />
          </div>

          <div class="col-md-3 my-3">
            <label class="text-default-label">Data de admissão</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="hiringDate"
              />
            </div>
          </div>
          <div class="col-md-3 my-3">
            <label class="text-default-label">Nº sequencial de matrícula</label>
            <input
              type="text"
              class="form-control"
              formControlName="registrationNumber"
            />
          </div>
        </div>
        <div class="col-md-12 row align-items-start">
          <div class="col-md-6 my-3">
            <label class="text-default-label">Nome*</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid': f?.username.dirty && f?.username.errors
              }"
              formControlName="username"
              placeholder="Digite o nome completo"
            />
            <div *ngIf="f?.username.errors" class="invalid-feedback">
              <span *ngIf="f?.username.errors.required"
                >O nome é obrigatório.</span
              >
            </div>
          </div>
          <div class="col-md-3 my-3">
            <label for="emailLabel" class="text-default-label"
              >E-mail ou CPF*</label
            >
            <input
              type="email"
              class="form-control"
              formControlName="email"
              [ngClass]="{ 'is-invalid': f?.email.dirty && f?.email.errors }"
              placeholder="ex: emailbom@email.com.br"
            />
            <div *ngIf="f?.email.errors" class="invalid-feedback">
              <span *ngIf="f?.email.errors.required"
                >O e-mail é obrigatório.</span
              >
            </div>
          </div>

          <div class="col-md-3 my-3">
            <label for="phoneLabel" class="text-default-label">Celular*</label>
            <input
              type="text"
              class="form-control"
              mask="(00)00000-0000"
              [ngClass]="{ 'is-invalid': f?.phone.dirty && f?.phone.errors }"
              placeholder="ex: (99) 99999-9999"
              formControlName="phone"
            />
            <div *ngIf="f?.phone.errors" class="invalid-feedback">
              <span *ngIf="f?.phone.errors.required"
                >O telefone é obrigatório.</span
              >
            </div>
          </div>
        </div>

        <hr class="w-100 border-bottom my-5" />
        <div class="col-md-12 row justify-content-end">
          <div class="col-md-4 text-align-end" *ngIf="!isExhibition">
            <button
              type="submit"
              class="btn btn-outline-secondary m-1 text-uppercase"
              (click)="next(true)"
            >
              salvar e sair
            </button>
            <button
              type="submit"
              class="btn btn-primary m-1 text-uppercase"
              (click)="next()"
            >
              Salvar e avançar
            </button>
          </div>

          <div class="col-md-4 text-align-end" *ngIf="isContributorProfile">
            <button
              type="submit"
              class="btn btn-primary m-1 text-uppercase"
              (click)="next()"
            >
              salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
