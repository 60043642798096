import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timerFormat",
})
export class TimerFormatPipe implements PipeTransform {
  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    // const seconds: number = Math.floor(value % 60);

    return `${this.pad(hours)}h:${this.pad(minutes)}m`;
  }

  private pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
