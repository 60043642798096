import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalAdmissionComponent } from './portal-admission.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  { path: "", component: PortalAdmissionComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes), NgbModule,], 
  exports: [RouterModule]
})
export class PortalAdmissionRoutingModule { }
