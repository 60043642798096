import { Component, Output, EventEmitter, Input } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { AppToastService } from "src/app/core/services/app-toast.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CompetenceService } from 'src/app/core/services/competence.service';

@Component({
  selector: "app-modal-create",
  templateUrl: "./modal-create.component.html",
  styleUrls: ["./modal-create.component.scss"],
})
export class ModalCreateComponent {
  @Output() clickUpdateTable: EventEmitter<void> = new EventEmitter();
  matriz$: Observable<any>; // Use a proper type if possible
  branches$: Observable<any>; // Use a proper type if possible
  form: FormGroup;
  id: number;
  totalAttitudes = 0;
  totalSkills = 0;

  get f() {
    return this.form ? this.form.controls : null;
  }
  get attitudes() {
    return this.form.get("attitudes") as FormArray;
  }
  get skills() {
    return this.form.get("skills") as FormArray;
  }
  get isTotalAttitudesValid(): boolean {
    return this.totalAttitudes === 100;
  }
  get isTotalSkillsValid(): boolean {
    return this.totalSkills === 100;
  }
  get formDisabled(): boolean {
    this.f.name.markAsDirty();
    this.f.description.markAsDirty();
    if (!this.toggle.switch)
      return this.f.name.invalid || this.f.description.invalid;
    return this.form.invalid || !this.isTotalAttitudesValid || !this.isTotalSkillsValid || this.skills.length <= 0 || this.attitudes.length <= 0;
  }

  constructor(
    private modalService: NgbModal,
    private formBuild: FormBuilder,
    private formBuilder: FormBuilder,
    private service: CompetenceService,
    private toast: AppToastService,
    public activeModal: NgbActiveModal
  ) { }

  toggle = { switch: false };

  ngOnInit() {
    this.createForm();
  }

  onSubmit() {
    if (!this.toggle.switch && this.f.name.invalid || this.f.description.invalid) {
      this.f.name.markAsDirty();
      this.f.description.markAsDirty();
      return;
    }

    if (this.toggle.switch && this.form.invalid) {
      this.f.name.markAsDirty();
      this.f.description.markAsDirty();
      return;
    }

    const payloadCompetence = { name: this.f.name.value, description: this.f.description.value };
    this.service.create(payloadCompetence).subscribe(respCompetence => {
      const paylodAttitudesAndSkills = {
        competenceId: respCompetence.data.id,
        listAttributes: this.toggle.switch ? this.attitudes.controls.filter(x => x != null).map((item) => {
          return { name: item.get('name').value, percentage: item.get('quantity').value };
        }) : [],
        listSkills: this.toggle.switch ? this.skills.controls.filter(x => x != null).map((item) => {
          return { name: item.get('name').value, percentage: item.get('quantity').value };
        }) : []
      };
      this.service.addSkillsAndAttitudes(paylodAttitudesAndSkills).subscribe((resp) => {
        this.toast.show("Competência", `Competência ${payloadCompetence.name} criada com sucesso!`);
      }, error => { }, () => {
        this.clickUpdateTable.emit();
        this.activeModal.close();
      })
    })

  }

  addEntrySkills() {
    if (this.skills.controls.length < 5) {
      this.skills.push(this.createEntry());
      this.calcSkills();
    }
    this.skills.controls.forEach(skill => {
      skill.get('name').markAsDirty();
      skill.get('quantity').markAsDirty();
    });
  }
  addEntryAttitudes() {
    if (this.attitudes.controls.length < 5) {
      this.attitudes.push(this.createEntry());
      this.calcAttitudes();
    }
    this.attitudes.controls.forEach(attitude => {
      attitude.get('name').markAsDirty();
      attitude.get('quantity').markAsDirty();
    });
  }

  updateAttitudesTotal(clearValues = false): void {
    const sum = Math.ceil(this.attitudes.controls
      .map((control) => control.get("quantity").value || 0)
      .reduce((sum, current) => sum + current, 0));

    if (clearValues && (sum > 100 || sum < 100))
      this.calcAttitudes();

    this.totalAttitudes = Math.ceil(this.attitudes.controls
      .map(control => control.get("quantity").value || 0)
      .reduce((sum, current) => sum + current, 0));
  }

  updateSkillsTotal(clearValues = false): void {
    const sum = Math.ceil(this.skills.controls
      .map((control) => control.get("quantity").value || 0)
      .reduce((sum, current) => sum + current, 0));

    if (clearValues && (sum > 100 || sum < 100))
      this.calcSkills();

    this.totalSkills = Math.ceil(this.skills.controls
      .map((control) => control.get("quantity").value || 0)
      .reduce((sum, current) => sum + current, 0));
  }

  private createForm() {
    this.form = this.formBuild.group({
      name: [null, Validators.required],
      description: ["", Validators.required],
      attitudes: this.formBuild.array([]),
      skills: this.formBuild.array([])
    });
  }

  createEntry(): FormGroup {
    return this.formBuilder.group({
      name: ["", Validators.required],
      quantity: [null, [Validators.required, Validators.min(1), Validators.max(100)]]
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  salvar() {
    this.skills.controls.forEach(skill => {
      skill.get('name').markAsDirty();
      skill.get('quantity').markAsDirty();
    });
    this.attitudes.controls.forEach(attitude => {
      attitude.get('name').markAsDirty();
      attitude.get('quantity').markAsDirty();
    });
    if (this.formDisabled) {
      alert('Os valores do peso tanto de habilidades como de atitudes devem ter o resultado igual a 100 para salvar.');
    }
  }

  removeSkillsItem(index) {
    this.skills.controls.splice(index, 1);
    this.calcSkills();
  }
  calcSkills() {
    this.skills.controls.forEach(skill => {
      const vlr = eval((100 / this.skills.controls.length).toFixed(2));
      skill.get('quantity').setValue(vlr);
    });
    this.updateSkillsTotal();
  }
  removeAttitudesItem(index) {
    this.attitudes.controls.splice(index, 1);
    this.calcAttitudes();
  }
  calcAttitudes() {
    this.attitudes.controls.forEach(attitude => {
      const vlr = eval((100 / this.attitudes.controls.length).toFixed(2));
      attitude.get('quantity').setValue(vlr);
    });
    this.updateAttitudesTotal();
  }
}
