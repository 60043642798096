<ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="active">
  <li [ngbNavItem]="tab.id" *ngFor="let tab of TabItems">
    <a
      ngbNavLink
      [ngClass]="{ 'text-default-label': active === tab.id }"
      (click)="selectTab(tab)"
    >
      <i [class]="tab.icon"></i>{{ tab.title }}</a
    >
    <!-- <ng-template ngbNavContent> </ng-template> -->
    <ng-content select="[content]"></ng-content>
  </li>
</ul>
