<div class="accordion">
    <div role="tab" class="accordion-heading card-header" (click)="toggleAccordion()">
      <div class="accordion-title">
          <div  role="button"
                class="accordion-toggle d-flex flex-wrap position-relative"
                aria-expanded="false">
                <button type="button"
                        class="btn btn-link btn-link-accordion text-dark">
                        <i [class]="iconTitle"></i>
                        {{ title }}
                </button>

                <span accordion-heading=""
                      class="float-end fs-2 position-absolute end-0">
                      <i class="mdi accor-plus-icon" [ngClass]="iconClass"></i>
                </span>
          </div>
      </div>
  </div>
  <div role="tabaccordion" 
  [ngClass]="isActive ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'" 
  aria-hidden="true">
 <div class="accordion-body card-block card-body">
     <ng-content></ng-content>
 </div>
</div>

</div>
