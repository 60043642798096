import { Component, Input, OnInit } from "@angular/core";
import Chart from "chart.js/auto";

@Component({
  selector: "app-spider-chart",
  templateUrl: "./spider-chart.component.html",
  styleUrls: ["./spider-chart.component.scss"],
})
export class SpiderChartComponent implements OnInit {
  @Input() chartDataSet: any;
  listOfCompetences: string[] = [];
  chart: any;

  ngOnInit(): void {
    if (this.chart) {
      this.chart.destroy();
      this.chart = this.fillChart();
    } else {
      this.chart = this.fillChart();
    }
  }

  checkScreenSize(): void {
    const isSmallScreen = window.screen.width < 768;
    if (isSmallScreen) {
      this.listOfCompetences = this.chartDataSet.competences;
      this.chartDataSet.competences = this.chartDataSet.competences.map(
        (_, i) => i + 1
      );
    }
  }

  private fillChart(): void {
    this.checkScreenSize();
    this.chart = new Chart("spider-chart", {
      type: "radar",
      data: {
        labels: this.chartDataSet?.competences,
        datasets: [
          {
            data: this.chartDataSet?.datasets?.currentCycle.map((value) =>
              value === 0 ? null : value
            ),
            backgroundColor: "rgba(214,243,233,0.5)",
            borderColor: "#34c38f",
            pointBackgroundColor: "#fff",
          },
          {
            data: this.chartDataSet?.datasets?.previousCycle.map((value) =>
              value === 0 ? null : value
            ),
            backgroundColor: "rgba(214,219,245,0.5)",
            borderColor: "#566fe6",
            pointBackgroundColor: "#fff",
          },
        ],
      },
      options: {
        responsive: true,
        aspectRatio: 2,
        elements: {
          line: {
            borderWidth: 3,
          },
        },
        scales: {
          r: {
            min: 0,
            max: 100,
            beginAtZero: true,
            pointLabels: {
              color: "#5A5B6A",
              font: {
                family: "Poppins",
                size: 16,
                weight: 400,
              },
            },
            ticks: {
              display: false,
              stepSize: 10,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });
  }

  getEvolutionBadgeClass(evolution: number): string {
    if (evolution > 0) return "mdi-arrow-up-bold-circle-outline";
    if (evolution < 0) return "mdi-arrow-down-bold-circle-outline";

    return "";
  }
}
