import { Component, Input, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Categories } from "src/app/core/models/categories.enum";
import { AuthenticationService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-meal-voucher-accordion",
  templateUrl: "./meal-voucher-accordion.component.html",
  styleUrls: ["./meal-voucher-accordion.component.scss"],
})
export class MealVoucherAccordionComponent {
  @Input() benefits;
  planControl = new FormControl();
  isDisabled = true;
  selectedVaMonthly: any;
  selectDisabled = false;

  constructor(private authService: AuthenticationService) {}
  employeeId;
  benefit: any;
  selectedPlan: any;

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    this.planControl.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  onOptionSelected(event: any) {
    this.removeSelectedPlan();

    if (!event) return;

    this.selectedPlan.selected = true;
    this.benefit.wantTheBenefit = true;
  }

  removeSelectedPlan() {
    this.benefit.items?.forEach((item) => (item.selected = false));
  }

  getBenefits(benefits?: any) {
    if (!benefits) return;
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.VA
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;

          this.selectedPlan = this.benefit.items.find((item) => item.selected);
          if (this.benefit?.items?.length === 1) {
            this.selectedPlan = this.benefit.items[0];

            this.selectDisabled = true;
          }

          break;
        }
      }
    }
  }
}
