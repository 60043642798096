<div class="modal-header">
    <h4 class="modal-title">INCLUIR DEPENDENTE?</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <img src="assets/images/CloseOutlined.svg" alt="" class="close-img" height="20" width="20">
    </button>
  </div>
  <div class="modal-body">
    <p *ngIf="changedAccordion">{{changedAccordion}}</p>
    <label class="text-just">Justificativa: <span class="opcional-text">(opcional)</span></label>
    <input placeholder="Digite aqui o motivo dessa solicitação caso necessário" type="text" [(ngModel)]="justification" class="form-control">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="saveData()">Salvar</button>
</div>

  