import { Component, Input } from "@angular/core";
import { TimelineStateEnum } from "src/app/core/models/timeline-state-enum";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent {
  @Input() currentStep: number;
  @Input() steps: any;
  @Input() title: string;
  @Input() subtitle: string;

  isCurrent(step: any): boolean {
    return step.current;
  }

  isCompleted(step: any): boolean {
    return step.state === TimelineStateEnum.Fullfilled;
  }

  isActive(step: any): boolean {
    return step.state === TimelineStateEnum.Activated;
  }

  isExpired(step: any): boolean {
    return step.state === TimelineStateEnum.Expired;
  }

  isReopened(step: any): boolean {
    return step.state === TimelineStateEnum.Reopened;
  }
}
