import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {

  baseUrl = environment.url;

  constructor(private http: HttpClient) { }

  getDocumentation(id: string) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3`;
    return this.http.get(endpoint);
  }

  postRg(id: string, body: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/rg`;
    return this.http.post(endpoint, body);
  }

  postCpf(id: string, body: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/cpf`;
    return this.http.post(endpoint, body);
  }

  postCtps(id: string, body: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/ctps`;
    return this.http.post(endpoint, body);
  }

  postBirthCertificate(id: string, body: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/birth-certificate`;
    return this.http.post(endpoint, body);
  }


  postDocumentVoter(id: string, body: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/document-voter`;
    return this.http.post(endpoint, body);
  }

  postAddressProofDocument(id: string, body: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/address-proof`;
    return this.http.post(endpoint, body);
  }

  postSchool(id: string, body: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/schoolings`;
    return this.http.post(endpoint, body);
  }

  putSchool(id: string, body: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/schoolings/${body.id}`;
    return this.http.put(endpoint, body);
  }

  deleteSchool(id: string, idSchool: any) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/3/types/schoolings/${idSchool}`;
    return this.http.delete(endpoint);
  }
}
