import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent {
  @ViewChild(NgSelectComponent) select: NgSelectComponent;

  @Input() tagText: string;
  @Input() items: any;
  @Input() selectedItems: any;
  @Input() bindLabel: string;
  @Input() placeholder: string;
  @Input() input: any;
  @Input() loading: boolean;
  @Input() notFoundText: any;
  @Input() termLength: number;
  @Input() clearable: boolean;
  @Input() typeToSearchText: string;
  @Input() hasFooterBottom: boolean;
  @Input() textButton: string;
  @Input() clearSearchOnAdd: boolean;
  @Input() virtualScroll: boolean;
  @Input() formControl: FormControl;
  @Output() closeDropdown = new EventEmitter<any>();
  @Output() searchMoreCompetences = new EventEmitter<any>();
  @Output() modelChange = new EventEmitter<string>();
  @Output() clearSelect = new EventEmitter<any>();
  @Output() openDropdown = new EventEmitter<any>();
  @Output() action = new EventEmitter<any>();

  click(): void {
    this.closeSelect();
    this.action.emit(true);
  }

  closeSelect(): void {
    this.select.close();
  }

  searchScroll(term: string): void {
    if (term) {
      this.searchMoreCompetences.emit(term);
    }
  }
}
