import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, pipe, shareReplay, tap } from "rxjs";
import { GetCompanyModel } from "../../models/get-company.model";
import { GetAllCompanyModel } from "../../models/get-all-company.model";
import { AuthenticationService } from "../auth.service";
import { CompanyService } from "../company.service";

@Injectable({
  providedIn: "root",
})
export class CompanyStore {
  private allBranchesSubject = new BehaviorSubject<GetAllCompanyModel[]>(null);
  private companyItemSubject = new BehaviorSubject<GetCompanyModel>(null);

  allBranches$: Observable<GetAllCompanyModel[]> =
    this.allBranchesSubject.asObservable();

  constructor(
    private companyService: CompanyService,
    private authService: AuthenticationService
  ) {
    this.loadAllBranches();
  }

  public getMatriz(): Observable<GetCompanyModel> {
    this.companyService
      .getSimple(this.authService.currentUser().payload.companyId)
      .pipe(
        shareReplay(),
        tap((company) => this.companyItemSubject.next(company))
      )
      .subscribe();

    return this.companyItemSubject.asObservable();
  }

  public loadAllBranches(): Observable<GetAllCompanyModel[]> {
    this.companyService
      .getAll()
      .pipe(
        shareReplay(),
        tap((data) => this.allBranchesSubject.next(data))
      )
      .subscribe();

    return this.allBranchesSubject.asObservable();
  }
}
