<article>
  <i
    class="mdi mdi-information-outline cursor-pointer"
    (mouseover)="myDrop.open()"
    (mouseout)="myDrop.close()"
  ></i>
  <div ngbDropdown #myDrop="ngbDropdown">
    <div ngbDropdownMenu aria-labelledby="dropdownManual">
      <ng-container *ngTemplateOutlet="content"> </ng-container>
    </div>
  </div>
</article>
