<section
  id="app-dropdown-tree-view"
  (appOutsideClick)="outsideClickEvent($event)"
>
  <div class="drowpdown-container">
    <div class="input-section">
      <div class="d-inline tags" *ngFor="let item of selected$ | async">
        <span class="tag" [id]="item.id">
          <i class="bx bx-x" (click)="remove(item)"></i>
          {{ item.name }}</span
        >
      </div>
      <input
        #searchInput
        type="text"
        placeholder="selecione um item"
        (click)="openList()"
        (keyup)="filterList($event)"
      />
    </div>
    <div class="list-items" *ngIf="showListItems">
      <ul>
        <li>
          <div class="item">
            <input
              type="checkbox"
              [name]="'selectAll'"
              [id]="'selectAll'"
              class="form-check-input"
              (click)="selectAll()"
            />
            <span class="li-text">Selecionar Todos</span>
          </div>
        </li>
      </ul>
      <ul>
        <li *ngFor="let item of filteredList; let i = index">
          <div class="item" (click)="selectItemEvent(item.id)">
            <input
              type="checkbox"
              [checked]="item.selected"
              [name]="'item' + i"
              [id]="'item' + i"
              class="form-check-input"
              [indeterminate]="item.indeterminate"
            />
            <span class="li-text">{{ item.name }}</span>
          </div>
          <ul *ngFor="let subItem of item.childrens; let j = index">
            <li>
              <div class="item" (click)="selectSubItemEvent(subItem.id)">
                <input
                  type="checkbox"
                  [name]="'subitem' + j"
                  [id]="'subitem' + j"
                  class="form-check-input"
                  [checked]="subItem.selected"
                />
                <span class="li-text">{{ subItem.name }}</span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</section>
