<section class="app-wizard">
  <article class="step">
    <ul>
      <ng-container *ngFor="let step of steps; let i = index">
        <li [ngClass]="{ current: step.selected }" (click)="selectedStep(i)">
          <span class="symbol">{{ i + 1 }}.</span>
          <span>
            {{ step.title }}
          </span>
        </li>
      </ng-container>
    </ul>
  </article>
</section>
