import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-popover-list',
  templateUrl: './popover-list.component.html',
  styleUrls: ['./popover-list.component.css']
})
export class PopoverListComponent {
  @Input() popoverTitleItem: string = "Membros"; // Titulo popover
  @Input() item: any; // Item raiz $
  @Input() itemList: any[]; // Listagem a ser interada.
  @Input() propLink: string = "informe uma prop que terá a saída boolean";
  @Input() propShow: string = "informe uma prop que deseja apresentar de 1 item da listagem itemList";
  @Input() valueHrefLink: string = "javascript:void(0);";
  @Input() valueLink: string = "informe um valor para o link valueHrefLink";
  @Input() valueHrefLinkMoreItems: string = "javascript:void(0);";
  @Input() valueMoreItemsPlural: string = "informe o texto para limitar a apresentação da listagem termo no plural";
  @Input() valueMoreItemsSingle: string = "informe o texto para limitar a apresentação da listagem termo no singular";
  @Input() closedOnmouseout: boolean = true; // impede que o popover se feche ao tirar o mouse
  public environment = environment;
  public showThisQuantity(): any[] {
    const items: any[] = this.itemList.length > this.environment.maxShowItemsPopover ? this.itemList.slice(0, this.environment.maxShowItemsPopover) : this.itemList;
    return items;
  }
  openPopover(popover: NgbPopover) {
    if (!popover.isOpen())
      popover.open();
  }
  closePopover(popover: NgbPopover) {
    if (popover.isOpen() && this.closedOnmouseout)
      popover.close();
  }
  closeForcePopover(popover: NgbPopover) {
    if (popover.isOpen())
      popover.close();
  }

  public getNestedProperty(obj: any, propertyPath: string) {
    const properties = propertyPath.split('.');
    let currentObject = obj;
    for (let property of properties) {
      if (currentObject.hasOwnProperty(property)) {
        currentObject = currentObject[property];
      } else {
        console.log("Property not find");
        return undefined;
      }
    }
    return currentObject;
  }
}
