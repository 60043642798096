import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AddressService } from "src/app/core/services/addres.service";
import { CompanyService } from "src/app/core/services/company.service";
import { UtilsService } from "src/app/core/services/utils.service";

import { enrollmentValidator } from "src/app/shared/directives/enrollment-validator.directive";

@Component({
  selector: "app-voter-registration",
  templateUrl: "./voter-registration.component.html",
  styleUrls: ["./voter-registration.component.scss"],
})
export class VoterRegistrationComponent implements OnChanges, OnInit {
  voterRegistrationForm: FormGroup;
  documentFile: any;
  documentFileExtension: any;

  states$ = this.addressService?.getAllStates();
  cities: any[];

  zonePattern = /^\d{3}$/;
  sectionPattern = /^\d{4}$/;

  @Input() voter;
  @Input() isExhibition = false;
  @Input() ufs: string[];

  @Output() formValue: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private addressService: AddressService,
    private companyService: CompanyService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.voter?.currentValue;

    if (data) {
      this.voterRegistrationForm?.patchValue({
        issueDate: data.issueDate?.split(" ")[0],
        cityId: data.cityId,
        countryId: data.countryId,
        section: data.section,
        subscriptionNumber: data.subscriptionNumber,
        zone: data.zone,
      });

      this.documentFile = `${data.document}`;
      this.documentFileExtension = `${data.documentExtension}`;
    }
  }

  buildForm() {
    this.voterRegistrationForm = this.fb.group({
      subscriptionNumber: [
        { value: "", disabled: this.isExhibition },
        [Validators.required, enrollmentValidator()],
      ],
      zone: [
        { value: "", disabled: this.isExhibition },
        [Validators.required, Validators.pattern(this.zonePattern)],
      ],
      section: [
        { value: "", disabled: this.isExhibition },
        [Validators.required, Validators.pattern(this.sectionPattern)],
      ],
      cityId: [{ value: "", disabled: this.isExhibition }, Validators.required],
      countryId: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      issueDate: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
    });
  }

  getCitiesByState() {
    const countryId = this.voterRegistrationForm.get("countryId")?.value;

    if (countryId !== null) {
      this.companyService.getAllCities(countryId).subscribe((data: any) => {
        this.cities = data;
      });
    }
  }

  getPhoto(event) {
    this.documentFile = event.base64;
    this.documentFileExtension = event.extension;
  }

  emitVoterRegistrationFormValue() {
    this.voterRegistrationForm.markAllAsTouched();
    if (this.voterRegistrationForm.invalid || !this.documentFile) {
      return;
    }

    const voterRegistrationFormValue = {
      ...this.voterRegistrationForm.getRawValue(),
      documentFile: this.documentFile,
      documentFileExtension: this.documentFileExtension,
    };

    this.formValue.emit(voterRegistrationFormValue);
  }

  isInvalidField(field: string): boolean | undefined {
    const formField = this.voterRegistrationForm.get(field);
    return formField?.invalid && formField?.touched;
  }

  isControlRequiredError(controlName: string) {
    const control = this.voterRegistrationForm.controls[controlName];
    return control.errors && control.errors.required && control.touched;
  }

  isControlPatternError(controlName: string) {
    const control = this.voterRegistrationForm.controls[controlName];
    return control.errors && control.errors.pattern && control.touched;
  }

  isInscricaoInvalida(): boolean {
    const control = this.voterRegistrationForm.controls.subscriptionNumber;
    return control.errors?.invalidRegistration && control.touched;
  }

  downloadFile() {
    if (this.documentFile && this.documentFile !== "null")
      this.utilsService.downloadFile(this.documentFile, `TITULO_ELEITOR.${this.documentFileExtension}`);
  }
}
