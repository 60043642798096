<div class="worked-days">
  <ng-container *ngFor="let item of items">
    <label
      class="worked-days__item"
      [ngClass]="{
      'worked-days__item--selected': item.checked,
    }"
    >
      <input
        type="checkbox"
        name="item"
        class="custom-control-input"
        [value]="item.value"
        [checked]="item.checked"
        (change)="changeItem($event.target.checked, item)"
      />{{ item.name }}
    </label>
  </ng-container>
</div>
