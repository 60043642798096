<section class="card" id="app-create-update-user">
  <div class="card-body row">
    <div class="col-md-12">
      <h6 class="text-uppercase">Informações pessoais</h6>
    </div>
    <div class="col-md-12 mt-3">
      <form action="" class="row form-group" [formGroup]="form">
        <div class="col-md-12 row">
          <div class="col-md-6 mt-2">
            <label class="text-default-label">Nome social</label>
            <input class="form-control" formControlName="name" />
          </div>
          <div class="col-md-2 mt-2">
            <label for="genderLabel" class="text-default-label">
              Selecione um gênero*
            </label>
            <ng-select
              class="custom"
              [ngClass]="{ 'is-invalid': f.gender.errors && f.gender.dirty }"
              formControlName="gender"
              [items]="genderArray"
              bindLabel="name"
              bindValue="value"
              placeholder="Selecione o gênero"
              notFoundText="Nenhum gênero encontrado."
            >
            </ng-select>
            <div
              *ngIf="f.gender.errors && f.gender.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.gender.errors.required">Selecione um gênero.</span>
            </div>
          </div>
          <div class="col-md-2 mt-2">
            <label class="text-default-label">Data de nascimento*</label>
            <div class="input-group">
              <input
                type="date"
                class="form-control col-md-12"
                placeholder="Digite aqui a informação"
                formControlName="birthdate"
                [ngClass]="{
                  'is-invalid': f.birthdate.errors && f.birthdate.dirty
                }"
              />
              <div class="text-danger" *ngIf="f.birthdate.invalid &&  f.birthdate.dirty">
                <span *ngIf="f.birthdate.errors.required"
                  >A Data de Nascimento é obrigatória</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-2 mt-2">
            <label for="meritalStatusLabel" class="text-default-label"
              >Selecione um estado civil*</label
            >
            <ng-select
              class="custom"
              [ngClass]="{ 'is-invalid': f.maritalStatus.errors && f.maritalStatus.dirty }"
              [items]="martialStatusArray"
              bindLabel="name"
              bindValue="value"
              formControlName="maritalStatus"
              placeholder="Selecione um estado civil"
              notFoundText="Nenhum Estado civil encontrado."
            >
            </ng-select>
            <div
              *ngIf="f.maritalStatus.errors && f.maritalStatus.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.maritalStatus.errors.required"
                >Selecione um estado civil.</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 row mt-3 align-items-end">
          <div class="col-md-2 mt-2">
            <label class="text-default-label">CPF*</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.cpf.errors && f.cpf.dirty }"
              formControlName="cpf"
              placeholder="ex: 999.999.999-99"
              mask="000.000.000-00"
            />
            <div *ngIf="f.cpf.errors && f.cpf.invalid" class="invalid-feedback">
              <span *ngIf="f.cpf.errors.required">O CPF é obrigatório.</span>
              <span *ngIf="f.cpf.errors.cpfValidator">O CPF é inválido.</span>
            </div>
          </div>
          <div class="col-md-2 mt-2">
            <label class="text-default-label">RG*</label>
            <input
              type="text"
              name="rg"
              id="rg"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.rg.errors && f.rg.dirty }"
              formControlName="rg"
              placeholder="ex: 99.999.999-9"
              mask="00.000.000-0||00.000.000"
            />
            <div *ngIf="f.rg.errors && f.rg.invalid" class="invalid-feedback">
              <span *ngIf="f.rg.errors.required">O RG é obrigatório.</span>
            </div>
          </div>
          <div class="col-md-2 mt-2">
            <label class="text-default-label">É PCD</label>
            <ng-select
              formControlName="isPcd"
              class="custom"
              placeholder="Selecione uma opção"
              notFoundText="Nenhuma opção encontrada."
            >
              <ng-option [value]="true">Sim</ng-option>
              <ng-option [value]="false">Não</ng-option>
            </ng-select>
          </div>
          <div class="col-md-2 mt-1">
            <label class="text-default-label">Altura (m)</label>
            <input
              type="text"
              name="height"
              id="height"
              class="form-control"
              formControlName="height"
              placeholder="0,00"
              mask="0,00"
            />
          </div>
          <div class="col-md-2 mt-1">
            <label class="text-default-label">Peso (kg)</label>
            <input
              type="text"
              name="weight"
              id="weight"
              class="form-control"
              formControlName="weight"
              placeholder="00,00"
              mask="99,99"
            />
          </div>
        </div>
        <div class="col-md-12 row mt-3 align-items-end">
          <div class="col-md-2 mt-2">
            <label class="text-default-label">PIS*</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.cpf.errors && f.cpf.dirty }"
              formControlName="pis"
              placeholder="ex: "
              mask=""
            />
            <div *ngIf="f.cpf.errors && f.cpf.invalid" class="invalid-feedback">
              <span *ngIf="f.cpf.errors.required">O PIS é obrigatório.</span>
              <span *ngIf="f.cpf.errors.cpfValidator">O PIS é inválido.</span>
            </div>
          </div>
          <div class="col-md-2 mt-2">
            <label class="text-default-label">Telefone*</label>
            <input
              type="text"
              name="rg"
              id="rg"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.rg.errors && f.rg.dirty }"
              formControlName="telefone"
              placeholder="ex: (99)99999-9999"
              mask="(00) 00000-0000"
            />
          </div>
          <div class="col-md-2 mt-1">
            <label class="text-default-label">Grau de instrução*</label>
            <ng-select
            class="custom"
            bindLabel="name"
            bindValue="value"
            formControlName="maritalStatus"
            placeholder="Selecione um Grão de Instrução*."
            notFoundText="Nenhum Grão de Instrução encontrado."
          >
          </ng-select>
          <div
            *ngIf="f.maritalStatus.errors && f.maritalStatus.invalid"
            class="invalid-feedback"
          >
            <span *ngIf="f.maritalStatus.errors.required"
              >Selecione um Grão de Instrução*.</span
            >
          </div>
          </div>
          <div class="col-md-4 mt-1">
            <label class="text-default-label">E-mail pessoal*</label>
            <input
              type="email"
              name="weight"
              id="weight"
              class="form-control"
              formControlName="weight"
              placeholder="email@email.com"
              mask="email@email.com"
            />
          </div>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-lg-5 col-md-6 mt-2">
            <label class="text-default-label">Perfil do LinkedIn</label>
            <input
              type="text"
              name="linkedin"
              id="linkedin"
              class="form-control"
              formControlName="linkedin"
              placeholder="Link (URL) do seu perfil (opcional)"
            />
          </div>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-lg-5 col-md-6 mt-2">
            <label class="text-default-label">Nome da mãe*</label>
            <input
              type="text"
              name="namemother"
              id="namemother"
              class="form-control"
              formControlName="linkedin"
              placeholder="Nome da mãe*"
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <h6 class="text-uppercase">Endereço</h6>
        </div>

        <div class="col-md-12 row mt-3">
          <div class="col-lg-2 col-md-2 mt-2">
            <label class="text-default-label">CEP*</label>
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              class="form-control"
              [ngClass]="{
                'is-invalid': f.postalCode.errors && f.postalCode.dirty
              }"
              formControlName="postalCode"
              placeholder="ex: 99999-999"
              mask="00000-000"
            />
            <div
              *ngIf="f.postalCode.errors && f.postalCode.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.postalCode.errors.required"
                >O CEP é obrigatório.</span
              >
            </div>
          </div>
          <div class="col-lg-6 col-md-5 mt-2">
            <label class="text-default-label">Endereço*</label>
            <input
              type="text"
              name="address"
              id="address"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.address.errors && f.address.dirty }"
              formControlName="address"
            />
            <div
              *ngIf="f.address.errors && f.address.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.address.errors.required"
                >O endereço é obrigatório.</span
              >
            </div>
          </div>
          <div class="col-lg-1 col-md-2 mt-2">
            <label class="text-default-label">Número</label>
            <input
              class="form-control"
              type="number"
              [ngClass]="{ 'is-invalid': f.number.errors && f.number.dirty }"
              formControlName="number"
            />
            <div
              *ngIf="f.number.errors && f.number.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.number.errors.required"
                >O número do endereço é obrigatório.</span
              >
            </div>
          </div>
          <div class="col-lg-3 col-md-3 mt-2">
            <label class="text-default-label">Bairro*</label>
            <input
              type="text"
              name="neighborhood"
              id="neighborhood"
              class="form-control"
              [ngClass]="{
                'is-invalid': f.neighborhood.errors && f.neighborhood.dirty
              }"
              formControlName="neighborhood"
            />
            <div
              *ngIf="f.neighborhood.errors && f.neighborhood.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.neighborhood.errors.required"
                >O bairro é obrigatório.</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-lg-2 col-md-2 mt-2">
            <label for="stateIdLabel" class="text-default-label">Estado*</label>
            <ng-select
              formControlName="stateId"
              class="custom"
              placeholder="Selecione o estado"
              [ngClass]="{ 'is-invalid': f.stateId.errors && f.stateId.dirty }"
              notFoundText="Nenhum Estado encontrado."
            >
              <ng-option *ngFor="let item of countries" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>

            <div
              *ngIf="f.stateId.errors && f.stateId.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.stateId.errors.required"
                >Selecione um estado.</span
              >
            </div>
          </div>
          <div class="col-lg-3 col-md-4 mt-2">
            <label for="cityLabel" class="text-default-label">Cidade*</label>
            <ng-select
              formControlName="cityId"
              class="custom"
              placeholder="Selecione a cidade"
              [ngClass]="{ 'is-invalid': f.cityId.errors && f.cityId.dirty }"
              notFoundText="Nenhuma Cidade encontrada."
            >
              <ng-option *ngFor="let item of cities" [value]="item.id">{{
                item.name
              }}</ng-option>
            </ng-select>
            <div
              *ngIf="f.cityId.errors && f.cityId.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.cityId.errors.required"
                >Selecione uma cidade.</span
              >
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mt-2">
            <label class="text-default-label">Complemento</label>
            <input
              name="complement"
              id="complement"
              class="form-control"
              formControlName="complement"
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <h6 class="text-uppercase">Dados bancários</h6>
        </div>
        <div class="col-md-12 my-3">
          <button
            class="col-md-3 btn btn-outline-primary"
            type="button"

          >
          BAIXAR CARTA DE REFERÊNCIA
          </button>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-lg-3 col-md-6 mt-2">
            <label class="text-default-label">Banco*</label>
            <ng-select
              class="custom"
              [ngClass]="{ 'is-invalid': f.bankId.errors && f.bankId.dirty }"
              formControlName="bankId"
              [items]="bankList"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione um banco"
              notFoundText="Nenhum Banco encontrado."
            >
            </ng-select>
            <div
              *ngIf="f.bankId.errors && f.bankId.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.bankId.errors.required">Selecione um banco.</span>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 mt-2">
            <label class="text-default-label">Agência*</label>
            <input
              type="text"
              name="agency"
              id="agency"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.agency.errors && f.agency.dirty }"
              formControlName="agency"
            />
            <div
              *ngIf="f.agency.errors && f.agency.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.agency.errors.required"
                >A agência é obrigatória.</span
              >
            </div>
          </div>
          <div class="col-lg-1 col-md-2 mt-2">
            <label class="text-default-label">Dígito</label>
            <input
              type="text"
              name="agencyDigit"
              id="agencyDigit"
              class="form-control"
              formControlName="agencyDigit"
            />
          </div>
          <div class="col-lg-2 col-md-4 mt-2">
            <label class="text-default-label">Conta*</label>
            <input
              type="text"
              name="account"
              id="account"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.account.errors && f.account.dirty }"
              formControlName="account"
            />
            <div
              *ngIf="f.account.errors && f.account.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.account.errors.required"
                >A conta é obrigatória.</span
              >
            </div>
          </div>
          <div class="col-lg-1 col-md-2 mt-2">
            <label class="text-default-label">Dígito*</label>
            <input
              type="text"
              name="accountDigit"
              id="accountDigit"
              class="form-control"
              formControlName="accountDigit"
            />
          </div>
          <div class="col-lg-3 col-md-6 mt-2">
            <label class="text-default-label"
              >Selecione um tipo de conta*</label
            >
            <ng-select
              class="custom"
              [ngClass]="{
                'is-invalid': f.accountType.errors && f.accountType.dirty
              }"
              formControlName="accountType"
              [items]="accountTypeArray"
              bindLabel="name"
              bindValue="value"
              placeholder="Selecione um banco"
              notFoundText="Nenhum Tipo de Conta encontrado."
            >
            </ng-select>
            <div
              *ngIf="f.accountType.errors && f.accountType.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.accountType.errors.required"
                >Selecione um tipo de conta.</span
              >
            </div>

          </div>
        </div>
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"
        ></div>
        <div
          class="col-md-12 row justify-content-end"
          *ngIf="!isContributorProfile"
        >
          <div class="col-md-4 text-align-end">
            <button
              type="submit"
              class="btn btn-outline-secondary m-1 text-uppercase"
              (click)="next(true)"
            >
              salvar e sair
            </button>
            <button
              class="btn btn-secondary text-uppercase"
              type="button"
              (click)="back()"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary m-1 text-uppercase"
              (click)="next()"
            >
              Salvar e avançar
            </button>
          </div>
        </div>
        <div
          class="col-md-12 row justify-content-end"
          *ngIf="isContributorProfile"
        >
          <div class="col-md-4 text-align-end">
            <button
              type="submit"
              class="btn btn-outline-primary m-1 text-uppercase"
              (click)="next()"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
