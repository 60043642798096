import { Component, Input } from "@angular/core";

@Component({
  selector: "app-progressbar",
  templateUrl: "./progressbar.component.html",
  styleUrls: ["./progressbar.component.scss"],
})
export class ProgressbarComponent {
  @Input() maxValue: number;
  @Input() currentValue: number;
  @Input() progressbarClass: string;

  get currentValueWith() {
    return this.currentValue + "%";
  }
}
