<app-accordion class="my-1" [title]="'Seguro de Vida'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label"
        >Termo de Nomeação e/ou Alteração de Beneficiário</label
      >
      <p class="text">
        Indique, abaixo, os seus beneficiários do seguro de vida.
      </p>
      <div class="row-mt-4">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-valuedesc">Valor </label>
          {{ benefit.items[0]?.cost | currency : "BRL" : "symbol" }}
        </div>
      </div>
      <form [formGroup]="seuFormulario">
        <div formArrayName="beneficiaries">
          <div
            *ngFor="
              let beneficiary of beneficiariesFormArray.controls;
              let i = index
            "
            [formGroupName]="i"
          >
            <div class="row mt-4">
              <div class="col-md-12 col-12 col-sale">
                <label class="text-sale">Nome completo do beneficiário*</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite aqui a informação"
                  formControlName="name"
                  [ngClass]="{
                    'is-invalid':
                      getBeneficiary(i).name?.errors &&
                      getBeneficiary(i).name?.dirty
                  }"
                />
                <div
                  *ngIf="getBeneficiary(i).name?.errors?.required"
                  class="invalid-feedback"
                >
                  <span> Nome é obrigatório </span>
                </div>
              </div>
              <div class="col-md-4 col-12 col-value mt-1">
                <label class="text-valuedesc">% de participação*</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite aqui a informação"
                  formControlName="sharePercentage"
                  [ngClass]="{
                    'is-invalid':
                      (getBeneficiary(i).sharePercentage?.errors &&
                        getBeneficiary(i).sharePercentage?.dirty) ||
                      showSharePercentageExceeded
                  }"
                />
                <div
                  *ngIf="
                    getBeneficiary(i).sharePercentage?.errors ||
                    showSharePercentageExceeded
                  "
                  class="invalid-feedback"
                >
                  <span *ngIf="showSharePercentageExceeded">
                    total de porcentagem excedido.</span
                  >
                  <span
                    *ngIf="getBeneficiary(i).sharePercentage?.errors?.required"
                  >
                    porcentagem de participação é obrigatório.
                  </span>
                  <span *ngIf="getBeneficiary(i).sharePercentage?.errors?.max"
                    >O valor maximo permitido é 100</span
                  >
                  <span *ngIf="getBeneficiary(i).sharePercentage?.errors?.min"
                    >O valor minimo permitido é 1</span
                  >
                </div>
              </div>
              <div class="col-md-4 col-12 col-sale mt-1">
                <label class="text-sale">Grau de parentesco*</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite aqui a informação"
                  formControlName="kinship"
                  [ngClass]="{
                    'is-invalid':
                      getBeneficiary(i).kinship?.errors &&
                      getBeneficiary(i).kinship?.dirty
                  }"
                />
                <div
                  *ngIf="getBeneficiary(i).kinship?.errors?.required"
                  class="invalid-feedback"
                >
                  <span> Grau de parantesco é obrigatório. </span>
                </div>
              </div>
              <div class="col-md-3 col-12 col-value mt-1">
                <label class="text-valuedesc">CPF*</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite aqui a informação"
                  placeholder="ex: 999.999.999-99"
                  mask="000.000.000-00"
                  formControlName="document"
                  [ngClass]="{
                    'is-invalid':
                      getBeneficiary(i).document?.errors &&
                      getBeneficiary(i).document?.dirty
                  }"
                />
                <div
                  *ngIf="getBeneficiary(i).document?.errors?.cpfValidator"
                  class="invalid-feedback"
                >
                  <span> CPF inválido. </span>
                </div>
              </div>
              <div class="col-md-1 col-12 col-delete">
                <button
                  class="btn-add"
                  type="button"
                  (click)="removeBeneficiary(i)"
                >
                  <img
                    src="assets/images/DeleteOutlined.svg"
                    alt=""
                    class="close-img mt-2"
                    height="24"
                    width="24"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-12">
          <button class="btn-add" type="button" (click)="addBeneficiary()">
            <img
              src="assets/images/btnaddframe.png"
              alt=""
              class="close-img mt-2"
              height="40"
              width="40"
            />
          </button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 mb-2">
          <small
            ><strong>Declaração do segurado:</strong> Na qualidade de segurado,
            nomeio, por meio deste formulário, como meu(s) beneficiário(s) a(s)
            pessoa(s) indicada(s) acima. Esclareço que tal indicação atende meus
            interesses próprios e pessoais e, portanto, deve ser considerada
            boa, firme e valiosa para todos os fins e efeitos de direito,
            isentando a Seguradora de qualquer responsabilidade.</small
          >
          <p>
            <small>
              O beneficiário é uma ou mais pessoas selecionadas pelo titular do
              seguro, com total liberdade, para receber a indenização em caso de
              seu falecimento, podendo ser o cônjuge, familiares, um amigo, um
              vizinho ou até um herdeiro
            </small>
          </p>
        </div>
        <div class="col-md-3 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ benefit.items[0]?.existDiscountToHolder }}</span>
        </div>
        <div class="col-md-4 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>
          <span>R$ {{ benefit.items[0]?.holderDiscount || 0 }}</span>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
