<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-content custom-modal">
    <section class="card custom-modal-content">
      <article class="card-body">
        <div class="row flex-shrink">
          <div class="col-md-12">
            <h4>CRIAR COMPETÊNCIA</h4>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 mt-3 mt-mobile-3">
              <label for="name" class="text-default-label">Competência*</label>
              <input
                placeholder="Digite o nome da competência"
                type="text"
                name="name"
                id="name"
                class="form-control"
                formControlName="name"
              />
            </div>
            <div class="col-12 col-md-6 mt-3 mt-mobile-3">
              <label for="description" class="text-default-label"
                >Descrição</label
              >
              <input
                placeholder="Digite a sua Descrição"
                type="text"
                name="description"
                id="description"
                class="form-control"
                formControlName="description"
              />
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 col-out">
            <h4 class="title-hability">
              HABILIDADES E ATITUDES
              <img
                ngbTooltip="Habilidades são as aptidões práticas que alguém possui para realizar tarefas. As atitudes são as posturas comportamentais ao lidar com situações e pessoas no trabalho. Ao habilitar habilidades e atitudes, cada item deverá ter um peso individual que somado aos demais irá compor o peso total (100) da respectiva categoria. Pesos servem para distinguir quais aspectos da competência são mais relevantes para cada cargo."
                placement="bottom"
                src="assets/images/InfoOutlined.svg"
                alt=""
                class="rounded-img"
                height="24"
                width="24"
              />
            </h4>
            <div
              class="btn-switch"
              [ngClass]="{ 'btn-switch--on': toggle.switch }"
              (click)="toggle.switch = !toggle.switch"
            >
              <div
                class="btn-switch-circle"
                [ngClass]="{ 'btn-switch-circle--on': toggle.switch }"
                (click)="toggle.switch = !toggle.switch"
              ></div>
            </div>
          </div>
          <div class="ocult" *ngIf="toggle.switch">
            <div class="col-md-4 col-p mt-5">
              <h4 class="title-p">HABILIDADES</h4>
              <div class="col-md-3">
                <div class="row line-status">
                  <div class="col-12 col-refresh">
                    <label class="text-ps"
                      >PESO TOTAL:
                      <span
                        class="value"
                        [ngClass]="
                          !isTotalSkillsValid ? 'is-invalid' : 'is-valid'
                        "
                        [style.color]="isTotalSkillsValid ? 'blue' : 'red'"
                        >{{ totalSkills }}</span
                      >
                      <label>
                        <button
                          type="button"
                          class="btn-refresh"
                          (click)="updateSkillsTotal(true)"
                        >
                          <img
                            *ngIf="this.totalSkills === 100"
                            src="assets/images/RefreshOutlined.svg"
                            alt=""
                            class="refresh-img"
                            height="25"
                            width="25"
                          />
                          <img
                            *ngIf="this.totalSkills != 100"
                            src="assets/images/RefreshOutlinedBlue.svg"
                            alt=""
                            class="refresh-img"
                            height="25"
                            width="25"
                          />
                        </button>
                      </label>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mb-2">
              <div class="row">
                <div class="col-12">
                  <div class="text-hab">
                    <label class="name">Nome</label
                    ><label class="burden">Peso</label>
                  </div>
                </div>
              </div>
              <div
                *ngFor="let skillsCtrl of skills.controls; let i = index"
                class="row"
              >
                <div class="col-7 col-md-9 mb-2">
                  <input
                    placeholder="Digite o nome da habilidade"
                    type="text"
                    class="form-control hab-input"
                    [formControl]="skillsCtrl.get('name')"
                    [ngClass]="{
                      'is-invalid':
                        skillsCtrl.get('name').dirty &&
                        skillsCtrl.get('name').errors
                    }"
                  />
                </div>
                <div class="col-3 col-md-2 mb-2">
                  <input
                    placeholder="0"
                    type="number"
                    class="form-control"
                    [formControl]="skillsCtrl.get('quantity')"
                    min="1"
                    max="100"
                    (input)="updateSkillsTotal()"
                    [ngClass]="{
                      'is-invalid':
                        skillsCtrl.get('quantity').dirty &&
                        skillsCtrl.get('quantity').errors
                    }"
                  />
                </div>
                <div class="col-2 col-md-1 mb-2">
                  <button class="btn-delete" (click)="removeSkillsItem(i)">
                    <img
                      src="assets/images/DeleteOutlined.svg"
                      alt=""
                      class="refresh-img"
                      height="20"
                      width="20"
                    />
                  </button>
                </div>
              </div>
              <button class="add-img" (click)="addEntrySkills()">
                <img
                  src="assets/images/AddOutlined.svg"
                  alt=""
                  class="add"
                  height="20"
                  width="20"
                />
              </button>
            </div>
            <div class="col-md-4 col-p mt-5">
              <h4 class="title-p">ATITUDES</h4>
              <div class="col-md-3">
                <div class="row line-status">
                  <div class="col-12 col-refresh">
                    <label class="text-ps"
                      >PESO TOTAL:
                      <span
                        class="value"
                        [ngClass]="
                          !isTotalAttitudesValid ? 'is-invalid' : 'is-valid'
                        "
                        [style.color]="isTotalAttitudesValid ? 'blue' : 'red'"
                        >{{ totalAttitudes }}</span
                      >
                      <label>
                        <button
                          type="button"
                          class="btn-refresh"
                          (click)="updateAttitudesTotal(true)"
                        >
                          <img
                            *ngIf="this.totalAttitudes === 100"
                            src="assets/images/RefreshOutlined.svg"
                            alt=""
                            class="refresh-img"
                            height="25"
                            width="25"
                          />
                          <img
                            *ngIf="this.totalAttitudes != 100"
                            src="assets/images/RefreshOutlinedBlue.svg"
                            alt=""
                            class="refresh-img"
                            height="25"
                            width="25"
                          />
                        </button>
                      </label>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-1">
              <div class="row">
                <div class="col-12">
                  <div class="text-hab">
                    <label class="name">Nome</label
                    ><label class="burden">Peso</label>
                  </div>
                </div>
              </div>
              <div
                *ngFor="let attitudesCtrl of attitudes.controls; let i = index"
                class="row"
              >
                <div class="col-7 col-md-9 mb-2">
                  <input
                    placeholder="Digite o nome da atitude"
                    type="text"
                    class="form-control hab-input"
                    [formControl]="attitudesCtrl.get('name')"
                    [ngClass]="{
                      'is-invalid':
                        attitudesCtrl.get('name').dirty &&
                        attitudesCtrl.get('name').errors
                    }"
                  />
                </div>
                <div class="col-3 col-md-2 mb-2">
                  <input
                    placeholder="0"
                    type="number"
                    class="form-control"
                    [formControl]="attitudesCtrl.get('quantity')"
                    min="1"
                    max="100"
                    (input)="updateAttitudesTotal()"
                    [ngClass]="{
                      'is-invalid':
                        attitudesCtrl.get('quantity').dirty &&
                        attitudesCtrl.get('quantity').errors
                    }"
                  />
                </div>
                <div class="col-2 col-md-1 mb-2">
                  <button class="btn-delete" (click)="removeAttitudesItem(i)">
                    <img
                      src="assets/images/DeleteOutlined.svg"
                      alt=""
                      class="refresh-img"
                      height="20"
                      width="20"
                    />
                  </button>
                </div>
              </div>
            </div>
            <button class="add-img" (click)="addEntryAttitudes()">
              <img
                src="assets/images/AddOutlined.svg"
                alt=""
                class="add-two"
                height="20"
                width="20"
              />
            </button>
          </div>
          <div class="col-md-12 row justify-content-end">
            <div class="col-md-4 text-align-end">
              <a class="btn btn-secondary m-1" (click)="closeModal()"
                >CANCELAR</a
              >
              <button
                type="submit"
                class="btn btn-primary m-1"
                [disabled]="formDisabled"
                (click)="salvar()"
              >
                SALVAR
              </button>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</form>
