<app-accordion class="my-1" [title]="'Plano de Saúde'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">{{ benefit.beneficiaries }}</label>
      <div class="w-100 row mx-0 align-items-end">
        <label class="select-plantext">{{ benefit.alias }}</label>
        <div class="col-md-4">
          <label class="mr-3 text-dinaplan"
            >{{ descCategory }} {{ benefit.descCategory }} - {{selectedPlan?.name}}</label
          >
          <button
            class="btn btn-outline-primary mt-2 btn-view"
            type="button"
            (click)="openCobertura(contentcobertura)"
          >
            <i class="mdi mdi-eye-outline"></i> VER COBERTURA
          </button>
        </div>
      </div>
      <div class="row-mt-4">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-valuedesc">Valor </label>
          {{ benefit.items[0]?.cost | currency : "BRL" : "symbol" }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ benefit.items[0]?.existDiscountToHolder }}</span>
        </div>
        <div class="col-md-4 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>

          <ng-container *ngIf="benefit.items[0]?.holderDiscountType === 2">
            <span>{{ benefit.items[0]?.holderDiscount }}%</span>
          </ng-container>
          <ng-container *ngIf="benefit.items[0]?.holderDiscountType === 1">
            <span>{{
              benefit.items[0]?.holderDiscount | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>
      </div>
      <hr />
      <div class="row mt-4 row-elegi">
        <div class="col-md-8 px-0">
          <div class="col-md-12 col-12 col-sale">
            <p class="text-elegi">Dependentes legais / elegíveis</p>
            <p class="text-sub" *ngFor="let dependent of includedDependents">
              {{ dependent.name }}
            </p>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-sale">Desc. dependente</label>
          <span>{{ benefit.items[0]?.existDependentDiscount }}</span>
        </div>
        <div class="col-md-3 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto (Filho)</label>
          <ng-container *ngIf="benefit.items[0]?.dependentDiscountType === 2">
            <span>{{ benefit.items[0]?.dependentDiscount }}%</span>
          </ng-container>
          <ng-container *ngIf="benefit.items[0]?.dependentDiscountType === 1">
            <span>{{
              benefit.dependentDiscount | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>
        <div class="col-md-3 col-12 col-value">
          <label class="text-valuedesc"
            >Valor ou % do desconto (Cônjuge/Companheiro(a))</label
          >
          <ng-container *ngIf="benefit.items[0]?.spouseDiscountType === 2">
            <span>{{ benefit.items[0]?.spouseDiscount }}%</span>
          </ng-container>
          <ng-container *ngIf="benefit.items[0]?.spouseDiscountType === 1">
            <span>{{
              benefit.items[0]?.spouseDiscount | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>
        <div class="col-md-3 col-12 col-value">
          <label class="text-valuedesc"
            >Valor ou % do desconto (outros dependentes)</label
          >
          <ng-container *ngIf="benefit.items[0]?.otherDependentDiscountType === 2">
            <span>{{ benefit.items[0]?.otherDependentDiscount ?? "0" }}%</span>
          </ng-container>
          <ng-container *ngIf="benefit.items[0]?.otherDependentDiscountType === 1">
            <span>{{
              benefit.items[0]?.otherDependentDiscount | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>
      </div>

      <ng-template #contentcobertura>
        <div class="modal-content custom-modal">
          <div class="row">
            <div class="col-12">
              <h4 class="text-ptransp">COBERTURA</h4>
              <div *ngFor="let plan of plans" class="col-12 mt-2">
                <div *ngFor="let coverage of plan.planCoverage">
                  <p>{{ coverage.name }}</p>
                </div>
              </div>
            </div>
            <div class="custom-checkbox-container">
              <div class="col-md-12 mt-3 text-align-end justify-content-end">
                <button
                  class="btn btn-secondary position-btn"
                  type="button"
                  (click)="goToBenefits()"
                >
                  VOLTAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</app-accordion>
