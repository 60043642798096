<section id="app-table" class="table-container p-0 my-3">
  <table class="table table-bordered" *ngIf="data">
    <thead >
      <tr>
        <ng-container
          *ngTemplateOutlet="
            header || defaultHeaderTemplate;
            context: { $implicit: data }
          "
        >
        </ng-container>
      </tr>
    </thead>
    <tfoot></tfoot>
    <tbody>
      <tr
        *ngFor="
          let item of data
            | paginate
              : {
                  id: 'server',
                  itemsPerPage: totalPerPage,
                  currentPage: page,
                  totalItems: totalItems
                }
        "
      >
        <ng-container
          *ngTemplateOutlet="
            items || defaultRowTemplate;
            context: { $implicit: item }
          "
        >
        </ng-container>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="20" >
          <div class="text-md-right float-md-end pagination-rounded">
            <pagination-controls
              id="server"
              directionLinks="true"
              autoHide="true"
              responsive="true"
              previousLabel=""
              nextLabel=""
              (pageChange)="pageChangeEvent.emit($event)"
            ></pagination-controls>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
  <article class="col-md-12 text-md-center mb-5" *ngIf="!data?.length">
    <span class="text-secondary">{{ emptyMessage }}</span>
  </article>
</section>

<ng-template #defaultRowTemplate let-item>
  <td *ngFor="let item of item | keyvalue">{{ item.value }}</td>
</ng-template>

<ng-template #defaultHeaderTemplate let-data>
  <th *ngFor="let header of data[0] | keyvalue">{{ header.key }}</th>
</ng-template>
