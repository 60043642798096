import { Component, Input, SimpleChanges } from "@angular/core";
import { Categories } from "../core/models/categories.enum";
import { AppToastService } from "../core/services/app-toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CompanyStore } from "../core/services/Stores/branches.store";
import { CompanyService } from "../core/services/company.service";
import { Router } from "@angular/router";
import { BenefitsService } from "../core/services/portal-admission/benefits.service";
import { AuthenticationService } from "../core/services/auth.service";
import { EmployeesHiringService } from "../core/services/portal-admission/employees-hiring.service";
import { Observable } from "rxjs";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-coverage-modal",
  templateUrl: "./coverage-modal.component.html",
  styleUrls: ["./coverage-modal.component.scss"],
})
export class CoverageModalComponent {
  constructor(
    private toast: AppToastService,
    private modalService: NgbModal,
    private companyStore: CompanyStore,
    private service: CompanyService,
    private router: Router,
    private benefitsService: BenefitsService,
    private authService: AuthenticationService,
    private employeesHiringService: EmployeesHiringService
  ) {}

  @Input() benefitId: number;
  plan: any[] = [];
  plans: any[] = [];
  coverages: any[] = [];
  currentBenefitId: number | null = null;

  @Input() selectedPlanName: string;

  ngOnChanges(changes: SimpleChanges) {
    console.log("ngOnChanges called, changes:", changes);
    if (changes["benefitId"] && changes["benefitId"].currentValue != null) {
      this.getBenefits(changes["benefitId"].currentValue);
    }
  }

  onPlanSelected(planIndex) {
    const selectedPlan = this.plans.find((plan) => plan.index === planIndex);
    if (selectedPlan) {
      this.currentBenefitId = selectedPlan.benefitId;
    }
  }

  selectedChoiceNine: string;
  selectedChoiceTen: string;
  planControl = new FormControl();
  isDisabled = true;
  includedDependents: any[] = [];
  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  selected: boolean;
  discount: string;
  selectedChoice: string;

  descCategory: string = "";

  openCobertura(contentcobertura: any) {
    this.modalService.open(contentcobertura, { size: "lg" });
  }

  goToBenefits() {
    this.router.navigate(["/Portal"]);
  }

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    this.getEmployeesHiring();
    this.planControl.disable();
  }

  getEmployeesHiring() {
    this.employeesHiringService
      .getEmployeesHiring(this.employeeId)
      .subscribe((res: any) => {
        const id = res.hiringProcessId;
        this.hiringProcessId = id;

        this.getBenefits(id);
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  setBenefitChoice(wantsBenefit: boolean): void {
    this.benefit.wantTheBenefit = wantsBenefit;
  }
  onPlanChange(event: any): void {
    this.selectedPlanName = event.name;
  }

  getBenefits(benefitId: number) {
    this.benefitsService.getBenefits(benefitId).subscribe(
      (response: any) => {

        if (response.benefitTypes && response.benefitTypes.length > 0) {
          console.log("Benefit Types:", response.benefitTypes);

          for (const type of response.benefitTypes) {
            console.log("Checking benefit type:", type);

            const foundBenefit = type.benefitList.find(
              (benefit) => benefit.categoryId === Categories.Odonto
            );

            console.log("Found Benefit:", foundBenefit);

            if (foundBenefit && foundBenefit.items) {
              console.log("Found Benefit Items:", foundBenefit.items);

              this.coverages = foundBenefit.items.flatMap((item) => {
                console.log("Item:", item);
                return {
                  name: item.name,
                  planCoverage: item.planCoverage,
                };
              });

              console.log("Coverages after flatMap:", this.coverages); // Log das coberturas após o processamento
              break;
            }
          }
        }
      },
      (error) => {
        console.error("Error fetching benefits:", error);
      }
    );
  }
}
