import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { AssessmentStatusEnum } from "src/app/core/models/assessment-status.enum";
import { CalibrationStatusEnum } from "src/app/core/models/calibration-status.enum";

@Component({
  selector: "app-performance-assessment",
  templateUrl: "./performance-assessment.component.html",
  styleUrls: ["./performance-assessment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceAssessmentComponent implements OnInit {
  @Input() assessment: any;
  @Input() hasActionButtons: boolean;
  @Output() getChart = new EventEmitter();
  @Output() goToAssessmentPage = new EventEmitter();

  nineboxChartDataSet: any;
  spiderChartDataSet: any;

  hasNineboxChart: boolean;

  chartDataSet: any;

  isFinalizedAssessment: boolean;
  isCalibratedAssessment: boolean;

  constructor() {}

  ngOnInit(): void {
    this.chartDataSet = {
      competences: [
        "Tomada de Decisão",
        "Visão Tecnológica Estratégica",
        "Gestão de Equipes Técnicas",
        "Gestão de Inovação e Mudança",
      ],
      datasets: {
        currentCycle: [100, 100, 100, 100],
        previousCycle: [66, 50, 16, 66],
      },
      currentGrade: {
        maxScore: 400,
        totalScore: 400,
        maxAverage: 100,
        totalAverage: 100,
        scorePercentage: 100,
        gradingSystem: 2,
      },
      previousGrade: {
        maxScore: 600,
        totalScore: 300,
        maxAverage: 150,
        totalAverage: 74,
        scorePercentage: 48,
        gradingSystem: 1,
      },
      evolutionPercentage: 52,
    };

    if (this.assessment) {
      this.isFinalizedAssessment =
        this.assessment.employee.statusAssessmentId ===
        AssessmentStatusEnum.Finalizado;

      this.isCalibratedAssessment =
        this.assessment.employee.statusCalibrationId ===
        CalibrationStatusEnum.Calibrado;
    }
  }

  getAssessmentChart(chartType: string) {
    this.getChart.emit(chartType);
  }

  goTo(assessmentId: string) {
    this.goToAssessmentPage.emit(assessmentId);
  }

  print(): void {
    window.print();
  }
}
