import { AbstractControl, ValidationErrors, ValidatorFn,Validators } from "@angular/forms";
import { cpf } from 'cpf-cnpj-validator';

export function loginValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const emailResult = Validators.email(control);
    const cnpjFormat = control.value;
    const isValid = cpf.isValid(cnpjFormat,true) || !emailResult;
    return isValid ? null : { loginValidator: true};
  };
}
