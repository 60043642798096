import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, filter, map } from 'rxjs';
import { AddressService } from 'src/app/core/services/addres.service';
import { AppToastService } from 'src/app/core/services/app-toast.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { EnumsService } from 'src/app/core/services/enums.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-dependens-accordion-new',
  templateUrl: './dependens-accordion-new.component.html',
  styleUrls: ['./dependens-accordion-new.component.scss']
})
export class DependensAccordionNewComponent {
  @Input() isActive: boolean = false;
  @Input() isContributorProfile = false;
  @Input() isExhibition = false;
  @Input() dependentsValue;
  @Input() dependentsRemovedId;
  @Input() mandatoryDocuments;
  @Input() dependentsBenefits = [];
  @Input() mandatoryDocumentItem;
  @Input() genericDocumentValue;
  @Input() enabledOnChangeEvent = false;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() saveDependentValue = new EventEmitter();
  @Output() deleteDependentsValue = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() AddGenericDocument = new EventEmitter();
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  dependentsList: any[] = [];
  selectedDependent: any;
  benefitTable = [];
  selectedBenefit = [];
  form: FormGroup;
  ufs = [];
  cities: any = [];
  documentFile: any;
  documentFileBack: any;
  documentFileNasc: any;
  documentFileEndereco: any;
  extension: any;
  birthCertificateFiles = [];
  frontOfRgFiles = [];
  backOfRgFiles = [];
  addressProofFiles = [];
  mandatoryDocumentsFiles = [];
  naoValue = false;
  simValue = false;
  dependentType = [];
  selectedChoice: string; 
  selectedChoiceTwo: string; 
  selectedChoiceTre: string; 
  documentExceptions = [
    "COMPROVANTE_RESIDENCIA",
    "FRENTE_RG",
    "VERSO_RG",
    "CERTIDAO_CASAMENTO_NASCIMENTO",
  ];

  martialStatusArray = [];
  acordeons: { isActive: boolean, type?: any, id: number, title?: string }[] = [];
  genderArray = [
    { name: "Masculino", value: 1 },
    { name: "Feminino", value: 2 },
    { name: "Não binário(a)", value: 3 },
    { name: "Fluido(a) de gênero", value: 4 },
    { name: "Bigênero", value: 5 },
  ];

  ParentArray = [
    { name: "Cônjugue", value: 1 },
    { name: "Filho(a)", value: 2 },
    { name: "Companheiro(a)", value: 3 },
    { name: "Filho(a)", value: 4 },
    { name: "Filho(a) Inválido(a)", value: 5 },
    { name: "Tutelado(a)", value: 6 },
    { name: "Menor sob Guardo(a)", value: 7 },
    { name: "Pai", value: 8 },
    { name: "Mãe", value: 9 },
    { name: "Sogra", value: 10 },
    { name: "Sogro", value: 11 },
    { name: "Irmão(a)", value: 12 },
    { name: "Padrasto", value: 13 },
    { name: "Madrasta", value: 14 },
    { name: "Enteado(a)", value: 15 },
  ];

  CivilArray = [
    { name: "Solteiro(a)", value: 1 },
    { name: "Casado(a)", value: 2 },
    { name: "Separado(a)", value: 3 },
    { name: "Divorciado(a)", value: 4 },
    { name: "Viúvo(a)", value: 5 },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private addressService: AddressService,
    private toast: AppToastService,
    private enumsService: EnumsService,
    private utilsService: UtilsService,
    private cdRef: ChangeDetectorRef,
  ) {}
  myGroup: FormGroup;
  ngOnInit(): void {
    this.myGroup = new FormGroup({
      firstName: new FormControl(''),

    });
    this.InitializeForm();
    this.companyService
      .getAllCountries()
      .subscribe((res: any) => (this.ufs = res));

    this.enumsService
      .getKinshipTypes()
      .subscribe((resp) => (this.dependentType = resp));
  }
  

  accordionActive: boolean = false;

  toggleAccordion() {
    const newAccordionId = this.acordeons.length; 
    this.acordeons.push({ 
      isActive: true, 
      id: newAccordionId
    });
    this.cdRef.detectChanges();
}

removeAccordion(accordionId: number) {
  const indexToRemove = this.acordeons.findIndex(acc => acc.id === accordionId);
  if (indexToRemove !== -1) {
    this.acordeons.splice(indexToRemove, 1);
  }
  this.cdRef.detectChanges();
}

saveDependentClick(index: number) {
  const currentAcordeon = this.acordeons[index];
  currentAcordeon.type = this.form.get('type').value;
  currentAcordeon.isActive = false;
  this.cdRef.detectChanges();    
}


  get dependents() {
    return this.form?.controls["dependents"] as FormArray;
  }

  getDependent(index: number) {
    const formGroup = this.dependents.controls[index] as FormGroup;
    return formGroup.controls;
  }



  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.dependentsValue?.currentValue;
    if (data) {
      data.forEach((payload, i) => {
        this.getBirthCertificateFile(i, { base64: payload?.birthCertificateFile, extension: payload?.birthCertificateFileExtension });
        this.getFrontOfRgFile(i, { base64: payload?.frontRgFile, extension: payload?.frontRgFileExtension });
        this.getBackOfRgFile(i, { base64: payload?.backRgFile, extension: payload?.backRgFileExtension });
        this.getAddressProofFile(i, { base64: payload?.addressProofFile, extension: payload?.addressProofFileExtension });
        payload?.mandatoryDocuments?.forEach((document) =>
          this.addMandatoryDocumentFile(i, document)
        );
      });
    }
  }


  removeData(changes: SimpleChanges) {
    const id = changes.dependentsRemovedId?.currentValue;
    if (id) {
      const array = this.dependents.controls;
      const i = array.findIndex((el: any) => el.id === id);
      this.dependents?.removeAt(i);
      this.birthCertificateFiles?.splice(i, 1);
      this.frontOfRgFiles?.splice(i, 1);
      this.backOfRgFiles?.splice(i, 1);
      this.addressProofFiles?.splice(i, 1);
    }
  }

  addressData = {
    postalCode: "04016-070",
    address: "Rua Jorge Chammas",
    number: "123",
    neighborhood: "Vila Mariana",
    stateId: "SP",
    cityId: "São Paulo",
    complement: "",
  };


  changeNao() {
    this.simValue = !this.naoValue;
  }

  changeSim() {
    this.naoValue = !this.simValue;
  }

  add() {
    console.log("Function ADD is called!");
    this.form.patchValue(this.addressData);
  }

  remove(i) {
    const id = this.dependents.controls[i].value.id;
    if (id) {
      this.deleteDependentsValue.emit(id);
    }
    this.dependents.removeAt(i);
    this.birthCertificateFiles?.splice(i, 1);
    this.frontOfRgFiles?.splice(i, 1);
    this.backOfRgFiles?.splice(i, 1);
    this.addressProofFiles?.splice(i, 1);
    this.removeMandatoryDocuments(i);
  }

  getDocumentFileRgFront({ base64, extension }) {
    this.documentFile = base64;
    this.extension = extension;

    this.genericDocumentValue = this.createGenericDocumenyPayload();

    if (this.enabledOnChangeEvent)
      this.fileChange.emit(this.genericDocumentValue);
  }

  
  getDocumentFileRgBack({ base64, extension }) {
    this.documentFile = base64;
    this.extension = extension;

    this.genericDocumentValue = this.createGenericDocumenyPayload();

    if (this.enabledOnChangeEvent)
      this.fileChange.emit(this.genericDocumentValue);
  }
  
  getDocumentFileNasci({ base64, extension }) {
    this.documentFile = base64;
    this.extension = extension;

    this.genericDocumentValue = this.createGenericDocumenyPayload();

    if (this.enabledOnChangeEvent)
      this.fileChange.emit(this.genericDocumentValue);
  }
  
  getDocumentFileEndereco({ base64, extension }) {
    this.documentFile = base64;
    this.extension = extension;

    this.genericDocumentValue = this.createGenericDocumenyPayload();

    if (this.enabledOnChangeEvent)
      this.fileChange.emit(this.genericDocumentValue);
  }

  // downloadFile() {
  //   const documentName = `${this.mandatoryDocumentItem.name}.${
  //     this.genericDocumentValue.documentExtension.split("/")[1]
  //   }`;

  //   this.utilsService.downloadFile(this.genericDocumentValue.documentFile,documentName);
  // }

  private createGenericDocumenyPayload() {
    return {
      documentFile: this.documentFile,
      documentId: this.mandatoryDocumentItem?.id,
      documentExtension: this.extension,
    };
  }
  saveDependent(i) {
    if (
      !this.hasAddedAllRequiredDocuments(i) ||
      this.dependents.controls[i].invalid
    ) {
      this.dependents.controls.forEach((form: FormGroup) =>
        Object.keys(form.controls).forEach((key) => {
          form.controls[key].markAsDirty();
        })
      );

      return;
    }

    const payload = {
      ...this.dependents.value[i],
      birthCertificateFile: this.birthCertificateFiles[i]?.doc,
      frontOfRgFile: this.frontOfRgFiles[i]?.doc,
      backOfRgFile: this.backOfRgFiles[i]?.doc,
      addressProofFile: this.addressProofFiles[i]?.doc,
      mandatoryDocuments: this.getDependentDocuments(
        i,
        this.mandatoryDocumentsFiles
      )?.map((data) => data.doc),
    };

    this.saveDependentValue.emit(payload);
  }

  nextPage() {
    this.next.emit();
  }

  InitializeForm() {
    this.form = this.formBuilder.group({
      type: [''],  
      dependents: this.formBuilder.array([]),  
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      dependents: this.formBuilder.array([]),
    });
  }

  getAddresByCep(index: number) {
    const formGroup = this.dependents?.controls[index] as FormGroup;

    formGroup
      ?.get("postalCode")
      ?.valueChanges.pipe(
        filter((value) => value?.length === 8),
        debounceTime(500),
        map((cep: string) => {
          this.addressService
            .getAddressByCEP(cep)
            .subscribe(async (res: any) => {
              if (res?.erro) {
                this.toast.show("Busca por cep", "Cep não encontrado", false);
                return;
              }
              if (res) {
                const uf = this.ufs.filter(
                  (data: any) => data?.name === res?.uf
                )[0]?.id;

                formGroup.get("countryId")?.patchValue(uf);
                formGroup.get("address")?.patchValue(res.logradouro);
                formGroup.get("neighborhood")?.patchValue(res.bairro);
                formGroup.get("cityId")?.patchValue(res.localidade);
                formGroup.get("complement")?.patchValue(res.complemento);
                formGroup.get("state")?.patchValue(res.localidade);

                this.getCitiesByState(uf, index, res.localidade);
              }
            });
        })
      )
      .subscribe();
  }

  getCitiesByState(countryId: number, index?: number, term?: string) {
    this.companyService.getAllCities(countryId, term).subscribe((data) => {
      this.cities = data;

      if (this.cities.length === 1 && index !== null) {
        const formGroup = this.dependents.controls[index] as FormGroup;
        formGroup.get("cityId").setValue(data[0].id);
      }
    });
  }

  getBirthCertificateFile(i, payload) {
    if (!payload) {
      this.birthCertificateFiles?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.Extension,
    };

    this.birthCertificateFiles.push(document);
  }

  getFrontOfRgFile(i, payload) {
    if (!payload) {
      this.frontOfRgFiles?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.Extension,
    };

    this.frontOfRgFiles.push(document);
  }

  getBackOfRgFile(i, payload) {
    if (!payload) {
      this.backOfRgFiles?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.Extension,
    };

    this.backOfRgFiles.push(document);
  }

  getAddressProofFile(i, payload) {
    if (!payload) {
      this.addressProofFiles?.splice(i, 1);
      return;
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.Extension,
    };

    this.addressProofFiles.push(document);
  }

  addMandatoryDocumentFile(i, payload) {
    const removeDuplicatedDocument = (documentId: number, arr: any[]): any[] =>
      arr.filter(({ doc }) => doc.documentId !== documentId);

    if (!payload) {
      this.mandatoryDocumentsFiles?.splice(i, 1);
      return;
    }

    let dependentDocuments = this.getDependentDocuments(
      i,
      this.mandatoryDocumentsFiles
    );

    if (dependentDocuments) {
      dependentDocuments = removeDuplicatedDocument(
        payload.documentId,
        dependentDocuments
      );

      this.mandatoryDocumentsFiles = [
        ...this.mandatoryDocumentsFiles.filter(({ id }) => id !== i),
        ...dependentDocuments,
      ];
    }

    const document = {
      id: i,
      doc: payload.base64,
      docExtension: payload.Extension,
    };

    this.mandatoryDocumentsFiles.push(document);
  }

  getDependentTitle(i) {
    const id = this.getDependent(i).type?.value;
    const title = this.dependentType.filter(
      (type: any) => type.value === id
    )[0];

    return title?.name;
  }

  logoutEmit() {
    this.logout.emit();
  }

  getMandatoryDocumentValue(item: any, i: number) {
    return this.getDependentDocuments(i, this.mandatoryDocumentsFiles)?.find(
      ({ doc }) => doc.documentId === item.id
    )?.doc;
  }

  downloadFile(i: number, mandatoryDocument: any, { doc, docExtension }) {
    const documentName = `${mandatoryDocument.name}.${docExtension}`;

    this.utilsService.downloadFile(doc, documentName);
  }

  private getDependentDocuments = (dependentId: number, arr: any[]): any[] =>
    arr.filter(({ id }) => id === dependentId);

  private removeMandatoryDocuments = (i: number) =>
    (this.mandatoryDocumentsFiles = [
      ...this.mandatoryDocumentsFiles.filter(({ id }) => id !== i),
    ]);

  private hasAddedAllRequiredDocuments(i: number) {
    let canSave = true;
    this.mandatoryDocuments.dependentDocuments.forEach((document) => {
      if (document.required && !this.getMandatoryDocumentValue(document, i)) {
        this.toast.show(
          "Documentos obrigatórios",
          `O documento "${document.displayName}" é obrigatório.`
        );
        canSave = false;
      }
    });

    return canSave;
  }

  
}
