<app-accordion
  class="my-1"
  [title]="benefit.descCategory"
  *ngIf="benefit && plan"
>
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">{{ plan.name }}</label>
      <button
        class="btn btn-outline-primary mt-2 col-md-3 col-sm-4"
        type="button"
        (click)="openCobertura(contentcobertura)"
      >
        <i class="mdi mdi-eye-outline"></i> VER COBERTURA
      </button>
      <div class="row mt-4">
        <div class="col-md-2 col-12 col-sale">
          <label class="text-sale">Valor</label>
          <span> {{ plan.cost || 0 | currency : "BRL" : "symbol" }}</span>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ plan.existDiscountToHolder }}</span>
        </div>
        <div class="col-md-4 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>
          <ng-container *ngIf="plan?.holderDiscountType === 2">
            <span>{{ plan?.holderDiscount || 0 }}%</span>
          </ng-container>
          <ng-container *ngIf="plan?.holderDiscountType === 1">
            <span>{{
              plan?.holderDiscount || 0 | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
<ng-template #contentcobertura>
  <div class="modal-content custom-modal">
    <div class="row">
      <div class="col-12">
        <h4 class="text-ptransp">COBERTURA</h4>
        <div *ngFor="let plan of benefit.items" class="col-12 mt-2">
          <div *ngFor="let coverage of plan.planCoverage">
            <p>{{ coverage.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
