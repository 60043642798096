import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appEnrollmentValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EnrollmentValidatorDirective,
      multi: true
    }
  ]
})
export class EnrollmentValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const enrollment = control.value;
    if (!enrollment) {
      return null; // Valor vazio, não há erro de validação
    }

    const digits = enrollment.split('').map(Number);
    const length = digits.length;

    let sum = 0;
    for (let i = 0; i < length - 1; i++) {
      sum += digits[i] * (length - i);
    }

    const expectedDV = (sum * 10) % 11;
    const actualDV = digits[length - 1];

    return (expectedDV === actualDV || (expectedDV === 10 && actualDV === 0)) ? null : { invalidRegistration: true };
  }
}

export function enrollmentValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const directive = new EnrollmentValidatorDirective();
    return directive.validate(control);
  };
}
