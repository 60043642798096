import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalBankDetailsService {

  apiUrl = environment.url;

  constructor(private http: HttpClient) { }

  getPersonalBankDetails(employeeId: string): Observable<any> {
    const endpoint = `${this.apiUrl}/v1/hirings/${employeeId}/steps/2`
    return this.http.get(endpoint);
  }

  postPersonalBankDetails(id: number, body: any): Observable<any> {
    const endpoint = `${this.apiUrl}/v1/hirings/${id}/steps/2`
    return this.http.post(endpoint, body);
  }

  putPersonalBankDetails(id: number, body: any): Observable<any> {
    const endpoint = `${this.apiUrl}/v1/hirings/${id}/steps/2`
    return this.http.put(endpoint, body);
  }

  getBankList():Observable<any>{
    return this.http.get(`${this.apiUrl}/v1/banks`)
  }
}
