import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-timer",
  templateUrl: "./timer.component.html",
  styleUrls: ["./timer.component.scss"],
})
export class TimerComponent implements OnChanges {
  @Input() startCounting: boolean;
  @Input() timerValue: number;
  timerInterval: any;

  counting = false;
  isStarted = false;

  constructor() {
    this.timerValue = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.startCounting?.currentValue !==
      changes?.startCounting?.previousValue
    ) {
      const curr = changes.startCounting.currentValue;
      if (curr) this.startTimer();
      else this.stopTimer();
    }
  }

  startTimer() {
    if (!this.isStarted)
      this.timerInterval = setInterval(() => {
        this.timerValue++;
      }, 1000); // Update every second

    this.isStarted = true;
  }

  stopTimer() {
    clearInterval(this.timerInterval);
  }

  resetTimer() {
    this.timerValue = 0;
  }
}
