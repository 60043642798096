<section class="card" id="app-create-update-user">
  <div class="card-body row">
    <div class="col-md-12">
      <h6 class="text-uppercase">Informações pessoais</h6>
    </div>
    <div class="col-md-12 mt-3">
      <form action="" class="row form-group" [formGroup]="form">
        <div class="col-md-12 row">
          <div class="col-xl-8 col-sm-12 mt-2">
            <label class="text-default-label">Nome social</label>
            <input class="form-control" formControlName="name" />
          </div>
          <div class="col-xl-4 col-md-4 col-sm-12 mt-2">
            <label for="genderLabel" class="text-default-label">
              Selecione um gênero*
            </label>
            <ng-select
              class="custom"
              [ngClass]="{ 'is-invalid': f.gender.errors && f.gender.dirty }"
              formControlName="gender"
              [items]="genderArray"
              bindLabel="name"
              bindValue="value"
              placeholder="Selecione o gênero"
              notFoundText="Nenhum gênero encontrado."
            >
            </ng-select>
            <div
              *ngIf="f.gender.errors && f.gender.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.gender.errors.required">Selecione um gênero.</span>
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">Data de nascimento*</label>
            <div class="input-group">
              <input
                type="date"
                class="form-control col-md-12"
                placeholder="Digite aqui a informação"
                formControlName="birthdate"
                [ngClass]="{
                  'is-invalid': f.birthdate.errors && f.birthdate.dirty
                }"
              />
              <div
                class="text-danger"
                *ngIf="f.birthdate.invalid && f.birthdate.dirty"
              >
                <span *ngIf="f.birthdate.errors.required"
                  >A Data de Nascimento é obrigatória</span
                >
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-12 mt-2">
            <label for="meritalStatusLabel" class="text-default-label"
              >Selecione um estado civil*</label
            >
            <ng-select
              class="custom"
              [ngClass]="{
                'is-invalid': f.maritalStatus.errors && f.maritalStatus.dirty
              }"
              [items]="martialStatusArray"
              bindLabel="name"
              bindValue="value"
              formControlName="maritalStatus"
              placeholder="Selecione um estado civil"
              notFoundText="Nenhum Estado civil encontrado."
            >
            </ng-select>
            <div
              *ngIf="f.maritalStatus.errors && f.maritalStatus.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.maritalStatus.errors.required"
                >Selecione um estado civil.</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 row mt-3 align-items-end">
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">CPF*</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.cpf.errors && f.cpf.dirty }"
              formControlName="cpf"
              placeholder="ex: 999.999.999-99"
              mask="000.000.000-00"
            />
            <div *ngIf="f.cpf.errors && f.cpf.invalid" class="invalid-feedback">
              <span *ngIf="f.cpf.errors.required">O CPF é obrigatório.</span>
              <span *ngIf="f.cpf.errors.cpfValidator">O CPF é inválido.</span>
            </div>
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">RG*</label>
            <input
              type="text"
              name="rg"
              id="rg"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.rg.errors && f.rg.dirty }"
              formControlName="rg"
              placeholder="ex: 99.999.999-9"
            />
            <div *ngIf="f.rg.errors && f.rg.invalid" class="invalid-feedback">
              <span *ngIf="f.rg.errors.required">O RG é obrigatório.</span>
            </div>
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">É PCD?</label>
            <ng-select
              formControlName="isPcd"
              class="custom"
              placeholder="Selecione uma opção"
              notFoundText="Nenhuma opção encontrada."
            >
              <ng-option [value]="true">Sim</ng-option>
              <ng-option [value]="false">Não</ng-option>
            </ng-select>
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-1">
            <label class="text-default-label">Altura (m)</label>
            <input
              type="text"
              name="height"
              id="height"
              class="form-control"
              formControlName="height"
              placeholder="0,00"
              mask="0,00"
            />
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-1">
            <label class="text-default-label">Peso (kg)</label>
            <input
              type="text"
              name="weight"
              id="weight"
              class="form-control"
              formControlName="weight"
              placeholder="00,00"
              mask="separator"
              thousandSeparator=","
            />
          </div>
        </div>
        <div class="col-md-12 row mt-3 align-items-end">
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">PIS*</label>
            <input
              type="text"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.pis.errors && f.pis.dirty }"
              formControlName="pis"
              placeholder="ex: "
              mask=""
            />
            <div *ngIf="f.pis.errors && f.pis.invalid" class="invalid-feedback">
              <span *ngIf="f.pis.errors.required">O PIS é obrigatório.</span>
            </div>
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">Telefone</label>
            <input
              type="text"
              class="form-control"
              formControlName="phone"
              placeholder="ex: (99)99999-9999"
              mask="(00) 00000-0000"
            />
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-1">
            <label class="text-default-label">Grau de instrução*</label>
            <ng-select
              class="custom"
              formControlName="schoolingId"
              [ngClass]="{
                'is-invalid': f.schoolingId.errors && f.schoolingId.dirty
              }"
              placeholder="Selecione um Grau de Instrução."
              notFoundText="Nenhum dado selecionado."
            >
              <ng-option *ngFor="let item of schooling" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <div
              *ngIf="f.schoolingId.errors && f.schoolingId.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.schoolingId.errors.required"
                >Selecione um Grão de Instrução*.</span
              >
            </div>
          </div>
          <div class="col-md-6 col-sm-12 mt-3">
            <label class="text-default-label">E-mail pessoal*</label>
            <input
              type="text"
              name="email"
              id="email"
              class="form-control"
              formControlName="email"
              placeholder="email@email.com"
              [ngClass]="{
                'is-invalid': f.email.errors && f.email.dirty
              }"
            />
            <div
              *ngIf="f.email.errors && f.email.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.email.errors.required">Campo obrigatório.</span>
            </div>
          </div>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
            <label class="text-default-label">Perfil do LinkedIn</label>
            <input
              type="text"
              name="linkedin"
              id="linkedin"
              class="form-control"
              formControlName="linkedin"
              placeholder="Link (URL) do seu perfil (opcional)"
            />
          </div>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
            <label class="text-default-label">Nome da mãe*</label>
            <input
              type="text"
              name="motherName"
              id="motherName"
              class="form-control"
              formControlName="motherName"
              placeholder="Nome da mãe*"
              [ngClass]="{
                'is-invalid': f.motherName.errors && f.motherName.dirty
              }"
            />
            <div
              *ngIf="f.motherName.errors && f.motherName.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.motherName.errors.required"
                >Campo obrigatório.</span
              >
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
            <label class="text-default-label">Nome do pai</label>
            <input
              type="text"
              name="fatherName"
              id="fatherName"
              class="form-control"
              formControlName="fatherName"
            />
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">Nacionalidade do pai</label>
            <input
              type="text"
              name="fatherNationality"
              id="fatherNationality"
              class="form-control"
              formControlName="fatherNationality"
            />
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">Nacionalidade da mãe</label>
            <input
              type="text"
              name="motherNationality"
              id="motherNationality"
              class="form-control"
              formControlName="motherNationality"
            />
          </div>
        </div>

        <div class="col-md-12 row mt-3">
          <div class="col-xl-5 col-md-4 col-sm-12 mt-2">
            <label for="raceColorLabel" class="text-default-label"
              >Raça / Cor</label
            >
            <ng-select
              class="custom"
              placeholder="Selecione a Raça/Cor"
              notFoundText="Nenhum item encontrado."
              formControlName="raceColorId"
            >
              <ng-container *ngIf="raceColors$ | async as items">
                <ng-option *ngFor="let item of items" [value]="item.value">
                  {{ item.name }}
                </ng-option>
              </ng-container>
            </ng-select>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">Data de expedição da CTPS</label>
            <input
              type="date"
              name="ctpsShippingDate"
              id="ctpsShippingDate"
              class="form-control"
              formControlName="ctpsShippingDate"
            />
          </div>
          <div class="col-xl-4 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">Número da reservista</label>
            <input
              type="text"
              name="reservistNumber"
              id="reservistNumber"
              class="form-control"
              formControlName="reservistNumber"
            />
          </div>
        </div>

        <div class="col-md-12 row mt-3">
          <div class="col-xl-4 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label"
              >RIC - Registro de identificação</label
            >
            <input
              type="text"
              name="ricIdentity"
              id="ricIdentity"
              class="form-control"
              formControlName="ricIdentity"
            />
          </div>
          <div class="col-xl-4 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">RIC - Orgão emissor</label>
            <input
              type="text"
              name="ricIssuingAgency"
              id="ricIssuingAgency"
              class="form-control"
              formControlName="ricIssuingAgency"
            />
          </div>
          <div class="col-xl-4 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">RIC - Data de expedição</label>
            <input
              type="date"
              name="ricShippingDate"
              id="ricShippingDate"
              class="form-control"
              formControlName="ricShippingDate"
            />
          </div>
        </div>
        <div class="col-md-12 row mt-3">
          <label for="isStudent" class="text-default-label">É estudante</label>
          <div class="custom-checkbox-container">
            <label class="custom-checkbox">
              <input
                type="radio"
                name="isStudent"
                [value]="false"
                formControlName="isStudent"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Não
            </label>

            <label class="custom-checkbox">
              <input
                type="radio"
                name="isStudent"
                [value]="true"
                formControlName="isStudent"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Sim
            </label>
          </div>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-xl-4 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label"
              >Tipo de aposentadoria sem resição</label
            >
            <ng-select
              class="custom"
              placeholder="Selecione um item"
              notFoundText="Nenhum item encontrado."
              formControlName="retiredTypeId"
            >
              <ng-container *ngIf="retiredTypes$ | async as types">
                <ng-option *ngFor="let item of types" [value]="item.value">
                  {{ item.name }}
                </ng-option>
              </ng-container>
            </ng-select>
          </div>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-xl-4 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label"
              >Informar Deficiência física</label
            >
            <ng-select
              class="custom"
              placeholder="Selecione um item"
              notFoundText="Nenhum item encontrado."
              formControlName="foreignPcdId"
            >
              <ng-container *ngIf="pcdTypes$ | async as types">
                <ng-option *ngFor="let item of types" [value]="item.value">
                  {{ item.name }}
                </ng-option>
              </ng-container>
            </ng-select>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <h6 class="text-uppercase">Estrangeiro</h6>
        </div>

        <div class="col-md-12 row mt-3">
          <div class="col-xl-12 col-md-12 col-sm-12 mt-2">
            <label class="text-default-label">Condição do trabalhador</label>
            <ng-select
              class="custom"
              placeholder="Selecione um item"
              notFoundText="Nenhum item encontrado."
              formControlName="foreignStatusId"
            >
              <ng-container *ngIf="foreignStatus$ | async as status">
                <ng-option *ngFor="let item of status" [value]="item.value">
                  {{ item.name }}
                </ng-option>
              </ng-container>
            </ng-select>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12 mt-2">
            <label class="text-default-label">RNE</label>
            <input
              type="tex"
              name="foreignRne"
              id="foreignRne"
              class="form-control"
              formControlName="foreignRne"
            />
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12 mt-2">
            <label class="text-default-label">Orgão emissor</label>
            <input
              type="text"
              name="foreignRneIssuingAgency"
              id="foreignRneIssuingAgency"
              class="form-control"
              formControlName="foreignRneIssuingAgency"
            />
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12 mt-2">
            <label class="text-default-label">Data de expedição</label>
            <input
              type="date"
              name="foreignRneShippingDate"
              id="foreignRneShippingDate"
              class="form-control"
              formControlName="foreignRneShippingDate"
            />
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12 mt-2">
            <label class="text-default-label">Data de de vencimento</label>
            <input
              type="date"
              name="foreignRneDueDate"
              id="foreignRneDueDate"
              class="form-control"
              formControlName="foreignRneDueDate"
            />
          </div>
          <div class="col-md-12 row mt-3">
            <label for="foreignMarried" class="text-default-label"
              >Casado com brasileiro</label
            >
            <div class="custom-checkbox-container">
              <label class="custom-checkbox">
                <input
                  type="radio"
                  name="isForeignMarried"
                  [value]="false"
                  formControlName="isForeignMarried"
                />
                <span class="checkmark">
                  <span class="checkmark-inner"></span>
                </span>
                Não
              </label>

              <label class="custom-checkbox">
                <input
                  type="radio"
                  name="isForeignMarried"
                  [value]="true"
                  formControlName="isForeignMarried"
                />
                <span class="checkmark">
                  <span class="checkmark-inner"></span>
                </span>
                Sim
              </label>
            </div>
          </div>
          <div class="col-md-12 row mt-3">
            <label for="foreignHasChild" class="text-default-label"
              >Filho(s) brasileiro(s)</label
            >
            <div class="custom-checkbox-container">
              <label class="custom-checkbox">
                <input
                  type="radio"
                  name="hasForeignChild"
                  [value]="false"
                  formControlName="hasForeignChild"
                />
                <span class="checkmark">
                  <span class="checkmark-inner"></span>
                </span>
                Não
              </label>

              <label class="custom-checkbox">
                <input
                  type="radio"
                  name="hasForeignChild"
                  [value]="true"
                  formControlName="hasForeignChild"
                />
                <span class="checkmark">
                  <span class="checkmark-inner"></span>
                </span>
                Sim
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <h6 class="text-uppercase">Endereço</h6>
        </div>

        <div class="col-md-12 row mt-3">
          <div class="col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">CEP*</label>
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              class="form-control"
              [ngClass]="{
                'is-invalid': f.postalCode.errors && f.postalCode.dirty
              }"
              formControlName="postalCode"
              placeholder="ex: 99999-999"
              mask="00000-000"
            />
            <div
              *ngIf="f.postalCode.errors && f.postalCode.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.postalCode.errors.required"
                >O CEP é obrigatório.</span
              >
            </div>
          </div>
          <div class="col-md-8 col-sm-12 mt-2">
            <label class="text-default-label">Endereço*</label>
            <input
              type="text"
              name="address"
              id="address"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.address.errors && f.address.dirty }"
              formControlName="address"
            />
            <div
              *ngIf="f.address.errors && f.address.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.address.errors.required"
                >O endereço é obrigatório.</span
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-3 mt-2">
            <label class="text-default-label">Número</label>
            <input
              class="form-control"
              type="number"
              [ngClass]="{ 'is-invalid': f.number.errors && f.number.dirty }"
              formControlName="number"
            />
            <div
              *ngIf="f.number.errors && f.number.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.number.errors.required"
                >O número do endereço é obrigatório.</span
              >
            </div>
          </div>
          <div class="col-md-8 col-sm-9 mt-2">
            <label class="text-default-label">Bairro*</label>
            <input
              type="text"
              name="neighborhood"
              id="neighborhood"
              class="form-control"
              [ngClass]="{
                'is-invalid': f.neighborhood.errors && f.neighborhood.dirty
              }"
              formControlName="neighborhood"
            />
            <div
              *ngIf="f.neighborhood.errors && f.neighborhood.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.neighborhood.errors.required"
                >O bairro é obrigatório.</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 row mt-2">
          <div class="col-md-2 col-sm-3 mt-2">
            <label for="stateIdLabel" class="text-default-label">Estado*</label>
            <ng-select
              formControlName="stateId"
              class="custom"
              placeholder="Selecione o estado"
              [ngClass]="{ 'is-invalid': f.stateId.errors && f.stateId.dirty }"
              notFoundText="Nenhum Estado encontrado."
            >
              <ng-option *ngFor="let item of countries" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>

            <div
              *ngIf="f.stateId.errors && f.stateId.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.stateId.errors.required"
                >Selecione um estado.</span
              >
            </div>
          </div>
          <div class="col-md-4 col-sm-9 mt-2">
            <label for="cityLabel" class="text-default-label">Cidade*</label>
            <ng-select
              formControlName="cityId"
              class="custom"
              placeholder="Selecione a cidade"
              [ngClass]="{ 'is-invalid': f.cityId.errors && f.cityId.dirty }"
              notFoundText="Nenhuma Cidade encontrada."
            >
              <ng-option *ngFor="let item of cities" [value]="item.id">{{
                item.name
              }}</ng-option>
            </ng-select>
            <div
              *ngIf="f.cityId.errors && f.cityId.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.cityId.errors.required"
                >Selecione uma cidade.</span
              >
            </div>
          </div>
          <div class="col-md-6 col-sm-12 mt-2">
            <label class="text-default-label">Complemento</label>
            <input
              name="complement"
              id="complement"
              class="form-control"
              formControlName="complement"
            />
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <h6 class="text-uppercase">Dados bancários</h6>
        </div>
        <div class="col-md-12 my-3" *ngIf="false">
          <button class="col-md-3 btn btn-outline-primary" type="button">
            BAIXAR CARTA DE REFERÊNCIA
          </button>
        </div>
        <div class="col-md-12 row mt-3">
          <div class="col-xl-3 col-md-12 col-sm-12 mt-2">
            <label class="text-default-label">Banco*</label>
            <ng-select
              class="custom"
              [ngClass]="{ 'is-invalid': f.bankId.errors && f.bankId.dirty }"
              formControlName="bankId"
              [items]="bankList"
              bindLabel="name"
              bindValue="id"
              placeholder="Selecione um banco"
              notFoundText="Nenhum Banco encontrado."
            >
            </ng-select>
            <div
              *ngIf="f.bankId.errors && f.bankId.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.bankId.errors.required">Selecione um banco.</span>
            </div>
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">Agência*</label>
            <input
              type="text"
              name="agency"
              id="agency"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.agency.errors && f.agency.dirty }"
              formControlName="agency"
            />
            <div
              *ngIf="f.agency.errors && f.agency.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.agency.errors.required"
                >A agência é obrigatória.</span
              >
            </div>
          </div>
          <div class="col-xl-2 col-md-2 col-sm-12 mt-2">
            <label class="text-default-label">Dígito</label>
            <input
              type="text"
              name="agencyDigit"
              id="agencyDigit"
              class="form-control"
              formControlName="agencyDigit"
            />
          </div>
          <div class="col-xl-2 col-md-4 col-sm-12 mt-2">
            <label class="text-default-label">Conta*</label>
            <input
              type="text"
              name="account"
              id="account"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.account.errors && f.account.dirty }"
              formControlName="account"
            />
            <div
              *ngIf="f.account.errors && f.account.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.account.errors.required"
                >A conta é obrigatória.</span
              >
            </div>
          </div>
          <div class="col-xl-2 col-md-2 col-sm-12 mt-2">
            <label class="text-default-label">Dígito*</label>
            <input
              type="text"
              name="accountDigit"
              id="accountDigit"
              class="form-control"
              formControlName="accountDigit"
              [ngClass]="{
                'is-invalid': f.accountDigit.errors && f.accountDigit.dirty
              }"
            />
            <div
              *ngIf="f.accountDigit.errors && f.accountDigit.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.accountDigit.errors.required"
                >A conta é obrigatória.</span
              >
            </div>
          </div>
          <div class="col-xl-3 col-md-6 col-sm-12 mt-2">
            <label class="text-default-label"
              >Selecione um tipo de conta*</label
            >
            <ng-select
              class="custom"
              [ngClass]="{
                'is-invalid': f.accountType.errors && f.accountType.dirty
              }"
              formControlName="accountType"
              [items]="accountTypeArray"
              bindLabel="name"
              bindValue="value"
              placeholder="Selecione um banco"
              notFoundText="Nenhum Tipo de Conta encontrado."
            >
            </ng-select>
            <div
              *ngIf="f.accountType.errors && f.accountType.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="f.accountType.errors.required"
                >Selecione um tipo de conta.</span
              >
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"
        ></div>
        <div
          class="col-md-12 row justify-content-end"
          *ngIf="!isContributorProfile"
        >
          <div class="col-md-4 text-align-end">
            <button
              type="submit"
              class="btn btn-outline-secondary m-1 text-uppercase"
              (click)="next(true)"
            >
              salvar e sair
            </button>
            <button
              class="btn btn-secondary text-uppercase"
              type="button"
              (click)="back()"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary m-1 text-uppercase"
              (click)="next()"
            >
              Salvar e avançar
            </button>
          </div>
        </div>
        <div
          class="col-md-12 row justify-content-end"
          *ngIf="isContributorProfile"
        >
          <div class="col-md-4 text-align-end">
            <button
              type="submit"
              class="btn btn-outline-primary m-1 text-uppercase"
              (click)="next()"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
