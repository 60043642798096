import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NotificationService } from "src/app/notification.service";

@Component({
  selector: "app-incluir-modal",
  templateUrl: "./incluir-modal.component.html",
  styleUrls: ["./incluir-modal.component.scss"],
})
export class IncluirModalComponent {
  @Input() changedAccordion: string;
  justification: string;
  requestData: Date;
  requestID: number;
  showNotification: boolean = false;
  notificationMessage: string;

  constructor(
    public activeModal: NgbActiveModal,
    private notificationService: NotificationService
  ) {}
  ngOnInit(): void {}

  closeModal() {
    this.activeModal.dismiss();
  }

  // Função para gerar ID aleatório
  generateRandomID(): number {
    return Math.floor(Math.random() * 100000);
  }

  // Função que será chamada ao clicar em "Salvar"
  saveData(): void {
    // Gerando a data e o ID
    this.requestData = new Date();
    this.requestID = this.generateRandomID();

    // Exibindo a notificação (ajuste conforme sua implementação de notificações)
    this.notificationMessage = `
      Solicitação efetuada com sucesso!
      Aguardando aprovação do RH para inclusão de dependente
      Data da solicitação: ${this.requestData.toLocaleDateString()}
      Protocolo (ID): ${this.requestID}
  `;
    this.notificationService.showNotification(this.notificationMessage);
    this.activeModal.dismiss();
  }
}
