import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tooltip-modal',
  templateUrl: './tooltip-modal.component.html',
  styleUrls: ['./tooltip-modal.component.scss']
})
export class TooltipModalComponent implements OnInit {
  @Input() public itemObj: any;
  constructor(public activeModal: NgbActiveModal) {
  }
  ngOnInit(): void {
  }
}
