<section id="app-table" class="table-container p-0 my-3">
  <table class="table table-bordered" *ngIf="data">
    <thead>
      <tr>
        <ng-container
          *ngTemplateOutlet="
            header || defaultHeaderTemplate;
            context: { $implicit: data }
          "
        >
        </ng-container>
        <th scope="col" *ngIf="data?.length && showDefaultActionButton">
          Ações
        </th>
      </tr>
    </thead>
    <tfoot></tfoot>
    <tbody>
      <tr
        *ngFor="
          let item of data
            | paginate
              : {
                  id: 'server',
                  itemsPerPage: totalPerPage,
                  currentPage: page,
                  totalItems: totalItems
                }
        "
      >
        <ng-container
          *ngTemplateOutlet="
            items || defaultRowTemplate;
            context: { $implicit: item }
          "
        >
        </ng-container>
        <td *ngIf="showDefaultActionButton">
          <div class="d-flex justify-content-between">
            <i
              *ngIf="canEdit"
              class="bx bxs-edit-alt"
              (click)="update(item)"
            ></i>
            <i
              *ngIf="canDelete"
              class="bx bx-trash-alt"
              (click)="remove(item)"
            ></i>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="20">
          <div class="text-md-right float-md-end pagination-rounded">
            <pagination-controls
              id="server"
              directionLinks="true"
              autoHide="true"
              responsive="true"
              previousLabel=""
              nextLabel=""
              (pageChange)="pageChangeEvent.emit($event)"
            ></pagination-controls>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
  <div
    class="col-md-12 d-flex align-items-center h-50 justify-content-center"
    *ngIf="!data?.length"
  >
    <span class="text-secondary">{{ emptyMessage }}</span>
  </div>
</section>

<ng-template #defaultRowTemplate let-item>
  <td *ngFor="let item of item | keyvalue">{{ item.value }}</td>
</ng-template>

<ng-template #defaultHeaderTemplate let-data>
  <th *ngFor="let header of data[0] | keyvalue">{{ header.key }}</th>
</ng-template>
