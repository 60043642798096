<section class="card" id="dependents">
    <article class="card-body row">
      <h5 class="col-md-12 col-12">DEPENDENTES</h5>
      <h6 class="my-3">Dependente 1</h6>
      <div *ngIf="!accordionActive && selectedDependent">
        Dependente selecionado: {{ selectedDependent }}
     </div>
     <div *ngFor="let acordeon of acordeons; let i = index">
      <app-accordionmetlife class="my-1" [title]="'Selecione um dependente'"  [isActive]="acordeon.isActive" >
        <div class="w-100">
          <form [formGroup]="form">
            <div class="col-md-12 col-12" formArrayName="dependents">
              <ng-container
                *ngFor="let item of dependents.controls; let i = index"
              >
                <h6 class="my-3">Dependente {{ i + 1 }}</h6>
                <app-accordion
                  [title]="getDependentTitle(i) || 'Selecione um dependente'"
                  (removeClick)="remove(i)"
                >
                  <div class="col-md-12 col-12 row" [formGroupName]="i">
                    <div class="card p-0 m-0">
                      <div class="card-body row">
                        <div class="col-md-12 row my-3">
                          <div class="col-md-3 col-12">
                            <label for="type" class="text-default-label"
                              >Selecione o parentesco*</label
                            >
                            <ng-select
                              class="custom"
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).type.errors &&
                                  getDependent(i).type.dirty
                              }"
                  
                              [items]="dependentType"
                              bindLabel="name"
                              bindValue="value"
                              placeholder="Digite ou selecione"
                              notFoundText="Nenhum parentesco encontrado."
                            >
                            </ng-select>
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).type.errors &&
                                getDependent(i).type.invalid
                              "
                            >
                              <span *ngIf="getDependent(i).type.errors">
                                Selecione um tipo de conta.
                              </span>
                            </div>
                          </div>
                          <div class="col-md-3 col-12">
                            <label for="name" class="text-default-label"
                              >Nome completo*</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Digite aqui a informação"
       
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).name.errors &&
                                  getDependent(i).name.dirty
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).name.errors &&
                                getDependent(i).name.invalid
                              "
                            >
                              <span *ngIf="getDependent(i).name.errors?.required"
                                >O nome do dependente é obrigatório.</span
                              >
                            </div>
                          </div>
                          <div class="col-md-3">
                            <label for="rg" class="text-default-label"
                              >Número do RG*</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Digite aqui a informação"
                              mask="00.000.000-0||00.000.000"
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).rg.errors &&
                                  getDependent(i).rg.dirty
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).rg.errors &&
                                getDependent(i).rg.invalid
                              "
                            >
                              <span *ngIf="getDependent(i).rg.errors.required"
                                >o número do RG é obrigatório.</span
                              >
                            </div>
                          </div>
                          <div class="col-md-3">
                            <label for="cpf" class="text-default-label"
                              >Número do CPF*</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Digite aqui a informação"
        
                              mask="000.000.000-00"
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).cpf.errors &&
                                  getDependent(i).cpf.dirty
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).cpf.errors &&
                                getDependent(i).cpf.invalid
                              "
                            >
                              <span *ngIf="getDependent(i).cpf.errors.required"
                                >o número do CPF é obrigatório.</span
                              >
                            </div>
                          </div>
                        </div>
  
                        <div class="row col-12 my-3">
                          <div class="col-md-6">
                            <label for="rg" class="text-default-label"
                              >Nome da mãe*</label
                            >
                            <input
                              class="form-control"
                              placeholder="Digite aqui o nome da mãe"
  
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).motherName.errors &&
                                  getDependent(i).motherName.dirty
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).motherName.errors &&
                                getDependent(i).motherName.invalid
                              "
                            >
                              <span
                                *ngIf="getDependent(i).motherName.errors.required"
                                >o nome da mãe é obrigatório.</span
                              >
                            </div>
                          </div>
  
                          <div class="col-md-3">
                            <label class="text-default-label"
                              >Data de nascimento*</label
                            >
                            <div class="input-group">
                              <input
                                type="date"
                                class="form-control"
                                placeholder="Digite aqui a informação"
  
                                [ngClass]="{
                                  'is-invalid':
                                    getDependent(i).birthdate.errors &&
                                    getDependent(i).birthdate.dirty
                                }"
                              />
  
                              <div
                                class="text-danger"
                                *ngIf="
                                  getDependent(i).birthdate.invalid &&
                                  getDependent(i).birthdate.dirty
                                "
                              >
                                <span
                                  *ngIf="
                                    getDependent(i).birthdate.errors.required
                                  "
                                  >A Data de Nascimento é obrigatória</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row col-12 my-3">
                          <div class="col-md-3">
                            <label for="postalCode" class="text-default-label"
                              >CEP*</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="ex:3333-333"
                              mask="00000-000||0000-000"
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).postalCode.errors &&
                                  getDependent(i).postalCode.dirty
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).postalCode.errors &&
                                getDependent(i).postalCode.invalid
                              "
                            >
                              <span
                                *ngIf="getDependent(i).postalCode.errors.required"
                                >o CEP é obrigatório.</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label for="address" class="text-default-label"
                              >Endereço*</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Digite o nome da rua,av..."
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).address.errors &&
                                  getDependent(i).address.dirty
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).address.errors &&
                                getDependent(i).address.invalid
                              "
                            >
                              <span
                                *ngIf="getDependent(i).address.errors.required"
                                >O endereço é obrigatório.</span
                              >
                            </div>
                          </div>
                          <div class="col-md-3">
                            <label for="number" class="text-default-label"
                              >Número</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="ex: 333"
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).number.errors &&
                                  getDependent(i).number.dirty
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).number.errors &&
                                getDependent(i).number.invalid
                              "
                            >
                              <span *ngIf="getDependent(i).number.errors.required"
                                >O número é obrigatório.</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 row my-3">
                          <div class="col-md-4">
                            <label for="neighborhood" class="text-default-label"
                              >Bairro*</label
                            >
                            <input
                              type="text"
                              class="form-control"
      
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).neighborhood.errors &&
                                  getDependent(i).neighborhood.dirty
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).neighborhood.errors &&
                                getDependent(i).neighborhood.invalid
                              "
                            >
                              <span
                                *ngIf="
                                  getDependent(i).neighborhood.errors.required
                                "
                                >O bairro é obrigatório.</span
                              >
                            </div>
                          </div>
                          <div class="col-md-4">
                            <label for="complement" class="text-default-label"
                              >Complemento</label
                            >
                            <input
                              type="text"
                              class="form-control"
                            />
                          </div>
                          <div class="col-md-2">
                            <label for="country" class="text-default-label"
                              >UF*</label
                            >
                            <ng-select
                              class="custom"
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).countryId.errors &&
                                  getDependent(i).countryId.dirty
                              }"
           
                              [items]="ufs"
                              bindLabel="name"
                              bindValue="id"
                              placeholder="Digite ou selecione"
                              notFoundText="Nenhum parentesco encontrado."
                            >
                            </ng-select>
                            <div
                              class="invalid-feedback"
                              *ngIf="
                                getDependent(i).countryId.errors &&
                                getDependent(i).countryId.invalid
                              "
                            >
                              <span
                                *ngIf="getDependent(i).countryId.errors.required"
                                >O UF é obrigatório.</span
                              >
                            </div>
                          </div>
                          <div class="col-md-2">
                            <label for="city" class="text-default-label">
                              Cidade*
                            </label>
                            <ng-select
                              class="custom"
                              [items]="cities"
                              bindLabel="name"
                              bindValue="id"
                              placeholder="Selecione a cidade"
                              [ngClass]="{
                                'is-invalid':
                                  getDependent(i).cityId.errors &&
                                  getDependent(i).cityId.dirty
                              }"
                              notFoundText="Nenhuma Cidade encontrada."
                            >
                            </ng-select>
                            <div
                              *ngIf="
                                getDependent(i).cityId.errors &&
                                getDependent(i).cityId.invalid
                              "
                              class="invalid-feedback"
                            >
                              <span *ngIf="getDependent(i).cityId.errors.required"
                                >Selecione uma cidade.</span
                              >
                            </div>
                          </div>
                          <div
                            class="invalid-feedback"
                            *ngIf="
                              getDependent(i).cityId.errors &&
                              getDependent(i).cityId.invalid
                            "
                          >
                            <span *ngIf="getDependent(i).cityId.errors.required"
                              >O Munícipio obrigatório.</span
                            >
                          </div>
                        </div>
                        <ng-container
                          *ngIf="mandatoryDocuments?.dependentDocuments"
                        >
                          <label
                            for="mandatoryDocumentslabel"
                            class="text-default-label"
                            >Documentos</label
                          >
                          <ng-container
                            *ngFor="
                              let item of mandatoryDocuments.dependentDocuments
                            "
                          >
                            <div class="col-md-12 row my-3">
                              <app-accordion
                                [title]="item.displayName"
                                *ngIf="
                                  item.name === 'CERTIDAO_CASAMENTO_NASCIMENTO'
                                "
                              >
                                <div class="col-md-12">
                                  <label
                                    for="birthCertificate"
                                    class="text-default-label"
                                    >Cert. de nascimento/casamento*</label
                                  >
                                  <app-upload-file
                                    [isExhibition]="isExhibition"
                                    [selectedImage]="birthCertificateFiles[i]?.doc && 'data:application/' + birthCertificateFiles[i]?.docExtension + ';base64,' + birthCertificateFiles[i]?.doc"
                                    (selectFileWithExtension)="
                                      getBirthCertificateFile(i, $event)
                                    "
                                  >
                                  </app-upload-file>
                                </div>
  
                                <div
                                  class="d-flex w-100 justify-content-end align-itens-center py-4"
                                >
                                  <button
                                    *ngIf="birthCertificateFiles[i]?.doc"
                                    class="btn btn-outline-secondary text-uppercase m-1"
                                    type="button"
                                    (click)="
                                      downloadFile(
                                        i,
                                        item,
                                        birthCertificateFiles[i]
                                      )
                                    "
                                  >
                                    Baixar o documento
                                  </button>
                                </div>
                              </app-accordion>
  
                              <app-accordion
                                [title]="item.displayName"
                                *ngIf="item.name === 'FRENTE_RG'"
                              >
                                <div class="col-md-12">
                                  <label
                                    for="documentFront"
                                    class="text-default-label"
                                    >Frente do RG*</label
                                  >
                                  <app-upload-file
                                    [isExhibition]="isExhibition"
                                    [selectedImage]="frontOfRgFiles[i]?.doc && 'data:application/' + frontOfRgFiles[i]?.docExtension + ';base64,' + frontOfRgFiles[i]?.doc"
                                    (selectFileWithExtension)="getFrontOfRgFile(i, $event)"
                                  >
                                  </app-upload-file>
                                  <div
                                    class="d-flex w-100 justify-content-end align-itens-center py-4"
                                  >
                                    <button
                                      *ngIf="frontOfRgFiles[i]?.doc"
                                      class="btn btn-outline-secondary text-uppercase m-1"
                                      type="button"
                                      (click)="
                                        downloadFile(i, item, frontOfRgFiles[i])
                                      "
                                    >
                                      Baixar o documento
                                    </button>
                                  </div>
                                </div>
                              </app-accordion>
                              <app-accordion
                                [title]="item.displayName"
                                *ngIf="item.name === 'VERSO_RG'"
                              >
                                <div class="col-md-12">
                                  <label
                                    for="documentBack"
                                    class="text-default-label"
                                    >Verso do RG*</label
                                  >
                                  <app-upload-file
                                    [isExhibition]="isExhibition"
                                    [selectedImage]="backOfRgFiles[i]?.doc && 'data:application/' + backOfRgFiles[i]?.docExtension + ';base64,' + backOfRgFiles[i]?.doc"
                                    (selectFileWithExtension)="getBackOfRgFile(i, $event)"
                                  >
                                  </app-upload-file>
                                  <div
                                    class="d-flex w-100 justify-content-end align-itens-center py-4"
                                  >
                                    <button
                                      *ngIf="backOfRgFiles[i]?.doc"
                                      class="btn btn-outline-secondary text-uppercase m-1"
                                      type="button"
                                      (click)="
                                        downloadFile(i, item, backOfRgFiles[i])
                                      "
                                    >
                                      Baixar o documento
                                    </button>
                                  </div>
                                </div>
                              </app-accordion>
                              <app-accordion
                                [title]="item.displayName"
                                *ngIf="item.name === 'COMPROVANTE_RESIDENCIA'"
                              >
                                <div class="col-md-12">
                                  <label
                                    for="proofAddress"
                                    class="text-default-label"
                                    >Comp. de endereço*</label
                                  >
                                  <app-upload-file
                                    [isExhibition]="isExhibition"
                                    [selectedImage]="addressProofFiles[i]?.doc && 'data:application/' + addressProofFiles[i]?.docExtension + ';base64,' + addressProofFiles[i]?.doc"
                                    (selectFileWithExtension)="getAddressProofFile(i, $event)"
                                  >
                                  </app-upload-file>
  
                                  <div
                                    class="d-flex w-100 justify-content-end align-itens-center py-4"
                                  >
                                    <button
                                      *ngIf="addressProofFiles[i]?.doc"
                                      class="btn btn-outline-secondary text-uppercase m-1"
                                      type="button"
                                      (click)="
                                        downloadFile(
                                          i,
                                          item,
                                          addressProofFiles[i]
                                        )
                                      "
                                    >
                                      Baixar o documento
                                    </button>
                                  </div>
                                </div>
                              </app-accordion>
  
                              <div
                                class="col-md-12"
                                *ngIf="!documentExceptions.includes(item.name)"
                              >
                                <app-generic-document
                                  [mandatoryDocumentItem]="item"
                                  [enabledOnChangeEvent]="true"
                                  [genericDocumentValue]="
                                    getMandatoryDocumentValue(item, i)
                                  "
                                  (fileChange)="
                                    addMandatoryDocumentFile(i, $event)
                                  "
                                >
                                </app-generic-document>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
  
                  <div class="my-3 col-12 d-flex justify-content-between">
                    <button
                    type="submit"
                    class="btn btn-outline-secondary m-1 text-uppercase"
                    (click)="removeAccordion(acordeon.id)">  <!-- Passando o ID aqui -->
                    REMOVER
                  </button>
                    <button
                      class="btn btn-outline-primary"
                      (click)="saveDependent(i)"
                    >
                      SALVAR
                    </button>
                  </div>
                </app-accordion>
              </ng-container>
            </div>
  
            <div class="col-md-12 my-3" *ngIf="!isExhibition">
              <div class="row">
                <div class="col-md-3 mt-2">
                  <label class="text-default-label"
                    >Selecione o parantesco*</label
                  >
                  <ng-select
                  class="custom"
                  [items]="ParentArray"
                  bindLabel="name"
                  bindValue="value"
                  placeholder="Selecione o parentesco"
                  notFoundText="Nenhum parentesco encontrado."
                >
                </ng-select>
                </div>
                <div class="col-md-9 mt-2">
                  <label class="text-default-label">Nome completo*</label>
                  <form [formGroup]="form"> 
                    <input class="form-control" />
                </form>
     
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mt-2">
                  <label class="text-default-label">Data de nascimento*</label>
                  <input
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3 mt-2">
                  <label class="text-default-label">Número do RG*</label>
                  <input
                    type="text"
                    name="rg"
                    id="rg"
                    class="form-control"
                    placeholder="ex: 99.999.999-9"
                    mask="00.000.000-0||00.000.000"
                  />
                </div>
                <div class="col-md-3 mt-2">
                  <label class="text-default-label">Número do CPF*</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="ex: 999.999.999-99"
                    mask="000.000.000-00"
                  />
                </div>
                <div class="col-md-3 mt-2">
                  <label class="text-default-label">Gênero*</label>
                  <ng-select
                    class="custom"
                    [items]="genderArray"
                    bindLabel="name"
                    bindValue="value"
                    placeholder="Selecione o gênero"
                    notFoundText="Nenhum gênero encontrado."
                  >
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mt-2">
                  <label class="text-default-label">Estado civil*</label>
                  <ng-select
                    class="custom"
                    [items]="CivilArray"
                    bindLabel="name"
                    bindValue="value"
                    placeholder="Selecione o estado civil"
                    notFoundText="Nenhum estado civil encontrado."
                  >
                  </ng-select>
                </div>
                <div class="col-md-8 mt-2">
                  <label class="text-default-label">Nome da mãe*</label>
                  <input class="form-control"  />
                </div>
                <div class="col-md-12 mt-2">
                  <label class="text-default-label">Universitário*</label>
                  <div class="custom-checkbox-container">
                    <label class="custom-checkbox">
                      <input type="radio" name="choice" value="nao" />
                      <span class="checkmark">
                        <span class="checkmark-inner"></span>
                      </span>
                      Não
                    </label>
                  
                    <label class="custom-checkbox">
                      <input type="radio" name="choice" value="sim" />
                      <span class="checkmark">
                        <span class="checkmark-inner"></span>
                      </span>
                      Sim
                    </label>
                  </div>
                  
                  
                  
                </div>
                <div class="col-md-12 mt-2">
                  <label class="text-default-label">Dependente do IRRF*</label>
                  <div class="custom-checkbox-container">
                    <label class="custom-checkbox">
                      <input type="radio" name="choiceTwo"   value="nao" />
                      <span class="checkmark">
                        <span class="checkmark-inner"></span>
                      </span>
                      Não
                    </label>
                  
                    <label class="custom-checkbox">
                      <input type="radio" name="choiceTwo"   value="sim" />
                      <span class="checkmark">
                        <span class="checkmark-inner"></span>
                      </span>
                      Sim
                    </label>
                  </div>
                  
                  
                </div>
                <div class="col-md-12 mt-2">
                  <label class="text-default-label">PCD*</label>
                  <div class="custom-checkbox-container">
                    <label class="custom-checkbox">
                      <input type="radio" name="choiceTre"  value="nao" />
                      <span class="checkmark">
                        <span class="checkmark-inner"></span>
                      </span>
                      Não
                    </label>
                  
                    <label class="custom-checkbox">
                      <input type="radio" name="choiceTre" value="sim" />
                      <span class="checkmark">
                        <span class="checkmark-inner"></span>
                      </span>
                      Sim
                    </label>
                  </div>
                  
                  
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <button
                    class="col-md-3 btn btn-outline-primary mt-2"
                    type="button"
                    (click)="add()"
                  >
                    COLAR ENDEREÇO DO TITULAR
                  </button>
                </div>
              </div>
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-md-2 mt-2">
                    <label class="text-default-label">CEP*</label>
                    <input class="form-control"  />
                  </div>
                  <div class="col-md-2 mt-2">
                    <label class="text-default-label">Logradouro*</label>
                    <input class="form-control"  />
                  </div>
                  <div class="col-md-1 mt-2">
                    <label class="text-default-label">Número*</label>
                    <input class="form-control"  />
                  </div>
                  <div class="col-md-3 mt-2">
                    <label class="text-default-label">Bairro*</label>
                    <input class="form-control"  />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 mt-2">
                    <label class="text-default-label">Complemento*</label>
                    <input class="form-control"  />
                  </div>
                  <div class="col-md-3 mt-2">
                    <label class="text-default-label">Estado*</label>
                    <input class="form-control" />
                  </div>
                  <div class="col-md-3 mt-2">
                    <label class="text-default-label">Cidade*</label>
                    <input class="form-control"  />
                  </div>
                </div>
              </form>
  
              <div class="row">
                <div class="col-md-6 mt-2">
                  <label class="text-default-label">Carregar frente do RG*</label>
                  <div class="row">
                    <div class="col-md-12">
                      <label class="text-default-label">
                        {{ mandatoryDocumentItem?.required ? "*" : "" }}</label
                      >
                      <app-upload-file
                        (selectFileWithExtension)="getDocumentFileRgFront($event)"
                        [isExhibition]="isExhibition"
                        [selectedImage]="documentFile && 'data:' + this.extension + ';base64,' + documentFile"
                      >
                      </app-upload-file>
                    </div>
                    <small
                      class="text-danger"
                      *ngIf="!documentFile && mandatoryDocumentItem?.required"
                    >
                      Por favor, carregue a foto.
                    </small>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <label class="text-default-label">Carregar verso do RG*</label>
                  <div class="row">
                    <div class="col-md-12">
                      <label class="text-default-label">
                        {{ mandatoryDocumentItem?.required ? "*" : "" }}</label
                      >
                      <app-upload-file
                        (selectFileWithExtension)="getDocumentFileRgBack($event)"
                        [isExhibition]="isExhibition"
                        [selectedImage]="documentFileBack && 'data:' + this.extension + ';base64,' + documentFileBack"
                      >
                      </app-upload-file>
                    </div>
                    <small
                      class="text-danger"
                      *ngIf="!documentFileBack && mandatoryDocumentItem?.required"
                    >
                      Por favor, carregue a foto.
                    </small>
                  </div>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-6 mt-2">
                  <label class="text-default-label"
                    >Carregar cert de nascimento*</label
                  >
                  <div class="row">
                    <div class="col-md-12">
                      <label class="text-default-label">
                        {{ mandatoryDocumentItem?.required ? "*" : "" }}</label
                      >
                      <app-upload-file
                        (selectFileWithExtension)="getDocumentFileNasci($event)"
                        [isExhibition]="isExhibition"
                        [selectedImage]="documentFileNasc && 'data:' + this.extension + ';base64,' + documentFileNasc"
                      >
                      </app-upload-file>
                    </div>
                    <small
                      class="text-danger"
                      *ngIf="!documentFileNasc && mandatoryDocumentItem?.required"
                    >
                      Por favor, carregue a foto.
                    </small>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  <label class="text-default-label"
                    >Carregar comp. de endereço</label
                  >
                  <div class="row">
                    <div class="col-md-12">
                      <label class="text-default-label">
                        {{ mandatoryDocumentItem?.required ? "*" : "" }}</label
                      >
                      <app-upload-file
                        (selectFileWithExtension)="getDocumentFileEndereco($event)"
                        [isExhibition]="isExhibition"
                        [selectedImage]="documentFileEndereco && 'data:' + this.extension + ';base64,' + documentFileEndereco"
                      >
                      </app-upload-file>
                    </div>
                    <small
                      class="text-danger"
                      *ngIf="!documentFileEndereco && mandatoryDocumentItem?.required"
                    >
                      Por favor, carregue a foto.
                    </small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <button
                    type="submit"
                    class="btn btn-outline-secondary m-1 text-uppercase"
                    (click)="removeAccordion(acordeon.id)"> 
                    REMOVER
                  </button>
               
                    <button class="btn btn-secondary text-uppercase" type="button" (click)="saveDependentClick(i)">
                      SALVAR
                    </button>
                  
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </app-accordionmetlife>
     </div>
  
     <button class="col-md-3 btn btn-outline-primary mt-2" type="button" (click)="toggleAccordion()">ADICIONAR DEPENDENTE</button>
  
      <article
        class="col-md-12 row justify-content-end"
        *ngIf="!isContributorProfile"
      >
        <div class="col-md-4 my-3 text-align-end">
          <button
            type="submit"
            class="btn btn-outline-secondary m-1 text-uppercase"
            (click)="logoutEmit()"
          >
            salvar e sair
          </button>
          <button
            class="btn btn-secondary text-uppercase"
            type="button"
            (click)="back.emit()"
          >
            Cancelar
          </button>
          <button
            type="submit"
            class="btn btn-primary m-1 text-uppercase"
            (click)="nextPage()"
          >
            Salvar e avançar
          </button>
        </div>
      </article>
    </article>
  </section>
  