<div class="modal-content custom-modal">
  <div class="row text-center">
    <div class="col-12">
      <img
        src="assets/images/InfoOutlinedRed.svg"
        alt=""
        class="info-img"
        height="104"
        width="104"
      />
      <img
        src="assets/images/CloseOutlined.svg"
        alt=""
        class="close-img"
        height="20"
        width="20"
      />
    </div>
    <div class="col-12">
      <h4 class="title-t" *ngIf="modal.title">{{ modal.title }}</h4>
      <p class="text-competence">{{ modal.text }}</p>
    </div>
    <div class="col-md-12 mt-4">
      <div class="col-md-12 text-align-center">
        <a class="btn btn-secondary m-1 btn-delete" (click)="closeModal(false)"
          >NÃO CANCELAR</a
        >
        <button
          type="button"
          class="btn btn-primary m-1 b btn-confirm"
          (click)="closeModal(true)"
        >
          {{ modal.labelButton }}
        </button>
      </div>
    </div>
  </div>
</div>
