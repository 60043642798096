import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  @Input() title: string;
  @Input() breadcrumbRoutes: BreadcrumbRoute[] = [];

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.generateBreadcrumbRoutes(this.route.root);
      }
    });
  }

  generateBreadcrumbRoutes(route: ActivatedRoute, url = '', breadcrumbRoutes: BreadcrumbRoute[] = []): void {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return;
    }

    for (const child of children) {
      if (child.outlet === 'primary') {
        const routePath = child.snapshot.url.map(segment => segment.path).join('/');
        if (routePath) {
          url += `/${routePath}`;
          const breadcrumbLabel = child.snapshot.data['breadcrumbLabel'];

          if (breadcrumbLabel) {
            breadcrumbRoutes.push({
              path: url,
              label: breadcrumbLabel
            });
          }
        }
        this.generateBreadcrumbRoutes(child, url, breadcrumbRoutes);
      }
    }

    this.breadcrumbRoutes = breadcrumbRoutes;
  }
}

export interface BreadcrumbRoute {
  path: string;
  label: string;
}
