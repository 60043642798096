import {
  ActivatedRouteSnapshot,
  CanDeactivateFn,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "../services/auth.service";
import { inject } from "@angular/core";

export const canDeactivateHiringProcess: CanDeactivateFn<any> = (
  component: any,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
) => {
  const authService: AuthenticationService = inject(AuthenticationService);

  if (
    authService.IsHiringProcess &&
    nextState.url !== "/users/portal" &&
    nextState.url !== "/users/first-access"
  ) {
    return false;
  }

  return true;
};
