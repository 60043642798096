import { Injectable, OnDestroy } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import {
  BehaviorSubject,
  catchError,
  Observable,
  switchMap,
  tap,
  throwError as observableThrowError,
  Subject,
  EMPTY,
} from "rxjs";

import { AuthenticationService } from "../services/auth.service";
import { AesService } from "../services/aes.service";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { AppToastService } from "../services/app-toast.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor, OnDestroy {
  tokenURL = "refresh";

  constructor(
    private authenticationService: AuthenticationService,
    private aesService: AesService,
    private router: Router,
    private toast: AppToastService
  ) {}

  subject = new Subject<string>();

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(environment.url)) return next.handle(request);

    request = this.addBearerToken(request);

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401 && request.url.endsWith(this.tokenURL)) {
          return this.authenticationService.logout().pipe(
            switchMap(() => {
              this.router.navigate(["account/login"]);
              return EMPTY;
            })
          );
        }

        if (err.status === 401 && request.url.includes("logout")) {
          this.router.navigate(["account/login"]);
          return EMPTY;
        }

        if (err.status === 401) {
          return this.authenticationService
            .getRefreshToken()
            .pipe(switchMap(() => next.handle(request)));
        }

        let error = err.error || err.message;
        if (err.error) {
          error = this.aesService.decryptData(
            err.error,
            environment.encryptionIv,
            environment.encryptionKey
          );
        }

        error = error || err.error || err.message;
        if (err) {
          this.toast.show("Erro", error);
          err.message = error;
        } else {
          this.toast.show("Erro", err.message);
          err = err.message;
        }

        return observableThrowError(() => err);
      })
    );
  }

  private addBearerToken(request: HttpRequest<any>) {
    request = request.clone({ withCredentials: true });

    return request;
  }

  ngOnDestroy(): void {
    this.subject.complete();
  }
}
