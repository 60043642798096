import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-proof-residence",
  templateUrl: "./proof-residence.component.html",
  styleUrls: ["./proof-residence.component.scss"],
})
export class ProofResidenceComponent implements OnChanges {
  documentFile: any;
  documentFileExtension: any;

  @Input() addressProofDocument;
  @Input() addressProofDocumentExtension;
  @Input() isExhibition = false;
  @Output() formValue: EventEmitter<any> = new EventEmitter();

  constructor(private utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.addressProofDocument?.currentValue;

    if (data) {
      this.documentFile = `${data}`;
      this.documentFileExtension = `${changes.addressProofDocumentExtension?.currentValue}`;
    }
  }

  emitFormProofResidenceValue() {
    if (!this.documentFile) {
      return;
    }

    const formProofResidenceValue = {
      documentFile: this.documentFile,
      documentFileExtension: this.documentFileExtension,
    };

    this.formValue.emit(formProofResidenceValue);
  }

  getDocumentFile(event) {
    this.documentFile = event.base64;
    this.documentFileExtension = event.extension;
  }

  downloadFile() {
    if (this.documentFile && this.documentFile !== "null")
      this.utilsService.downloadFile(this.documentFile,`COMPROVANTE_RESIDENCIA.${this.documentFileExtension}`);
  }
}
