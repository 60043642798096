import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable, map, pipe, tap } from "rxjs";
import { AesService } from "../services/aes.service";
import { environment } from "src/environments/environment";

@Injectable()
export class decryptInterceptor implements HttpInterceptor {
  constructor(private aesService: AesService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (!event.body) return event;

          const rawData = this.aesService.decryptData(
            event.body,
            environment.encryptionIv,
            environment.encryptionKey
          );
          const response = event.clone({
            body: rawData,
          });

          return response;
        }
        return event;
      })
    );
  }
}
