<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul
      class="metismenu list-unstyled"
      id="side-menu"
      #sideMenu
      *ngIf="
        !authService?.isFirstAccess &&
        !authService?.isNewUSerCompanyWasNotConfigured
      "
    >
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <li class="menu-title" *ngIf="item.isTitle">
          {{ item.label | translate }}
        </li>
        <li *ngIf="!item.isTitle && !item.isLayout">
          <a
            *ngIf="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent has-arrow"
            (click)="item.collapsed = !item.collapsed"
            [ngClass]="{ 'mm-show': item.collpased }"
          >
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate }}</span>
            <span
              class="badge rounded-pill float-end bg-{{ item.badge.variant }}"
              *ngIf="item.badge"
              >{{ item.badge.text | translate }}</span
            >
          </a>

          <a
            [routerLink]="item.link"
            *ngIf="!hasItems(item)"
            class="side-nav-link-ref"
            routerLinkActive="active"
            [ngClass]="{ 'mm-active': item.active }"
            (click)="toggleActive(item)"
          >
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate }}</span>
            <span
              class="badge rounded-pill bg-{{ item.badge.variant }} float-end"
              *ngIf="item.badge"
              >{{ item.badge.text | translate }}</span
            >
          </a>

          <ul
            *ngIf="hasItems(item)"
            class="sub-menu"
            aria-expanded="false"
            [ngClass]="{ 'mm-collapse': item.collapsed }"
          >
            <li *ngFor="let subitem of item.subItems">
              <a
                [routerLink]="subitem.link"
                *ngIf="!hasItems(subitem)"
                class="side-nav-link-ref"
                [ngClass]="{ 'mm-active': subitem.active }"
                (click)="toggleActive(subitem)"
                [attr.data-parent]="subitem.parentId"
                routerLinkActive="active"
              >
                {{ subitem.label | translate }}
                <span
                  class="badge rounded-pill bg-{{
                    subitem.badge.variant
                  }} float-end"
                  *ngIf="subitem.badge"
                  >{{ subitem.badge.text | translate }}</span
                >
              </a>
              <a
                *ngIf="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId"
                (click)="subitem.collapsed = !subitem.collapsed"
                [ngClass]="{ 'mm-show': subitem.collpased }"
              >
                {{ subitem.label | translate }}
              </a>
              <ul
                *ngIf="hasItems(subitem)"
                class="sub-menu"
                aria-expanded="false"
                [ngClass]="{ 'mm-collapse': subitem.collapsed }"
              >
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a
                    [routerLink]="subSubitem.link"
                    *ngIf="!hasItems(subSubitem)"
                    class="side-nav-link-ref"
                    [ngClass]="{ 'mm-active': subSubitem.active }"
                    (click)="toggleActive(subSubitem)"
                    [attr.data-parent]="subSubitem.parentId"
                    routerLinkActive="active"
                  >
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu scroll">
  <div>
    <div *ngIf="!isCondensed" #componentRef>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </div>
  <span class="logo-lg logo-company">
    <ng-container *ngIf="tenantStore.style$ | async as style">
      <a class="logo logo-dark">
        <span class="logo-sm">
          <ng-container *ngIf="tenantStore.style$ | async as style">
            <img
              [src]="style.LogoBottom"
              alt="logo-bottom"
              *ngIf="style.LogoBottom"
            />
          </ng-container>
        </span>
        <span class="logo-lg">
          <!-- <img src="assets/images/meurh360.png" alt="" height="17" /> -->
          <img
            [src]="style.LogoBottom"
            alt="logo-bottom"
            *ngIf="style.LogoBottom"
            height="17"
          />
        </span>
      </a>

      <a class="logo logo-light d-flex" [routerLink]="['dashboard']">
        <span class="logo-sm">
          <ng-container *ngIf="tenantStore.style$ | async as style">
            <img
              [src]="style.LogoBottom"
              alt="logo-Bottom"
              height="15"
              *ngIf="style.LogoBottom"
            />
          </ng-container>
        </span>
        <span class="logo-lg">
          <ng-container *ngIf="tenantStore.style$ | async as style">
            <img
              *ngIf="style.LogoBottom"
              [src]="style.LogoBottom"
              alt="logo-Bottom"
              height="50"
            />
          </ng-container>
        </span>
      </a>
    </ng-container>
  </span>
</div>
<!-- Left Sidebar End -->
