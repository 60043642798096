<app-accordion class="my-1" [title]="'Estacionamento'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">{{ benefit.alias }}</label>
      <p class="text-color">
        <img
          src="assets/images/InfoOutlinedRed.svg"
          alt=""
          class="rounded-img"
          height="20"
          width="20"
        />
        Ao aderir ao estacionamento, você está abrindo mão de receber vale
        transporte.
      </p>
      <!-- <form > -->
        <div class="row mt-4">
          <!-- Tipo de veículo -->
          <div class="col-md-4 col-12 col-sale">
            <label class="text-sale">Tipo de veículo*</label>
            <ng-select
              [items]="benefits"
              bindLabel="name"
              placeholder="Selecione"
              [(ngModel)]="benefit.vehicleType"
            >
              <ng-option *ngFor="let item of benefits" [value]="item.selected">{{benefits.name}}</ng-option>
            </ng-select>
          </div>

          <!-- Modelo -->
          <div class="col-md-4 col-12 col-value">
            <label class="text-valuedesc">Modelo*</label>
            <input
              type="text"
              class="form-control"
              placeholder="Digite aqui a informação"
              [(ngModel)]="benefit.vehicleModel"
            />
          </div>

          <!-- Cor -->
          <div class="col-md-4 col-12 col-sale">
            <label class="text-sale">Cor*</label>
            <input
              type="text"
              class="form-control"
              placeholder="Digite aqui a informação"
              [(ngModel)]="benefit.color"
            />
          </div>

          <!-- Placa -->
          <div class="col-md-4 col-12 col-value">
            <label class="text-valuedesc">Placa*</label>
            <input
              type="text"
              class="form-control"
              placeholder="Digite aqui a informação"
              [(ngModel)]="benefit.board"
            />
          </div>

          <!-- Número do verso do crachá -->
          <div class="col-md-4 col-12 col-sale">
            <label class="text-sale">Número do verso do crachá</label>
            <input
              type="text"
              class="form-control"
              placeholder="Digite aqui a informação"
              [(ngModel)]="benefit.numberVersoCracha"
            />
          </div>
        </div>

        <!-- Botão de envio -->
        <!-- <div class="form-group">
            <button type="submit" class="btn btn-primary">Enviar</button>
          </div> -->
      <!-- </form> -->

      <div class="row mt-4">
        <div class="col-md-2 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ benefit.items[0]?.existDiscountToHolder }}</span>
        </div>
        <div class="col-md-6 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>
          <span>R$ {{ benefit.items[0]?.holderDiscount }}</span>
        </div>

        <div class="col-md-4 col-12 col-benefic">
          <label class="text-benefictransp">Deseja aderir ao benefício</label>
          <div class="custom-checkbox-container">
            <label class="custom-checkbox">
              <input
                type="radio"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="false"
                (change)="setBenefitChoice(false)"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Não
            </label>

            <label class="custom-checkbox">
              <input
                type="radio"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="true"
                (change)="setBenefitChoice(true)"
                >

              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Sim
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
