import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { Categories } from "../../../core/models/categories.enum";

@Component({
  selector: "app-health-plan-accordion",
  templateUrl: "./health-plan-accordion.component.html",
  styleUrls: ["./health-plan-accordion.component.scss"],
})
export class HealthPlanAccordionComponent implements OnInit, OnChanges {
  @Input() benefits;
  selectedPlan: any;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  selectedChoiceNine: string;
  selectedChoiceTen: string;
  planControl = new FormControl();
  isDisabled = true;
  includedDependents: any[] = [];
  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  selected: boolean;
  discount: string;
  selectedPlanName: string;
  plans: any[] = [];
  selectedChoice: string;

  descCategory = "";

  openCobertura(contentcobertura: any) {
    this.modalService.open(contentcobertura, { size: "lg" });
  }

  goToBenefits() {
    this.modalService.dismissAll();
  }

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    // this.getEmployeesHiring();
    this.planControl.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }
  // getEmployeesHiring() {
  //   this.employeesHiringService
  //     .getEmployeesHiring(this.employeeId)
  //     .subscribe((res: any) => {
  //       const id = res.hiringProcessId;
  //       this.hiringProcessId = id;

  //       this.getBenefits(id);
  //     });
  // }

  setBenefitChoice(wantsBenefit: boolean): void {
    this.benefit.wantTheBenefit = wantsBenefit;
  }
  onPlanChange(event: any): void {
    this.selectedPlanName = event.name;
  }

  getBenefits(benefits: any) {
    // this.benefitsService.getBenefits(id).subscribe(
    // (response: any) => {
    if (!benefits) return;
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.Saude //"Assistência Médica"
        );

        if (foundBenefit) {
          this.benefit = foundBenefit;
          this.selected = foundBenefit.wantTheBenefit;
          this.discountValue = foundBenefit.holderDiscount;
          this.includedDependents = foundBenefit.includedDependents;
          this.discount = foundBenefit.existDiscountToHolder;
          this.plans = foundBenefit.items;
          // if (
          //   foundBenefit.wantTheBenefit &&
          //   foundBenefit.item &&
          //   foundBenefit.item.index
          // ) {
          this.selectedPlan = this.plans[0];

          if (this.benefit.items) {
            this.benefit.items[0].cost =
              this.benefit.items[0]?.cost < 1 ? 0 : this.benefit.items[0]?.cost;
          }

          // }
          break;
        }
      }
    }
    //   },
    //   (error) => {
    //     console.error(
    //       "Error fetching benefits:",
    //       error.message || JSON.stringify(error)
    //     );
    //   }
    // );
  }
}
