import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CopyTextDirective } from "./directives/copy-text.directive";
import { TagInputComponent } from "./tag-input/tag-input.component";
import { UIModule } from "./ui/ui.module";
import { SafePipe } from "./pipes/sanitizer-pipe";

@NgModule({
  declarations: [TagInputComponent, CopyTextDirective, SafePipe],
  imports: [CommonModule, UIModule, NgbModule],
  exports: [TagInputComponent, CopyTextDirective, SafePipe],
})
export class SharedModule {}
