import { Component, OnInit } from "@angular/core";
import { WizardStep } from "../core/models/wizard-step.models";
import { AuthenticationService } from "../core/services/auth.service";
import { DataAccessService } from "../core/services/portal-admission/data-access.service";
import { EmployeesHiringService } from "../core/services/portal-admission/employees-hiring.service";
import { Observable, tap } from "rxjs";
import { PersonalBankDetailsService } from "../core/services/portal-admission/personal-bank-details.service";
import { DocumentationService } from "../core/services/portal-admission/documentation.service";
import { DependentsService } from "../core/services/portal-admission/dependents.service";
import { BenefitsService } from "../core/services/portal-admission/benefits.service";
import { Router } from "@angular/router";
import { MandatoryDocumentService } from "../core/services/mandatory-document.service";
import { EmployeeStore } from "../core/services/Stores/employee.store";
import { DocumentsService } from "../core/services/contributor-profile/documents.service";
import { CompanyService } from "../core/services/company.service";
import { GetCompanyModel } from "../core/models/get-company.model";

@Component({
  selector: "app-portal-admission",
  templateUrl: "./portal-admission.component.html",
  styleUrls: ["./portal-admission.component.scss"],
})
export class PortalAdmissionComponent implements OnInit {
  steps: WizardStep[] = [
    { title: "Dados de acesso", selected: true },
    { title: "Dados pessoais e bancários", selected: false },
    { title: "Documentação", selected: false },
    { title: "Dependentes", selected: false },
    { title: "Benefícios", selected: false },
  ];

  currentStepIndex = 0;

  employeeId = this.authService.employeeId();
  hiringProcessId;

  getDataAccess$: Observable<any>;
  getPersonalBankDetails$: Observable<any>;
  documentation$: Observable<any>;
  dependents$: Observable<any>;
  benefits$: Observable<any>;
  benefitsValue$: Observable<any>;
  bankList$: Observable<any>;

  schoolRemovedId;
  dependentsRemovedId;
  mandatoryDocumentsList$: any;
  matriz: GetCompanyModel;

  constructor(
    private authService: AuthenticationService,
    private employeesHiringService: EmployeesHiringService,
    private dataAccessService: DataAccessService,
    private personalBankDetailsService: PersonalBankDetailsService,
    private documentationService: DocumentationService,
    private dependentsService: DependentsService,
    private benefitsService: BenefitsService,
    private mandatoryDocumentService: MandatoryDocumentService,
    private router: Router,
    private employeeStore: EmployeeStore,
    private documentsService: DocumentsService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.getEmployeesHiring();

    // this.companyService
    //   .getSimple(companyId)
    //   .subscribe((data) => (this.matriz = data));
  }

  getEmployeesHiring() {
    this.employeesHiringService
      .getEmployeesHiring(this.employeeId)
      .subscribe((res: any) => {
        const id = res.hiringProcessId;
        this.hiringProcessId = res.hiringProcessId;
        this.getDataAccess$ = this.dataAccessService.getDataAccess(id);
        this.getPersonalBankDetails$ =
          this.personalBankDetailsService.getPersonalBankDetails(id);
        this.documentation$ = this.documentationService.getDocumentation(id);
        this.dependents$ = this.dependentsService.getDependents(id);
        this.benefits$ = this.benefitsService.getBenefits(id);
        this.benefitsValue$ = this.benefitsService.getBenefitsValue(id);
        this.bankList$ = this.personalBankDetailsService.getBankList();
        this.getMandatoryDocuments();

        this.getDataAccess$.subscribe((data) => {
          const companyId = this.authService.currentUser().payload.companyId;

          this.companyService
            .getSimple(data.branchId || companyId)
            .subscribe((company) => (this.matriz = company));
        });
      });
  }

  onStepIndexChanged(index?: number) {
    this.currentStepIndex++;
    if (this.currentStepIndex === 4)
      this.benefits$ = this.benefitsService.getBenefits(this.hiringProcessId);
  }

  nextStep() {
    const id = this.hiringProcessId;

    if (this.currentStepIndex === 3) {
      this.benefits$ = this.benefitsService.getBenefits(id);
      this.benefitsValue$ = this.benefitsService.getBenefitsValue(id);
    }
    if (this.currentStepIndex === 1) {
      this.getPersonalBankDetails$ =
        this.personalBankDetailsService.getPersonalBankDetails(id);
    }

    this.currentStepIndex++;
  }

  getDataAccessValue(payload: any) {
    this.dataAccessService
      .putDataAccess(this.hiringProcessId, payload?.body)
      .subscribe(() => this.logoutOrNextStep(payload));
  }

  getPersonalBankDetailsValue(payload: any) {
    this.personalBankDetailsService
      .postPersonalBankDetails(this.hiringProcessId, payload.body)
      .subscribe(() => this.logoutOrNextStep(payload));
  }

  getIdCardValue(payload: any) {
    this.documentationService.postRg(this.hiringProcessId, payload).subscribe();
  }

  getCpfValue(payload: any) {
    this.documentationService
      .postCpf(this.hiringProcessId, payload)
      .subscribe();
  }

  WorkCardValue(payload) {
    this.documentationService
      .postCtps(this.hiringProcessId, payload)
      .subscribe();
  }

  BirthMarriageCertificateDoc(payload) {
    this.documentationService
      .postBirthCertificate(this.hiringProcessId, payload)
      .subscribe();
  }

  VoterRegistrationValue(payload) {
    this.documentationService
      .postDocumentVoter(this.hiringProcessId, payload)
      .subscribe();
  }

  AddressProofDocument(payload) {
    this.documentationService
      .postAddressProofDocument(this.hiringProcessId, payload)
      .subscribe();
  }

  SaveSchoolValue(payload) {
    if (payload?.id) {
      this.documentationService
        .putSchool(this.hiringProcessId, payload)
        .subscribe();
    } else {
      this.documentationService
        .postSchool(this.hiringProcessId, payload)
        .subscribe();
    }
  }

  DeleteSchoolValue(idSchool) {
    this.documentationService
      .deleteSchool(this.hiringProcessId, idSchool)
      .subscribe(() => (this.schoolRemovedId = idSchool));
  }

  deleteDependentsValue(idDependents) {
    this.dependentsService
      .deleteDependent(this.hiringProcessId, idDependents)
      .subscribe(() => (this.dependentsRemovedId = idDependents));
  }

  saveDependentValue(payload) {
    if (payload?.id) {
      this.dependentsService
        .putDependents(this.hiringProcessId, payload)
        .subscribe();
    } else {
      this.dependentsService
        .postDependents(this.hiringProcessId, payload)
        .subscribe();
    }
  }

  getBenefitsValue(payload, logout?) {
    this.benefitsService
      .PostBenefitsValue(this.hiringProcessId, payload)
      .subscribe(() => logout && this.logout());
  }

  onPreviousStepIndexChange() {
    this.currentStepIndex--;
  }

  logoutOrNextStep(payload) {
    if (payload?.logout) {
      this.logout();
    } else {
      this.nextStep();
    }
  }

  logout() {
    this.authService
      .logout()
      .subscribe(() => this.router.navigate(["account/login"]));
  }

  finish($event) {
    this.benefitsService
      .PostBenefitsValue(this.hiringProcessId, $event)
      .subscribe(() => {
        this.employeeStore
          .sendToHR(this.authService.employeeId())
          .subscribe(() => this.logout());
      });
  }

  addGenericDocument(payload) {
    this.documentsService
      .postGenericDocument(this.employeeId, payload)
      .subscribe();
  }

  private getMandatoryDocuments() {
    this.getDataAccess$.subscribe((generalData) => {
      const { branchId, areaId, roleId, contractType } = generalData;

      const queryParams = {
        branchId: branchId,
        contractType: contractType,
        areaId: areaId,
        roleId: roleId,
      };

      this.mandatoryDocumentsList$ =
        this.mandatoryDocumentService.getDocuments(queryParams);
    });
  }
}
