import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, filter, reduce, tap } from "rxjs";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { Categories } from "../../../core/models/categories.enum";
import { cpfValidator } from "src/app/shared/directives/cpf-validator.directive";

@Component({
  selector: "app-life-insurance-accordion",
  templateUrl: "./life-insurance-accordion.component.html",
  styleUrls: ["./life-insurance-accordion.component.scss"],
})
export class LifeInsuranceAccordionComponent implements OnInit, OnChanges {
  @Input() disableInputs = false;
  @Input() benefits;

  constructor(private authService: AuthenticationService) {}

  selectedChoiceNine: string;
  selectedChoiceTen: string;
  planControl = new FormControl();
  isDisabled = true;
  includedDependents: any[] = [];
  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  selected: boolean;
  discount: string;
  selectedPlanName: string;
  plans: any[] = [];
  selectedChoice: string;
  seuFormulario: FormGroup;
  descCategory = "";
  showSharePercentageExceeded: boolean;

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;

    this.planControl.disable();
    this.seuFormulario = new FormGroup({
      beneficiaries: new FormArray([]),
      document: new FormControl("", [Validators.required, cpfValidator()]),
    });

    const sharePercentageValidation = (value) => {
      if (value > 100) {
        this.showSharePercentageExceeded = true;
        return false;
      }
      this.showSharePercentageExceeded = false;
      return true;
    };

    const validateFormArray = (data?: any[]) =>
      this.beneficiariesFormArray.controls.every(
        (form: FormGroup) => !form.invalid
      );

      const shareExceededValidation =(data)=>  sharePercentageValidation(data?.reduce((acc, curr) => acc + Number.parseInt(curr.sharePercentage), 0))

    this.beneficiariesFormArray.valueChanges
      .pipe(filter((data) => data && (shareExceededValidation(data) && validateFormArray(data) )))
      .subscribe((beneficiaries) => {
        this.benefit.beneficiaries = [...beneficiaries];
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  get beneficiariesFormArray() {
    return this.seuFormulario.get("beneficiaries") as FormArray;
  }
  getBeneficiary(index: number) {
    const formGroup = this.beneficiariesFormArray.controls[index] as FormGroup;
    return formGroup.controls;
  }

  addBeneficiary(beneficiary?: any) {
    const beneficiaryGroup = new FormGroup({
      name: new FormControl("", Validators.required),
      sharePercentage: new FormControl("", [
        Validators.required,
        Validators.max(100),
        Validators.min(1),
      ]),
      kinship: new FormControl("", Validators.required),
      document: new FormControl("", [cpfValidator()]),
    });

    if (beneficiary) beneficiaryGroup.patchValue(beneficiary);

    this.beneficiariesFormArray.push(beneficiaryGroup);
  }

  removeBeneficiary(index: number) {
    this.beneficiariesFormArray.removeAt(index);
  }

  setBenefitChoice(wantsBenefit: boolean): void {
    this.benefit.wantTheBenefit = wantsBenefit;
  }
  onPlanChange(event: any): void {
    this.selectedPlanName = event.name;
  }

  onSubmit() {
    if (this.seuFormulario.valid) {
      const formData = this.seuFormulario.value;
    }
  }

  getBenefits(benefits: any) {
    if (!benefits) return;
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.Vida //"Seguro de Vida"
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;
          this.benefit.beneficiaries?.forEach((beneficiary) =>
            this.addBeneficiary(beneficiary)
          );
          break;
        }
      }
    }
  }
}
