import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-accordionmetlife',
  templateUrl: './accordion-metlife.component.html',
  styleUrls: ['./accordion-metlife.component.scss']
})
export class AccordionComponentMetlife {

  @Input() isActive: boolean = false;
  iconClass: string = 'mdi-chevron-down';
  @Input() title: string;
  @Input() iconTitle: string;


  updateIcon() {
    this.iconClass = this.isActive ? 'mdi-chevron-up' : 'mdi-chevron-down';
}

  toggleAccordion() {
    this.isActive = !this.isActive;
    this.updateIcon();
}



  ngOnChanges(changes: SimpleChanges) {
    if (changes.isActive) {
      this.updateIcon();
  }
  }

}
