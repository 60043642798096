<ol class="stepper">
  <li
    *ngFor="let step of steps"
    class="stepper__item stepper__item--completed"
    [ngClass]="{
      'stepper__item--current': isCurrent(step) && isActive(step),
      'stepper__item--completed-default': isCompleted(step),
      'stepper__item--completed-expired': isExpired(step),
      'stepper__item--completed-reopened': isReopened(step),
    }"
  >
    <span
      class="stepper__number"
      [ngClass]="{
        'stepper__number--default': isActive(step),
        'stepper__number--expired': isActive(step) && isExpired(step),
        'stepper__number--reopened': isActive(step) && isReopened(step),
      }"
      >{{ step.id }}</span
    >
    <h3 class="stepper__title">{{ step.label }}</h3>
    <p class="stepper__desc">{{ step.description }}</p>
  </li>
</ol>
