import { Component, Input } from "@angular/core";
import { WizardStep } from "src/app/core/models/wizard-step.models";

@Component({
  selector: "app-wizard-step-content",
  templateUrl: "./wizard-step-content.component.html",
  styleUrls: ["./wizard-step-content.component.scss"],
})
export class WizardStepContentComponent {
  @Input() step: WizardStep;
  
}
