<app-accordion class="my-1" [title]="'Vale Combustível'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">{{ benefit.alias }}</label>

      <div class="row mt-4">
        <div class="col-md-2 col-12 col-sale">
          <label class="text-sale">VC/Mensal</label>
          <span>R$ {{ benefit.items[0].cost }}</span>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ benefit.items[0].existDiscountToHolder }}</span>
        </div>
        <div class="col-md-4 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>

          <ng-container *ngIf="benefit.items[0].holderDiscountType === 2">
            <span>{{ benefit.items[0].holderDiscount }}%</span>
          </ng-container>
          <ng-container *ngIf="benefit.items[0].holderDiscountType === 1">
            <span>{{
              benefit.items[0].holderDiscount | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
