import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { CompanyService } from "src/app/core/services/company.service";
import { loginValidator } from "src/app/shared/directives/login.validator";

@Component({
  selector: "app-data-access",
  templateUrl: "./data-access.component.html",
  styleUrls: ["./data-access.component.scss"],
})
export class DataAccessComponent implements OnInit, OnChanges {
  form: FormGroup;

  @Input() isContributorProfile = false;

  @Input() currentStepIndex = 0;
  @Input() isExhibition = false;
  @Input() dataAccess;
  @Output() dataAccessValue = new EventEmitter<any>();
  @Output() saveAndExit = new EventEmitter();

  companyName = "";

  get f() {
    return this.form.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getCompanyName();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadForm(changes);
  }

  getCompanyName() {
    const companyId = this.authService.currentUser().payload.companyId;
    this.companyService
      .getSimple(companyId)
      .subscribe((res) => (this.companyName = res.name));
  }

  loadForm(changes: SimpleChanges) {
    const data = changes.dataAccess?.currentValue;

    if (data) {
      this.form?.patchValue(data);
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      branch: [{ value: "", disabled: true || this.isExhibition }],
      area: [{ value: "", disabled: true || this.isExhibition }],
      directSupervisor: [{ value: "", disabled: true || this.isExhibition }],
      centralCostName: [{ value: "", disabled: true || this.isExhibition }],
      centralCostNumber: [{ value: "", disabled: true || this.isExhibition }],
      capacity: [{ value: "", disabled: true || this.isExhibition }],
      role: [{ value: "", disabled: true || this.isExhibition }],
      salary: [{ value: "", disabled: true || this.isExhibition }],
      hiringDate: [{ value: "", disabled: true || this.isExhibition }],
      registrationNumber: [{ value: "", disabled: true || this.isExhibition }],
      username: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      email: [
        { value: "", disabled: this.isExhibition },
        [loginValidator(), Validators.required],
      ],
      phone: [{ value: "", disabled: this.isExhibition }, Validators.required],
    });
  }

  next(logout?) {
    if (this.form.invalid) {
      Object.keys(this.f).forEach((c) => this.f[c].markAsDirty());
      return;
    }

    const form = this.form.value;
    const body = {
      username: form.username,
      email: form.email,
      phone: form.phone,
    };

    this.dataAccessValue.emit({ body, logout });
  }
}
