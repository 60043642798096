import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Transporte } from "src/app/core/models/transporte.models";
import { AppToastService } from "src/app/core/services/app-toast.service";
import { CompanyService } from "src/app/core/services/company.service";
import { Categories } from "../../../core/models/categories.enum";

declare let google: any;

@Component({
  selector: "app-voucher-transportation-accordion",
  templateUrl: "./voucher-transportation-accordion.component.html",
  styleUrls: ["./voucher-transportation-accordion.component.scss"],
})
export class VoucherTransportationAccordionComponent
  implements OnInit, OnChanges
{
  formBranch: UntypedFormGroup;
  linha: string;
  valor: number;
  countries: any[];
  cities: any[];
  selectedLine: any;
  @Input() isExhibition = false;
  @Input() benefits;
  @Input() showMoreOptionsButton = true;
  @Input() showRemoveAll = true;
  @Input() lines: any[];
  @Input() operators: string[];
  @Input() jobAddress: any;
  @Input() employeeAddress: any;
  @Input() statesAndCities: any[];

  @Output() clickRemoveAll: EventEmitter<void> = new EventEmitter();
  @Output() requestedChartered: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestedPublic: EventEmitter<any> = new EventEmitter<any>();

  selectedOption: any;

  selectedEstado = null;
  selectedCidade = null;
  selectedChoice: string;
  selectedChoiceTranspTre: string;
  selectedChoiceTranspOne: string;
  transportToJob: Transporte[] = [];
  transportFromJob: Transporte[] = [];
  selectedChoiceTranspTwo: string | null = null;
  selectedChoiceTranspFor: boolean | null = null;
  selectedEstadoone: any;
  filteredLines: any[];
  currentRouteType: string;
  isEdit: boolean;
  lineInEdition: Transporte;
  benefit: any;
  calculateDistanceNotFound = false;
  calculatedDistanceValue: any;

  constructor(
    private toast: AppToastService,
    private modalService: NgbModal,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.selectedChoiceTranspFor = true;
    this.requestedPublic.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.lines) {
        this.filteredLines = changes.lines.currentValue;

        if (this.selectedLine) {
          this.selectedLine = this.filteredLines.find(
            (x) =>
              x.line === this.selectedLine.line &&
              x.description == this.selectedLine.description
          );
        }
      }
    }
    if (this.jobAddress && this.employeeAddress) {
      this.calculateDistance();
    }
    this.getBenefits(changes?.benefits?.currentValue);
  }

  get isWithinDistanceLimit(): boolean {
    const distance = parseFloat(this.calculatedDistanceValue);
    return !isNaN(distance) && distance <= 20000;
  }

  calculatedDistance: string;
  get getJobAddress() {
    if (!this.jobAddress) return "";

    const { adress, zipCode, number, neighborhood } = this.jobAddress;
    return `${adress}${number ? `, ${number}` : ""}${
      neighborhood ? `, ${neighborhood}` : ""
    }${zipCode ? ", " + zipCode : ""}`;
  }

  get getEmployeeAddress() {
    if (!this.employeeAddress) return "";

    const { address, postalCode, number, neighborhood } = this.employeeAddress;
    return `${address}${number ? `, ${number}` : ""}${
      neighborhood ? `, ${neighborhood}` : ""
    } ${postalCode ? ", " + postalCode : ""}`;
  }

  removeTransporte(transporteToRemove: Transporte) {
    const index = this.transportToJob.indexOf(transporteToRemove);
    if (index > -1) {
      this.transportToJob.splice(index, 1);
    }
  }

  removeTransporteBack(transporteToRemove: Transporte) {
    const index = this.transportFromJob.indexOf(transporteToRemove);
    if (index > -1) {
      this.transportFromJob.splice(index, 1);

      this.wantBenefitChange();
    }
  }

  onEstadoChange($event) {
    if (!$event) {
      // this.cityChanged.emit();
      this.requestedPublic.emit();
      return;
    }
    this.cities = this.statesAndCities.find(
      (item) => item.state === $event
    )?.cities;
  }

  private loadModal(state: string, city?: string) {
    this.selectedEstado = state;
    this.selectedCidade = city;

    if (this.selectedLine.line)
      this.requestedPublic.emit({
        city: this.selectedCidade || "",
        state: this.selectedEstado || "",
      });
    else this.requestedChartered.emit();
  }

  clearCountry() {
    this.selectedCidade = null;
    this.selectedEstado = null;
    this.cities = [];
    this.cities = [];
    this.selectedLine = [];
    this.operators = [];
  }

  onCityChangeEvent(event) {
    if (!event) {
      this.requestedPublic.emit({
        state: this.selectedEstado,
      });
      return;
    }
    this.selectedCidade = event;

    this.requestedPublic.emit({
      city: event,
      state: this.selectedEstado,
    });
  }

  onSelectOperator($event) {
    if (!$event) {
      this.filteredLines = this.lines;
      return;
    }

    this.filteredLines = this.lines.filter(
      (line) => line.operatorName === $event
    );
  }

  open(content: any, routeType: string) {

    this.isEdit = false;
    this.modalService.open(content, { size: "xl" });
    this.currentRouteType = routeType;
  }

  close = () => {
    this.selectedCidade = null;
    this.selectedEstado = null;
    this.selectedLine = null;
    this.modalService.dismissAll();
  };

  edit($event: Transporte, content: any, routeType: string) {
    this.isEdit = true;
    this.lineInEdition = $event;
    this.modalService.open(content, { size: "xl" });
    this.currentRouteType = routeType;
    this.selectedEstado = $event.estado;
    this.selectedLine = { line: $event.linha, description: $event.description };
    this.loadModal($event.estado, $event.cidade);
  }

  remove($event: Transporte, routeType: string) {
    if (routeType === "origin") this.removeLine($event, this.transportToJob);

    if (routeType === "destination")
      this.removeLine($event, this.transportFromJob);

    this.transportToJob = [...this.transportToJob];
    this.transportFromJob = [...this.transportFromJob];

    this.wantBenefitChange();
  }

  private removeLine(trasport: Transporte, transports: Transporte[]) {
    const index = transports.findIndex((t) => t.linha === trasport.linha);
    transports = transports.splice(index, 1);
  }

  selectedCidadeone = null;

  addTransport() {
    if (!this.selectedLine) {
      this.toast.show("Aviso!", "Selecione uma linha!");
      return;
    }

    if (this.currentRouteType === "origin") {
      this.tryAddNewSelectedTransport(
        this.transportToJob,
        this.selectedChoiceTranspFor
      );
    }

    if (this.currentRouteType === "destination")
      this.tryAddNewSelectedTransport(
        this.transportFromJob,
        this.selectedChoiceTranspFor
      );

    this.wantBenefitChange();
  }

  private tryAddNewSelectedTransport(
    transport: Transporte[],
    isPublic: boolean
  ) {
    const isNewTransportSameType = (
      transport: Transporte[],
      isPublic: boolean
    ) =>
      transport.filter((t) => t.isPublic === isPublic).length !==
      transport.length;

    if (isNewTransportSameType(transport, isPublic)) {
      this.toast.show(
        "Aviso!",
        "Você pode usar somente um tipo de transporte por itinerário"
      );
      return;
    }

    if (transport.length < 5) {
      const newTransport: Transporte = {
        linha: this.selectedLine.line,
        description: this.selectedLine.description,
        valor: this.selectedLine.tariff,
        estado: this.selectedEstado,
        cidade: this.selectedCidade,
        isPublic: this.selectedChoiceTranspFor,
        operadora:this.selectedLine.operatorName

      };

      if (this.isEdit) {
        this.removeLine(this.lineInEdition, transport);
      }

      transport.push(newTransport);
      this.modalService.dismissAll();

      this.clearCountry();

      console.log(transport);
    } else {
      this.toast.show(
        "Aviso!",
        "Você não pode adicionar mais de 5 transportes."
      );
    }
  }

  getBenefits(benefits: any) {
    if (!benefits) return;
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.VT //"Farmácia"
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;

          this.MapTransportSettings(this.benefit);

          this.benefit.employeeAddress = this.getEmployeeAddress;
          this.benefit.companyAddress = this.getJobAddress;

          break;
        }
      }
    }
  }

  private MapTransportSettings(benefit) {
    const mapPublicToModel = (data) => ({
      linha: data.line,
      valor: data.value,
      description: "",
      isPublic: true,
      operadora:data.operator
    });

    const mapCharteredToModel = (data) => ({
      valor: data.value,
      description: data.line,
      isPublic: false,
    });

    const MapFromJobSettings = () => {
      let toJob =
        benefit.transportType?.public?.listDestination?.map(mapPublicToModel);

      if (!toJob || toJob?.length == 0)
        toJob = benefit.transportType?.chartered?.listDestination?.map((t) =>
          mapCharteredToModel(t)
        );
      return toJob;
    };

    const mapFromJobSettings = () => {
      let fromJob =
        benefit.transportType?.public?.listBack?.map(mapPublicToModel);

      if (!fromJob || fromJob?.length == 0)
        fromJob =
          benefit.transportType?.chartered?.listBack?.map(mapCharteredToModel);
      return fromJob;
    };

    const fromJob = mapFromJobSettings();
    const toJob = MapFromJobSettings();

    if (fromJob) this.transportFromJob = [...fromJob];
    if (toJob) this.transportToJob = [...toJob];
  }

  calculateDistance() {
    const service = new google.maps.DistanceMatrixService();

    const origin = this.getEmployeeAddress;
    const destination = this.getJobAddress;
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status == "OK") {
          if (response.rows[0].elements[0]?.status === "NOT_FOUND") {
            this.calculateDistanceNotFound = true;
            return;
          }
          const distance = response.rows[0].elements[0].distance;
          this.calculatedDistance = distance?.text;
          this.calculatedDistanceValue = distance?.value;
          this.calculateDistanceNotFound = false;
        }
      }
    );
  }

  wantBenefitChange() {
    const createFromModelToPublicPayload = (t) => ({
      line: `${t.linha.toString()} - ${t.description}`,
      value: parseFloat(t.valor.toString()),
    });
    const createFromModelToCharteredPayload = (t) => ({
      line: t.description ,
      value: parseFloat(t.valor.toString()),
    });

    if (this.benefit.wantTheBenefit) {
      const isTherePublicTransport = (transport) =>
        transport.some((t) => t.isPublic);

      if (isTherePublicTransport(this.transportToJob)) {
        this.benefit.transportType.chartered.listDestination = [];
        this.benefit.transportType.public.listDestination =
          this.transportToJob.map(createFromModelToPublicPayload);
      } else {
        this.benefit.transportType.public.listDestination = [];
        this.benefit.transportType.chartered.listDestination =
          this.transportToJob.map(createFromModelToCharteredPayload);
      }

      if (isTherePublicTransport(this.transportFromJob)) {
        this.benefit.transportType.chartered.listBack = [];
        this.benefit.transportType.public.listBack = this.transportFromJob.map(
          createFromModelToPublicPayload
        );
      } else {
        this.benefit.transportType.public.listBack = [];
        this.benefit.transportType.chartered.listBack =
          this.transportFromJob.map(createFromModelToCharteredPayload);
      }
    } else {
      this.benefit.transportType.chartered = {
        listDestination: [],
        listBack: [],
      };
      this.benefit.transportType.public = { listDestination: [], listBack: [] };
    }
  }
}
