import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GetCompanyModel } from "src/app/core/models/get-company.model";
import { Transporte } from "src/app/core/models/transporte.models";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { CompanyService } from "src/app/core/services/company.service";
import { TraveService } from "src/app/core/services/travel.service";

@Component({
  selector: "app-benefits",
  templateUrl: "./benefits.component.html",
  styleUrls: ["./benefits.component.scss"],
})
export class BenefitsComponent {
  @Input() enabledOnChangeEvent = false;
  @Input() mandatoryDocumentItem;
  @Input() genericDocumentValue;
  @Input() isExhibition;
  @Input() benefits;
  @Input() dependentsBenefits = [];
  @Input() showMoreOptionsButton = true;
  @Input() showRemoveAll = true;
  @Input() employeeAddress: string;
  @Input() matriz: GetCompanyModel;
  @Output() clickRemoveAll: EventEmitter<void> = new EventEmitter();
  @Output() previous = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Output() saveAndFinish: EventEmitter<any> = new EventEmitter();

  selectedOption: any;

  selectedEstado = null;
  selectedCidade = null;
  selectedEstadoone = null;
  selectedChoice: string;
  selectedChoiceTranspTre: string;
  selectedChoiceTranspOne: string;
  selectedChoiceFive: string;
  selectedChoiceSix: string;
  selectedChoiceSeven: string;
  selectedChoiceEigth: string;
  selectedChoiceNine: string;
  selectedChoiceTen: string;
  selectedChoiceOnce: string;
  selectedChoiceCar: string;
  selectedChoiceAux: string;
  selectedChoiceFuneral: string;
  selectedChoiceOnceAux: string;
  selectedChoicePlan: string;
  transportes: Transporte[] = [];
  transportesBack: Transporte[] = [];
  selectedChoiceTranspTwo: string | null = null;
  selectedChoiceTranspFor: string | null = null;
  documentFile: any;
  extension: any;
  lines: any[];
  operators: string[];
  matrizData: GetCompanyModel;
  statesAndCities: any[];

  constructor(
    private travelService: TraveService,
    private companyService: CompanyService,
    private authService: AuthenticationService
  ) {}

  public getPublicItinerary($event) {
    this.travelService.getPublicTravel($event).subscribe((data) => {
      this.lines = data?.lines;
      this.operators = data?.operators;
      this.statesAndCities = data?.states
    });
  }

  getCharteredLines() {
    this.travelService.getCharteredTravel().subscribe((data) => {
      this.lines = data?.lines;
      this.operators = data?.operators;
    });
  }
}
