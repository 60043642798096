import { Component, Input, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { CompanyStore } from "src/app/core/services/Stores/branches.store";
import { AppToastService } from "src/app/core/services/app-toast.service";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { CompanyService } from "src/app/core/services/company.service";
import { BenefitsService } from "src/app/core/services/portal-admission/benefits.service";
import { EmployeesHiringService } from "src/app/core/services/portal-admission/employees-hiring.service";
import { Categories } from "../../../core/models/categories.enum";

@Component({
  selector: "app-fuel-voucher-accordion",
  templateUrl: "./fuel-voucher-accordion.component.html",
  styleUrls: ["./fuel-voucher-accordion.component.scss"],
})
export class FuelVoucherAccordionComponent {
  @Input() benefits;
  constructor(
    private toast: AppToastService,
    private modalService: NgbModal,
    private companyStore: CompanyStore,
    private service: CompanyService,
    private router: Router,
    private benefitsService: BenefitsService,
    private authService: AuthenticationService,
    private employeesHiringService: EmployeesHiringService
  ) {}
  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  selected: boolean;
  discount: string;

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    // this.getEmployeesHiring();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  getBenefits(benefits) {
    if (!benefits) return;
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.VC
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;
          this.selected = foundBenefit.wantTheBenefit;
          this.discountValue = foundBenefit.holderDiscount;
          this.discount = foundBenefit.existDiscountToHolder;
        }
      }
    }
  }
}
