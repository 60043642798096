<app-accordion [title]="'Carteira de trabalho (CTPS)'">
  <div class="d-flex flex-column">
    <app-form-card>
      <form [formGroup]="workCardForm">
        <div class="row my-5">
          <div class="col-md">
            <label for="" class="text-default-label">Tipo de carteira*</label>
            <ng-select formControlName="documentType">
              <ng-option *ngFor="let item of docType" [value]="item.value">{{
                item.name
              }}</ng-option>
            </ng-select>

            <small
              class="text-danger"
              *ngIf="isControlRequiredError('documentType')"
            >
              Por favor, selecione o tipo da carteira.
            </small>
          </div>

          <div class="col-md">
            <label for="" class="text-default-label">PIS/PASEP*</label>
            <input
              type="text"
              class="form-control"
              placeholder="Digite aqui a informação"
              mask="000.00000.00-0"
              [dropSpecialCharacters]="false"
              formControlName="socialSecurityFinancing"
            />

            <small
              class="text-danger"
              *ngIf="isControlRequiredError('socialSecurityFinancing')"
            >
              O número do PIS/PASEP é obrigatório.
            </small>
            <small
              class="text-danger"
              *ngIf="isControlPatternError('socialSecurityFinancing')"
            >
              O número do PIS/PASEP é inválido.
            </small>
          </div>

          <div class="col-md">
            <label for="" class="text-default-label">Número*</label>
            <input
              type="text"
              class="form-control"
              placeholder="Digite aqui a informação"
              formControlName="number"
            />

            <small class="text-danger" *ngIf="isControlRequiredError('number')">
              O número da CTPS é obrigatório.
            </small>
            <small class="text-danger" *ngIf="isControlPatternError('number')">
              O número da CTPS é inválido.
            </small>
          </div>

          <div class="col-md">
            <label for="" class="text-default-label">Série*</label>
            <input
              type="text"
              class="form-control"
              placeholder="Digite aqui a informação"
              formControlName="serie"
            />

            <small class="text-danger" *ngIf="isControlRequiredError('serie')">
              A série da CTPS é obrigatória.
            </small>
            <small class="text-danger" *ngIf="isControlPatternError('serie')">
              A série da CTPS é inválida.
            </small>
          </div>

          <div class="col-md">
            <label for="" class="text-default-label">UF*</label>
            <ng-select
              [notFoundText]="'UF não encontrado'"
              formControlName="countryId"
            >
              <ng-option *ngFor="let countryId of ufs" [value]="countryId.id">{{
                countryId.name
              }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="isInvalidField('countryId')">
              Por favor, selecione a UF emissor.
            </small>
          </div>
        </div>
      </form>
      <div class="row" *ngIf="workCardForm.controls.documentType.value === 1">
        <div class="col-md-12">
          <label class="text-default-label">Carregar PDF*</label>
          <app-upload-file
            (selectFileWithExtension)="getWalletPdf($event)"
            [selectedImage]="digitalDocument && 'data:application/' + digitalDocumentExtension + ';base64,' + digitalDocument"
            [isExhibition]="isExhibition"
          >
          </app-upload-file>
          <small class="text-danger" *ngIf="!digitalDocument">
            Por favor, carregue o PDF.
          </small>
        </div>
      </div>

      <div class="row" *ngIf="workCardForm.controls.documentType.value === 2">
        <div class="col">
          <label class="text-default-label">Carregar página 01*</label>
          <app-upload-file
            (selectFileWithExtension)="getPageOne($event)"
            [selectedImage]="firsDocumentPage && 'data:application/' + firsDocumentPageExtension + ';base64,' + firsDocumentPage"
            [isExhibition]="isExhibition"
          >
          </app-upload-file>
          <small class="text-danger" *ngIf="!firsDocumentPage">
            Por favor, carregue a página 01.
          </small>
        </div>

        <div class="col">
          <label class="text-default-label">Carregar página 02 e 03*</label>
          <app-upload-file
            (selectFileWithExtension)="getPageTwoAndThree($event)"
            [selectedImage]="secondAndThirdPageDocument && 'data:application/' + secondAndThirdPageDocumentExtension + ';base64,' + secondAndThirdPageDocument"
            [isExhibition]="isExhibition"
          >
          </app-upload-file>
          <small class="text-danger" *ngIf="!secondAndThirdPageDocument">
            Por favor, carregue a página 02 e 03.
          </small>
        </div>

        <div class="col">
          <label class="text-default-label"
            >Carregar última página preenchida*</label
          >
          <app-upload-file
            (selectFileWithExtension)="getLastPage($event)"
            [selectedImage]="lastDocumentPage && 'data:application/' + lastDocumentPageExtension + ';base64,' + lastDocumentPage"
            [isExhibition]="isExhibition"
          >
          </app-upload-file>
          <small class="text-danger" *ngIf="!lastDocumentPage">
            Por favor, carregue a última página.
          </small>
        </div>
      </div>

      <div
        class="d-flex w-100 justify-content-end align-itens-center py-4"
        *ngIf="!isExhibition"
      >
        <button
          class="btn btn-outline-primary text-uppercase m-1"
          (click)="workCardFormEmit()"
        >
          salvar
        </button>

        <button
          class="btn btn-outline-secondary text-uppercase m-1"
          type="button"
          (click)="downloadFile()"
        >
          Baixar o documento
        </button>
      </div>
    </app-form-card>
  </div>
</app-accordion>
