import { Component, EventEmitter, Input, Output } from "@angular/core";
import { WizardStep } from "src/app/core/models/wizard-step.models";

@Component({
  selector: "app-wizard",
  templateUrl: "./wizard.component.html",
})
export class WizardComponent {
  @Input() steps: WizardStep[];
  @Input() currentStepIndex: number;
  @Input() allowNavigation:boolean;
  @Output() stepChangeIndex:EventEmitter<number> = new EventEmitter<number>();


}
