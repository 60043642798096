<section class="card" id="dependents">
  <article class="card-body row">
    <h5 class="col-md-12">DEPENDENTES</h5>
    <form [formGroup]="form">
      <div class="col-md-12" formArrayName="dependents">
        <ng-container *ngFor="let item of dependents.controls; let i = index">
          <h6 class="my-3">Dependente {{ i + 1 }}</h6>
          <app-accordion
            [title]="getDependentTitle(i) || 'Selecione um dependente'"
            (removeClick)="remove(i)"
          >
            <div class="col-md-12 row" [formGroupName]="i">
              <div class="card p-0 m-0">
                <div class="card-body row">
                  <div class="col-md-12 row my-3">
                    <div class="col-md-3">
                      <label for="type" class="text-default-label"
                        >Selecione o parentesco*</label
                      >
                      <ng-select
                        class="custom"
                        [ngClass]="{
                          'is-invalid':
                            getDependent(i).type.errors &&
                            getDependent(i).type.dirty
                        }"
                        formControlName="type"
                        [items]="dependentType"
                        bindLabel="name"
                        bindValue="value"
                        placeholder="Digite ou selecione"
                        notFoundText="Nenhum parentesco encontrado."
                      >
                      </ng-select>
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          getDependent(i).type.errors &&
                          getDependent(i).type.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).type.errors">
                          Selecione um tipo de conta.
                        </span>
                      </div>
                    </div>

                    <div class="col-md-9">
                      <label for="name" class="text-default-label"
                        >Nome completo*</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Digite aqui a informação"
                        formControlName="name"
                        [ngClass]="{
                          'is-invalid':
                            getDependent(i).name.errors &&
                            getDependent(i).name.dirty
                        }"
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          getDependent(i).name.errors &&
                          getDependent(i).name.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).name.errors?.required"
                          >O nome do dependente é obrigatório.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-12 my-3">
                    <div class="col-md-3">
                      <label for="rg" class="text-default-label"
                        >Número do RG*</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Digite aqui a informação"
                        formControlName="rg"
                        [ngClass]="{
                          'is-invalid':
                            getDependent(i).rg.errors &&
                            getDependent(i).rg.dirty
                        }"
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          getDependent(i).rg.errors &&
                          getDependent(i).rg.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).rg.errors.required"
                          >o número do RG é obrigatório.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label for="cpf" class="text-default-label"
                        >Número do CPF*</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Digite aqui a informação"
                        formControlName="cpf"
                        mask="000.000.000-00"
                        [ngClass]="{
                          'is-invalid':
                            getDependent(i).cpf.errors &&
                            getDependent(i).cpf.dirty
                        }"
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          getDependent(i).cpf.errors &&
                          getDependent(i).cpf.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).cpf.errors.required"
                          >o número do CPF é obrigatório.</span
                        >
                      </div>
                    </div>

                    <div class="col-md-3">
                      <label class="text-default-label">Gênero*</label>
                      <ng-select
                        class="custom"
                        [items]="genderArray"
                        bindLabel="name"
                        bindValue="value"
                        placeholder="Selecione o gênero"
                        notFoundText="Nenhum gênero encontrado."
                        formControlName="gender"
                        [ngClass]="{
                          'is-invalid':
                            getDependent(i).gender.errors &&
                            getDependent(i).gender.dirty
                        }"
                      >
                      </ng-select>
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          getDependent(i).gender.errors &&
                          getDependent(i).gender.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).gender.errors.required"
                          >o Gênero é obrigatório.</span
                        >
                      </div>
                    </div>

                    <div class="col-md-3">
                      <label class="text-default-label"
                        >Data de nascimento*</label
                      >
                      <div class="input-group">
                        <input
                          type="date"
                          class="form-control"
                          placeholder="Digite aqui a informação"
                          formControlName="birthdate"
                          [ngClass]="{
                            'is-invalid':
                              getDependent(i).birthdate.errors &&
                              getDependent(i).birthdate.dirty
                          }"
                        />

                        <div
                          class="invalid-feedback"
                          *ngIf="
                            getDependent(i).birthdate.invalid &&
                            getDependent(i).birthdate.dirty
                          "
                        >
                          <span
                            *ngIf="getDependent(i).birthdate.errors.required"
                            >A Data de Nascimento é obrigatória</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 row my-3">
                    <div class="col-md-12 mt-2">
                      <label class="text-default-label">Universitário*</label>
                      <div class="custom-checkbox-container">
                        <label class="custom-checkbox">
                          <input
                            type="radio"
                            [value]="false"
                            formControlName="university"
                            [ngClass]="{
                              'is-invalid':
                                getDependent(i).university.errors &&
                                getDependent(i).university.dirty
                            }"
                          />
                          <span class="checkmark">
                            <span class="checkmark-inner"></span>
                          </span>
                          Não
                        </label>

                        <label class="custom-checkbox">
                          <input
                            type="radio"
                            [value]="true"
                            formControlName="university"
                            [ngClass]="{
                              'is-invalid':
                                getDependent(i).university.errors &&
                                getDependent(i).university.dirty
                            }"
                          />
                          <span class="checkmark">
                            <span class="checkmark-inner"></span>
                          </span>
                          Sim
                        </label>
                      </div>
                      <div
                        class="invalid-feedback d-flex"
                        *ngIf="
                          getDependent(i).university.errors &&
                          getDependent(i).university.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).university.errors.required"
                          >Informe se o dependente é universitário ou não.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-12 mt-2">
                      <label class="text-default-label"
                        >Dependente do IRRF*</label
                      >
                      <div class="custom-checkbox-container">
                        <label class="custom-checkbox">
                          <input
                            type="radio"
                            [value]="false"
                            formControlName="irrf"
                            [ngClass]="{
                              'is-invalid':
                                getDependent(i).irrf.errors &&
                                getDependent(i).irrf.dirty
                            }"
                          />
                          <span class="checkmark">
                            <span class="checkmark-inner"></span>
                          </span>
                          Não
                        </label>

                        <label class="custom-checkbox">
                          <input
                            type="radio"
                            [value]="true"
                            formControlName="irrf"
                            [ngClass]="{
                              'is-invalid':
                                getDependent(i).irrf.errors &&
                                getDependent(i).irrf.dirty
                            }"
                          />
                          <span class="checkmark">
                            <span class="checkmark-inner"></span>
                          </span>
                          Sim
                        </label>
                      </div>
                      <div
                        class="invalid-feedback d-flex"
                        *ngIf="
                          getDependent(i).irrf.errors &&
                          getDependent(i).irrf.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).irrf.errors.required"
                          >Informe se deseja adiciona-lo como dependente no
                          IRRF.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-12 mt-2">
                      <label class="text-default-label">PCD*</label>
                      <div class="custom-checkbox-container">
                        <label class="custom-checkbox">
                          <input
                            type="radio"
                            [value]="false"
                            formControlName="pcd"
                            [ngClass]="{
                              'is-invalid':
                                getDependent(i).pcd.errors &&
                                getDependent(i).pcd.dirty
                            }"
                          />
                          <span class="checkmark">
                            <span class="checkmark-inner"></span>
                          </span>
                          Não
                        </label>

                        <label class="custom-checkbox">
                          <input
                            type="radio"
                            [value]="true"
                            formControlName="pcd"
                            [ngClass]="{
                              'is-invalid':
                                getDependent(i).pcd.errors &&
                                getDependent(i).pcd.dirty
                            }"
                          />
                          <span class="checkmark">
                            <span class="checkmark-inner"></span>
                          </span>
                          Sim
                        </label>
                      </div>
                      <div
                        class="invalid-feedback d-flex"
                        *ngIf="
                          getDependent(i).pcd.errors &&
                          getDependent(i).pcd.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).pcd.errors.required"
                          >Informe se o dependente é PCD.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 row my-3">
                    <div class="col-md-4">
                      <label class="text-default-label">Estado civil*</label>
                      <ng-select
                        class="custom"
                        [items]="CivilArray"
                        bindLabel="name"
                        bindValue="value"
                        placeholder="Selecione o estado civil"
                        notFoundText="Nenhum estado civil encontrado."
                        formControlName="maritalStatus"
                        [ngClass]="{
                          'is-invalid':
                            getDependent(i).maritalStatus.errors &&
                            getDependent(i).maritalStatus.dirty
                        }"
                      >
                      </ng-select>
                      <div
                        class="invalid-feedback d-flex"
                        *ngIf="
                          getDependent(i).maritalStatus.errors &&
                          getDependent(i).maritalStatus.invalid
                        "
                      >
                        <span
                          *ngIf="getDependent(i).maritalStatus.errors.required"
                          >O estado civil é obrigatório.</span
                        >
                      </div>
                    </div>
                    <div class="col-md-8">
                      <label for="rg" class="text-default-label"
                        >Nome da mãe*</label
                      >
                      <input
                        class="form-control"
                        placeholder="Digite aqui o nome da mãe"
                        formControlName="motherName"
                        [ngClass]="{
                          'is-invalid':
                            getDependent(i).motherName.errors &&
                            getDependent(i).motherName.dirty
                        }"
                      />
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          getDependent(i).motherName.errors &&
                          getDependent(i).motherName.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).motherName.errors.required"
                          >o nome da mãe é obrigatório.</span
                        >
                      </div>
                    </div>

                    <div class="row col-12 my-3">
                      <div class="col-md-3">
                        <label for="postalCode" class="text-default-label"
                          >CEP</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="ex:3333-333"
                          mask="00000-000||0000-000"
                          formControlName="postalCode"
                          [ngClass]="{
                            'is-invalid':
                              getDependent(i).postalCode.errors &&
                              getDependent(i).postalCode.dirty
                          }"
                        />
                        <div
                          class="invalid-feedback"
                          *ngIf="
                            getDependent(i).postalCode.errors &&
                            getDependent(i).postalCode.invalid
                          "
                        >
                          <span
                            *ngIf="getDependent(i).postalCode.errors.required"
                            >o CEP é obrigatório.</span
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label for="address" class="text-default-label"
                          >Endereço</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Digite o nome da rua,av..."
                          formControlName="address"
                          [ngClass]="{
                            'is-invalid':
                              getDependent(i).address.errors &&
                              getDependent(i).address.dirty
                          }"
                        />
                        <div
                          class="invalid-feedback"
                          *ngIf="
                            getDependent(i).address.errors &&
                            getDependent(i).address.invalid
                          "
                        >
                          <span *ngIf="getDependent(i).address.errors.required"
                            >O endereço é obrigatório.</span
                          >
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label for="number" class="text-default-label"
                          >Número</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="ex: 333"
                          formControlName="number"
                          [ngClass]="{
                            'is-invalid':
                              getDependent(i).number.errors &&
                              getDependent(i).number.dirty
                          }"
                        />
                        <div
                          class="invalid-feedback"
                          *ngIf="
                            getDependent(i).number.errors &&
                            getDependent(i).number.invalid
                          "
                        >
                          <span *ngIf="getDependent(i).number.errors.required"
                            >O número é obrigatório.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 row my-3">
                      <div class="col-md-4">
                        <label for="neighborhood" class="text-default-label"
                          >Bairro</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          formControlName="neighborhood"
                          [ngClass]="{
                            'is-invalid':
                              getDependent(i).neighborhood.errors &&
                              getDependent(i).neighborhood.dirty
                          }"
                        />
                        <div
                          class="invalid-feedback"
                          *ngIf="
                            getDependent(i).neighborhood.errors &&
                            getDependent(i).neighborhood.invalid
                          "
                        >
                          <span
                            *ngIf="getDependent(i).neighborhood.errors.required"
                            >O bairro é obrigatório.</span
                          >
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label for="complement" class="text-default-label"
                          >Complemento</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          formControlName="complement"
                        />
                      </div>
                      <div class="col-md-2">
                        <label for="country" class="text-default-label"
                          >UF</label
                        >
                        <ng-select
                          class="custom"
                          [ngClass]="{
                            'is-invalid':
                              getDependent(i).countryId.errors &&
                              getDependent(i).countryId.dirty
                          }"
                          formControlName="countryId"
                          [items]="ufs"
                          bindLabel="name"
                          bindValue="id"
                          placeholder="Digite ou selecione"
                          notFoundText="Nenhum parentesco encontrado."
                        >
                        </ng-select>
                        <div
                          class="invalid-feedback"
                          *ngIf="
                            getDependent(i).countryId.errors &&
                            getDependent(i).countryId.invalid
                          "
                        >
                          <span
                            *ngIf="getDependent(i).countryId.errors.required"
                            >O UF é obrigatório.</span
                          >
                        </div>
                      </div>
                      <div class="col-md-2">
                        <label for="city" class="text-default-label">
                          Cidade
                        </label>
                        <ng-select
                          formControlName="cityId"
                          class="custom"
                          [items]="cities"
                          bindLabel="name"
                          bindValue="id"
                          placeholder="Selecione a cidade"
                          [ngClass]="{
                            'is-invalid':
                              getDependent(i).cityId.errors &&
                              getDependent(i).cityId.dirty
                          }"
                          notFoundText="Nenhuma Cidade encontrada."
                        >
                        </ng-select>
                        <div
                          *ngIf="
                            getDependent(i).cityId.errors &&
                            getDependent(i).cityId.invalid
                          "
                          class="invalid-feedback"
                        >
                          <span *ngIf="getDependent(i).cityId.errors.required"
                            >Selecione uma cidade.</span
                          >
                        </div>
                      </div>
                      <div
                        class="invalid-feedback"
                        *ngIf="
                          getDependent(i).cityId.errors &&
                          getDependent(i).cityId.invalid
                        "
                      >
                        <span *ngIf="getDependent(i).cityId.errors.required"
                          >O Munícipio obrigatório.</span
                        >
                      </div>
                    </div>
                    <div class="row col-md-12 mt-3">
                      <div class="col-xl-3 col-md-4 col-sm-12 mt-2">
                        <label for="raceColorLabel" class="text-default-label"
                          >Raça / Cor</label
                        >
                        <ng-select
                          class="custom"
                          placeholder="Selecione a Raça/Cor"
                          notFoundText="Nenhum item encontrado."
                          formControlName="raceColorId"
                        >
                          <ng-container *ngIf="raceColors$ | async as items">
                            <ng-option
                              *ngFor="let item of items"
                              [value]="item.value"
                            >
                              {{ item.name }}
                            </ng-option>
                          </ng-container>
                        </ng-select>
                      </div>
                      <div class="col-xl-3 col-md-4 col-sm-12 mt-2">
                        <label for="raceColorLabel" class="text-default-label"
                          >Tipo de deficiência física</label
                        >
                        <ng-select
                          class="custom"
                          placeholder="Selecione a Raça/Cor"
                          notFoundText="Nenhum item encontrado."
                          formControlName="pcdTypeId"
                        >
                          <ng-container *ngIf="pcdTypes$ | async as items">
                            <ng-option
                              *ngFor="let item of items"
                              [value]="item.value"
                            >
                              {{ item.name }}
                            </ng-option>
                          </ng-container>
                        </ng-select>
                      </div>
                      <div class="col-xl-3 col-md-4 col-sm-12 mt-2">
                        <label for="raceColorLabel" class="text-default-label"
                          >IRRF Data inicio</label
                        >
                        <input
                          type="date"
                          class="form-control"
                          formControlName="irrfStartDate"
                        />
                      </div>
                      <div class="col-xl-3 col-md-4 col-sm-12 mt-2">
                        <label for="raceColorLabel" class="text-default-label"
                          >IRRF Data Fim</label
                        >
                        <input
                          type="date"
                          class="form-control"
                          formControlName="irrfEndDate"
                        />
                      </div>
                      <div class="col-xl-3 col-md-4 col-sm-12 mt-2">
                        <label for="raceColorLabel" class="text-default-label"
                          >Data de casamento / União estavel</label
                        >
                        <input
                          type="date"
                          class="form-control"
                          formControlName="marriedDate"
                        />
                      </div>
                    </div>
                    <ng-container
                      *ngIf="mandatoryDocuments?.dependentDocuments"
                    >
                      <label
                        for="mandatoryDocumentslabel"
                        class="text-default-label"
                        >Documentos</label
                      >
                      <ng-container
                        *ngFor="
                          let item of mandatoryDocuments.dependentDocuments
                        "
                      >
                        <div class="col-md-12 row my-3">
                          <div class="col-md-12">
                            <app-generic-document
                              [mandatoryDocumentItem]="item"
                              [enabledOnChangeEvent]="true"
                              [genericDocumentValue]="
                                getMandatoryDocumentValue(item, i)
                              "
                              (fileChange)="addMandatoryDocumentFile(i, $event)"
                            >
                            </app-generic-document>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="my-3 col-12 d-flex justify-content-between">
              <button
                class="btn btn-secondary"
                *ngIf="!isExhibition"
                (click)="remove(i)"
              >
                REMOVER
              </button>
              <button
                class="btn btn-outline-primary"
                (click)="saveDependent(i)"
              >
                SALVAR
              </button>
            </div>
          </app-accordion>
        </ng-container>
      </div>
    </form>

    <div class="col-md-12 my-3" *ngIf="!isExhibition">
      <button
        class="col-md-3 btn btn-outline-primary"
        type="button"
        (click)="add()"
      >
        ADICIONAR DEPENDENTE
      </button>
    </div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"
    ></div>
    <article
      class="col-md-12 row justify-content-end"
      *ngIf="!isContributorProfile"
    >
      <div class="col-md-4 my-3 text-align-end">
        <button
          type="submit"
          class="btn btn-outline-secondary m-1 text-uppercase"
          (click)="logoutEmit()"
        >
          salvar e sair
        </button>
        <button
          class="btn btn-secondary text-uppercase"
          type="button"
          (click)="back.emit()"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary m-1 text-uppercase"
          (click)="next.emit(3)"
        >
          Avançar
        </button>
      </div>
    </article>
  </article>
</section>
