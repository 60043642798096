<ng-template #popContentItem>
  <div *ngFor="let model of popoverModel">
    <div class="header-popover">
      <strong class="text-strong">{{ model.popoverTitleItem }}</strong>
    </div>
    <ng-container>
      <ul>
        <li *ngFor="let i of showThisQuantity(model.itemList)">
          <div>
            {{ getNestedProperty(i, model.propShow) }}
          </div>
        </li>
        <li *ngIf="model.itemList.length > this.maxShowItemsPopover">
          <a href="{{ model.valueHrefLinkMoreItems }}"
            >+{{ model.itemList.length - this.maxShowItemsPopover
            }}{{
              model.itemList.length - this.maxShowItemsPopover > 1
                ? model.valueMoreItemsPlural
                : model.valueMoreItemsSingle
            }}</a
          >
        </li>
      </ul>
    </ng-container>
  </div>
</ng-template>

<button
  type="button"
  class="btn btn-outline-secondary mr-2 badge-circle"
  [ngbPopover]="popContentItem"
  triggers="manual"
  #popoverItem="ngbPopover"
  (mouseover)="openPopover(popoverItem)"
  (mouseout)="closePopover(popoverItem)"
  (click)="closeForcePopover(popoverItem)"
>
  {{ items }}
</button>
