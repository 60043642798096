import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { debounceTime, filter, switchMap } from "rxjs";
import { AppToastService } from "src/app/core/services/app-toast.service";
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { CompanyService } from "src/app/core/services/company.service";
import { EnumsService } from "src/app/core/services/enums.service";
import { cpfValidator } from 'src/app/shared/directives/cpf-validator.directive';

@Component({
  selector: "app-personal-bank-details-metlife",
  templateUrl: "./personal-bank-details-metlife.component.html",
  styleUrls: ["./personal-bank-details-metlife.component.scss"],
})
export class PersonalBankDetailsComponentMetlife implements OnInit, OnChanges {

  martialStatusArray = [];

  genderArray = [
    { name: 'Masculino', value: 1 },
    { name: 'Feminino', value: 2 },
    { name: 'Não binário(a)', value: 3 },
    { name: 'Fluido(a) de gênero', value: 4 },
    { name: 'Bigênero', value: 5 }
  ];

  accountTypeArray = [
    { name: 'Desconhecido', value: 0 },
    { name: 'Conta Corrente', value: 1 },
    { name: 'Conta Poupança', value: 2 },
    { name: 'Conta de Pagamento', value: 3 },
    { name: 'Conta Salário', value: 4 }
  ];


  form: UntypedFormGroup;
  phoneDirty = false;

  @Input() isContributorProfile = false;
  @Input() isExhibition = false;
  @Input() getPersonalBankDetails;
  @Input() bankList;

  @Output() personalBankDetailsValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() currentStepIndexChange = new EventEmitter<number>();
  @Output() previousStepIndexChange = new EventEmitter<void>();

  countries: any[];
  cities: any[];

  get f() {
    return this.form.controls;
  }


  get formValue() {
    return JSON.stringify(this.form.getRawValue())
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: CompanyService,
    private authService: AuthenticationService,
    private router: Router,
    private toast:AppToastService,
    private enumsService:EnumsService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.initCep();
    this.initCountrySelect();
    this.initCitySelect();

    this.enumsService.getMaritalType().subscribe(res => this.martialStatusArray = res)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadForm(changes);
  }

  loadForm(changes: SimpleChanges) {
    const data = changes.getPersonalBankDetails?.currentValue;

    if (data) {
      this.form?.patchValue(data);
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: [{ value: '', disabled: this.isExhibition }],
      gender: [{ value: "", disabled: this.isExhibition }, Validators.required],
      birthdate: [{ value: "", disabled: this.isExhibition }, [Validators.required]],
      maritalStatus: [{ value: "", disabled: this.isExhibition }, Validators.required],
      cpf: [{ value: "", disabled: this.isExhibition }, [Validators.required, cpfValidator()]],
      rg: [{ value: "", disabled: this.isExhibition }, Validators.required],
      isPcd: [{ value: "", disabled: this.isExhibition }],
      height: [{ value: "", disabled: this.isExhibition }],
      weight: [{ value: null, disabled: this.isExhibition }],
      linkedin: [{ value: "", disabled: this.isExhibition }],
      postalCode: [{ value: "", disabled: this.isExhibition }, Validators.required],
      address: [{ value: "", disabled: this.isExhibition }, Validators.required],
      number: [{ value: "", disabled: this.isExhibition }],
      neighborhood: [{ value: "", disabled: this.isExhibition }, Validators.required],
      stateId: [{ value: "", disabled: this.isExhibition }, Validators.required],
      cityId: [{ value: "", disabled: this.isExhibition }, Validators.required],
      complement: [{ value: "", disabled: this.isExhibition }],
      bankId: [{ value: "", disabled: this.isExhibition }, Validators.required],
      agency: [{ value: "", disabled: this.isExhibition }, Validators.required],
      agencyDigit: [{ value: "", disabled: this.isExhibition }],
      account: [{ value: "", disabled: this.isExhibition }, Validators.required],
      accountDigit: [{ value: "", disabled: this.isExhibition }],
      accountType: [{ value: "", disabled: this.isExhibition }, Validators.required],
      searchCity: { value: "", disabled: this.isExhibition },
      searchCountry: { value: "", disabled: this.isExhibition },
    });
  }

  private initCep() {
    this.f.postalCode.valueChanges
      .pipe(
        filter(value => value?.length === 8),
        debounceTime(500),
        switchMap((value: string) =>   this.service.findAddress(value))
      )
      .subscribe((cepData: any) => {
        if(cepData?.erro){
         this.toast.show("busca por cep","cep não encontrado",false)
         return;
        }
        if (cepData) {
          this.f.address.setValue(cepData.logradouro);
          this.f.neighborhood.setValue(cepData.bairro);
          this.service
            .getAllCountries(cepData.uf)
            .subscribe((countryResult: any) => {
              this.f.stateId.setValue(countryResult[0]?.id);
              this.service
                .getAllCities(countryResult[0]?.id, cepData.localidade)
                .subscribe((cityResult: any) => {
                  this.cities = [...cityResult];
                  this.f.cityId.setValue(cityResult[0]?.id);
                });
            });
        }
      });
  }

  private initCitySelect() {
    this.f.searchCity.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((value: string) => {
          if (this.f.stateId.value == null) {
            if (this.f.cityId.value != null) this.f.cityId.setValue(null);
            return [];
          }
          return this.service.getAllCities(this.f.stateId.value, value);
        })
      )
      .subscribe((data: any) => {
        this.cities = [...data];
      });
  }

  private initCountrySelect() {
    this.service
      .getAllCountries(" ")
      .subscribe((data: any) => (this.countries = [...data]));

    this.f.searchCountry.valueChanges
      .pipe(
        debounceTime(500),
        switchMap((value: string) => this.service.getAllCountries(value))
      )
      .subscribe((data: any) => {
        this.countries = [...data];
      });

    this.f.searchCountry.setValue(" ");
  }

  next(logout?) {
    if (this.form.invalid) {
      Object.keys(this.f).forEach((c) => this.f[c].markAsDirty());
      this.toast.show("Validação de formulário","Informações obrigatórias não preenchidas. Revise os campos e salve novamente.")
      return false;
    }

    const body = this.form.value;

    this.personalBankDetailsValue.emit({ body:{...body, weight: body.weight || null, height: body.height || null}, logout });
    return true
  }

  back() {
    this.previousStepIndexChange.emit();
  }

}
