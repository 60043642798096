import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-current-time",
  templateUrl: "./current-time.component.html",
  styleUrls: ["./current-time.component.scss"],
})
export class CurrentTimeComponent implements OnInit {
  currentTime: string;
  @Output() currentTimeChanges: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() {
    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  ngOnInit(): void {
    this.currentTimeChanges.emit(new Date());
  }

  updateTime() {
    const now = new Date();
    this.currentTime = this.formatTime(now);
    this.currentTimeChanges.emit(now);
  }

  formatTime(date: Date): string {
    const hours = this.padNumber(date.getHours());
    const minutes = this.padNumber(date.getMinutes());
    return `${hours}:${minutes}`;
  }

  padNumber(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }
}
