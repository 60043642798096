import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { Categories } from "src/app/core/models/categories.enum";
import { AuthenticationService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-pac-accordion",
  templateUrl: "./pac-accordion.component.html",
  styleUrls: ["./pac-accordion.component.scss"],
})
export class PacAccordionComponent implements OnInit, OnChanges {
  @Input() benefits;
  plan: any;
  constructor(
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {}
  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  selected: boolean;
  discount: string;

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
  }

  openCobertura(contentcobertura: any) {
    this.modalService.open(contentcobertura, { size: "lg" });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  getBenefits(benefits) {
    if (!benefits) return;
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.PAC
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;

          if (this.benefit.items.length === 1)
            this.plan = this.benefit.items[0];
        }
      }
    }
  }
}
