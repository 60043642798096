import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-generic-document",
  templateUrl: "./generic-document.component.html",
  styleUrls: ["./generic-document.component.scss"],
})
export class GenericDocumentComponent implements OnChanges {
  documentFile: any;
  extension: any;
  @Input() mandatoryDocumentItem;
  @Input() genericDocumentValue;
  @Input() isExhibition = false;
  @Input() enabledOnChangeEvent = false;
  @Output() formValue: EventEmitter<any> = new EventEmitter();
  @Output() fileChange :EventEmitter<any> = new EventEmitter();


  constructor(private utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.genericDocumentValue?.currentValue;

    if (data) {
      const { documentFile, documentExtension } = data;
      this.documentFile = `${documentFile}`;
      this.extension = documentExtension;
    }
  }

  emitFormGenericDocumentValue() {
    if (!this.documentFile) {
      return;
    }

    const formGenericDocumentValue = this.createGenericDocumenyPayload();

    this.formValue.emit(formGenericDocumentValue);
  }

  getDocumentFile({ base64, extension }) {
    this.documentFile = base64;
    this.extension = extension;

    this.genericDocumentValue = this.createGenericDocumenyPayload();

    if(this.enabledOnChangeEvent)
     this.fileChange.emit(this.genericDocumentValue);
  }

  downloadFile() {
    const documentName = `${this.mandatoryDocumentItem.name}.${this.extension}`;

    this.utilsService.downloadFile(this.genericDocumentValue.documentFile,documentName);
  }

  private createGenericDocumenyPayload() {
    return {
      documentFile: this.documentFile,
      documentId: this.mandatoryDocumentItem?.id,
      documentExtension: this.extension,
    };
  }
}
