import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class CustomAdapter extends NgbDateAdapter<string> {

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split('/');
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? `${this.pad(date.day)}/${this.pad(date.month)}/${date.year}` : null;
}

pad(value: number) {
    return (value < 10 ? '0' : '') + value;
}

}
