<app-accordion class="my-1" [title]="benefit.descCategory" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <!-- <label class="text-default-label">{{ benefit.alias }}</label> -->
      <!-- <label class="text-default-label"></label> -->
      <div class="w-100 row mx-0 align-items-end">
        <label class="cred-text">Selecione uma opção</label>
        <div class="col-md-12 px-0">
          <ng-select
            [items]="benefit.items"
            [disabled]="selectDisabled"
            bindLabel="name"
            placeholder="Selecione uma opção"
            [(ngModel)]="selectedPlan"
            (clear)="removeSelectedPlan()"
            (change)="onOptionSelected($event)"
          ></ng-select>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-2 col-12 col-sale">
          <label class="text-sale">Valor:</label>
          <span>
            {{ selectedPlan?.cost || 0 | currency : "BRL" : "symbol" }}</span
          >
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ selectedPlan?.existDiscountToHolder || "Não" }}</span>
        </div>
        <div class="col-md-4 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>
          <ng-container *ngIf="selectedPlan?.holderDiscountType === 2">
            <span>{{ selectedPlan?.holderDiscount || 0 }}%</span>
          </ng-container>
          <ng-container *ngIf="selectedPlan?.holderDiscountType === 1">
            <span>{{
              selectedPlan?.holderDiscount || 0 | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
        </div>
        <label class="text-benefictransp mt-3"
          >Deseja aderir ao benefício</label
        >
        <div class="custom-checkbox-container">
          <label class="custom-checkbox">
            <input
              type="radio"
              name="choiceNine"
              [(ngModel)]="benefit.wantTheBenefit"
              [value]="false"
              (change)="setBenefitChoice()"
            />
            <span class="checkmark">
              <span class="checkmark-inner"></span>
            </span>
            Não
          </label>

          <label class="custom-checkbox">
            <input
              type="radio"
              name="choiceNine"
              [(ngModel)]="benefit.wantTheBenefit"
              [value]="true"
              (change)="setBenefitChoice()"
            />
            <span class="checkmark">
              <span class="checkmark-inner"></span>
            </span>
            Sim
          </label>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
