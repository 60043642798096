<app-accordion class="my-1" [title]="'Auxílio Creche / Babá'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">Auxílio Creche/Babá</label>
      <p>Auxílio Creche ou Babá para filhos até 71 meses.</p>

      <p><strong>Documentos necessários para adesão do benefício</strong></p>
      <p>
        <span><strong>Creche</strong>:</span> Declaração de
        matrícula/rematrícula em creche.
      </p>
      <p>
        <span><strong>Babá:</strong></span> Comprovante de contratação (CTPS
        Digital, E-social) e comprovante de quitação dos recolhimentos à
        Previdência Social.
      </p>
      <p>
        Importante: ao aderir o benefício, você deverá comprovar anualmente, (em
        janeiro) através de declaração a quitação anual do pagamento da
        creche/babá.
      </p>
      <p>
        <strong class="mb-2">Valor do benefício</strong>

        <span class="d-block">
          Creche (filhos até 6 meses de idade) – R$ 889,37
        </span>
        <span class="d-block"
          >Creche (filhos após 6 meses de idade)– R$ 479,28
        </span>
        <span class="d-block"
          >Babá (filhos até 6 meses de idade)– R$ 889,37
        </span>
        <span class="d-block"
          >Babá (filhos após 6 meses de idade) – R$ 479,28
        </span>
      </p>
      <div class="col-md-12">
        <div *ngFor="let child of listChildren; let i = index">
          <div class="card mt-12">
            <div class="card-title">
              <label
                >{{ child.kinship }} {{ child.order }} - {{ child.name }}</label
              >
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <label class="text-default-label baba-text"
                    >Necessita de auxílio creche / babá</label
                  >
                  <div class="custom-checkbox-container">
                    <label class="custom-checkbox">
                      <input
                        type="radio"
                        [name]="'dependent' + i"
                        [(ngModel)]="child.requiresCrecheAssistance"
                        [value]="false"
                      />
                      <span class="checkmark">
                        <span class="checkmark-inner"></span>
                      </span>
                      Não
                    </label>

                    <label class="custom-checkbox">
                      <input
                        type="radio"
                        [name]="'dependent' + i"
                        [(ngModel)]="child.requiresCrecheAssistance"
                        [value]="true"
                      />
                      <span class="checkmark">
                        <span class="checkmark-inner"></span>
                      </span>
                      Sim
                    </label>
                  </div>
                </div>
              </div>
              <!-- <div class="col-6">
                <label class="text-default-label text-reem"
                  >Reembolso até</label
                >
                <p class="text-default-label price-reem">
                  R${{ child.refundValue }}
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <app-upload-file
          *ngIf="listChildren?.length"
          (selectFileWithExtension)="getDocumentFile($event)"
          [isExhibition]="isExhibition"
          [selectedImage]="documentFile && 'data:application/' + this.extension + ';base64,' + documentFile"
        >
        </app-upload-file>
      </div>
      <div class="col-md-12 row justify-content-end m-0 p-0" *ngIf="this.extension">
        <button
          class="col-md-3 btn btn-outline-secondary text-uppercase mt-1"
          type="button"
          (click)="downloadFile()"
        >
          Baixar o documento
        </button>
      </div>
      <div class="row mt-4">
        <div class="col-md-3 col-12 col-sale">
          <label class="text-valuedesc">Valor </label>
          {{ benefit?.items[0]?.cost | currency : "BRL" : "symbol" }}
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-2 col-12 col-sale">
          <label class="text-sale">Desconto do titular</label>
          <span>{{ benefit?.items[0]?.existDiscountToHolder }}</span>
        </div>
        <div class="col-md-6 col-12 col-value">
          <label class="text-valuedesc">Valor ou % do desconto</label>
          <span>R$ {{ benefit?.items[0]?.holderDiscount ?? 0 }}</span>
        </div>
        <div class="col-md-4 col-12 col-benefic">
          <label class="text-benefictransp">Deseja aderir ao benefício</label>
          <div class="custom-checkbox-container">
            <label class="custom-checkbox">
              <input
                type="radio"
                name="selectedChoice"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="false"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Não
            </label>

            <label class="custom-checkbox">
              <input
                type="radio"
                name="selectedChoice"
                [(ngModel)]="benefit.wantTheBenefit"
                [value]="true"
              />
              <span class="checkmark">
                <span class="checkmark-inner"></span>
              </span>
              Sim
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
