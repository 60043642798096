import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent {
  @Input() columnLabels: string[];
  @Input() data: any[];
  @Input() page: number;
  @Input() totalItems: number;
  @Input() canEdit = true;
  @Input() canDelete = true;
  @Input() emptyMessage = "Não há dados";
  @Input() totalPerPage = 10;
  @Input() showDefaultActionButton = true;

  @ContentChild("items") items!: TemplateRef<any>;
  @ContentChild("header") header!: TemplateRef<any>;

  @Output() removeItemEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateItemEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChangeEvent: EventEmitter<number> = new EventEmitter<number>();

  update(item) {
    this.updateItemEvent.emit(item);
  }

  remove(item) {
    this.removeItemEvent.emit(item);
  }
}
