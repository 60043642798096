<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex align-items-center">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a class="logo logo-dark">
          <span class="logo-sm">
            <ng-container *ngIf="tenantStore.style$ | async as style">
              <img [src]="style.LogoTop" alt="" height="40" />
            </ng-container>

            <ng-container *ngIf="tenantStore.style$ | async as style">
              <img [src]="style.LogoTop" alt="logo-top" *ngIf="style.LogoTop" />
            </ng-container>
          </span>
          <span class="logo-lg">
            <!-- <img src="assets/images/meurh360.png" alt="" height="17" /> -->
            <img alt="logo-top" class="logo-top" height="17" />
          </span>
        </a>

        <a class="logo logo-light d-flex" [routerLink]="['dashboard']">
          <span class="logo-sm">
            <!-- <img
              src="assets/images/rh360-white-logo2.png"
              alt=""
              height="22"
            /> -->
            <ng-container *ngIf="tenantStore.style$ | async as style">
              <img [src]="style.LogoTop" alt="logo-top" height="22" />
            </ng-container>
          </span>
          <span class="logo-lg">
            <!-- <img
              src="assets/images/rh360-white-logo2.png"
              alt="logo-meurh360"
              height="50"
            /> -->
            <ng-container *ngIf="tenantStore.style$ | async as style">
              <img
                [src]="style.LogoTop"
                alt="logo-top"
                [height]="style.LogoTopHeight || 40"
              />
            </ng-container>
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          ngbDropdownToggle
        >
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">{{
            totalNotifications | async
          }}</span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          ngbDropdownMenu
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notificações</h6>
              </div>
              <!-- <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{
                  "HEADER.NOTIFICATIONS.VIEW_ALL" | translate
                }}</a>
              </div> -->
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px">
            <ng-container *ngIf="canManageEmployeeProfile">
              <ng-container *ngFor="let item of waitingHRApproval$ | async">
                <a [routerLink]="item.url" class="text-reset notification-item">
                  <div class="d-flex">
                    <div class="m-1">
                      <app-avatar
                        [tooltipText]="item.name"
                        [nickName]="item.nickname"
                      >
                      </app-avatar>
                    </div>
                    <div class="flex-grow-1">
                      <h6 class="mb-1">Atualização cadastral</h6>
                      <div class="font-size-12 text-muted">
                        <p class="mb-1">
                          O colaborador <strong>{{ item.name }}</strong> enviou
                          o perfil para validação do RH
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </ng-container>
            </ng-container>
          </ngx-simplebar>
          <!-- <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">{{
                "HEADER.NOTIFICATIONS.LOAD_MORE" | translate
              }}</span>
            </a>
          </div> -->
        </div>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item d-flex align-items-center"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <app-avatar [tooltipText]="username" [nickName]="nickname">
          </app-avatar>
          <span class="d-none d-xl-inline-block ms-1">{{ username }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
