<div class="modal-overlay" *ngIf="modalOpen">
  <div class="modal-content" [@modalAnimation]>
    <div style="position: relative">
      <button
        class="btn btn-light"
        style="position: absolute; right: 0"
        (click)="closeModal()"
      >
        &times;
      </button>
    </div>
    <div class="avatar-sm mx-auto mb-4 mt-3" *ngIf="icon">
      <div class="avatar-title bg-white rounded-circle h1" [ngClass]="icon">
        <i class="mdi mdi-alert-circle-outline"></i>
      </div>
    </div>
    <div class="text-center" *ngIf="title">
      <h2 class="m-0">{{ title }}</h2>
    </div>

    <ng-content></ng-content>
  </div>
</div>
