import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  selector: "[appDragAndDrop]",
})
export class DragAndDropDirective {
  fileOver: boolean;
  @Output() fileDropped = new EventEmitter<unknown>();



  @HostListener("drop", ["$event"]) public onDrop(evt) {
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
      console.log("dropped file");
    }
  }

  @HostListener("body:dragover", ["$event"])
  onBodyDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("body:drop", ["$event"])
  onBodyDrop(event: DragEvent) {
    event.preventDefault();

    console.log("body drop")
  }

}
