<app-accordion [title]="'Carteira de identidade (RG)'">
  <div class="d-flex flex-column">
    <app-form-card>
      <form [formGroup]="idCardForm">
        <div class="row my-5">
          <div class="col-md-3">
            <label class="text-default-label">Data de expedição*</label>
            <input type="date"
                   class="form-control"
                   placeholder="Digite aqui a informação"
                   formControlName="issueDate">

            <small class="text-danger"
                   *ngIf="isInvalidField('issueDate')">Por favor, informe a data de expedição.</small>
          </div>

          <div class="col-md-3">
            <label class="text-default-label">Orgão emissor*</label>
            <input type="text"
                   class="form-control"
                   placeholder="Digite aqui a informação"
                   formControlName="issuingAgency">

            <small class="text-danger"
                   *ngIf="isInvalidField('issuingAgency')">Por favor, informe o órgão emissor.</small>
          </div>

          <div class="col-md-6">
            <label class="text-default-label">UF emissor*</label>
            <ng-select  [notFoundText]="'UF não encontrado'" formControlName="countryId">
              <ng-option *ngFor="let uf of ufs"
                      [value]="uf.id">{{ uf.name }}</ng-option>
            </ng-select>

            <small class="text-danger"
                   *ngIf="isInvalidField('countryId')">Por favor, selecione a UF emissor.</small>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-6">
          <label class="text-default-label">Carregar frente do RG*</label>
          <app-upload-file 
            [isExhibition]="isExhibition"
            [selectedImage]="frontDocumentFile && 'data:application/' + frontDocumentFileExtension + ';base64,'+frontDocumentFile"
            (selectFileWithExtension)="getFrontDocumentFile($event)">
          </app-upload-file>

          <small class="text-danger"
                 *ngIf="!frontDocumentFile">Por favor, carregue a frente do RG.</small>
        </div>

        <div class="col-md-6">
          <label class="text-default-label">Carregar verso do RG*</label>
          <app-upload-file 
            [isExhibition]="isExhibition"
            [selectedImage]="backDocumentFile && 'data:application/' + backDocumentFileExtension + ';base64,'+backDocumentFile"
            (selectFileWithExtension)="getBackDocumentFile($event)">
          </app-upload-file>

          <small class="text-danger"
                 *ngIf="!backDocumentFile">Por favor, carregue o verso do RG.</small>
        </div>
      </div>
      <div class="d-flex w-100 justify-content-end align-itens-center py-4"
           *ngIf="!isExhibition">
        <button class="btn btn-outline-primary text-uppercase m1"
                (click)="idFormValueEmit()">salvar</button>

        <button class="btn btn-outline-secondary text-uppercase m-1" type="button" (click)="downloadFile()">Baixar o documento</button>
      </div>
    </app-form-card>
  </div>
</app-accordion>
