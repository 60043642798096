import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthenticationService } from "../services/auth.service";
import { TenantStore } from "../services/Stores/tenant.store";
import { catchError, of, switchMap } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private tenantStore: TenantStore
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getMe().pipe(
      switchMap((user) => {
        this.authService.saveCurrentUser(user);
        this.tenantStore.applyStyle();
        this.tenantStore.getTermsAndConditions();

        if (
          this.authService.IsHiringProcess &&
          !this.authService.isFirstAccess
        ) {
          this.router.navigate(["portal"]);
          return of(false);
        }

        return of(true);
      }),
      catchError(() => {
        this.router.navigate(["/account/login"], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }
}
