<div class="row" *ngIf="assessment?.employee?.id">
  <div class="col-md-7 performance-assessment__header">
    <div class="performance-assessment__filter">
      <button
        *ngIf="hasActionButtons"
        [disabled]="assessment?.employee.previousPageAssessmentId === 0"
        (click)="goTo(assessment?.employee.previousPageAssessmentId)"
      >
        <i class="mdi mdi-arrow-left"></i>
      </button>
      <span
        class="performance-assessment__filter-date"
        [ngStyle]="{
          opacity:
            assessment?.employee?.nextPageAssessmentId === 0 &&
            assessment?.employee?.previousPageAssessmentId === 0
              ? '0.5'
              : '1'
        }"
        >Ciclo {{ assessment?.employee?.cycleId }}</span
      >
      <button
        *ngIf="hasActionButtons"
        [disabled]="
          assessment?.employee?.nextPageAssessmentId === 0 ||
          !assessment?.employee?.nextPageAssessmentId
        "
        (click)="goTo(assessment?.employee?.nextPageAssessmentId)"
      >
        <i class="mdi mdi-arrow-right"></i>
      </button>
    </div>
    <div class="performance-assessment__status">
      <div
        class="d-flex align-items-center"
        *ngIf="assessment?.employee?.statusAssessmentId === 1; else concludedBy"
      >
        <span
          class="performance-assessment__status performance-assessment__status--open"
        ></span
        >Aberto
      </div>

      <ng-template #concludedBy
        >Concluido por:
        <a class="text-decoration-none">{{
          assessment?.employee?.finishedBy || "-"
        }}</a>
        em
        {{ assessment?.employee?.finishedAt | date : "shortDate" }}</ng-template
      >
    </div>
  </div>
  <div class="col-md-5 d-flex justify-content-end">
    <div class="d-flex align-items-center">
      <span class="performance-assessment__amount">
        <span class="performance-assessment__amount-label">Soma</span>
        <span class="performance-assessment__amount-value">
          {{ assessment?.employee?.amountScore }}/{{
            assessment?.employee?.totalAmount
          }}</span
        >
      </span>
      <span class="performance-assessment__amount">
        <span class="performance-assessment__amount-label">Média</span>
        <span class="performance-assessment__amount-value"
          >{{ assessment?.employee?.total }}/{{
            assessment?.employee?.maxAverageScore
          }}</span
        >
      </span>
      <span class="performance-assessment__amount-percentage">
        {{ assessment?.employee?.assessmentPercentage }}%
      </span>
    </div>
    <div
      class="d-flex align-items-center hide-on-print"
      *ngIf="hasActionButtons"
    >
      <app-more-options-button [secondBgColor]="true">
        <ng-template let-items #items>
          <div class="col-sm-12 row">
            <button
              ngbDropdownItem
              class="btn hidden-on-print"
              (click)="print()"
            >
              Baixar PDF
            </button>
          </div>
        </ng-template>
      </app-more-options-button>
    </div>
  </div>
</div>
<hr />

<div
  class="row d-flex align-items-center performance-assessment__chart justify-content-center"
  *ngIf="
    isFinalizedAssessment || isCalibratedAssessment || assessment?.hasChart
  "
>
  <div
    class="d-flex align-items-center justify-content-end z-2"
    *ngIf="assessment?.isNinebox"
  >
    <button
      class="btn"
      (click)="getAssessmentChart('spider')"
      *ngIf="assessment?.hasNineboxChart"
    >
      <img src="assets/images/spider-chart.svg" alt="" />
    </button>

    <button
      class="btn"
      (click)="getAssessmentChart('ninebox')"
      *ngIf="!assessment?.hasNineboxChart"
    >
      <img src="assets/images/carbon_apps.svg" alt="" />
    </button>
  </div>

  <ng-container
    *ngIf="assessment?.nineboxChartDataSet && assessment?.hasNineboxChart"
  >
    <app-ninebox-chart
      [chartDataSet]="assessment?.nineboxChartDataSet"
    ></app-ninebox-chart>
  </ng-container>

  <ng-container
    *ngIf="assessment?.spiderChartDataSet && !assessment?.hasNineboxChart"
  >
    <app-spider-chart
      *ngIf="assessment?.spiderChartDataSet?.competences.length > 2"
      [chartDataSet]="assessment?.spiderChartDataSet"
    ></app-spider-chart>

    <div
      class="row performance-assessment__chart-feedback-message"
      *ngIf="assessment?.spiderChartDataSet?.competences.length <= 2"
    >
      <p class="text-center">
        O gráfico aranha só está disponível para colaboradores que possuam três
        ou mais competências.
      </p>
    </div>
  </ng-container>

  <hr class="mt-4" />
</div>
