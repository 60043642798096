<nav aria-label="breadcrumb">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <h3 class="text-uppercase">{{ title }}</h3>
    </div>

    <div>
      <ol class="breadcrumb">
        <li *ngFor="let route of breadcrumbRoutes; let last = last" class="breadcrumb-item" [ngClass]="{active: last}">
          <a *ngIf="!last" [routerLink]="route.path">{{ route.label }}</a>
          <span *ngIf="last">{{ route.label }}</span>
        </li>
      </ol>
    </div>
  </div>
</nav>
