import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-create-service',
  templateUrl: './create-service.component.html',
  styleUrls: ['./create-service.component.scss']
})
export class CreateServiceComponent {
  justification: string;

  // Altere para listas
  documentFiles: any[] = [];
  extensions: string[] = [];
  

  @Input() enabledOnChangeEvent = false;
  @Input() mandatoryDocumentItem;
  @Input() genericDocumentValues: any[] = [];
  @Output() fileChange: EventEmitter<any[]> = new EventEmitter();
  @Input() isExhibition = false;
  
  private createGenericDocumentPayload(index: number) {
    return {
      documentFile: this.documentFiles[index],
      documentId: this.mandatoryDocumentItem?.id,
      documentExtension: this.extensions[index],
    };
  }

  addUploadComponent() {
    this.documentFiles.push(null);
    this.extensions.push('');
  }


  getDocumentFile({ base64, extension }) {
    this.documentFiles.push(base64);
    this.extensions.push(extension);
  }
  
  removeFile(index: number) {
    this.documentFiles.splice(index, 1);
    this.extensions.splice(index, 1);
  }
  
}
