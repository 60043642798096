import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, tap } from "rxjs";
import { MenuItem } from "src/app/layouts/sidebar/menu.model";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class MenuService {
  private menuUrl = `${environment.url}/v1/menus`;
  private menuSubject: BehaviorSubject<MenuItem[]> = new BehaviorSubject<
    MenuItem[]
  >(null);

  menus$: Observable<MenuItem[]> = this.menuSubject.asObservable();

  constructor(private http: HttpClient) {}

  public getAll(): Observable<MenuItem[]> {
    this.http
      .get<MenuItem[]>(this.menuUrl)
      .pipe(
        map((menus) => menus.map((menu) => ({ ...menu, collapsed: true }))),
        map((menus) => {
          const roots = menus?.filter((m) => m.parentId === 0);

          this.buildMenus(roots, menus);

          return roots;
        }),
        tap((data) => this.menuSubject.next(data))
      )
      .subscribe();

    return this.menus$;
  }

  private buildMenus(roots: MenuItem[], menus: MenuItem[]) {
    roots?.forEach((root) => {
      const childrens = menus.filter((m) => m.parentId === root.id);
      this.buildMenus(childrens, menus);
      root.subItems = childrens;
    });
  }
}
