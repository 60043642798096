import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-popover-list',
  templateUrl: './list-popover-list.component.html',
  styleUrls: ['./list-popover-list.component.css']
})


export class ListPopoverListComponent implements OnInit {
  // @Input() popoverModel: any[] = [{
  //   popoverTitleItem: "Membros", // Titulo popover
  //   item: null, // Item raiz $
  //   itemList: [], // Listagem a ser interada.
  //   propLink: "informe uma prop que terá a saída boolean",
  //   propShow: "informe uma prop que deseja apresentar de 1 item da listagem itemList",
  //   valueHrefLink: "javascript:void(0);",
  //   valueLink: "informe um valor para o link valueHrefLink",
  //   valueHrefLinkMoreItems: "javascript:void(0);",
  //   valueMoreItemsPlural: "informe o texto para limitar a apresentação da listagem termo no plural",
  //   valueMoreItemsSingle: "informe o texto para limitar a apresentação da listagem termo no singular"
  // }];
  @Input() popoverModel: any[] = [];
  @Input() closedOnmouseout: boolean = true;
  @Input() maxShowItemsPopover: number = environment.maxShowItemsPopover;

  items = 0;
  constructor() { }
  ngOnInit(): void {
    this.items = this.popoverModel.filter(item => item.itemList.length > 0).reduce((sum, current) => sum + current.itemList.length, 0);
  }

  public environment = environment;
  public showThisQuantity(itemList): any[] {
    const items: any[] = itemList.length > this.environment.maxShowItemsPopover ? itemList.slice(0, this.environment.maxShowItemsPopover) : itemList;
    return items;
  }
  openPopover(popover: NgbPopover) {
    if (!popover.isOpen())
      popover.open();
  }
  closePopover(popover: NgbPopover) {
    if (popover.isOpen())
      popover.close();
  }
  closeForcePopover(popover: NgbPopover) {
    if (popover.isOpen())
      popover.close();
  }

  public getNestedProperty(obj: any, propertyPath: string) {
    const properties = propertyPath.split('.');
    let currentObject = obj;
    for (let property of properties) {
      if (currentObject.hasOwnProperty(property)) {
        currentObject = currentObject[property];
      } else {
        console.log("Property not find");
        return undefined;
      }
    }
    return currentObject;
  }
}
