import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { WizardStep } from "src/app/core/models/wizard-step.models";

@Component({
  selector: "app-wizard-steps",
  templateUrl: "./wizard-steps.component.html",
  styleUrls: ["./wizard-steps.component.scss"],
})
export class WizardStepsComponent implements OnChanges {

  @Input() steps: WizardStep[] = [];
  @Input() currentStepIndex: number;
  @Input() allowNavigation: boolean;
  @Output() stepChangeIndex: EventEmitter<number> = new EventEmitter<number>();

  currentStep: WizardStep;

  ngOnChanges(changes: SimpleChanges): void {
    const { currentStepIndex } = changes;

    if (currentStepIndex.previousValue !== currentStepIndex.currentValue) {
      this.changeStep(currentStepIndex.currentValue);
    }
  }

  selectedStep(i) {
    if (!this.allowNavigation) return;

    this.changeStep(i);
    this.stepChangeIndex.emit(i);
  }

  private changeStep(i: number) {
    this.steps.forEach((item) => (item.selected = false));
    if (this.steps[i]) {
      this.steps[i].selected = true;
    }
  }
}
