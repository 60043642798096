import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "kbToMb",
})
export class KbToMbPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null || isNaN(value)) {
      return "0 MB";
    }

    const mbValue = value / (1024 * 1024); // Convert KB to MB
    return `${mbValue.toFixed(2)} MB`;
  }
}
