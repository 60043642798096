<app-accordion [title]="'Título de eleitor'">
  <div class="d-flex flex-column">
    <app-form-card>
      <form [formGroup]="voterRegistrationForm">
        <div class="row my-5">
          <div class="col-md">
            <label for="" class="text-default-label">N° Inscrição / D.V*</label>
            <input
              type="text"
              class="form-control"
              formControlName="subscriptionNumber"
            />
            <small
              class="text-danger"
              *ngIf="isControlRequiredError('subscriptionNumber')"
            >
              O número de inscrição é obrigatório.
            </small>
            <small class="text-danger" *ngIf="isInscricaoInvalida()">
              O número de inscrição é inválido.
            </small>
          </div>
        </div>

        <div class="row my-5">
          <div class="col-md">
            <label class="text-default-label">Zona*</label>
            <input
              type="text"
              class="form-control"
              mask="999"
              formControlName="zone"
            />
            <small class="text-danger" *ngIf="isControlRequiredError('zone')">
              Por favor, digite a zona do eleitor.
            </small>
            <small class="text-danger" *ngIf="isControlPatternError('zone')">
              A zona deve ter exatamente 3 dígitos.
            </small>
          </div>
          <div class="col-md">
            <label class="text-default-label">Seção*</label>
            <input
              type="text"
              class="form-control"
              mask="0000"
              formControlName="section"
            />
            <small
              class="text-danger"
              *ngIf="isControlRequiredError('section')"
            >
              Por favor, digite a seção do eleitor.
            </small>
            <small class="text-danger" *ngIf="isControlPatternError('section')">
              A seção de votação deve ter exatamente 4 dígitos.
            </small>
          </div>
          <div class="col-md">
            <label class="text-default-label">UF*</label>
            <ng-select
              formControlName="countryId"
              (ngModelChange)="getCitiesByState()"
            >
              <ng-option *ngFor="let item of ufs" [value]="item.id">{{
                item.name
              }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="isInvalidField('countryId')">
              Por favor, selecione o estado.
            </small>
          </div>
          <div class="col-md">
            <label class="text-default-label">Munícipio*</label>
            <ng-select formControlName="cityId">
              <ng-option *ngFor="let citie of cities" [value]="citie.id">{{
                citie.name
              }}</ng-option>
            </ng-select>
            <small class="text-danger" *ngIf="isInvalidField('cityId')">
              Por favor, selecione o munícipio.
            </small>
          </div>
          <div class="col-md">
            <label class="text-default-label">Data de emissão*</label>
            <input
              type="date"
              class="form-control"
              formControlName="issueDate"
            />
            <small class="text-danger" *ngIf="isInvalidField('issueDate')">
              Por favor, selecione a data de emissão.
            </small>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-md-12">
          <label class="text-default-label">Carregar Foto*</label>
          <app-upload-file
            (selectFileWithExtension)="getPhoto($event)"
            [selectedImage]="documentFile && 'data:application/' + documentFileExtension + ';base64,' + documentFile"
            [isExhibition]="isExhibition"
          ></app-upload-file>
          <small class="text-danger" *ngIf="!documentFile">
            Por favor, carregue o título de eleitor.
          </small>
        </div>
      </div>

      <div class="d-flex w-100 justify-content-end align-itens-center py-4">
        <button
          class="btn btn-outline-primary text-uppercase m-1"
          *ngIf="!isExhibition"
          (click)="emitVoterRegistrationFormValue()"
        >
          salvar
        </button>

        <button
          class="btn btn-outline-secondary text-uppercase m-1"
          type="button"
          (click)="downloadFile()"
        >
          Baixar o documento
        </button>
      </div>
    </app-form-card>
  </div>
</app-accordion>
