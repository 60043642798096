import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

interface ITabItem {
  // Define the properties of TabItem here
  id: number;
  title: string;
  icon: string;
  code: string;
}

@Component({
  selector: "app-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"],
})
export class TabComponent {
  @Input() TabItems: ITabItem[];
  @Output() selectedTab: EventEmitter<string> = new EventEmitter<string>();
  @Input() active: number;

  selectTab = (tab: ITabItem) => {
    this.selectedTab.emit(tab.code);
  };
}
