import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-birth-marriage-certificate",
  templateUrl: "./birth-marriage-certificate.component.html",
  styleUrls: ["./birth-marriage-certificate.component.scss"],
})
export class BirthMarriageCertificateComponent implements OnChanges {
  birthCertificateDocument: any;
  birthCertificateDocumentExtension: any;

  @Input() isExhibition = false;
  @Input() birthCertificate;
  @Input() birthCertificateExtension;

  @Output() birthCertificateDocumentValue: EventEmitter<any> =
    new EventEmitter();
  base64Data: string;

  constructor(private utilsService: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const birthCertificate = changes.birthCertificate?.currentValue;
    const birthCertificateExtension = changes.birthCertificateExtension?.currentValue;

    if (birthCertificate) {
      this.birthCertificateDocument = `${birthCertificate}`;
      this.birthCertificateDocumentExtension = `${birthCertificateExtension}`;
    }
  }

  emitBirthMarriageCertificate() {
    if (!this.birthCertificateDocument) {
      return;
    }

    this.birthCertificateDocumentValue.emit({
      birthCertificateDocument: this.birthCertificateDocument,
      birthCertificateDocumentExtension: this.birthCertificateDocumentExtension,
    });
  }

  getBirthMarriageCertificate($event) {
    // if(!$event)return;
    const { extension, base64 } = $event;
    this.base64Data =`data:application/${extension};base64,${base64}`;
    this.birthCertificateDocument = base64;
    this.birthCertificateDocumentExtension = extension;
  }
  downloadFile() {
    this.utilsService.downloadFile(this.birthCertificateDocument,`certidao.${this.birthCertificateDocumentExtension}`);
  }
}
