import { Component, Input, OnInit } from "@angular/core";
import { Chart } from "chart.js/auto";
import { INineBoxDataSet } from "src/app/pages/performance-manage/models/assessment-ninebox-chart-response.interface";

@Component({
  selector: "app-ninebox-chart",
  templateUrl: "./ninebox-chart.component.html",
  styleUrls: ["./ninebox-chart.component.scss"],
})
export class NineboxChartComponent implements OnInit {
  @Input() chartDataSet: INineBoxDataSet;
  chart: any;

  private hoverLine = {
    id: "hoverLine",
    afterDatasetsDraw(chart: any) {
      const {
        ctx,
        tooltip,
        chartArea: { left, bottom },
      } = chart;

      if (tooltip._active.length > 0) {
        //cria as dashed lines no mouse hover da marcação
        const xCoor = tooltip._active[0].element.x;
        const yCoor = tooltip._active[0].element.y;

        ctx.strokeStyle = "#2979FF";
        ctx.lineWidth = 3;

        //cria linha do eixo Y até a marcação (linha horizontal)
        ctx.setLineDash([6, 3]);
        ctx.beginPath();
        ctx.moveTo(left, yCoor);
        ctx.lineTo(xCoor - 9, yCoor);
        ctx.stroke();

        //cria linha do eixo x até a marcação (linha vertical)
        ctx.beginPath();
        ctx.moveTo(xCoor, yCoor + 10);
        ctx.lineTo(xCoor, bottom);
        ctx.stroke();

        //reset
        ctx.setLineDash([]);
      }
    },
  };

  ngOnInit(): void {
    this.chart = this.fillChart(["Avaliação"]);
  }

  private fillChart(competences: string[]) {
    this.chart = new Chart("ninebox", {
      type: "scatter",
      data: {
        labels: competences,
        datasets: [
          {
            pointBackgroundColor: "#2979FF",
            pointRadius: 10,
            pointHoverRadius: 10,
            data: [
              {
                x: this.chartDataSet.xValue,
                y: this.chartDataSet.yValue,
              },
            ],
            borderWidth: 10,
            label: "Avaliação atual",
            backgroundColor: "rgba(104,204,70, 0.50)",
          },
        ],
      },
      options: {
        clip: false,
        responsive: true,
        aspectRatio: 1,
        scales: {
          //configuração do eixo X e do Y para construir o gráfico com nove quadrantes
          x: {
            min: Math.min(...this.chartDataSet.xAxisLabels),
            // valor referente ao valor maximo para eixo,
            max: Math.max(...this.chartDataSet.xAxisLabels),
            afterTickToLabelConversion: (ctx) => {
              //cria linhas do eixo x
              ctx.ticks = [];

              this.chartDataSet.xAxisLabels.forEach((value: number) => {
                ctx.ticks.push({
                  value: value,
                  label: value.toString(),
                });
              });
            },
            grid: {
              drawTicks: false,
              color: "#888",
            },
          },
          y: {
            min: Math.min(...this.chartDataSet.xAxisLabels),
            // valor referente ao valor maximo para eixo,
            max: Math.max(...this.chartDataSet.xAxisLabels),

            //cria linhas do eixo y
            afterTickToLabelConversion: (ctx) => {
              ctx.ticks = [];

              this.chartDataSet.xAxisLabels.forEach(
                (x: number, index: number) => {
                  ctx.ticks.push({
                    value: x,
                    label: this.chartDataSet.yAxisLabels[index],
                  });
                }
              );
            },

            grid: {
              drawTicks: false,
              color: "#888",
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: "Resultado 9box",
            color: "#3A3A44",
            font: {
              family: "Poppins",
              size: 16,
              weight: 500,
            },
            padding: {
              bottom: 50,
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
      plugins: [this.hoverLine],
    });
  }

  getEvolutionBadgeClass(evolution: number): string {
    if (evolution > 0) return "mdi-arrow-up-bold-circle-outline";
    if (evolution < 0) return "mdi-arrow-down-bold-circle-outline";

    return "";
  }
}
