import { Component, EventEmitter, Input, Output } from "@angular/core";

interface IWorkedDay {
  value: number;
  name: string;
  checked: boolean;
}

@Component({
  selector: "app-worked-days",
  templateUrl: "./worked-days.component.html",
  styleUrls: ["./worked-days.component.scss"],
})
export class WorkedDaysComponent {
  @Input() items: IWorkedDay[] = [];
  @Output() selectedItem: EventEmitter<IWorkedDay[]> = new EventEmitter();

  changeItem(isChecked: boolean, item: IWorkedDay) {
    item.checked = isChecked;
    this.sendSelectedItems();
  }

  sendSelectedItems() {
    const items = this.items.filter((i) => i.checked);
    this.selectedItem.emit(items);
  }
}
