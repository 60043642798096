import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PortalAdmissionRoutingModule } from "./portal-admission-routing.module";
import { PortalAdmissionComponent } from "./portal-admission.component";
import { DataAccessComponent } from "./data-access/data-access.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { UIModule } from "../shared/ui/ui.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PersonalBankDetailsComponent } from "./personal-bank-details/personal-bank-details.component";
import { NgxMaskModule } from "ngx-mask";
import { DependentsComponent } from "./dependents/dependents.component";
import { IdCardComponent } from "./documentation-step/id-card/id-card.component";
import { CpfComponent } from "./documentation-step/cpf/cpf.component";
import { WorkCardComponent } from "./documentation-step/work-card/work-card.component";
import { BirthMarriageCertificateComponent } from "./documentation-step/birth-marriage-certificate/birth-marriage-certificate.component";
import { VoterRegistrationComponent } from "./documentation-step/voter-registration/voter-registration.component";
import { ProofResidenceComponent } from "./documentation-step/proof-residence/proof-residence.component";
import { DocumentationStepComponent } from "./documentation-step/documentation-step.component";
import { BenefitsComponent } from "./benefits/benefits.component";
import { AcademicEducationComponent } from "./documentation-step/academic-education/academic-education.component";
import { SharedModule } from "../shared/shared.module";
import { GenericDocumentComponent } from "./documentation-step/generic-document/generic-document.component";
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PersonalBankDetailsComponentMetlife } from "./metlife/personal-bank-details-metlife/personal-bank-details-metlife.component";
import { DependentsComponentMetlife } from "./metlife/dependents-metlife/dependents-metlife.component";
import { HealthPlanAccordionComponent } from "./metlife/health-plan-accordion/health-plan-accordion.component";
import { LifeInsuranceAccordionComponent } from "./metlife/life-insurance-accordion/life-insurance-accordion.component";
import { PharmacyAccordionComponent } from "./metlife/pharmacy-accordion/pharmacy-accordion.component";
import { FuneralAssistanceAccordionComponent } from "./metlife/funeral-assistance-accordion/funeral-assistance-accordion.component";
import { DaycareAccordionComponent } from "./metlife/daycare-accordion/daycare-accordion.component";
import { ParkingAccordionComponent } from "./metlife/parking-accordion/parking-accordion.component";
import { MobilityBenefitAccordionComponent } from "./metlife/mobility-benefit-accordion/mobility-benefit-accordion.component";
import { FuelVoucherAccordionComponent } from "./metlife/fuel-voucher-accordion/fuel-voucher-accordion.component";
import { MealandFoodVoucherAccordionComponent } from "./metlife/meal-and-food-voucher-accordion/meal-and-food-voucher-accordion.component";
import { DentalPlanAccordionComponent } from "./metlife/dental-plan-accordion/dental-plan-accordion.component";
import { VoucherTransportationAccordionComponent } from "./metlife/voucher-transportation-accordion/voucher-transportation-accordion.component";
import { RouteComponent } from "./metlife/voucher-transportation-accordion/route/route.component";
import { MealVoucherAccordionComponent } from "./metlife/meal-voucher-accordion/meal-voucher-accordion.component";
import { FoodVoucherAccordionComponent } from "./metlife/food-voucher-accordion/food-voucher-accordion.component";
import { GymVoucherAccordionComponent } from "./metlife/gym-voucher-accordion/gym-voucher-accordion.component";
import { RefectoryVoucherComponent } from "./metlife/refectory-voucher/refectory-voucher.component";
import { PacAccordionComponent } from "./pac-accordion/pac-accordion.component";
import { PerformanceAssessmentComponent } from "../pages/collaborator-gestation/components/performance-assessment/performance-assessment.component";

@NgModule({
  declarations: [
    PortalAdmissionComponent,
    DataAccessComponent,
    NavbarComponent,
    PersonalBankDetailsComponent,
    DocumentationStepComponent,
    IdCardComponent,
    CpfComponent,
    WorkCardComponent,
    BirthMarriageCertificateComponent,
    VoterRegistrationComponent,
    ProofResidenceComponent,
    DependentsComponent,
    BenefitsComponent,
    AcademicEducationComponent,
    GenericDocumentComponent,
    PersonalBankDetailsComponentMetlife,
    DependentsComponentMetlife,
    HealthPlanAccordionComponent,
    LifeInsuranceAccordionComponent,
    PharmacyAccordionComponent,
    FuneralAssistanceAccordionComponent,
    DaycareAccordionComponent,
    FuelVoucherAccordionComponent,
    ParkingAccordionComponent,
    MobilityBenefitAccordionComponent,
    MealandFoodVoucherAccordionComponent,
    DentalPlanAccordionComponent,
    VoucherTransportationAccordionComponent,
    RouteComponent,
    MealVoucherAccordionComponent,
    FoodVoucherAccordionComponent,
    GymVoucherAccordionComponent,
    RefectoryVoucherComponent,
    PacAccordionComponent,
    PerformanceAssessmentComponent,
  ],
  exports: [
    PortalAdmissionComponent,
    DataAccessComponent,
    NavbarComponent,
    PersonalBankDetailsComponent,
    DocumentationStepComponent,
    IdCardComponent,
    CpfComponent,
    WorkCardComponent,
    BirthMarriageCertificateComponent,
    VoterRegistrationComponent,
    ProofResidenceComponent,
    DependentsComponent,
    HealthPlanAccordionComponent,
    BenefitsComponent,
    GenericDocumentComponent,
    AcademicEducationComponent,
    PersonalBankDetailsComponentMetlife,
    LifeInsuranceAccordionComponent,
    PharmacyAccordionComponent,
    FuneralAssistanceAccordionComponent,
    DaycareAccordionComponent,
    ParkingAccordionComponent,
    MobilityBenefitAccordionComponent,
    MealandFoodVoucherAccordionComponent,
    VoucherTransportationAccordionComponent,
    DependentsComponentMetlife,
    GymVoucherAccordionComponent,
    RefectoryVoucherComponent,
    PerformanceAssessmentComponent,
  ],
  imports: [
    CommonModule,
    PortalAdmissionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    UIModule,
    NgbModule,
    SharedModule,
    NgbDropdownModule,
    NgxMaskModule.forRoot(),
  ],
})
export class PortalAdmissionModule {}
