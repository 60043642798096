import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LanguageModel } from "../models/language.model";

@Injectable({ providedIn: "root" })
export class LanguageService {
  public languages: string[] = ["en", "es", "de", "it", "ru"];
  private languageUrl = `${environment.url}/v1/languages`

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check("lang")) {
      browserLang = this.cookieService.get("lang");
    } else {
      this.setLanguage("en");
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru/) ? browserLang : "en");
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.cookieService.set("lang", lang);
  }

  public getAll():Observable<LanguageModel[]>{
    return this.http.get<LanguageModel[]>(this.languageUrl)
  }
}
