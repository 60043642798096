import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, shareReplay } from "rxjs";
import { environment } from "src/environments/environment";
import { GetAreaModel } from "../models/get-area.model";
import { ICompetenceResponse } from "src/app/shared/interfaces/competences.interface";

@Injectable({
  providedIn: "root",
})
export class CompetenceService {
  competenceUrl = `${environment.url}/v1/competence`;

  constructor(private http: HttpClient) { }

  create(payload: {
    name: string;
    description: string;
  }): Observable<any> {
    return this.http.post(this.competenceUrl, payload);
  }

  update(payload: {
    id: number;
    name: string;
    description: string
  }): Observable<any> {
    return this.http.put(this.competenceUrl, payload);
  }

  get(id: number): Observable<any> {
    return this.http.get(`${this.competenceUrl}/${id}`).pipe(shareReplay());
  }

  getAll(query: {
    currentPage: number;
    totalPerPage: number;
    hiddenItems: boolean;
  }): Observable<ICompetenceResponse> {
    return this.http.get<ICompetenceResponse>(this.competenceUrl, { params: { ...query } });
  }

  getAllSearch(query: {
    currentPage: number;
    totalPerPage: number;
    name: string;
  }): Observable<any> {
    return this.http.get(`${this.competenceUrl}/search`, { params: { ...query } });
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.competenceUrl}/${id}`).pipe(shareReplay());
  }

  deleteAll(listId: number[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ListId: listId }
    };
    return this.http.delete(this.competenceUrl, options).pipe(shareReplay());
  }

  copy(listId: number[]): Observable<any> {
    return this.http.post(`${this.competenceUrl}/copy`, { ListId: listId }).pipe(shareReplay());
  }

  addSkillsAndAttitudes(payload: any): Observable<any> {
    return this.http.post(`${this.competenceUrl}/attributes-skills`, payload).pipe(shareReplay());
  }

  updateAddSkillsAndAttitudes(payload: any): Observable<any> {
    return this.http.put(`${this.competenceUrl}/attributes-skills`, payload).pipe(shareReplay());
  }
}
