<app-accordion [title]="'Formação Acadêmica'">
  <form [formGroup]="form">
    <div formArrayName="educations">
      <app-form-card *ngFor="let item of educations.controls; let i = index" class="form-group">
        <div [formGroupName]="i" class="d-flex flex-column">
          <div class="row">
            <div class="col-md-4 my-3">
              <label class="text-default-label">Instituição de Ensino</label>
              <input
                type="text"
                class="form-control"
                formControlName="educationInstitutionName"
                [ngClass]="{
                  'is-invalid':
                    getEducationForm(i).educationInstitutionName.errors &&
                    getEducationForm(i).educationInstitutionName.dirty
                }"
              />

              <div
                class="invalid-feedback"
                *ngIf="
                  getEducationForm(i).educationInstitutionName.invalid &&
                  getEducationForm(i).educationInstitutionName.errors
                "
              >
                <span>Instituição de ensino é obrigatório</span>
              </div>
            </div>

            <div class="col-md-4 my-3">
              <label class="text-default-label">Nome do Curso</label>
              <input
                type="text"
                class="form-control"
                formControlName="courseName"
                [ngClass]="{
                  'is-invalid':
                    getEducationForm(i).courseName.errors &&
                    getEducationForm(i).courseName.dirty
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getEducationForm(i).courseName.invalid &&
                  getEducationForm(i).courseName.errors
                "
              >
                <span>Instituição de ensino é obrigatório</span>
              </div>
            </div>

            <div class="col-md-4 my-3">
              <label class="text-default-label">Data de conclusão</label>
              <input
                type="date"
                class="form-control"
                formControlName="conclusionDate"
                [ngClass]="{
                  'is-invalid':
                    getEducationForm(i).conclusionDate.errors &&
                    getEducationForm(i).conclusionDate.dirty
                }"
              />
              <div
                class="invalid-feedback"
                *ngIf="
                  getEducationForm(i).conclusionDate.invalid &&
                  getEducationForm(i).conclusionDate.errors
                "
              >
                <span>data de conclusão é obrigatório</span>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="my-3 col-md-12 row justify-content-between">
              <label class="text-default-label">Documento (upload)</label>
              <app-upload-file
                [isExhibition]="isExhibition"
                [selectedImage]="documentList[i]?.doc && 'data:application/' + documentList[i]?.docExtension + ';base64,' + documentList[i]?.doc"
                (selectFileWithExtension)="getDocument(i, $event)"
              >
              </app-upload-file>
            </div>
            <div class="my-3 col-12 d-flex justify-content-between">
              <button
                class="btn btn-secondary m-1 col-sm-12 ng-star-inserted col-md-2 col-lg-2"
                *ngIf="!isExhibition"
                (click)="removeEducation(i)"
              >
                REMOVER
              </button>

              <div
                class="col-md-9 col-lg-9 row col-sm-12 m-1 justify-content-end p-0"
              >
                <button
                  class="btn btn-outline-primary col-sm-12 col-md-2 m-1"
                  (click)="saveForm(i)"
                >
                  SALVAR
                </button>

                <button
                  class="btn btn-outline-secondary text-uppercase col-sm-12 m-1 col-md-5"
                  type="button"
                  (click)="downloadFile(i)"
                >
                  Baixar o documento
                </button>
              </div>
            </div>
          </div>
        </div>
      </app-form-card>
    </div>

    <div class="w-100 d-flex justify-content-between" *ngIf="!isExhibition">
      <button class="btn btn-outline-primary" (click)="addEducation()">
        Adicionar formação Acadêmica
      </button>
    </div>
  </form>
</app-accordion>
