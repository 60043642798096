import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { BenefitsService } from "src/app/core/services/portal-admission/benefits.service";
import { Categories } from "../../../core/models/categories.enum";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-daycare-accordion",
  templateUrl: "./daycare-accordion.component.html",
  styleUrls: ["./daycare-accordion.component.scss"],
})
export class DaycareAccordionComponent {
  selectedChoiceAux: string;
  selectedChoiceEigth: string;
  selectedChoiceOnceAux: string;
  documentFile: any;
  extension: any;
  listChildren: any[] = [];
  @Input() enabledOnChangeEvent = false;
  @Input() mandatoryDocumentItem;
  @Input() genericDocumentValue;
  @Input() isExhibition = false;
  @Input() benefits;
  @Input() dependentsBenefits = [];
  @Input() showMoreOptionsButton = true;
  @Input() showRemoveAll = true;
  @Output() fileChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private benefitsService: BenefitsService,
    private authService: AuthenticationService,
    private utilsService: UtilsService
  ) {}

  private createGenericDocumenyPayload() {
    return {
      documentFile: this.documentFile,
      documentId: this.mandatoryDocumentItem?.id,
      documentExtension: this.extension,
    };
  }

  getDocumentFile({ base64, extension }) {
    this.documentFile = base64;
    this.extension = extension;

    this.genericDocumentValue = this.createGenericDocumenyPayload();

    this.benefit.attachedDocument = { base64: base64, extension: extension };

    if (this.enabledOnChangeEvent)
      this.fileChange.emit(this.genericDocumentValue);
  }

  employeeId;
  benefitDescription: string;
  discountValue: string;
  hiringProcessId;
  benefitsValue$: Observable<any>;
  benefit: any;
  selected: boolean;
  discount: string;

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    // this.getEmployeesHiring();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  setBenefitChoice(wantsBenefit: boolean): void {
    this.benefit.wantTheBenefit = wantsBenefit;
  }

  setChildBenefitChoice(child, wantsBenefit: boolean): void {
    child.requiresCrecheAssistance = wantsBenefit;
  }

  // getEmployeesHiring() {
  //   this.employeesHiringService
  //     .getEmployeesHiring(this.employeeId)
  //     .subscribe((res: any) => {
  //       const id = res.hiringProcessId;
  //       this.hiringProcessId = id;

  //     });
  // }

  getBenefits(benefits: any) {
    if (!benefits) return;
    // this.benefitsService.getBenefits(id).subscribe(
    // (response: any) => {
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.Creche
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;
          this.selected = foundBenefit.wantTheBenefit;
          this.discountValue = foundBenefit.holderDiscount;
          this.discount = foundBenefit.existDiscountToHolder;
          this.listChildren = foundBenefit.listChildren;

          if (foundBenefit.attachedDocument) {
            const { base64, extension } = foundBenefit.attachedDocument;
            this.documentFile = base64;
            this.extension = extension;
          }
          break;
        }
      }
    }
    // },
    // (error) => {
    //   console.error(
    //     "Error fetching benefits:",
    //     error.message || JSON.stringify(error)
    //   );
    // }
    // );
  }

  uploadFile() {
    if (!this.documentFile || !this.mandatoryDocumentItem) return;

    const formData = new FormData();
    formData.append("file", this.documentFile);
    formData.append("documentId", this.mandatoryDocumentItem.id);
    formData.append("extension", this.extension);

    this.benefitsService.uploadDocument(formData).subscribe(
      (response) => {
        console.log("Upload successful", response);
      },
      (error) => {
        console.error("Error during file upload", error);
      }
    );
  }

  downloadFile() {
    const documentName = `document.${this.extension}`;
    this.utilsService.downloadFile(this.documentFile, documentName);
  }

}
