import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {


  baseUrlApi = environment.url;

  constructor(private http: HttpClient) { }

  getDocuments(id) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents`;
    return this.http.get(endpoint);
  }

  postRg(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/rg`
    return this.http.post(endpoint, body)
  }

  postCpf(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/cpf`
    return this.http.post(endpoint, body)
  }

  postCtps(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/ctps`
    return this.http.post(endpoint, body)
  }

  postBirthCertificate(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/birth-certificate`
    return this.http.post(endpoint, body)
  }

  postDocumentVoter(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/document-voter`
    return this.http.post(endpoint, body)
  }

  postAddressProofDocument(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/address-proof`
    return this.http.post(endpoint, body)
  }

  putSchool(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/schoolings/${body?.id}`
    return this.http.put(endpoint, body)
  }

  postSchool(id, body) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/schoolings`
    return this.http.post(endpoint, body)
  }

  deleteSchool(id, scoolingsId) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${id}/documents/schoolings/${scoolingsId}`
    return this.http.delete(endpoint)
  }

postGenericDocument(employeeId: number, payload: any) {
    const endpoint = `${this.baseUrlApi}/v1/employees/${employeeId}/documents/mandatory-documents`
    return this.http.post(endpoint,payload)
  }
}
