import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EnumsService } from "src/app/core/services/enums.service";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-work-card",
  templateUrl: "./work-card.component.html",
  styleUrls: ["./work-card.component.scss"],
})
export class WorkCardComponent implements OnInit, OnChanges {
  @Input() ufs: string[];
  @Input() isExhibition = false;
  @Input() ctps;

  workCardForm: FormGroup;
  pisPasepPattern = /^\d{3}\.\d{5}\.\d{2}-\d{1}$/;
  workCardNumberPattern = /^\d{7}$/;
  seriePattern = /^\d{1,5}$/;

  firsDocumentPage: string;
  firsDocumentPageExtension: string;
  secondAndThirdPageDocument: string;
  secondAndThirdPageDocumentExtension: string;
  lastDocumentPage: string;
  lastDocumentPageExtension: string;
  digitalDocument: string;
  digitalDocumentExtension: string;

  docType = [];

  @Output() formValue: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private enumSrvice: EnumsService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.enumSrvice.getCtpsType().subscribe((res) => (this.docType = res));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.ctps?.currentValue;

    if (data) {
      this.workCardForm?.patchValue(data);

      this.firsDocumentPage = data.firstPage;
      this.firsDocumentPageExtension = data.firstPageExtension;
      this.secondAndThirdPageDocument = data.secondPage;
      this.secondAndThirdPageDocumentExtension = data.secondPageExtension;
      this.lastDocumentPage = data.lastPage;
      this.lastDocumentPageExtension = data.lastPageExtension;
      this.digitalDocument = data.digital;
      this.digitalDocumentExtension = data.digitalExtension;
    }
  }

  buildForm() {
    this.workCardForm = this.fb.group({
      documentType: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
      socialSecurityFinancing: [
        { value: "", disabled: this.isExhibition },
        [Validators.required, Validators.pattern(this.pisPasepPattern)],
      ],
      number: [
        { value: "", disabled: this.isExhibition },
        [Validators.required, Validators.pattern(this.workCardNumberPattern)],
      ],
      serie: [
        { value: "", disabled: this.isExhibition },
        [Validators.required, Validators.pattern(this.seriePattern)],
      ],
      countryId: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
    });
  }

  workCardFormEmit() {
    this.workCardForm.markAllAsTouched();
    if (this.workCardForm.invalid || !this.workCardForm) {
      return;
    }

    const data = {
      ...this.workCardForm.getRawValue(),
      firsDocumentPageExtension: this.firsDocumentPageExtension,
      firsDocumentPage: this.firsDocumentPage,
      secondAndThirdPageDocumentExtension: this.secondAndThirdPageDocumentExtension,
      secondAndThirdPageDocument: this.secondAndThirdPageDocument,
      lastDocumentPage: this.lastDocumentPage,
      lastDocumentPageExtension: this.lastDocumentPageExtension,
      digitalDocument: this.digitalDocument,
      digitalDocumentExtension: this.digitalDocumentExtension,
    };

    this.formValue.emit(data);
  }

  isInvalidField(field: string): boolean | undefined {
    const formField = this.workCardForm.get(field);
    return formField?.invalid && formField?.touched;
  }

  isControlRequiredError(controlName: string) {
    const control = this.workCardForm.controls[controlName];
    return control.errors && control.errors.required && control.touched;
  }

  isControlPatternError(controlName: string) {
    const control = this.workCardForm.controls[controlName];
    return control.errors && control.errors.pattern && control.touched;
  }

  getWalletPdf(event) {
    this.digitalDocument = event.base64;
    this.digitalDocumentExtension = event.extension;
  }

  getPageOne(event) {
    this.firsDocumentPage = event.base64;
    this.firsDocumentPageExtension = event.extension;
  }

  getPageTwoAndThree(event) {
    this.secondAndThirdPageDocument = event.base64;
    this.secondAndThirdPageDocumentExtension = event.extension;
  }

  getLastPage(event) {
    this.lastDocumentPage = event.base64;
    this.lastDocumentPageExtension = event.extension;
  }

  downloadFile() {
    if (this.workCardForm.controls.documentType.value === 1) {
      if (this.digitalDocument && this.digitalDocument !== "null"){
        this.utilsService.downloadFile(this.digitalDocument,`CTPS_DIGITAL.${this.digitalDocumentExtension}`);
      }
    }
    else if (this.workCardForm.controls.documentType.value === 2) {
      if (this.firsDocumentPage && this.firsDocumentPage !== "null") {
        this.utilsService.downloadFile(this.firsDocumentPage,`CTPS_PRIMEIRA_PAGINA.${this.firsDocumentPageExtension}`);
      }
      if (this.secondAndThirdPageDocument && this.secondAndThirdPageDocument !== "null") {
        this.utilsService.downloadFile(this.secondAndThirdPageDocument,`CTPS_SEGUNDA_PAGINA.${this.secondAndThirdPageDocumentExtension}`);
      }
      if (this.lastDocumentPageExtension && this.lastDocumentPageExtension !== "null") {
        this.utilsService.downloadFile(this.lastDocumentPage,`CTPS_ULTIMA_PAGINA.${this.lastDocumentPageExtension}`);
      }
    }
  }
}
