import { Injectable } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (!date) return "";
  
    const { day, month, year } = date;
    return `${this.pad(day)}${this.DELIMITER}${this.pad(month)}${this.DELIMITER}${year}`;
  }
  
  private pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
