import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Categories } from "src/app/core/models/categories.enum";
import { AuthenticationService } from "src/app/core/services/auth.service";

@Component({
  selector: "app-gym-voucher-accordion",
  templateUrl: "./gym-voucher-accordion.component.html",
  styleUrls: ["./gym-voucher-accordion.component.scss"],
})
export class GymVoucherAccordionComponent implements OnInit, OnChanges {
  @Input() benefits;
  planControl = new FormControl();
  isDisabled = true;
  selectedVaMonthly: any;
  selectDisabled = false;

  constructor(private authService: AuthenticationService) {}
  employeeId;
  benefit: any;
  selectedPlan: any;

  ngOnInit(): void {
    this.employeeId = this.authService.currentUser().payload.employeeId;
    this.planControl.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getBenefits(changes?.benefits?.currentValue);
  }

  onOptionSelected(event: any) {
    this.removeSelectedPlan();

    if (!event) return;

    this.selectedPlan.selected = true;
    this.benefit.wantTheBenefit = true;
  }

  removeSelectedPlan() {
    this.benefit.items?.forEach((item) => (item.selected = false));
  }

  getBenefits(benefits?: any) {
    if (!benefits) return;
    const response = benefits;
    if (response.benefitTypes && response.benefitTypes.length > 0) {
      for (const type of response.benefitTypes) {
        const foundBenefit = type.benefitList.find(
          (benefit) => benefit.categoryId === Categories.Academia
        );
        if (foundBenefit) {
          this.benefit = foundBenefit;

          this.selectedPlan = this.benefit.items.find((item) => item.selected);

          break;
        }
      }
    }
  }

  setBenefitChoice(): void {
    this.benefit.items?.forEach((item) => (item.selected = false));

    const plan = this.benefit.items?.find(
      (item) => item.index == this.selectedPlan.index
    );

    plan.selected = this.benefit.wantTheBenefit;
  }
}
