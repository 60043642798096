import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, shareReplay, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { GetCompanyModel } from "../models/get-company.model";
import { GetAllCompanyModel } from "../models/get-all-company.model";
import { PaginationContainer } from "../models/pagination-container.model";
import { MenuService } from "./menu.service";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  companyUrl = `${environment.url}/v1/companies`;
  branchUrl = `${environment.url}/v1/branches`;

  constructor(private http: HttpClient, private menuService: MenuService) {}

  setUpdateMenus(): void {
    this.menuService.getAll();
  }

  getAllCities(countryId, term?: string) {
    let options = {};

    if (!countryId || countryId == undefined) {
      options = { params: { term: term ?? " " } };
    } else {
      options = { params: { term: term ?? " ", countryId: countryId } };
    }

    return this.http.get(`${this.companyUrl}/cities`, options);
  }

  getAllCountries(term?: string) {
    return this.http.get(`${this.companyUrl}/countries`, {
      params: { term: term ?? "" },
    });
  }

  add(company: {
    businessType: any;
    name: any;
    cnpj: any;
    postalCode: any;
    address: any;
    neighborhood: any;
    country: any;
    phoneNumber: any;
    adminName: any;
    cpf: any;
    email: any;
    city: any;
    addressDetails: any;
    syndicate: string;
  }) {
    return this.http.post(this.companyUrl, company).pipe(tap());
  }

  update(payload: {
    id: any;
    businessType: any;
    name: any;
    cnpj: any;
    postalCode: any;
    address: any;
    neighborhood: any;
    country: any;
    phoneNumber: any;
    adminName: any;
    cpf: any;
    email: any;
    city: any;
    addressDetails: any;
    syndicate: string;
  }) {
    return this.http.put(this.companyUrl, payload);
  }

  get(id: number): Observable<GetCompanyModel> {
    return this.http
      .get<GetCompanyModel>(`${this.companyUrl}/${id}`)
      .pipe(shareReplay());
  }

  getSimple(id: number): Observable<GetCompanyModel> {
    return this.http
      .get<GetCompanyModel>(`${this.companyUrl}/${id}/simple`)
      .pipe(shareReplay());
  }

  AddBranch(branch: {
    id: number;
    businessType: string;
    name: string;
    cnpj: string;
    postalCode: string;
    address: string;
    neighborhood: string;
    country: number;
    phoneNumber: string;
    numberAddress: number;
    adminName: string;
    cpf: string;
    email: string;
    city: number;
    addressDetails: string;
  }) {
    return this.http.post(this.branchUrl, branch);
  }

  updateBranch(payload: {
    id: any;
    businessType: any;
    name: any;
    cnpj: any;
    postalCode: any;
    address: any;
    neighborhood: any;
    country: any;
    phoneNumber: any;
    adminName: any;
    cpf: any;
    email: any;
    city: any;
    addressDetails: any;
    syndicate: string;
  }) {
    return this.http.put(this.branchUrl, payload);
  }

  findAddress(value: string): any {
    return this.http.get(`https://viacep.com.br/ws/${value}/json/`);
  }

  getAllBranches(query: any) {
    return this.http.get<PaginationContainer<GetAllCompanyModel>>(
      `${this.branchUrl}`,
      {
        params: { ...query },
      }
    );
  }

  getAll() {
    return this.http.get<GetAllCompanyModel[]>(`${this.branchUrl}/all`);
  }

  delete(id: number) {
    return this.http.delete(`${this.branchUrl}/${id}`);
  }

  disableBranch() {
    return this.http.patch(`${this.companyUrl}/branches/inactive`, {});
  }
  enableBranch() {
    return this.http.patch(`${this.companyUrl}/branches/active`, {});
  }

  existsBranches() {
    return this.http.get(`${this.companyUrl}/branches/exists`);
  }

  disableSalaryRange() {
    return this.http.patch(`${this.companyUrl}/salary-ranges/inactive`, {});
  }

  enableSalaryRange() {
    return this.http.patch(`${this.companyUrl}/salary-ranges/active`, {});
  }

  existsSalaryRange() {
    return this.http.get(`${this.companyUrl}/salary-ranges/exists`);
  }

  existsJobLevel() {
    return this.http.get(`${this.companyUrl}/job-levels/exists`);
  }

  disableJobLevel() {
    return this.http.patch(`${this.companyUrl}/job-levels/inactive`, {});
  }

  enableJobLevel() {
    return this.http.patch(`${this.companyUrl}/job-levels/active`, {});
  }

  getAllCompanies = () => this.http.get(`${this.companyUrl}`);
}
