<ng-select
  #select
  [items]="items"
  [multiple]="true"
  [closeOnSelect]="false"
  [searchable]="true"
  [loading]="loading"
  clearAllText="Limpar"
  bindLabel="{{ bindLabel }}"
  placeholder="{{ placeholder }}"
  [notFoundText]="notFoundText"
  [selectableGroup]="true"
  [selectableGroupAsModel]="false"
  [typeahead]="input"
  [typeToSearchText]="typeToSearchText"
  [minTermLength]="termLength"
  [clearable]="clearable"
  [clearSearchOnAdd]="clearSearchOnAdd"
  [(ngModel)]="selectedItems"
  [virtualScroll]="true"
  [notFoundText]="notFoundText"
  (open)="openDropdown.emit()"
  (close)="closeDropdown.emit()"
  (clear)="clearSelect.emit()"
  (scrollToEnd)="searchScroll(select.searchTerm)"
  (ngModelChange)="modelChange.emit(selectedItems)"
>
  <ng-template ng-option-tmp let-item="item" *ngIf="items">
    <img [src]="item.urlAvatar" width="24px" height="24px" />
    <span>{{ item.name }}</span>
  </ng-template>

  <ng-template ng-footer-tmp let-search="searchTerm" *ngIf="hasFooterBottom">
    <div class="footer-bottom">
      <button
        class="btn btn-outline-primary col-sm-8 text-uppercase"
        (click)="click()"
      >
        {{ textButton }}
      </button>
    </div>
  </ng-template>
</ng-select>
