import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BenefitsService {
  baseUrl = environment.url;

  constructor(private http: HttpClient) {}

  getBenefits(id) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/5/benefits`;
    return this.http.get(endpoint);
  }
  //  esse e o certo.

  getBenefitsValue(id) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/5`;
    return this.http.get(endpoint);
  }
  // esse e o errado - não e pra usar agora.

  PostBenefitsValue(id, body) {
    const endpoint = `${this.baseUrl}/v1/hirings/${id}/steps/5`;
    return this.http.post(endpoint, body);
  }
  // create / edit

  uploadDocument(documentData: FormData) {
    const endpoint = `${this.baseUrl}/v1/documents/uploads`;
    return this.http.post(endpoint, documentData);
  }

  downloadDocument(documentId: string) {
    const endpoint = `${this.baseUrl}/v1/documents/downloads`;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      responseType: "blob" as "json",
    };
    return this.http.post(endpoint, { documentId }, options);
  }
}
