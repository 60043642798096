import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "app-drop-down-menu",
  templateUrl: "./drop-down-menu.component.html",
  styleUrls: ["./drop-down-menu.component.scss"],
})
export class DropDownMenuComponent {
  @Input() menuItems: { id: unknown; title: string; disabled: boolean }[];
  @Input() header: string;

  @Output() selectedMenu: EventEmitter<{ id: unknown; title: string }> =
    new EventEmitter<{ id: unknown; title: string; disabled: boolean }>();

  @ContentChild("items") items!: TemplateRef<any>;

  onSelectItem(item) {
    if (item.disabled) return;

    this.selectedMenu.emit(item);
  }
}
