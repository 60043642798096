<app-accordion class="my-1" [title]="'Assistência Funeral'" *ngIf="benefit">
  <div class="w-100">
    <div class="row mt-3">
      <label class="text-default-label">{{ benefit.alias }}</label>
      <p class="text">{{ benefit.descBenefit }}</p>
    </div>
    <div class="row mt-4">
      <div class="col-md-2 col-12 col-sale">
        <label class="text-sale">Valor</label>
        <span>R$ {{ benefit.items[0].cost }}</span>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-2 col-12 col-sale">
        <label class="text-sale">Desconto do titular</label>
        <span>{{ discount }}</span>
      </div>
      <div class="col-md-6 col-12 col-value">
        <label class="text-valuedesc">Valor ou % do desconto</label>
        <!-- Ajuste para mostrar o desconto formatado corretamente -->
        <!-- <span>R$ {{ discountValue | number : "1.2-2" }} / mês</span> -->
          <ng-container *ngIf="benefit.items[0].holderDiscountType === 2">
            <span>{{ benefit.items[0].holderDiscount }}%</span>
          </ng-container>
          <ng-container *ngIf="benefit.items[0].holderDiscountType === 1">
            <span>{{
              benefit.items[0].holderDiscount | currency : "BRL" : "symbol"
            }}</span>
          </ng-container>
      </div>
      <div class="col-md-4 col-12 col-benefic">
        <label class="text-benefictransp">Deseja aderir ao beneficio</label>
        <div class="custom-checkbox-container">
          <label class="custom-checkbox">
            <input
              type="radio"
              name="choiceOnceFuneral"
              value="nao"
              [checked]="!selected"
              (change)="setBenefitChoice(false)"
            />
            <span class="checkmark">
              <span class="checkmark-inner"></span>
            </span>
            Não
          </label>

          <label class="custom-checkbox">
            <input
              type="radio"
              name="choiceOnceFuneral"
              value="sim"
              [checked]="selected"
              (change)="setBenefitChoice(true)"
            />
            <span class="checkmark">
              <span class="checkmark-inner"></span>
            </span>
            Sim
          </label>
        </div>
      </div>
    </div>
  </div>
</app-accordion>
