import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PaginationContainer } from "../models/pagination-container.model";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class MandatoryDocumentService {
  private url = `${environment.url}/v1/mandatory-documents`;

  constructor(private http: HttpClient) {}
  getPaginated(query): Observable<PaginationContainer<any>> {
    return this.http.get<PaginationContainer<any>>(this.url, {
      params: { ...query },
    });
  }

  getDocumentList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/documents`);
  }

  add(payload: any) {
    return this.http.post(this.url, payload);
  }

  get(id: number): Observable<any> {
    return this.http.get<any>(`${this.url}/${id}`);
  }

  update(payload: any): Observable<any> {
    return this.http.put(this.url, payload);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`);
  }

  getDocuments(queryParams: any) {
    return this.http.get<any>(`${this.url}/match`, { params: { ...queryParams } });
  }
}
