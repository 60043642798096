<div class="accordion">
  <div
    role="tab"
    class="accordion-heading card-header"
    [ngClass]="{ show: isExpanded }"
    (click)="toggleAccordion()"
  >
    <div class="accordion-title d-flex align-items-center position-relative">
      <div role="button" class="accordion-toggle" aria-expanded="false">
        <button
          type="button"
          class="btn btn-link btn-link-accordion text-dark d-flex align-items-center"
        >
          <i [class]="iconTitle"></i>
          <span>{{ title }}</span>

          <ng-content select="[header]"></ng-content>
        </button>
      </div>
      <span accordion-heading="" class="icon-end fs-2">
        <i class="mdi accor-plus-icon" [ngClass]="iconClass"></i>
      </span>
    </div>
  </div>
  <div
    role="tabaccordion"
    class="accordion-collapse collapse"
    aria-hidden="true"
    [ngClass]="{ show: isExpanded }"
    style="overflow: hidden"
  >
    <div class="accordion-body card-block card-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
