import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

export interface IModal {
  title: string;
  text: string;
  labelButton: string;
}
@Component({
  selector: "app-modal-delete",
  templateUrl: "./modal-delete.component.html",
  styleUrls: ["./modal-delete.component.scss"],
})
export class ModalDeleteComponent {
  @Input() public modal: IModal;

  constructor(public activeModal: NgbActiveModal) {}

  closeModal(action: boolean) {
    this.activeModal.close(action);
  }
}
