import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataAccessService {

  constructor(private http: HttpClient) { }

  getDataAccess(employeeId: string): Observable<any> {
    const endpoint = `${environment.url}/v1/hirings/${employeeId}/steps/1`
    return this.http.get(endpoint);
  }

  putDataAccess(id: number, body: any): Observable<any> {
    const endpoint = `${environment.url}/v1/hirings/${id}/steps/1`
    return this.http.put(endpoint, body);
  }

}
