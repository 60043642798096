import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class AesService {
  decryptData(encryptedData: any, iv: string, secretKey: string): string {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const parsedIV = CryptoJS.enc.Utf8.parse(iv);

    if (!encryptedData.data) return;

    const decrypted = CryptoJS.AES.decrypt(encryptedData.data, key, {
      keySize: 128 / 8,
      iv: parsedIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const data = decrypted.toString(CryptoJS.enc.Utf8);
    if (this.isJson(data)) return JSON.parse(data);

    return data;
  }

  encryptData(data: any, iv: string, secretKey: string): string {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const parsedIV = CryptoJS.enc.Utf8.parse(iv);

    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      keySize: 128 / 8,
      iv: parsedIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
  }

  private isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
