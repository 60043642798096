import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ModalOptions } from "../models/modal-options.models";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private showModal: BehaviorSubject<boolean>;
  private modalOptions: BehaviorSubject<ModalOptions>;
  private modalResult: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public notifier = new Subject();

  constructor() {
    this.showModal = new BehaviorSubject<boolean>(false);
    this.modalOptions = new BehaviorSubject<ModalOptions>(new ModalOptions());
    this.modalResult = new BehaviorSubject<boolean>(false);
  }

  setModalData(data: ModalOptions) {
    this.modalOptions.next(data);
  }

  setshowModalStatus(value: boolean): void {
    this.showModal.next(value);
  }

  setsResult(value: boolean): void {
    this.modalResult.next(value);
    this.notifier.next(null);
    this.modalResult.next(false);
  }

  getModalData(): Observable<ModalOptions> {
    return this.modalOptions.asObservable();
  }

  getShowModalStatus(): Observable<boolean> {
    return this.showModal.asObservable();
  }

  getResult(): Observable<boolean> {
    return this.modalResult.asObservable();
  }

  reset() {
    this.modalResult.next(false);
  }
}
