<ng-template #popTitleItem
             let-language="language">
  <ul>
    <li class="p-ul"><strong class="text-strong">{{popoverTitleItem}}</strong></li>
  </ul>
</ng-template>
<ng-template #popContentItem
             let-greeting="greetings">
  <ng-container>
    <ul>
      <li *ngFor="let i of showThisQuantity()">
        <div *ngIf="getNestedProperty(i, propLink) === true">
          <a href="{{valueHrefLink}}">{{i[propShow]}} {{valueLink}}</a>
        </div>
        <div *ngIf="getNestedProperty(i, propLink) === false">
          {{getNestedProperty(i, propShow)}}
        </div>
      </li>
      <li *ngIf="itemList.length > this.environment.maxShowItemsPopover"><a href="{{valueHrefLinkMoreItems}}">+{{(itemList.length - this.environment.maxShowItemsPopover)}}{{(itemList.length - this.environment.maxShowItemsPopover) > 1 ? valueMoreItemsPlural: valueMoreItemsSingle}}</a></li>
    </ul>
  </ng-container>
</ng-template>
<button type="button"
        class="btn btn-outline-secondary mr-2 badge-circle"
        [ngbPopover]="popContentItem"
        [popoverTitle]="popTitleItem"
        triggers="manual"
        #popoverItem="ngbPopover"
        (mouseover)="openPopover(popoverItem)"
        (mouseout)="closePopover(popoverItem)"
        (click)="closeForcePopover(popoverItem)"
        placement="left">{{itemList.length}}</button>