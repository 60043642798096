import { Component, ContentChild, Input, TemplateRef } from "@angular/core";

@Component({
  selector: "app-more-options-button",
  templateUrl: "./more-options-button.component.html",
  styleUrls: ["./more-options-button.component.scss"],
})
export class MoreOptionsButtonComponent {
  @Input() secondBgColor = false;
  @Input() disabled = false;

  @ContentChild("items") items!: TemplateRef<any>;
}
