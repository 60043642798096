import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MenuItem } from "./menu.model";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { MenuService } from "src/app/core/services/menu.service";
import { TenantStore } from "src/app/core/services/Stores/tenant.store";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit {
  @ViewChild("componentRef") scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;

  menuItems: MenuItem[];

  @ViewChild("sideMenu") sideMenu: ElementRef;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public authService: AuthenticationService,
    private menuService: MenuService,
    public tenantStore:TenantStore
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._scrollElement();
      }
    });
  }

  ngOnInit() {
    this._scrollElement();

    this.getMenus();

    this.authService.getCurrentUserUpdates().subscribe(async (refresh) => {
      if (refresh) {
        this.getMenus();
      }
    });
  }

  private getMenus() {
    this.menuService.getAll().subscribe((menu) => {
      this.menuItems = menu;
    });
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle("mm-show");
  }

  toggleActive(item) {
    const deactivate = (menus: MenuItem[]) => {
      menus.forEach((menu) => {
        menu.active = false;
        menu.subItems?.forEach((sub) => (sub.active = false));
        deactivate(menu.subItems)
      });
    };

    deactivate(this.menuItems);

    item.active = true;
  }

  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0]["offsetTop"];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
