import { Component, ContentChild, TemplateRef } from "@angular/core";

@Component({
  selector: "app-info-tooltip",
  templateUrl: "./info-tooltip.component.html",
  styleUrls: ["./info-tooltip.component.scss"],
})
export class InfoTooltipComponent {
  @ContentChild("content") content!: TemplateRef<any>;
}
