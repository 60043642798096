import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EnumsService } from "src/app/core/services/enums.service";
import { UtilsService } from "src/app/core/services/utils.service";

@Component({
  selector: "app-cpf",
  templateUrl: "./cpf.component.html",
  styleUrls: ["./cpf.component.scss"],
})
export class CpfComponent {
  documentType = [];

  formCpf: FormGroup;
  cpfDocument: any;
  cpfDocumentExtension: any;

  @Input() cpfValue;
  @Input() isExhibition = false;
  @Output() formValue: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private enumsService: EnumsService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.enumsService
      .getCpfDocumentType()
      .subscribe((res) => (this.documentType = res));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData(changes);
  }

  loadData(changes: SimpleChanges) {
    const data = changes.cpfValue?.currentValue;

    if (data) {
      this.formCpf?.patchValue({
        documentType: data.type,
      });

      this.cpfDocument = `${data.document}`;
      this.cpfDocumentExtension = `${data.documentExtension}`;
    }
  }

  buildForm() {
    this.formCpf = this.fb.group({
      documentType: [
        { value: "", disabled: this.isExhibition },
        Validators.required,
      ],
    });
  }

  isInvalidField(field: string): boolean | undefined {
    const formField = this.formCpf.get(field);
    return formField?.invalid && formField?.touched;
  }

  getDoc(event) {
    this.cpfDocument = event.base64;
    this.cpfDocumentExtension = event.extension;
  }

  emitValue() {
    this.formCpf.markAllAsTouched();

    if (this.formCpf.invalid || !this.cpfDocument) {
      return;
    }

    const formCpfValue = {
      ...this.formCpf.getRawValue(),
      cpfDocument: this.cpfDocument,
      cpfDocumentExtension: this.cpfDocumentExtension,
    };

    this.formValue.emit(formCpfValue);
  }

  downloadFile() {
    this.utilsService.downloadFile(this.cpfDocument,`cpf.${this.cpfDocumentExtension}`);
  }
}
