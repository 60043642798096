<div class="card-body row">
  <h5 class="text-uppercase my-3">benefícios</h5>

  <!-- <ng-container *ngFor="let item of dependentsBenefits; let i = index"> -->
  <ng-container>
    <app-parking-accordion [benefits]="benefits"></app-parking-accordion>
    <app-mobility-benefit-accordion
      [benefits]="benefits"
    ></app-mobility-benefit-accordion>
    <app-funeral-assistance-accordion
      [benefits]="benefits"
    ></app-funeral-assistance-accordion>
    <app-pharmacy-accordion [benefits]="benefits"> </app-pharmacy-accordion>
    <app-fuel-voucher-accordion
      [benefits]="benefits"
    ></app-fuel-voucher-accordion>
    <app-health-plan-accordion
      [benefits]="benefits"
    ></app-health-plan-accordion>
    <app-meal-and-food-voucher-accordion
      [benefits]="benefits"
    ></app-meal-and-food-voucher-accordion>
    <app-meal-voucher-accordion
      [benefits]="benefits"
    ></app-meal-voucher-accordion>
    <app-food-voucher-accordion
      [benefits]="benefits"
    ></app-food-voucher-accordion>
    <app-gym-voucher-accordion
      [benefits]="benefits"
    ></app-gym-voucher-accordion>
    <app-pac-accordion [benefits]="benefits"></app-pac-accordion>
    <app-refectory-voucher [benefits]="benefits"></app-refectory-voucher>
    <app-life-insurance-accordion
      [benefits]="benefits"
      [disableInputs]="true"
    ></app-life-insurance-accordion>
    <app-daycare-accordion [benefits]="benefits"></app-daycare-accordion>
    <app-dental-plan-accordion
      [benefits]="benefits"
    ></app-dental-plan-accordion>
    <app-voucher-transportation-accordion
      [benefits]="benefits"
      (requestedPublic)="getPublicItinerary($event)"
      (requestedChartered)="getCharteredLines()"
      [lines]="lines"
      [operators]="operators"
      [statesAndCities]="statesAndCities"
      [jobAddress]="matriz"
      [employeeAddress]="employeeAddress"
    ></app-voucher-transportation-accordion>
  </ng-container>
  <div class="col-md-12 col-12 row justify-content-end">
    <div
      class="col-md-4 col-12 mt-3 text-align-end"
      *ngIf="!isExhibition; else profileSaveButton"
    >
      <button
        type="submit"
        class="btn btn-outline-secondary m-1 text-uppercase"
        (click)="logout.emit(benefits)"
      >
        salvar e sair
      </button>
      <button
        class="btn btn-secondary text-uppercase"
        type="button"
        (click)="previous.emit()"
      >
        Cancelar
      </button>
      <button
        type="submit"
        class="btn btn-primary m-1 text-uppercase"
        (click)="saveAndFinish.emit(benefits)"
      >
        Salvar e Finalizar
      </button>
    </div>
    <ng-template #profileSaveButton>
      <div class="col-md-4 col-12 mt-3 text-align-end">
        <button
          type="submit"
          class="btn btn-primary m-1 text-uppercase"
          (click)="saveAndFinish.emit(benefits)"
        >
          Salvar
        </button>
      </div>
    </ng-template>
  </div>
</div>
