import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appCopyText]",
})
export class CopyTextDirective {
 constructor(private el: ElementRef) {}

  @HostListener('click') onClick() {
    const textToCopy = this.el.nativeElement.innerText;

    // Create a new textarea element
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    try {
      // Use the Clipboard API to copy the selected text to the clipboard
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
    } finally {
      // Remove the textarea from the DOM
      document.body.removeChild(textarea);
    }
  }
}
