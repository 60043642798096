<app-accordion [title]="mandatoryDocumentItem?.displayName">
  <div class="d-flex flex-column">
    <app-form-card>
      <div class="row">
        <div class="col-md-12">
          <label class="text-default-label"
            >Carregar Foto
            {{ mandatoryDocumentItem?.required ? "*" : "" }}</label
          >
          <app-upload-file
            (selectFileWithExtension)="getDocumentFile($event)"
            [isExhibition]="isExhibition"
            [selectedImage]="documentFile && 'data:application/' + this.extension + ';base64,' + documentFile"
          >
          </app-upload-file>
        </div>
        <small
          class="text-danger"
          *ngIf="!documentFile && mandatoryDocumentItem?.required"
        >
          Por favor, carregue a foto.
        </small>
      </div>

      <div class="d-flex w-100 justify-content-end align-itens-center py-4">
        <button
          class="btn btn-outline-primary text-uppercase m-1"
          (click)="emitFormGenericDocumentValue()"
          *ngIf="!enabledOnChangeEvent"
        >
          salvar
        </button>
        <button
          class="btn btn-outline-secondary text-uppercase m-1"
          type="button"
          *ngIf="genericDocumentValue"
          (click)="downloadFile()"
        >
          Baixar o documento
        </button>
      </div>
    </app-form-card>
  </div>
</app-accordion>
