<div class="modal-content custom-modal">
  <div class="row">
    <div class="col-12 mt-2" *ngIf="coverages">
      <h4 class="title-t">Detalhes da Cobertura</h4>
      <div *ngFor="let coverage of coverages">
        <p>{{ coverage.name }}</p>
        <div *ngFor="let planCoverageItem of coverage.planCoverage">
          <p>- {{ planCoverageItem.name }}</p>
        </div>
      </div>
    </div>
    <div class="custom-checkbox-container">
      <div class="col-md-12 mt-3 text-align-end justify-content-end">
        <button
          class="btn btn-secondary position-btn"
          type="button"
          (click)="closeModal()"
        >
          VOLTAR
        </button>
      </div>
    </div>
  </div>
</div>
