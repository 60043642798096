import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";
@Component({
  selector: "app-group-matrix",
  templateUrl: "./add-group-matrix.component.html",
  styleUrls: ["./add-group-matrix.component.scss"],
})
export class AddGroupMatrixComponent implements OnInit, ControlValueAccessor {
  public modalOpen = false;
  public toggleMatrix = false;
  public toggleBranches = false;

  @Input() isAdmin = false;
  @Input() arrGroup: any = [];
  @Input() arrMatrix: any = [];
  @Input() arrBranches: any = [];

  isCustom = false;
  comboMatrix: any = [];
  comboBranches: any = [];
  arrDestinations: any[] = [];

  selectedGroup: any;
  selectedMatrix: any;
  selectedBranch: any;

  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter();
  @Output() clickAddNew = new EventEmitter();
  @Output() clickEdit: EventEmitter<void> = new EventEmitter();

  ngOnInit() {
    this.arrGroup = [
      { id: 1, name: "Grupo 1 - Americanas" },
      { id: 2, name: "Grupo 2 - Carrefour" },
      { id: 3, name: "Grupo 3 - Açaí" },
    ];
    this.arrMatrix = [
      { id: 1, name: "Americanas SP", group: 1 },
      { id: 2, name: "Americanas RJ", group: 1 },
      { id: 3, name: "Carrefour SP", group: 2 },
      { id: 4, name: "Carrefour RJ", group: 2 },
      { id: 5, name: "Carrefour PE", group: 2 },
      { id: 6, name: "Açaí Petrolina", group: 3 },
    ];
    this.arrBranches = [
      { id: 1, name: "Americanas SP - Shopping D", matrix: 1 },
      { id: 2, name: "Americanas RJ - Shopping Barra", matrix: 2 },
      { id: 3, name: "Carrefour SP - Cambuci", matrix: 3 },
      { id: 3, name: "Carrefour SP - Ipiranga", matrix: 3 },
      { id: 4, name: "Carrefour RJ - Lapa", matrix: 4 },
      { id: 5, name: "Carrefour PE - Petrolina", matrix: 5 },
    ];
  }
  addNew() {
    this.modalOpen = true;
  }

  listCustom() {
    this.modalOpen = true;
  }

  removeCustom(id: number) {
    this.arrDestinations.splice(id, 1);
  }

  closeModal() {
    this.modalOpen = false;
  }

  apply() {
    this.closeModal();
    const body = {
      group: this.selectedGroup,
      matrix: this.selectedMatrix,
      branches: this.selectedBranch,
    };
    this.arrDestinations.push(body);
    this.clickAddNew.emit(this.arrDestinations);
  }

  groupSelectedEvent(event) {
    this.selectedGroup = event;
    this.toggleBranches = false;
    this.toggleMatrix = false;
    this.selectedMatrix = null;
    this.selectedBranch = null;
    this.comboMatrix = this.arrMatrix.filter(
      (r) => r.group === this.selectedGroup.id
    );
  }

  matrixSelectedEvent(event) {
    this.selectedMatrix = event;
    this.selectedBranch = null;
    this.comboBranches = this.arrBranches.filter(
      (r) => r.matrix === this.selectedMatrix.id
    );
  }

  branchSelectedEvent(event) {
    this.selectedBranch = event;
  }

  writeValue(branches: any): void {
    this.arrBranches
  }
  registerOnChange(fn: any): void {
    throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }
}
